import React ,{useState,useEffect}from 'react'
import {
  Clock,File
} from "react-feather"
import b4 from '../../../../assets/images/user/b4.png'
import { Link } from "react-router-dom"


const ViewAssignmnet = props => {
  useEffect(() => {
    document.title = "Edsys | Assignments Student Details "
  }, [])
  
  const mydata = [
    {
      "id": 1,
      "name": "Video file 01",
      "size": "22.33 Mb",
      "duration": "00:05:04",
      "Pages": "",
      

    },
    {
      "id": 2,
      "name": "Image File 01",
      "size": "22.33 Mb",
      "duration": "",
      "Pages": ""

    },
    {
      "id": 3,
      "name": "Audio File 01",
      "size": "22.33 Mb",
      "duration": "00:05:04",
      "Pages": ""

    },
    {
      "id": 4,
      "name": "PDF File 01",
      "size": "22.33 Mb",
      "duration": "",
      "Pages": "15"

    },
    ,
    {
      "id": 5,
      "name": "Text File 01",
      "size": "22.33 Mb",
      "duration": "",
      "Pages": ""

    }


  ]
  return (
    <div className="col-12">
      <div className="file-content">
        <div className="card br-15">
          <div className="card-body file-manager">
          <h4 class="mt-1 mb-3">Check Answers</h4>
            <ul className="files">
                            {mydata.map(mydata =>
                <li className="file-box" key={mydata}>
                  <div className="file-top">  <i className="icofont icofont-file-avi-mp4"></i></div>
                  <div className="file-bottom">
                    <h6>{mydata.name}</h6>
                    <p className="mb-1">{mydata.size}</p>
                    <p> <b>Duration : </b>{mydata.duration}</p>
                  </div>
                </li>
              )
              }
            </ul>
          </div>
          <div className="card-footer d-flex justify-content-end br-b-15">
            <Link to="/ReviewAssignment">
              <button className="btn btn-primary">REVIEW SECTION</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewAssignmnet
