import React, { useState } from 'react';
import Period from './Period';
import Lecture from './Lecture';
import Weekend from './Weekend';

const TimeTableGridView = props => {
    const { Periods, Lectures } = props.data

    const weekDays = [
        { Id: 0, Name: "Periods" },
        { Id: 1, Name: "Monday" },
        { Id: 2, Name: "Tuesday" },
        { Id: 3, Name: "Wednesday" },
        { Id: 4, Name: "Thursday" },
        { Id: 5, Name: "Friday" },
        { Id: 6, Name: "Saturday" },
        { Id: 7, Name: "Sunday" },
    ]
    // const Periods = [
    //     { id:1, PeriodId: 1, startTime: "09:30 AM", endTime: "10:15 AM", isInterval: false },
    //     { id:2, PeriodId: 2, startTime: "10:15 AM", endTime: "11:00 AM", isInterval: false },
    //     { id:3, PeriodId: 0, startTime: "11:00 AM", endTime: "11:15 AM", isInterval: true },
    //     { id:4, PeriodId: 3, startTime: "11:15 AM", endTime: "12:00 PM", isInterval: false },
    //     { id:5, PeriodId: 4, startTime: "12:00 PM", endTime: "12:45 PM", isInterval: false },
    //     { id:6, PeriodId: 0, startTime: "12:45 PM", endTime: "01:45 PM", isInterval: true },
    //     { id:7, PeriodId: 5, startTime: "01:45 PM", endTime: "02:30 PM", isInterval: false },
    //     { id:8, PeriodId: 6, startTime: "02:30 PM", endTime: "03:15 PM", isInterval: false },
    //     { id:9, PeriodId: 0, startTime: "03:15 PM", endTime: "03:30 PM", isInterval: true },
    //     { id:10, PeriodId: 7, startTime: "03:30 PM", endTime: "04:15 PM", isInterval: false },
    // ]

    const isBeforInterval = (startTime, interval) => {
        // debugger
        var st = Number(startTime.substring(0, startTime.indexOf(" ")).replace(":", ""))
        var et = Number(interval.substring(0, interval.indexOf(" ")).replace(":", ""))
        return st < et

    }
    return (
        <div class="tt-box">
            <div class="tt-row">
                {weekDays ? weekDays.map((day, index) => {
                    return (
                        <div class={"tt-col" + (day.Id !== 0 ? " " + day.Name.toLowerCase() : "")}>
                            <div class="tt-title">
                                <div class="tt-title-inner ">
                                    <div class="title">{day.Name}</div>
                                </div>
                            </div>
                        </div>
                    )
                }) : null
                }
            </div>
            {Periods ? Periods.map((period, i) => {
                // debugger

                if (period.isInterval) {
                    return (
                        <div className="break-row">
                            <span className="break-time">{period.startTime} - Break - {period.endTime}</span>
                        </div>
                    )
                }
                else {
                    var filteredlist = Lectures.filter((lecture) => lecture.Period === period.PeriodId)
                    if (filteredlist.length > 0) {
                        return (
                            <div class="tt-row">
                                <Period data={period} />
                                {filteredlist ? filteredlist.map((lecture, j) => {
                                    var day = weekDays.filter((day) => day.Id === lecture.weekDay)
                                    if (day && day.length > 0) {
                                        return (
                                            <Lecture data={lecture} weekDayName={day[0].Name.toLowerCase()} />
                                        )
                                    }
                                }) : null}
                                <Weekend />
                            </div>
                        )
                    }
                }

            }) : null}
        </div>

    )
};

export default TimeTableGridView;




