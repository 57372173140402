import React, { useState } from "react";
// import SpinnerCmp from "../../components/SpinnerCmp";
import { TabPanel, TabView } from "primereact/tabview";
import { FaCirclePlus } from "react-icons/fa6";
import UserPic from "../../assets/images/dashboard/1.png";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./tabstyles.css";
const Wallets = () => {
  const navig = useNavigate();
  const tabs = ["Last 10 Transactions", "Detailed Transactions"];

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  const listData = [
    {
      id: 1,
      date: "12-Aug-2023",
      upi: "3453453454",
      amt: " $300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 2,
      date: "12-Aug-2023",
      upi: "3453453454",
      amt: "$80",
      simple: "-",
      color: "red",
    },
    {
      id: 3,
      date: "11-Aug-2023",
      upi: "3453453454",
      amt: "$100",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 4,
      date: "09-Aug-2023",
      upi: "3453453454",
      color: "red",
      amt: "$200",
      simple: "-",
      color: "red",
    },
    {
      id: 5,
      date: "09-Aug-2023",
      upi: "3453453454",
      amt: "$300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 6,
      date: "08-Aug-2023",
      upi: "3453453454",
      amt: "$200",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 7,
      date: "08-Aug-2023",
      upi: "3453453454",
      amt: "$450",
      simple: "-",
      color: "red",
    },
    {
      id: 8,
      date: "07-Aug-2023",
      upi: "3453453454",
      amt: "$50",
      simple: "+",
      color: "#72A44B",
    },
  ];

  return (
    <>
      <div className="library announcementnew">
        {/* <!-- Container-fluid starts--> */}
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    padding: "15px",
                    borderRadius: 10,
                  }}
                  className="shbg col-md-12"
                >
                  <div className="col-md-1">
                    <img src={UserPic} alt="" />
                  </div>

                  <div
                    className="col-md-11"
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      margin: "5px",
                    }}
                  >
                    <label className="shfontfam text-white">Zarin</label>
                    <label
                      className="shfontfam text-white"
                      style={{ fontSize: "12px" }}
                    >
                      Zarin
                    </label>
                    <label
                      style={{ color: "#BB9AB5", fontSize: "11px" }}
                      className="shfontfam"
                    >
                      sopr@gmailinator.com
                    </label>
                  </div>
                </div>
                <label
                  style={{
                    marginTop: "30px",
                    fontSize: "16px",
                    fontWeight: "100",
                  }}
                >
                  Balance
                </label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <label style={{ fontSize: "18px", fontWeight: "200" }}>
                    200
                  </label>
                  <FaCirclePlus
                    style={{ height: "30px", width: "30px", color: "#772569" }}
                    onClick={handleShow}
                  />
                </div>
              </div>

              <div className="row card-body">
                <div className="col-md-12">
                  <div className="books-main-wrapper p-4">
                    <div>
                      <div className="tabs">
                        {tabs.map((tab, index) => (
                          <div
                            key={index}
                            className={`tab ${
                              tab === activeTab ? "active" : ""
                            }`}
                            onClick={() => handleClick(tab)}
                          >
                            {tab}
                          </div>
                        ))}
                      </div>
                      <div className="tab-content">
                        {activeTab === "Last 10 Transactions" && (
                          <div>
                            {" "}
                            <div className="my-books">
                              <div className="row">
                                {listData.map((item, index) => (
                                  <div
                                    className="col-md-6 col-lg-4 col-sm-12"
                                    key={index}
                                  >
                                    <div
                                      className="book-card"
                                      style={{
                                        display: "flex",
                                        backgroundColor: "#EEF2FF",
                                        justifyContent: "space-between",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <div>
                                        <p
                                          style={{
                                            color: "#241549",
                                            fontSize: "14px",
                                          }}
                                        >
                                          {item.date}
                                        </p>
                                        <p
                                          style={{
                                            color: "#857E84",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item.upi}
                                        </p>
                                      </div>

                                      <div
                                        style={{
                                          flexDirection: "row",
                                          display: "flex",
                                        }}
                                      >
                                        <p
                                          style={{
                                            color: item.color,
                                            fontSize: "16px",
                                          }}
                                        >
                                          {item.simple}
                                        </p>
                                        <p
                                          style={{
                                            color: "#1E0A1B",
                                            fontSize: "16px",
                                          }}
                                        >
                                          {item.amt}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                        {activeTab === "Detailed Transactions" && (
                          <div>
                            {" "}
                            <div className="my-books">
                              <div className="row">
                                <div className="col-md-12">
                                  <p>
                                    For which period do you need a statement?
                                  </p>
                                </div>
                                <div class="row">
                                  <div class="col-6">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="exampleRadios1"
                                        value="option1"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="exampleRadios1"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "200",
                                        }}
                                      >
                                        Last Month
                                      </label>
                                    </div>
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="exampleRadios2"
                                        value="option2"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="exampleRadios2"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "200",
                                        }}
                                      >
                                        Last 6 months
                                      </label>
                                    </div>
                                  </div>
                                  <div class="col-6">
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="exampleRadios3"
                                        value="option3"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="exampleRadios3"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "200",
                                        }}
                                      >
                                        Last 3 Months
                                      </label>
                                    </div>
                                    <div class="form-check">
                                      <input
                                        class="form-check-input"
                                        type="radio"
                                        name="exampleRadios"
                                        id="exampleRadios4"
                                        value="option4"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="exampleRadios4"
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: "200",
                                        }}
                                      >
                                        Last Year
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <p>Or select a custom date of your choice</p>
                                <div className="row">
                                  <div className="form-group col-md-6">
                                    <label
                                      for="task-title"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "200",
                                      }}
                                    >
                                      From
                                    </label>
                                    <input
                                      className="datepicker-here form-control digits"
                                      type="date"
                                      data-language="en"
                                      placeholder="MM-DD-YYYY"
                                      data-position="top left"
                                    />
                                  </div>
                                  <div className="form-group col-md-6">
                                    <label
                                      for="task-title"
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "200",
                                      }}
                                    >
                                      To
                                    </label>
                                    <input
                                      className="datepicker-here form-control digits"
                                      type="date"
                                      data-language="en"
                                      placeholder="MM-DD-YYYY"
                                      data-position="top left"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-12 btnshpro"
                                onClick={() => navig("/TransactionHis")}
                              >
                                PROCEED
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <TabView>
                      <TabPanel header="Last 10 Transactions">
                        <div className="my-books">
                          <div className="row">
                            {listData.map((item, index) => (
                              <div
                                className="col-md-6 col-lg-4 col-sm-12"
                                key={index}
                              >
                                <div
                                  className="book-card"
                                  style={{
                                    display: "flex",
                                    backgroundColor: "#EEF2FF",
                                    justifyContent: "space-between",
                                    flexDirection: "row",
                                  }}
                                >
                                  <div>
                                    <p
                                      style={{
                                        color: "#241549",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {item.date}
                                    </p>
                                    <p
                                      style={{
                                        color: "#857E84",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {item.upi}
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      flexDirection: "row",
                                      display: "flex",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: item.color,
                                        fontSize: "16px",
                                      }}
                                    >
                                      {item.simple}
                                    </p>
                                    <p
                                      style={{
                                        color: "#1E0A1B",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {item.amt}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel header="Detailed Transactions">
                        <div className="my-books">
                          <div className="row">
                            <div className="col-md-12">
                              <p>For which period do you need a statement?</p>
                            </div>
                            <div class="row">
                              <div class="col-6">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios1"
                                    value="option1"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios1"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "200",
                                    }}
                                  >
                                    Last Month
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios2"
                                    value="option2"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios2"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "200",
                                    }}
                                  >
                                    Last 6 months
                                  </label>
                                </div>
                              </div>
                              <div class="col-6">
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios3"
                                    value="option3"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios3"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "200",
                                    }}
                                  >
                                    Last 3 Months
                                  </label>
                                </div>
                                <div class="form-check">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="exampleRadios"
                                    id="exampleRadios4"
                                    value="option4"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="exampleRadios4"
                                    style={{
                                      fontSize: "14px",
                                      fontWeight: "200",
                                    }}
                                  >
                                    Last Year
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-3">
                            <p>Or select a custom date of your choice</p>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <label
                                  for="task-title"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "200",
                                  }}
                                >
                                  From
                                </label>
                                <input
                                  className="datepicker-here form-control digits"
                                  type="date"
                                  data-language="en"
                                  placeholder="MM-DD-YYYY"
                                  data-position="top left"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label
                                  for="task-title"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "200",
                                  }}
                                >
                                  To
                                </label>
                                <input
                                  className="datepicker-here form-control digits"
                                  type="date"
                                  data-language="en"
                                  placeholder="MM-DD-YYYY"
                                  data-position="top left"
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            className="col-12 btnshpro"
                            onClick={() => navig("/TransactionHis")}
                          >
                            PROCEED
                          </div>
                        </div>
                      </TabPanel>
                    </TabView> */}
                  </div>
                </div>
              </div>
            </div>
            <Modal
              show={show}
              onHide={handleClose}
              size="md"
              centered
              aria-labelledby="contained-modal-title-vcenter"
              // className="common-filter-modal"
              style={{
                display: "flex",
                position: "absolute",
                width: "100%",
              }}
            >
              <div style={{ padding: "20px" }}>
                <p className="AddBalance">Add Balance</p>
                <p
                  style={{
                    color: "#857E84",
                    fontSize: "12px",
                  }}
                >
                  Amount
                </p>
                <input
                  type="text"
                  className="form-control"
                  aria-describedby="basic-addon1"
                ></input>
                <div className="col-12 btnshpro">PROCEED</div>
              </div>
            </Modal>
          </div>
        </div>

        {/* <!-- Container-fluid Ends--> */}
      </div>
    </>
  );
};

export default Wallets;
