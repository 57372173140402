import React from "react";

const TransactionHis = () => {
  const listData = [
    {
      id: 1,
      date: "12-Aug-2023",
      upi: " UPI/3453453454/UPI",
      amt: " $300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 2,
      date: "12-Aug-2023",
      upi: " UPI/3453453454/UPI",
      amt: "$80",
      simple: "-",
      color: "red",
    },
    {
      id: 3,
      date: "11-Aug-2023",
      upi: " UPI/3453453454/UPI",
      amt: "$100",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 4,
      date: "09-Aug-2023",
      upi: " UPI/3453453454/UPI",
      color: "red",
      amt: "$200",
      simple: "-",
      color: "red",
    },
    {
      id: 5,
      date: "09-Aug-2023",
      upi: " UPI/3453453454/UPI",
      amt: "$300",
      simple: "+",
      color: "#72A44B",
    },
    {
      id: 6,
      date: "08-Aug-2023",
      upi: " UPI/3453453454/UPI",
      amt: "$300",
      simple: "+",
      color: "#72A44B",
    },
  ];
  return (
    <>
      <div className="library announcementnew">
        {/* <!-- Container-fluid starts--> */}
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <h4 className="p-4"> Transaction history </h4>
              {/* <div className="card-header pb-0">
                <p style={{ color: "#857E84" }}></p>
              </div> */}

              <div className="row card-body">
                <div className="col-md-12">
                  <div className="books-main-wrapper">
                    <div className="my-books">
                      <div className="row">
                        {listData.map((item, index) => (
                          <div
                            className="col-md-6 col-lg-4 col-sm-12"
                            key={index}
                          >
                            <div
                              className="book-card"
                              style={{
                                display: "flex",
                                backgroundColor: "#EEF2FF",
                                justifyContent: "space-between",
                                flexDirection: "row",
                                marginLeft: "20px",
                                marginRight: "20px",
                              }}
                            >
                              <div>
                                <p
                                  style={{
                                    color: "#241549",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.date}
                                </p>
                                <p
                                  style={{
                                    color: "#857E84",
                                    fontSize: "12px",
                                  }}
                                >
                                  {item.upi}
                                </p>
                              </div>

                              <div
                                style={{
                                  flexDirection: "row",
                                  display: "flex",
                                }}
                              >
                                <p
                                  style={{
                                    color: item.color,
                                    fontSize: "16px",
                                  }}
                                >
                                  {item.simple}
                                </p>
                                <p
                                  style={{
                                    color: "#1E0A1B",
                                    fontSize: "16px",
                                  }}
                                >
                                  {item.amt}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Container-fluid Ends--> */}
      </div>
    </>
  );
};

export default TransactionHis;
