// import Header from '../src/components/Header'
import Layout from "../src/screens/Layout";
import Assignment from "./screens/academics/Assignment";
//import LoginTwo from './screens/login/LoginTwo';

function App() {
  return (
    <div className="App page-wrapper compact-wrapper" id="pageWrapper">
      {/* {/* <Header />  */}

      <Layout />
    </div>
  );
}

export default App;
