import React, { useState, useEffect } from "react";
import AssignmentCardData from "../../../api/assignment_card_data.json";
import subjecticon from "../../../assets/images/assignment/subtopicicon.svg";
import { Link } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import AssignmentModal from "../Assignment/AssignmentModal";
import { Edit3, Trash2, PlusCircle } from "react-feather";
import { Modal, Button } from "react-bootstrap";
import { RiDeleteBin6Fill } from "react-icons/ri";
import "../../Wallet/tabstyles.css";
const Assignment = () => {
  const [isTodoModelShow, setTodoModelShow] = useState(false);
  const [isTagModelShow, setTagModelShow] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.title = "Edsysy | Assignment";
  }, []);

  const setInitialState = () => {
    setTodoModelShow(false);
    setTagModelShow(false);
  };

  const openCloseTodoModel = () => {
    setInitialState();
    setTodoModelShow(!isTodoModelShow);
  };

  const openCloseTagModel = () => {
    setInitialState();
    setTagModelShow(!isTagModelShow);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tabs = ["Pending", "Upcoming", "History"];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="library announcementnew">
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h4 className="flex-grow-1">Assignment</h4>
                  <a
                    type="button"
                    onClick={openCloseTodoModel}
                    className="btn btn-edsys-primary btn1"
                  >
                    <PlusCircle className="me-2" />
                    <span>Create New Assignment</span>
                  </a>
                  {isTodoModelShow ? (
                    <AssignmentModal onClose={openCloseTodoModel} />
                  ) : null}
                </div>
              </div>

              <div className="row card-body">
                <div className="col-md-12">
                  <div className="books-main-wrapper p-4">
                    <div>
                      <div className="tabs">
                        {tabs.map((tab, index) => (
                          <div
                            key={index}
                            className={`tab ${
                              tab === activeTab ? "active" : ""
                            }`}
                            onClick={() => handleClick(tab)}
                          >
                            {tab}
                          </div>
                        ))}
                      </div>
                      <div className="tab-content">
                        {activeTab === "Pending" && (
                          <div className="my-books">
                            <div className="row">
                              {AssignmentCardData.map((postDetail, index) => (
                                <div className="col-md-6" key={index}>
                                  <div className={postDetail.card_bgcolor}>
                                    <div className="card-header">
                                      <div className="d-flex">
                                        <img
                                          className="flex-grow-0"
                                          src={subjecticon}
                                          alt=""
                                        />
                                        <h6 className="flex-grow-1">
                                          {postDetail.subject}
                                        </h6>
                                        <div className="class">
                                          {postDetail.division_grade}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      // onClick={() =>
                                      //   (window.location.href =
                                      //     "assignment-detail.html")
                                      // }
                                      className="card-body"
                                    >
                                      <Link to="/AssignmentDetails">
                                        {postDetail.assignment_title}
                                      </Link>
                                    </div>
                                    <div className="card-footer">
                                      <div className="d-sm-flex">
                                        <h5>{postDetail.date}</h5>
                                        <div className="action-list">
                                          <ul className="list-unstyled">
                                            <li>
                                              <div
                                                onClick={openCloseTodoModel}
                                                className="setting-secondary"
                                              >
                                                <Edit3 />
                                              </div>
                                            </li>
                                            <li>
                                              <div
                                                onClick={handleShow}
                                                className="setting-secondary"
                                              >
                                                <Trash2 />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {activeTab === "Upcoming" && (
                          <div className="my-books">
                            <div className="row">
                              {AssignmentCardData.map((postDetail, index) => (
                                <div className="col-md-6" key={index}>
                                  <div className={postDetail.card_bgcolor}>
                                    <div className="card-header">
                                      <div className="d-flex">
                                        <img
                                          className="flex-grow-0"
                                          src={subjecticon}
                                          alt=""
                                        />
                                        <h6 className="flex-grow-1">
                                          {postDetail.subject}
                                        </h6>
                                        <div className="class">
                                          {postDetail.division_grade}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      // onClick={() =>
                                      //   (window.location.href =
                                      //     "assignment-detail.html")
                                      // }
                                      className="card-body"
                                    >
                                      <Link to="/AssignmentDetails">
                                        {postDetail.assignment_title}
                                      </Link>
                                    </div>
                                    <div className="card-footer">
                                      <div className="d-sm-flex">
                                        <h5>{postDetail.date}</h5>
                                        <div className="action-list">
                                          <ul className="list-unstyled">
                                            <li>
                                              <div
                                                onClick={openCloseTodoModel}
                                                className="setting-secondary"
                                              >
                                                <Edit3 />
                                              </div>
                                            </li>
                                            <li>
                                              <div
                                                onClick={handleShow}
                                                className="setting-secondary"
                                              >
                                                <Trash2 />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {activeTab === "History" && (
                          <div className="my-books">
                            <div className="row">
                              {AssignmentCardData.map((postDetail, index) => (
                                <div className="col-md-6" key={index}>
                                  <div className={postDetail.card_bgcolor}>
                                    <div className="card-header">
                                      <div className="d-flex">
                                        <img
                                          className="flex-grow-0"
                                          src={subjecticon}
                                          alt=""
                                        />
                                        <h6 className="flex-grow-1">
                                          {postDetail.subject}
                                        </h6>
                                        <div className="class">
                                          {postDetail.division_grade}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      // onClick={() =>
                                      //   (window.location.href =
                                      //     "assignment-detail.html")
                                      // }
                                      className="card-body"
                                    >
                                      <Link to="/AssignmentDetails">
                                        {postDetail.assignment_title}
                                      </Link>
                                    </div>
                                    <div className="card-footer">
                                      <div className="d-sm-flex">
                                        <h5>{postDetail.date}</h5>
                                        <div className="action-list">
                                          <ul className="list-unstyled">
                                            <li>
                                              <div
                                                onClick={openCloseTodoModel}
                                                className="setting-secondary"
                                              >
                                                <Edit3 />
                                              </div>
                                            </li>
                                            <li>
                                              <div
                                                className="setting-secondary"
                                                onClick={handleShow}
                                              >
                                                <Trash2 />
                                              </div>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter border-bottom">
                        Delete
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="delete-text-wrapper">
                        Are you sure you want to delete ?
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="delete-btn-wrapper border-top w-100">
                        <button onClick={handleClose} className="dlt-btn-1">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="dlt-btn-2"
                          value="delete"
                        >
                          Delete
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* <Modal
        // show={modalShow2}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
              Cancel
            </button>
            <button type="submit" className="dlt-btn-2" value="delete">
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Assignment;
