import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import book_1 from "../../assets/images/Feed/book_1.svg";
import book_2 from "../../assets/images/Feed/book_2.svg";
import book_3 from "../../assets/images/Feed/book_3.svg";
import book_4 from "../../assets/images/Feed/book_4.svg";
import book_5 from "../../assets/images/Feed/book_5.svg";
import book_6 from "../../assets/images/Feed/book_6.svg";
import book_7 from "../../assets/images/Feed/book_7.svg";
import book_8 from "../../assets/images/Feed/book_8.svg";
import book_9 from "../../assets/images/Feed/book_9.svg";
import search_icon from "../../assets/images/Feed/search_icon.svg";

function Library() {
  const [q, setQ] = useState("");
  const [searchParam] = useState(["title"]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const tabs = ["My Books", "Book History"];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  const books = [
    {
      title: "Mathematics Class 9",
      img: book_1,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "All in One Mathematics",
      img: book_2,
      statusClass1: "date-gray",
      statusClass2: "date-red",
      fineClass: "red",
      fine: "Fine : Rs 100",
    },
    {
      title: "Sura's Mathematics",
      img: book_3,
      statusClass1: "date-gray",
      statusClass2: "date-red",
      fineClass: "red",
      fine: "Fine : Rs 100",
    },
    {
      title: "Smart English",
      img: book_4,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Kumarbharti Digest",
      img: book_5,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "My English Navneet",
      img: book_6,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "History of Goa",
      img: book_7,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Science and Technology",
      img: book_8,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Kumarbharti Class 9",
      img: book_9,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
  ];
  function search(books) {
    return books.filter((items) => {
      return searchParam.some((newItem) => {
        return (
          items[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
  if (error) {
    return <p>{error.message}</p>;
  } else {
    return (
      <div>
        <div class="library announcementnew">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="announcement-top">
                  <h4>Library</h4>
                  <div className="search-wrapper">
                    <input
                      type="text"
                      placeholder="Search Book"
                      name="search-form"
                      id="search-form"
                      className="search-input"
                      value={q}
                      onChange={(e) => setQ(e.target.value)}
                    />
                    <img src={search_icon} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <div>
                    <div className="tabs">
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className={`tab ${tab === activeTab ? "active" : ""}`}
                          onClick={() => handleClick(tab)}
                        >
                          {tab}
                        </div>
                      ))}
                    </div>

                    <div className="tab-content">
                      {activeTab === "My Books" && (
                        <div className="my-books">
                          {q === "" ? (
                            ""
                          ) : (
                            <p className="search-result">Search Results</p>
                          )}
                          <div className="row">
                            {search(books).map((item) => (
                              <>
                                <div className="col-md-6 col-lg-4 col-sm-12">
                                  <div className="book-card">
                                    <div className="row">
                                      <div
                                        className="col-md-4"
                                        // style={{ background: "pink" }}
                                      >
                                        <div className="bc-img-box">
                                          <img src={item.img} alt="" />
                                        </div>
                                      </div>
                                      <div
                                        className="col-md-8"
                                        style={{ marginLeft: "-5px" }}
                                      >
                                        <p className="bc-title">{item.title}</p>
                                        <p className="bc-author">
                                          By Manjit Singh
                                        </p>
                                        <p className="date-green"></p>
                                        <p className="date-green">
                                          Available{" "}
                                          <span className="available">
                                            2/24
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          {q === "" ? (
                            ""
                          ) : (
                            <div className="row">
                              <p className="recommended">Recommended Books</p>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_4} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_5} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-10">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_6} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}

                      {activeTab === "Book History" && (
                        <div className="my-books">
                          <div className="row">
                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_1} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_2} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023{" "}
                                      <span className="red">Fine : Rs 100</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_3} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023{" "}
                                      <span className="red">Fine : Rs 100</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_4} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_5} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_6} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_7} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_8} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6 col-lg-4 col-sm-12">
                              <div className="book-card">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="bc-img-box">
                                      <img src={book_9} alt="" />
                                    </div>
                                  </div>
                                  <div className="col-md-8">
                                    <p className="bc-title">
                                      ICSE Mathematics 9th
                                    </p>
                                    <p className="bc-author">By Manjit Singh</p>
                                    <p className="date-gray">
                                      Issued Date 23/02/2023
                                    </p>
                                    <p className="date-gray">
                                      Due Date 23/02/2023
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Library;
