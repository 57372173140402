import React, { Component } from 'react'

const SyllabusModal = props =>{
    const displayStyle={
        display:"block"
      }

        return (
            <div className="modal fade modal-bookmark show testingll" style={displayStyle} id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div className="modal-dialog modal-md" role="document">
                    <div className="modal-content">

                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Create Syllabus</h5>
                        <button className="btn-close" onClick={props.onClose} type="button" onClick={props.onClose} data-bs-dismiss="modal" aria-label="Close">                                                 </button>
                      </div>


                      <div className="modal-body datetime-picker modal-scroll">
                        <form className="form-bookmark needs-validation" id="bookmark-form" novalidate="">
                          <div className="form-row">
                            <div className="form-group col-md-12">
                              <label for="task-title">Syllabus Name</label>
                              <input className="form-control " id="task-title" type="text" required="" autocomplete="off" />
                            </div>
                            <div className="form-group col-md-12">
                              <label for="task-title">Select Class</label>
                              <select className="js-example-basic-single form-select">
                                <option value="task">5th A</option>
                                <option value="task">5th B</option>
                                <option value="task">5th C</option>
                              </select>
                            </div>
                            <div className="form-group col-md-12">
                              <label for="task-title">Select Subject</label>
                              <select className="js-example-basic-single form-select">
                                <option value="task">English</option>
                                <option value="task">Maths</option>
                                <option value="task">Physics</option>
                              </select>
                            </div>
                            <div className="form-group col-md-12">
                              <label for="task-title">Syllabus Language</label>
                              <select className="js-example-basic-single form-select">
                                <option value="task">English</option>
                                <option value="task">Arabic</option>
                                <option value="task">Urdu</option>
                              </select>
                            </div>
                          </div>
                        </form>
                      </div>


                      <div className="modal-footer">
                        <button className="btn btn-primary" onClick={props.onClose} type="button" data-bs-dismiss="modal">Cancel</button>
                        <button className="btn btn-secondary" id="Bookmark" onclick="submitBookMark()" type="submit">Create</button>

                      </div>

                    </div>
                  </div>
                </div>
        )
    }

export default SyllabusModal
