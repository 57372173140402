import React from "react";

function Footer() {
  return (
    // footer start
    <footer className="footer" id="the_footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 footer-copyright">
            <p className="mb-0">
              Copyright 2021-22 © edsys All rights reserved.
            </p>
          </div>
          <div className="col-md-6">
            <p className="pull-right mb-0 ftr-contact">
              <a href="#">Contact Us</a>
              <a href="#">Terms & Conditions</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
    // footer start end
  );
}

export default Footer;
