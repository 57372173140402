import React, { useEffect, useState } from "react";
import active_like_btn from "../../assets/images/MyDiary/active_like_btn.svg";
import like_btn from "../../assets/images/Fourms/like_btn.svg";
import part_1 from "../../assets/images/MyDiary/part_1.svg";
import part_2 from "../../assets/images/MyDiary/part_2.svg";
import part_3 from "../../assets/images/MyDiary/part_3.svg";
import part_4 from "../../assets/images/MyDiary/part_4.svg";
import com_send_btn from "../../assets/images/MyDiary/com_send_btn.svg";
import reply_icon from "../../assets/images/MyDiary/reply_icon.svg";
import filter_icon from "../../assets/images/Fourms/filter_icon.svg";
import { Link } from "react-router-dom";
import forum_img_3 from "../../assets/images/forum_img_3.svg";
import main_img from "../../assets/images/main_img.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import dislike_btn from "../../assets/images/Fourms/dislike_btn.svg";
import com_icon from "../../assets/images/Fourms/com_icon.svg";
import add_btn from "../../assets/images/Fourms/add_btn.svg";

function ForumView() {
  const [isShown, setIsShown] = useState(false);
  const [validated, setValidated] = useState(false);

  const [showreply, setShowReply] = useState(false);
  const handleshow = () => setShowReply(!showreply);

  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  return (
    <>
      <div class="Note_view Forum">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div className="announcement-top">
                <label>Forums</label>
                <div className="top-btn-wrapper">
                  <button onClick={() => setModalShow(true)}>
                    <img src={add_btn} alt="" />
                    Create Forum
                  </button>
                  <img src={filter_icon} alt="" onClick={handleShow} />
                </div>
              </div>
              <div className="class-text-wrapper">
                <div className="forum-header">
                  <div className="forum-img">
                    <img src={forum_img_3} alt="" />
                  </div>
                  <div className="forum-title-wrapper">
                    <p className="ft-main-title">Lorem Ipsum dolor</p>
                    <p className="ft-sub-title">Gathering</p>
                    <p className="ft-date">12 JUN 2018</p>
                  </div>
                </div>
              </div>
              <div className="test-desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation.Ut enim ad minim
                veniam, quis nostrud exercitation. Lorem ipsum dolor sit amet,
                consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                nostrud exercitation.Ut enim ad minim veniam, quis nostrud
                exercitation. Lorem ipsum dolor sit amet, consectetur adipiscing
                elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation.Ut
                enim ad minim veniam, quis nostrud exercitation.
              </div>
              <div className="forum-img-wrapper">
                <img src={main_img} alt="" className="img-fluid" />
              </div>
              <div className="forum-footer border-bottom">
                <div className="like-btn">
                  <img src={like_btn} alt="" />
                  <span>10</span>
                </div>
                <div className="dislike-btn">
                  <img src={dislike_btn} alt="" />
                  <span>5</span>
                </div>
                <div className="comments-btn">
                  <img src={com_icon} alt="" />
                  <span>18</span>
                </div>
              </div>
              <div className="comments-wrapper">
                <p className="title">Comments</p>
                <div className="single-comment com-border-bottom">
                  <div className="com-img-box">
                    <img src={part_3} alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="com-heading-wrapper">
                      <p className="title">Parent Name</p>
                      <div className="time-and-like">
                        <div className="time">Mon 21, 2023 0:00 AM</div>
                        <div className="like">
                          <img src={like_btn} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="comment-text">
                      <p>
                        The Parent Comment Will be shown here…Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud.
                      </p>
                    </div>
                    <div className="reply-btn-wrapper" onClick={handleshow}>
                      <img src={reply_icon} alt="" />
                      <span>Reply</span>
                    </div>
                    {showreply ? (
                      <>
                        <Form>
                          <Form.Group
                            className="mb-3 mt-2"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Type Your Comment Here..."
                            />
                            <Form.Text className="text-muted">
                              {/* We'll never share your email with anyone else. */}
                            </Form.Text>
                          </Form.Group>
                        </Form>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="single-comment mt-3">
                  <div className="com-img-box">
                    <img src={part_1} alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="com-heading-wrapper">
                      <p className="title">Parent Name</p>
                      <div className="time-and-like">
                        <div className="time">Mon 21, 2023 0:00 AM</div>
                        <div className="like">
                          <img src={like_btn} alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="comment-text">
                      <p>
                        The Parent Comment Will be shown here…Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud.
                      </p>
                    </div>
                    <div className="reply-btn-wrapper">
                      <img src={reply_icon} alt="" />
                      <span>Reply</span>
                    </div>

                    <div className="teachers-reply">
                      <div className="single-comment mt-3">
                        <div className="com-img-box">
                          <img src={part_2} alt="" />
                        </div>
                        <div className="text-wrapper">
                          <div className="com-heading-wrapper">
                            <p className="title">Parent Name</p>
                            <div className="time-and-like">
                              <div className="time">Mon 21, 2023 0:00 AM</div>
                              <div className="like">
                                <img src={like_btn} alt="" />
                              </div>
                            </div>
                          </div>
                          <div className="comment-text">
                            <p>The Teacher's Comment Will be shown here</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="main-comment-input-wrapper">
          <Form>
            <Form.Group className="" controlId="formBasicEmail">
              <Form.Control type="text" placeholder="Write Comment..." />
              <img src={com_send_btn} alt="" />
            </Form.Group>
          </Form>
        </div>
      </div>

      {/* Filter Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>Filter By</p>
              <p>Clear</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="select-wrapper">
            <ul>
              <li>
                <input type="radio" id="f-option" name="selector" />
                <label for="f-option">Most Recent</label>

                <div class="check"></div>
              </li>

              <li>
                <input type="radio" id="s-option" name="selector" />
                <label for="s-option">New</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>

              <li>
                <input type="radio" id="t-option" name="selector" />
                <label for="t-option">Most Liked</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
              <li>
                <input type="radio" id="i-option" name="selector" />
                <label for="i-option">Most Commented</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
              <li>
                <input type="radio" id="j-option" name="selector" />
                <label for="j-option">Most Shared</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>
      {/* Filter Modal End */}

      {/* Create Forum Modal Start */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Create Forum
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Title Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Category</Form.Label>
                    <Form.Select>
                      <option> Select Subject </option>
                      <option> English</option>
                      <option> Mathematics</option>
                      <option> Geography</option>
                      <option> Civics</option>
                      <option> Science</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please choose a subject
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Sub-Type</Form.Label>
                    <Form.Control type="text" placeholder=""></Form.Control>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Forum Text</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Type Your Text Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Recipients</Form.Label>
                    <Form.Select>
                      <option> Recipients </option>
                      <option> Recipients 2</option>
                      <option> Recipients 3</option>
                      <option> Recipients 4</option>
                      <option> Recipients 5</option>
                      <option> Recipients 6</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="col-md-6">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>Attachments</Form.Label>
                    <Form.Control
                      type="file"
                      placeholder="Type Your Text Here..."
                    ></Form.Control>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            <button onClick={() => setModalShow(false)} className="cx-btn-1">
              Cancel
            </button>
            <button
              type="submit"
              className="cx-btn-2"
              form="myForm"
              value="Update"
            >
              Publish
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Create Forum Modal End */}
    </>
  );
}
export default ForumView;
