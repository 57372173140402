import React, { useEffect } from "react";
import { Video, FileText, Image, Headphones } from "react-feather";
import announcement_data from "../../api/announcement_data.json";

function Announcement() {
  useEffect(() => {
    document.title = "Edsys | Announcement ";
  }, []);
  return (
    <div>
      {/* Container-fluid starts */}
      <div class="row announcement">
        <h4>Announcement</h4>
        {announcement_data.map((postDetail, index) => {
          return (
            <div class="col-md-4">
              <div class="card br-15">
                <div class="card-body">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0">
                      <div class="ico-n">
                        {/* <img src={postDetail.icon_set} /> */}
                        <Image />
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      {postDetail.announcement_description}
                    </div>
                  </div>
                  <div class="date-footer">{postDetail.date}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* Container-fluid Ends */}
    </div>
  );
}

export default Announcement;
