import React, { useEffect } from "react";
import Punch from "../myattendance/Punch";
import PunchDetails from "../myattendance/PunchDetails";
import { Link } from "react-router-dom";

function MyAttendance() {
  useEffect(() => {
    document.title = "Edsys | ndance ";
  }, []);
  return (
    <div>
      {/* Container-fluid starts */}
      <div class="row my-attendance">
        <div class="col-md-12 text-end">
          <Link to="/EmpLeaves" className="btn-apply-leave">
            Apply Leave
          </Link>
        </div>
        <div class="col-md-12" style={{ marginTop: 20 }}>
          <div class="card profile-greeting">
            <div class="confetti">
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
              <div class="confetti-piece"></div>
            </div>
            <div class="card-body">
              <div class="row edsys-check-in">
                <Punch />
                <PunchDetails />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Container-fluid Ends  */}
    </div>
  );
}

export default MyAttendance;
