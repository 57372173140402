import React, { useState, useEffect } from "react";

import PushNotificationCardData from "../../../api/PushNotification_card_data.json";

import PushNotificationModel from "./PushNotificationModel";
import { Edit3, Trash2, PlusCircle } from "react-feather";

import dashboardAvatar from "../../../assets/images/dashboard/1.png";

import { Link } from "react-router-dom";

const PushNotification = () => {
  const [isTodoModelShow, setIsTodoModelShow] = useState(false);

  const openCloseTodoModel = () => {
    // setInitialState();
    setIsTodoModelShow(!isTodoModelShow);
  };

  return (
    <div className="">
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Push Notification</h5>
                <a
                  type="button"
                  data-bs-toggle="modal"
                  onClick={openCloseTodoModel}
                  data-bs-target="#exampleModal"
                  className="btn btn-edsys-primary btn1"
                >
                  <PlusCircle className="me-2" />
                  <span>Create Notification</span>
                </a>
                {/* Modal start */}
                {isTodoModelShow ? (
                  <PushNotificationModel onClose={openCloseTodoModel} />
                ) : null}
                {/* modal End */}
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                {PushNotificationCardData.map((postDetail, index) => (
                  <div className="col-md-4" key={index}>
                    <Link to="/PushNotificationDetails">
                      <div className={postDetail.card_bgcolor}>
                        <div className="card-header d-flex justify-content-between">
                          <img
                            className=" rounded-circle"
                            src={dashboardAvatar}
                            alt="image"
                            style={{ height: "40px", width: "40x" }}
                          />
                          <div className="class-body">
                            {postDetail.Participants}
                          </div>
                        </div>

                        <div className="card-body">
                          {postDetail.assignment_title}
                        </div>
                        <div className="card-footer d-flex justify-content-between">
                          <h5>{postDetail.date}</h5>

                          <div className="class">
                            {postDetail.division_grade}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PushNotification;
