import React, { useState,useEffect } from "react";
// import { Modal } from "react-bootstrap";
// import Tabs from 'react-bootstrap/Tabs';
// import Tab from 'react-bootstrap/Tab';
import b1 from '../../../assets/images/user/b1.png'
import b2 from '../../../assets/images/user/b2.png'
import b3 from '../../../assets/images/user/b3.png'
import b4 from '../../../assets/images/user/b4.png'
import b5 from '../../../assets/images/user/b5.png'
import b6 from '../../../assets/images/user/b6.png'
import b7 from '../../../assets/images/user/b7.png'
import b8 from '../../../assets/images/user/b8.png'
import radio_img1 from '../../../assets/images/assessments/radio_img1.png'
import radio_img2 from '../../../assets/images/assessments/radio_img2.png'
import radio_img3 from '../../../assets/images/assessments/radio_img3.png'
import { ChevronLeft } from "react-feather";
import { Link } from "react-router-dom";

const QuestionList1 = (props) => {

  return (
    <>
      <div className="qa-header">

        <div className="d-flex justify-content-between">
          <div className="question-type">Multiple Choice Single Answers</div>
          <div className="question-number">Question <span>01</span>/<span className="t-ques">06</span></div>

        </div>
        <div className="h-line"></div>
      </div>
      <div className="multi-choice question">
        <p><span>Q.</span>The Chairman is ill and we'll have to _______ the meeting for a few days.</p>
        <div className="row answer">
          <div className="col-md-6">
            <div className="d-flex answer-list wrong-ans ans-selected" title="Wrong Answer">
              <div className="radio-btn"></div>
              <div className="ans-title">put on</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex answer-list correct-ans" title="Correct Answer">
              <div className="radio-btn"></div>
              <div className="ans-title">put off</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex answer-list">
              <div className="radio-btn"></div>
              <div className="ans-title">put away</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex answer-list">
              <div className="radio-btn"></div>
              <div className="ans-title">None of these</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
        </div>
      </div>
      <div>


      </div>

    </>
  );
}
const QuestionList2 = (props) => {

  return (
    <>

      <div className="qa-header">
        <div className="d-flex justify-content-between">
          <div className="question-type">Multiple Choice Multiple Answer</div>
          <div className="question-number">Question <span>02</span>/<span className="t-ques">06</span></div>
        </div>
        <div className="h-line"></div>
      </div>
      <div className="multi-choice question">
        <p><span>Q.</span>Choose mammalsChoose all correct answers that apply</p>
        <div className="row answer">
          <div className="col-md-6">
            <div className="d-flex answer-list correct-ans ans-selected">
              <div className="radio-btn"></div>
              <div className="ans-title">Bats</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex answer-list correct-ans ans-selected">
              <div className="radio-btn"></div>
              <div className="ans-title">Cats</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex answer-list">
              <div className="radio-btn"></div>
              <div className="ans-title">hen</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="d-flex answer-list">
              <div className="radio-btn"></div>
              <div className="ans-title">None of these</div>
              <div className="ms-auto"><img src={b1} /></div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
}
const QuestionList3 = (props) => {

  return (
    <>
      <div>
        <div className="qa-header">
          <div className="d-flex justify-content-between">
            <div className="question-type">Fill in the blanks</div>
            <div className="question-number">Question <span>03</span>/<span className="t-ques">06</span></div>
          </div>
          <div className="h-line"></div>
        </div>
        <div className="fill-in-the-blanks question">
          <div className="correct own-ans">
            <p><span className="i-m-g"><img src={b1} /></span><span className="answer">Holi</span> is known as the festival of the colours</p>
          </div>
          <h6 className="mt-4">-- OR --</h6>
          <div className="wrong">
            <p><span className="answer">Diwali</span> is known as the festival of the colours</p>
            <div className="correct-answer"><i className="bi bi-check2-circle"></i> Answer : <span>Holi</span></div>
          </div>
        </div>

      </div>

    </>
  );
}
const QuestionList4 = (props) => {

  return (
    <>
      <div>
        <div className="qa-header">
          <div className="d-flex justify-content-between">
            <div className="question-type">Match the following</div>
            <div className="question-number">Question <span>04</span>/<span className="t-ques">06</span></div>
          </div>
          <div className="h-line"></div>
        </div>
        <div className="multi-choice match-the-following question">
          <p><span>Q.</span>Match the mother and the baby</p>
          <div className="row answer">
            <div className="col-md-6">
              <div className="d-flex answer-list">
                <div className="ans-title">Cow</div>
              </div>
              <div className="d-flex answer-list">
                <div className="ans-title">Lioness</div>
              </div>
              <div className="d-flex answer-list">
                <div className="ans-title">Hen</div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex answer-list correct-ans ans-selected" title="Correct Answer">
                <div className="ans-title">Calf</div>
                <div className="ms-auto"><img src={b1} /></div>
              </div>
              <div className="d-flex answer-list wrong-ans ans-selected" title="Wrong Answer">
                <div className="ans-title">Gosling</div>
                <div className="ms-auto"><img src={b1} /></div>
              </div>
              <div className="d-flex answer-list correct-ans ans-selected" title="Correct Answer">
                <div className="ans-title">Chick</div>
                <div className="ms-auto"><img src={b1} /></div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
const QuestionList5 = (props) => {

  return (
    <>
      <div >
        <div className="qa-header">
          <div className="d-flex justify-content-between">
            <div className="question-type">Multiple Choice Multiple Answer</div>
            <div className="question-number">Question <span>05</span>/<span className="t-ques">06</span></div>
          </div>
          <div className="h-line"></div>
        </div>
        <div className="multi-choice question">
          <p><span>Q.</span>Choose mammals Choose all correct answers that apply</p>
          <div className="row answer">
            <div className="col-md-6">
              <div className="d-flex answer-list wrong-ans ans-selected">
                <div className="radio-btn"></div>
                <div className="ans-title">True/Yes</div>
                <div className="ms-auto"><img src={b1} /></div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex answer-list correct-ans">
                <div className="radio-btn"></div>
                <div className="ans-title">False/No</div>
                <div className="ms-auto"><img src={b1} /></div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </>
  );
}
const QuestionList6 = (props) => {

  return (
    <>
      <div >
        <div className="qa-header">
          <div className="d-flex justify-content-between">
            <div className="question-type">Multiple Choice Single Answer</div>
            <div className="question-number">Question <span>06</span>/<span className="t-ques">06</span></div>
          </div>
          <div className="h-line"></div>
        </div>
        <div className="single-choice-img question">
          <p><span>Q.</span>Choose mammalsChoose all correct answers that apply</p>
          <div className="d-flex answer-choice">
            <div className="answer">
              <img src={radio_img1} />
            </div>
            <div className="answer wrong selected">
              <img src={radio_img1} />
            </div>
            <div className="answer correct">
              <img src={radio_img1} />
            </div>
            <div className="answer">
              <img src={radio_img1} />
            </div>
          </div>
        </div>

      </div>

    </>
  );
}

function ScoreDetails() {
  useEffect(() => {
    document.title = "Edsys | Score Details"
  }, [])
  
  const Question =[0];


  const [state, setState] = useState(1);
  return (
    <div className="page-body mark-sheet">
      {/* <!-- Container-fluid starts--> */}
      <div className="row view-assignment">
        <div className="col-12">
          <Link to="/RankList">
            <h2 onclick="location.href='rank-list.html';" className="back-btn"><ChevronLeft /><span>Back</span></h2>
          </Link>
        </div> 
        <div className="col-12">
          <div className="card br-15">
            <div className="card-body header-card">
              <div className="student-header">
                <div className="details-banner">
                  <h3>Assessment Title 01</h3>
                  <h5 className="subject">PHYSICS</h5>
                  <div className="sub-date">28 Oct 2021, Thu</div>
                </div>
              </div>
              <div className="student-image">
                <div className="student-avatar">
                  <img src={b1} />
                  <div className="class">6th D</div>
                </div>
                <div className="student-name">Maria George</div>
              </div>
              <div className="duration d-flex justify-content-between">
                <div className="time"><i data-feather="clock"></i>02:16 Hrs</div>
                <div className="total-mark">Total Marks <span>50</span></div>
                <div className="scored-marks">Scored Marks <span>38</span></div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="card br-15">
            <div className="card-body">
              <form className="form-wizard" id="regForm" action="#" method="POST">
                <div>
                  {Question.map((item, index) => {

                    return (
                      <>

                        {state == 1 ?
                          <QuestionList1 questionData={item} /> :

                          null
                        }


                        {state == 2 ?
                          <QuestionList2 questionData={item} /> :

                          null
                        }
                        {state == 3 ?
                          <QuestionList3 questionData={item} /> :

                          null
                        }
                        {state == 4 ?
                          <QuestionList4 questionData={item} /> :

                          null
                        }
                        {state == 5 ?
                          <QuestionList5 questionData={item} /> :

                          null
                        }
                        {state == 6 ?
                          <QuestionList6 questionData={item} /> :

                          null
                        }

                      </>
                    );
                  }

                  )}

                </div>

                <div>
                  <div className="text-end btn-mb">
                    <button className="btn btn-secondary" style={{marginRight:"5px"}} id="prevBtn" type="button" disabled={state === 1 ? true : false} onClick={() => { setState(state - 1) }} >Previous</button>
                    <button className="btn btn-primary" id="nextBtn" type="button" disabled={state === 6 ? true : false} onClick={() => { setState(state + 1) }} >Next</button>
                  </div>
                </div>
                {/* <!-- Circles which indicates the steps of the form:--> */}
                <div className="text-center">
                  <span className="step"></span>
                  <span className="step"></span>
                  <span className="step"></span>
                  <span className="step"></span>
                  <span className="step"></span>
                  <span className="step"></span>
                </div>
                {/* <!-- Circles which indicates the steps of the form:--> */}
              </form>
            </div>
          </div>
        </div>

      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  )
}
export default ScoreDetails
