import React, { useEffect, useState } from "react";
import active_like_btn from "../../assets/images/MyDiary/active_like_btn.svg";
import like_btn from "../../assets/images/Fourms/like_btn.svg";
import part_1 from "../../assets/images/MyDiary/part_1.svg";
import part_2 from "../../assets/images/MyDiary/part_2.svg";
import part_3 from "../../assets/images/MyDiary/part_3.svg";
import part_4 from "../../assets/images/MyDiary/part_4.svg";
import com_send_btn from "../../assets/images/MyDiary/com_send_btn.svg";
import reply_icon from "../../assets/images/MyDiary/reply_icon.svg";
import filter_icon from "../../assets/images/MyDiary/filter_icon.svg";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import dislike_btn from "../../assets/images/Fourms/dislike_btn.svg";
import com_icon from "../../assets/images/Fourms/com_icon.svg";

function FeedView() {
  const [isShown, setIsShown] = useState(false);

  const [showreply, setShowReply] = useState(false);
  const handleshow = () => setShowReply(!showreply);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div class="Note_view card p-4">
        <div className="main-section">
          <div className="row">
            <div className="col-md-7">
              <div className="comments-wrapper ">
                <div className="single-comment com-border-bottom">
                  <div className="com-img-box">
                    <img src={part_3} alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="com-heading-wrapper">
                      <p className="title">Parent Name</p>
                      {/* <div className="time-and-like">
                        <div className="time">Mon 21, 2023 0:00 AM</div>
                      </div> */}
                    </div>
                    <div className="comment-text">
                      <p>
                        The Parent Comment Will be shown here…Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud.
                      </p>
                    </div>
                    <div className="reply-btn-wrapper">
                      <span>Mon 21, 2023 10:00 AM</span>
                    </div>
                    <div className="d-flex align-items-center ml-3">
                      <div className="like-btn  align-items-center ">
                        <img src={like_btn} alt="" />
                        <span className="margin-left">61 Likes</span>
                      </div>

                      <div className="comments-btn  align-items-center margin-left">
                        <Link to="/FeedView">
                          <img src={com_icon} alt="" />
                        </Link>{" "}
                        <span className="margin-left">31 comments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="main-comment-input-wrapper"
                // style={{ height: "1px", background: "green" }}
              >
                <Form>
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Control type="text" placeholder="Write Comment…" />
                    <img src={com_send_btn} alt="" />
                  </Form.Group>
                </Form>
              </div>
              <div
                className="comments-wrapper "
                style={{ background: "white" }}
              >
                <div className="single-comment com-border-bottom">
                  <div className="com-img-box">
                    <img src={part_3} alt="" />
                  </div>
                  <div className="text-wrapper">
                    <div className="com-heading-wrapper">
                      <p className="title">Parent Name</p>
                      {/* <div className="time-and-like">
                        <div className="time">Mon 21, 2023 0:00 AM</div>
                      </div> */}
                    </div>
                    <div className="comment-text">
                      <p>
                        The Parent Comment Will be shown here…Lorem ipsum dolor
                        sit amet, consectetur adipiscing elit, sed do eiusmod
                        tempor incididunt ut labore et dolore magna aliqua. Ut
                        enim ad minim veniam, quis nostrud.
                      </p>
                    </div>
                    <div className="reply-btn-wrapper">
                      <span>Mon 21, 2023 10:00 AM</span>
                    </div>
                    <div className="d-flex align-items-center ml-3">
                      <div className="like-btn  align-items-center ">
                        <img src={like_btn} alt="" />
                        <span className="margin-left">61 Likes</span>
                      </div>

                      <div className="comments-btn  align-items-center margin-left">
                        <Link to="/FeedView">
                          <img src={com_icon} alt="" />
                        </Link>{" "}
                        <span className="margin-left">31 comments</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="comments-wrapper col-md-5">
              <div className="single-comment com-border-bottom">
                <div className="com-img-box">
                  <img src={part_3} alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="com-heading-wrapper">
                    <p className="title">Parent Name</p>
                    <div className="time-and-like">
                      <div className="time">Mon 21, 2023 0:00 AM</div>
                      <div className="like">
                        <img src={like_btn} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="comment-text">
                    <p>
                      The Parent Comment Will be shown here…Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud.
                    </p>
                  </div>
                  <div className="reply-btn-wrapper" onClick={handleshow}>
                    <img src={reply_icon} alt="" />
                    <span>Reply</span>
                  </div>
                  {showreply ? (
                    <>
                      <Form>
                        <Form.Group
                          className="mb-3 mt-2"
                          controlId="formBasicEmail"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Type Your Comment Here..."
                          />
                          <Form.Text className="text-muted">
                            {/* We'll never share your email with anyone else. */}
                          </Form.Text>
                        </Form.Group>
                      </Form>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <p className="title">Comments</p>

              <div className="single-comment mt-3">
                <div className="com-img-box">
                  <img src={part_1} alt="" />
                </div>
                <div className="text-wrapper">
                  <div className="com-heading-wrapper">
                    <p className="title">Parent Name</p>
                    <div className="time-and-like">
                      <div className="time">Mon 21, 2023 0:00 AM</div>
                      <div className="like">
                        <img src={like_btn} alt="" />
                      </div>
                    </div>
                  </div>
                  <div className="comment-text">
                    <p>
                      The Parent Comment Will be shown here…Lorem ipsum dolor
                      sit amet, consectetur adipiscing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua. Ut
                      enim ad minim veniam, quis nostrud.
                    </p>
                  </div>
                  <div className="reply-btn-wrapper">
                    <img src={reply_icon} alt="" />
                    <span>Reply</span>
                  </div>

                  <div className="teachers-reply">
                    <div className="single-comment mt-3">
                      <div className="com-img-box">
                        <img src={part_2} alt="" />
                      </div>
                      <div className="text-wrapper">
                        <div className="com-heading-wrapper">
                          <p className="title">Parent Name</p>
                          <div className="time-and-like">
                            <div className="time">Mon 21, 2023 0:00 AM</div>
                            <div className="like">
                              <img src={like_btn} alt="" />
                            </div>
                          </div>
                        </div>
                        <div className="comment-text">
                          <p>The Teacher's Comment Will be shown here</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Sight Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>Sight</p>
              <p>25/40</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="title">
            <p>See By</p>
            <p>25</p>
          </div>
          <div className="list-wrapper">
            <div className="list-item">
              <img src={part_4} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_3} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
          </div>
          <div className="title mt-2">
            <p>Remaining</p>
            <p>15</p>
          </div>
          <div className="list-wrapper">
            <div className="list-item">
              <img src={part_4} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_3} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
            <div className="list-item">
              <img src={part_1} alt="" />
              <div className="text-box">
                <p className="main">Parent Name</p>
                <p className="sub">Mon 21, 2023 0:00 AM</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Sight Modal End */}
    </>
  );
}
export default FeedView;
