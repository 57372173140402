import React, { useState } from "react";

import { Link } from "react-router-dom";
import { PlusCircle } from "react-feather";

import assignment_card_data from "../../api/assignment_card_data.json";
import AssessmentDetails from "../academics/Assesment/AssessmentDetails";
import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import CusDroprow from ".//CusDroprow";
function EditOfflineAssessment() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [students, setStudents] = useState([
    { id: 1, name: "Student 1", marks: "80" },
    { id: 2, name: "Student 2", marks: "45" },
    { id: 3, name: "Student 3", marks: "90" },
    { id: 4, name: "Student 4", marks: "55" },
    { id: 5, name: "Student 5", marks: "79" },
    { id: 6, name: "Student 6", marks: "98" },
    { id: 7, name: "Student 7", marks: "86" },
    { id: 8, name: "Student 8", marks: "69" },
    { id: 9, name: "Student 9", marks: "46" },
    { id: 10, name: "Student 10", marks: "95" },
    { id: 11, name: "Student 11", marks: "65" },
    { id: 12, name: "Student 12", marks: "59" },
  ]);
  const handleMarksChange = (id, marks) => {
    // Update marks for the corresponding student
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === id ? { ...student, marks } : student
      )
    );
  };

  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Edit OfflineAssessment</h5>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <CusDroprow />
                {/* <div className="col">
                  <p className="DropTxt"> Academic year</p>

                  <select
                    className="form-control"
                    defaultValue=""
                    placeholder="Select Academic Year"
                  >
                    <option value="" disabled>
                      Select Academic Year
                    </option>
                    <option value="2024-2025">2024-2025</option>
                    <option value="2023-2024">2023-2024</option>
                    <option value="2022-2023">2022-2023</option>
                  
                  </select>
                </div>
                <div className="col">
                  <p className="DropTxt"> Grade</p>

                  <select
                    className="form-control"
                    defaultValue=""
                    placeholder="Select Grade Division"
                  >
                    <option value="" disabled>
                      Select Grade Division
                    </option>
                    <option value="Grade 1">Grade 1</option>
                    <option value="Grade 2">Grade 2</option>
                    <option value="Grade 3">Grade 3</option>
                  
                  </select>
                </div>
                <div className="col">
                  <p className="DropTxt"> Exam/Term</p>

                  <select
                    className="form-control"
                    defaultValue=""
                    placeholder="Select Exam/Term"
                  >
                    <option value="" disabled>
                      Select Exam/Term
                    </option>
                    <option value="Midterm">Midterm</option>
                    <option value="Final Exam">Final Exam</option>
                    <option value="Quarterly Exam">Quarterly Exam</option>
                
                  </select>
                </div>
                <div className="col">
                  <p className="DropTxt"> Subject</p>

                  <select
                    className="form-control"
                    defaultValue=""
                    placeholder="Select Subject"
                  >
                    <option value="" disabled>
                      Select Subject
                    </option>
                    <option value="Mathematics">Mathematics</option>
                    <option value="Science">Science</option>
                    <option value="English">English</option>
                    
                  </select>
                </div> */}
              </div>
            </div>
            <div className="d-flex">
              <h5 className="flex-grow-1 ml-30">Mark List</h5>
            </div>
            <div className="table">
              <div className="thead">
                <div className="tr tableheadstyle">
                  <div className="thsl">Sl. No</div>
                  {/* <div className="thI">Image</div> */}
                  <div className="thsn">Student Name</div>
                  <div className="thm">Marks</div>
                  <div className="thm"> Total Marks</div>
                </div>
              </div>
              <div className="tbody">
                {students.map((student, index) => (
                  <div className="tr" key={student.id}>
                    <div className="tdsl">{index + 1}</div>
                    {/* <div className="tdI"></div> */}
                    <div className="tdsn">
                      {" "}
                      <img
                        className="rounded-circle"
                        src={dashboardAvatar}
                        alt="image"
                        style={{
                          height: "25px",
                          width: "25x",
                          marginRight: "10px",
                        }}
                      />
                      {student.name}
                    </div>
                    <div className="tdm">
                      <input
                        type="number"
                        min="0"
                        max="3"
                        placeholder="Enter marks"
                        // value={student.marks}
                        style={{ border: "none", width: "120px" }}
                        onChange={(e) =>
                          handleMarksChange(student.id, e.target.value)
                        }
                      />
                      {/* {student.marks} */}
                    </div>
                    <div className="tdm">100</div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default EditOfflineAssessment;
