import React, { useState } from "react";
import { TabPanel, TabView } from "primereact/tabview";
import { FaSearch } from "react-icons/fa";
import UserPic from "../../assets/images/dashboard/1.png";
import { useNavigate } from "react-router-dom";
// import "font-awesome/css/font-awesome.min.css";
import "../Wallet/tabstyles.css";
const SubordinateLeave = () => {
  const dataList = [
    {
      id: 1,
      name: "Rinku",
      UserPic: UserPic,
      days: "1 day",
    },
    {
      id: 2,
      name: "Rinku",
      UserPic: UserPic,
      days: "2 days",
    },
    {
      id: 3,
      name: "Rinku",
      UserPic: UserPic,
      days: "1 day",
    },
    {
      id: 4,
      name: "Rinku",
      UserPic: UserPic,
      days: "1 day",
    },
    {
      id: 5,
      name: "Rinku",
      UserPic: UserPic,
      days: "2 days",
    },
    {
      id: 6,
      name: "Rinku",
      UserPic: UserPic,
      days: "1 day",
    },
  ];
  const tabs = ["Pending", "History"];

  const navig = useNavigate();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <div className=" library announcementnew">
        {/* <!-- Container-fluid starts--> */}
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="row card-body">
                <div className="col-md-12">
                  <div className="books-main-wrapper">
                    <div>
                      <div className="tabs">
                        {tabs.map((tab, index) => (
                          <div
                            key={index}
                            className={`tab ${
                              tab === activeTab ? "active" : ""
                            }`}
                            onClick={() => handleClick(tab)}
                          >
                            {tab}
                          </div>
                        ))}
                      </div>
                      <div className="tab-content">
                        {activeTab === "Pending" && (
                          <div className="my-books p-4">
                            <form className="d-flex">
                              <div
                                style={{
                                  width: "100%",
                                  marginBottom: 10,
                                  borderRadius: 10,
                                }}
                              >
                                <div className="input-container">
                                  <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                    style={{ width: "100%" }}
                                  />
                                  <FaSearch
                                    className="search-icon"
                                    style={{ marginLeft: 3, color: "#949FA6" }}
                                  />
                                </div>
                              </div>
                            </form>
                            <div className="row">
                              {dataList.map((item, index) => (
                                <div
                                  className="col-md-6 col-lg-4 col-sm-12"
                                  key={index}
                                >
                                  <div
                                    className="book-card"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "#772569",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                    onClick={() => navig("/LeaveReq")}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={item.UserPic}
                                        alt=""
                                        style={{
                                          height: "25px",
                                          width: "25px",
                                        }}
                                      />
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "white",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        {item.name}
                                      </p>
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "#72A44B",
                                      }}
                                    >
                                      {item.days}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                        {activeTab === "History" && (
                          <div className="my-books  p-4">
                            <form className="d-flex">
                              <div
                                style={{
                                  width: "100%",
                                  marginBottom: 10,
                                  borderRadius: 10,
                                }}
                              >
                                <div className="input-container">
                                  <input
                                    className="form-control"
                                    type="search"
                                    placeholder="Search"
                                    aria-label="Search"
                                    style={{ width: "100%" }}
                                  />
                                  <FaSearch
                                    className="search-icon"
                                    style={{ marginLeft: 3, color: "#949FA6" }}
                                  />
                                </div>
                              </div>
                            </form>
                            <div className="row">
                              {dataList.map((item, index) => (
                                <div
                                  className="col-md-6 col-lg-4 col-sm-12"
                                  key={index}
                                >
                                  <div
                                    className="book-card"
                                    style={{
                                      display: "flex",
                                      backgroundColor: "#772569",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      flexDirection: "row",
                                    }}
                                    onClick={() => navig("/LeaveReq")}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                      }}
                                    >
                                      <img
                                        src={item.UserPic}
                                        alt=""
                                        style={{
                                          height: "25px",
                                          width: "25px",
                                        }}
                                      />
                                      <p
                                        style={{
                                          fontSize: "12px",
                                          color: "white",
                                          marginLeft: "5px",
                                        }}
                                      >
                                        {item.name}
                                      </p>
                                    </div>
                                    <p
                                      style={{
                                        fontSize: "12px",
                                        color: "#72A44B",
                                      }}
                                    >
                                      {item.days}
                                    </p>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Container-fluid Ends--> */}
      </div>
    </>
  );
};

export default SubordinateLeave;
