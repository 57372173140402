import React, { useEffect } from "react";
import dashboardAvatar from "../../../assets/images/dashboard/1.png";

function PushNotificationDetails() {
  useEffect(() => {
    document.title = "Edsys | Assignment Details ";
  }, []);

  const smsDetailsStyle1 = {
    textAlign: "center",

    // justifycontent:"center"
  };

  const smsDetailsStyle = {
    color: "blue",
    fontSize: "15px",
    backgroundColor: "#b5daff",
    // width:"100%",
  };

  return (
    <div className="">
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <div className="d-flex align-items-center justify-content-between">
                <h5 className="">Notification Details</h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <img
          className="img-90 rounded-circle"
          src={dashboardAvatar}
          alt="image"
          style={{ height: "50px", width: "50px" }}
        />
        <div className="class-body ml-2">Participants</div>
      </div>
      <div style={smsDetailsStyle1}>
        <a style={smsDetailsStyle}>6TH A</a>
      </div>

      <div class="grid text-center"></div>

      <div className="mt-2 col-12">
        <div className="card">
          <div className="card-header">
            <h6 className="text-primary mt-2">Subject</h6>

            <div className="mt-2">test mail</div>
            <div className="d-flex justify-content-between mt-2">
              <div className="details-banner">
                <div className="sub-date">28 Oct 2021, Thu</div>
              </div>
              <div className="details-banner">
                <div className="sub-date">10:42</div>
              </div>
            </div>
            <div className="mt-2">Message</div>

            <div className="mt-2">
              hii sir i am not able to join the metting
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PushNotificationDetails;
