import React, { Component } from "react";
import user from "../../assets/images/gif/user.gif";
import b1 from "../../assets/images/user/b1.png";
import b2 from "../../assets/images/user/b2.png";
import b3 from "../../assets/images/user/b3.png";
import b4 from "../../assets/images/user/b4.png";
import b5 from "../../assets/images/user/b5.png";
import b6 from "../../assets/images/user/b6.png";
import b7 from "../../assets/images/user/b7.png";
import b8 from "../../assets/images/user/b8.png";
// import ApproveLeaves from './ApproveLeaves'
import StudentModal from "./StudentModal";
import StudentModal1 from "./StudentModal1";

export class StudentLeaves extends Component {
  //#region constructor
  constructor(props) {
    super(props);
    this.state = {
      isTodoModelShow: false,
      isTagModelShow: false,
    };
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = " Student Leaves";
  }
  //#endregion

  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isTodoModelShow: false,
      isTagModelShow: false,
    });
  };
  openCloseTodoModel = () => {
    //  this.setInitialState();
    this.setState({ isTodoModelShow: !this.state.isTodoModelShow }, () => {});
  };
  openCloseTagModel = () => {
    // this.setInitialState();
    this.setState({ isTagModelShow: !this.state.isTagModelShow });
  };
  // //#endregion

  // //#region Render

  render() {
    const myData = [
      {
        id: 1,
        rank: 1,
        name: "Lucy Tom",
        icon_set: "../assets/images/user/b1.png",
        leaves: 5,
      },
      {
        id: 2,
        rank: 2,
        name: "Lucy Tom",
        icon_set: "../assets/images/user/b2.png",
        leaves: 7,
      },
      {
        id: 3,
        rank: 3,
        name: "Thomas Abraham",
        icon_set: "../assets/images/user/b3.png",
        leaves: 3,
      },
      {
        id: 4,
        rank: 4,
        name: "Janet James",
        icon_set: "../assets/images/user/b4.png",
        leaves: 10,
      },

      {
        id: 5,
        rank: 5,
        name: "Ganesh",
        icon_set: "../assets/images/user/b5.png",
        leaves: 9,
      },

      {
        id: 6,
        rank: 6,
        name: "Linga Lopez",
        icon_set: "../assets/images/user/b6.png",
        leaves: 10,
      },

      {
        id: 7,
        rank: 7,
        name: "Sam Joseph",
        icon_set: "../assets/images/user/b7.png",
        leaves: 0,
      },
    ];

    return (
      <div className="approve-leave">
        {/* <!-- Container-fluid starts--> */}
        <div className="row">
          <div className="col-12">
            <div className="card br-15">
              <div className="card-body">
                <div className=" mb-3 n-attend-total d-flex justify-content-between">
                  <div className=" n-attend-title">
                    <h3>Student Leave Management</h3>
                  </div>
                  <ul className="nav nav-pills" id="pills-tab" role="tablist">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        href="#pills-home"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        Leaves
                        <div className="media"></div>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        href="#pills-profile"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        Approve Leaves
                      </a>
                    </li>
                  </ul>
                  <div className="h-i-m-g">
                    <img src={user} />
                  </div>
                </div>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {myData.map((myData) => (
                      <div
                        data-bs-toggle="modal"
                        onClick={this.openCloseTodoModel}
                        data-bs-target="#exampleModal1"
                        className="student-rank-list"
                      >
                        <div className="d-flex align-items-center" key={myData}>
                          <div className="ranks">{myData.rank}</div>
                          <div className="p-image">
                            <img src={myData.icon_set} alt="..." />
                          </div>
                          <div
                            className="student-name"
                            style={{ color: "black" }}
                          >
                            {myData.name}
                          </div>
                          <div className="no-leave ms-auto">
                            {myData.leaves} Days
                          </div>
                        </div>
                      </div>
                    ))}
                    {/* {Modal Start} */}
                    {this.state.isTodoModelShow ? (
                      <StudentModal onClose={this.openCloseTodoModel} />
                    ) : null}

                    {/* modal End */}
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    {myData.map((myData) => (
                      <div
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        onClick={this.openCloseTodoModel}
                        className="student-rank-list"
                      >
                        <div className="d-flex align-items-center" key={myData}>
                          <div className="ranks">{myData.rank}</div>
                          <div className="p-image">
                            <img src={myData.icon_set} alt="..." />
                          </div>
                          <div
                            className="student-name"
                            style={{ color: "black" }}
                          >
                            {myData.name}
                          </div>
                          <div className="no-leave ms-auto">
                            {myData.leaves} Days
                          </div>
                        </div>
                      </div>
                    ))}

                    {this.state.isTodoModelShow ? (
                      <StudentModal1 onClose={this.openCloseTodoModel} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div class="modal fade modal-bookmark  show" id="exampleModal"  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-md" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="d-flex align-items-center">
                                    <div class="p-image flex-grow-0">
                                        <img src={{ b3 }} />
                                    </div>
                                    <div class="student-name flex-grow-1">Alexander Miller</div>
                                    <div class="no-leave">4 Days</div>
                                </div>
                            </div>
                            <div class="modal-body modal-scroll">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="fixed-form">
                                            <div class="al-label">From</div>
                                            <div class="al-input">01-12-2021</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="fixed-form">
                                            <div class="al-label">To</div>
                                            <div class="al-input">05-12-2021</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="fixed-form">
                                            <div class="al-label">Applied Date</div>
                                            <div class="al-input">28-11-2021</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="fixed-form">
                                            <div class="al-label">No. of Days</div>
                                            <div class="al-input">5 Days</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="fixed-form">
                                            <div class="al-label">Leave Type</div>
                                            <div class="al-input">Full Day</div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="fixed-form">
                                            <div class="al-label">Leave Category</div>
                                            <div class="al-input">Planned</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="fixed-form">
                                            <div class="al-label">Leave Reason</div>
                                            <div class="al-text-area">Going to native place</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                <button class="btn btn-primary" type="submit">Approve</button>
                            </div>
                        </div>
                    </div>
                </div> */}
        {/* <!-- Container-fluid Ends--> */}\
      </div>
    );
  }
}

export default StudentLeaves;
