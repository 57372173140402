import React, { useEffect, useState } from "react";
import watch_icon from "../../assets/images//MyDiary/watch_icon.svg";
import view_note_icon from "../../assets/images/MyDiary/view_note_icon.svg";
import schedule_icon_white from "../../assets/images/MyDiary/schedule_icon_white.svg";
import filter_icon from "../../assets/images/MyDiary/filter_icon.svg";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import DiaryIcon from "../../assets/images/MyDiary/DiaryIcon.svg";

import MyDiarynew from "./MyDiarynew.css";
function MyDiary() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [selectedDay, setSelectedDay] = useState(null);
  const diaryData = [
    {
      date: "1st March, 2023",
      entries: [
        {
          color: "color-1",
          class: "10D",
          period: "Period 01",
          time: "12:30 AM",
          subject: "English",
          notificationCount: 4,
          type: "High",
        },
        {
          color: "color-3",
          class: "10D",
          period: "Period 01",
          time: "12:30 AM",
          subject: "English",
          notificationCount: 4,
          type: "Medium",
        },
        // Add more entries as needed
      ],
    },
    {
      date: "2nd March, 2023",
      entries: [
        {
          color: "color-2",
          class: "10D",
          period: "Period 01",
          time: "12:30 AM",
          subject: "English",
          notificationCount: 4,
          type: "Low",
        },
        // Add more entries as needed
      ],
    },
    // Add more dates and entries as needed
  ];
  const getColour = (id) => {
    const colors = ["#FFCFC4", "#A7BEFF", "#E2CCFF", "#FAC4C4"];
    return colors[id % colors.length];
  };
  const getColour1 = (id) => {
    const colors = ["#FFF6F1", "#EAEEFD", "#F7F3FF", "#FFF0F0"];
    return colors[id % colors.length];
  };
  const getColour2 = (id) => {
    const colors = ["#FFD8CE", "#D5DEFD", "#EAE0FD", "#FAC4C4"];
    return colors[id % colors.length];
  };
  const getColour3 = (id) => {
    const colors = ["#EF5934", "#305EDC", "#7945E4", "F58888"];
    return colors[id % colors.length];
  };

  return (
    <>
      <div className="MyDiary SchoolTvChannel  card p-4 ">
        <div className="main-section">
          <div className="row">
            <div className="col-md-12">
              <div
                className="announcement-top d-flex align-items-center justify-content-space-between pl-2 pb-2"
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h5>My Diary</h5>
                <img src={filter_icon} alt="" onClick={handleShow} />
              </div>
            </div>
            {diaryData.map((dateEntry, index) => (
              <React.Fragment key={index}>
                <div className="row align-items-center d-flex mt-1 mb-1 ">
                  <div className="col-md-2 d-flex diary-date">
                    {dateEntry.date}
                  </div>
                  <div className="col-md-10 d-flex border-bottom-style"></div>
                </div>

                {dateEntry.entries.map((entry, index) => (
                  <div className="col-md-4" key={index}>
                    <Link to="/NoteView">
                      <div className={`program-card ${entry.color}`}>
                        <div className="noti-count">
                          {entry.notificationCount}
                        </div>

                        <div className="pc-class">
                          <img src={DiaryIcon} alt="" />
                        </div>
                        <div className="pc-details">
                          <div className="diary-title">
                            <p>My Diary Title</p>
                            <p className="timing">
                              <img src={watch_icon} alt="" /> {entry.time}
                            </p>
                          </div>
                          <div className="diary-sub-wrapper">
                            <button
                              className={`subject`}
                              style={{ background: getColour(entry.id) }}
                            >
                              {entry.subject}
                            </button>
                            <button
                              className={`subject`}
                              style={{ background: getColour(entry.id) }}
                            >
                              {entry.type}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>

      {/* Filter Modal Start */}
      <Modal
        show={show}
        onHide={handleClose}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="title-wrapper">
              <p>Filter By</p>
              <p>Clear</p>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="select-wrapper">
            <ul>
              <li>
                <input type="radio" id="f-option" name="selector" />
                <label
                  for="f-option"
                  onClick={() => {
                    handleClose();
                    handleShow1();
                  }}
                >
                  Date
                </label>

                <div class="check"></div>
              </li>

              <li>
                <input type="radio" id="s-option" name="selector" />
                <label for="s-option">Unread</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>

              <li>
                <input type="radio" id="t-option" name="selector" />
                <label for="t-option">Read</label>

                <div class="check">
                  <div class="inside"></div>
                </div>
              </li>
            </ul>
          </div>
        </Modal.Body>
      </Modal>

      {/* Filter Modal End */}

      {/* Date Selector Modal Start */}
      <Modal
        show={show1}
        onHide={handleClose1}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="common-filter-modal"
      >
        <Modal.Body className="date-picker-body">
          <Calendar
            value={selectedDay}
            onChange={setSelectedDay}
            shouldHighlightWeekends
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper">
            <button className="calendar-btn-1" onClick={handleClose1}>
              Cancel
            </button>
            <button className="calendar-btn-2" onClick={handleClose1}>
              Ok
            </button>
          </div>
        </Modal.Footer>
      </Modal>

      {/* Date Selector Modal End */}
    </>
  );
}

export default MyDiary;
