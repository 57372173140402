import React, { useState, useEffect } from "react";
import TimeTableGridView from "./gridView/TimeTableGridView";
import TimetableListView from "./listView/TimeTableListView";
import { Grid } from "react-feather";

const Timetable = (props) => {
  useEffect(() => {
    document.title = "Edsys | Timetable ";
  }, []);

  const Periods = [
    {
      id: 1,
      PeriodId: 1,
      startTime: "09:30 AM",
      endTime: "10:15 AM",
      isInterval: false,
    },
    {
      id: 2,
      PeriodId: 2,
      startTime: "10:15 AM",
      endTime: "11:00 AM",
      isInterval: false,
    },
    {
      id: 3,
      PeriodId: 0,
      startTime: "11:00 AM",
      endTime: "11:15 AM",
      isInterval: true,
    },
    {
      id: 4,
      PeriodId: 3,
      startTime: "11:15 AM",
      endTime: "12:00 PM",
      isInterval: false,
    },
    {
      id: 5,
      PeriodId: 4,
      startTime: "12:00 PM",
      endTime: "12:45 PM",
      isInterval: false,
    },
    {
      id: 6,
      PeriodId: 0,
      startTime: "12:45 PM",
      endTime: "01:45 PM",
      isInterval: true,
    },
    {
      id: 7,
      PeriodId: 5,
      startTime: "01:45 PM",
      endTime: "02:30 PM",
      isInterval: false,
    },
    {
      id: 8,
      PeriodId: 6,
      startTime: "02:30 PM",
      endTime: "03:15 PM",
      isInterval: false,
    },
    {
      id: 9,
      PeriodId: 0,
      startTime: "03:15 PM",
      endTime: "03:30 PM",
      isInterval: true,
    },
    {
      id: 10,
      PeriodId: 7,
      startTime: "03:30 PM",
      endTime: "04:15 PM",
      isInterval: false,
    },
  ];

  const lectures = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 2,
      Period: 2,
      subject: "Mathematics",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 3,
      Period: 3,
      subject: "Chemistry",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 4,
      Period: 4,
      subject: "Biology",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 5,
      Period: 5,
      subject: "History",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 6,
      Period: 6,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
    {
      id: 7,
      Period: 7,
      subject: "English",
      name: "Mary Ann",
      imgPath: "assets/images/user/g1.png",
    },
  ];
  const WeeklyPeriods = [
    {
      id: 1,
      Period: 1,
      subject: "Physics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 2,
      Period: 1,
      subject: "Physics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 3,
      Period: 1,
      subject: "Physics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 4,
      Period: 1,
      subject: "Physics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 5,
      Period: 1,
      subject: "Physics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 6,
      Period: 1,
      subject: "Mathematics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //2nd Period
    {
      id: 7,
      Period: 2,
      subject: "Chemistry",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 8,
      Period: 2,
      subject: "Biology",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 9,
      Period: 2,
      subject: "History",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 10,
      Period: 2,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 11,
      Period: 2,
      subject: "Physics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 12,
      Period: 2,
      subject: "Mathematics",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //3rd Period
    {
      id: 13,
      Period: 3,
      subject: "Chemistry",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 14,
      Period: 3,
      subject: "Biology",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 15,
      Period: 3,
      subject: "History",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 16,
      Period: 3,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 17,
      Period: 3,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 18,
      Period: 3,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //4th Period
    {
      id: 19,
      Period: 4,
      subject: "Chemistry",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 20,
      Period: 4,
      subject: "Biology",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 21,
      Period: 4,
      subject: "History",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 22,
      Period: 4,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 23,
      Period: 4,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 24,
      Period: 4,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //5th Period
    {
      id: 25,
      Period: 5,
      subject: "Chemistry",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 26,
      Period: 5,
      subject: "Biology",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 27,
      Period: 5,
      subject: "History",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 28,
      Period: 5,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 29,
      Period: 5,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //6th Period
    {
      id: 30,
      Period: 6,
      subject: "Chemistry",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 31,
      Period: 6,
      subject: "Biology",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 32,
      Period: 6,
      subject: "History",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 33,
      Period: 6,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 34,
      Period: 6,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 35,
      Period: 6,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
    //7th Period
    {
      id: 36,
      Period: 7,
      subject: "Chemistry",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 1,
    },
    {
      id: 37,
      Period: 7,
      subject: "Biology",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 2,
    },
    {
      id: 38,
      Period: 7,
      subject: "History",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 3,
    },
    {
      id: 39,
      Period: 7,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 4,
    },
    {
      id: 40,
      Period: 7,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 5,
    },
    {
      id: 41,
      Period: 7,
      subject: "English",
      name: "Mary Alex",
      imgPath: "assets/images/user/g1.png",
      weekDay: 6,
    },
  ];
  const dataList = {
    Periods: Periods,
    Lectures: lectures,
  };
  const dataGrid = {
    Periods: Periods,
    Lectures: WeeklyPeriods,
  };
  const [isGridView, setIsGridView] = useState(true);
  return (
    <div className="timetable">
      <div className="row">
        <div className="col-sm-12">
          <div className="d-flex">
            <h5 className="mb-2 flex-grow-1">Timetable</h5>
            <a onClick={() => setIsGridView(!isGridView)} className="tt-align">
              {isGridView ? (
                <i className="icofont icofont-listing-box"></i>
              ) : (
                <Grid />
              )}
            </a>
          </div>
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                href="#pills-home"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                My Timetable
                <div class="media"></div>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                href="#pills-profile"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Class 5th A
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                href="#pills-contact"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Class 5th B
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                href="#pills-contact1"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Class 5th C
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                href="#pills-profile1"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                Class 6th A
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                href="#pills-contact2"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Class 6th B
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                id="pills-contact-tab"
                data-bs-toggle="pill"
                href="#pills-contact3"
                role="tab"
                aria-controls="pills-contact"
                aria-selected="false"
              >
                Class 6th C
              </a>
            </li>
          </ul>
          <div class="card mt-2 br-15">
            <div class="card-body">
              <div class="tab-content" id="pills-tabContent">
                <div
                  class="tab-pane fade show active"
                  id="pills-home"
                  role="tabpanel"
                  aria-labelledby="pills-home-tab"
                >
                  {isGridView ? (
                    <TimeTableGridView data={dataGrid} />
                  ) : (
                    <TimetableListView data={dataList} />
                  )}
                </div>
                <div
                  class="tab-pane fade"
                  id="pills-profile"
                  role="tabpanel"
                  aria-labelledby="pills-profile-tab"
                >
                  {isGridView ? (
                    <TimeTableGridView data={dataGrid} />
                  ) : (
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book. It has survived not only five
                      centuries, but also the leap into electronic typesetting,
                      remaining essentially unchanged. It was popularised in the
                      1960s with the release of Letraset sheets containing Lorem
                      Ipsum passages, and more recently with desktop publishing
                      software like Aldus PageMaker including versions of Lorem
                      Ipsum
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timetable;
