// import React, { useState } from "react";
// import styled from "styled-components";
// import "../../assets/css/CreateExam.css";
// import "../../assets/css/OfflineAssesment.css";
// import { useNavigate} from "react-router-dom";

// const MainContainer = styled.div`
//   width: 100%;
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 0 16px;
// `;

// const StepContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 40px;
//   position: relative;
//   :before {
//     content: "";
//     position: absolute;
//     background: #f3e7f3;
//     height: 5px;
//     width: 100%;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 0;
//   }
//   :after {
//     content: "";
//     position: absolute;
//     background: #adc925;
//     height: 4px;
//     width: ${({ width }) => width};
//     top: 50%;
//     transition: 0.4s ease;
//     transform: translateY(-50%);
//     left: 0;
//   }
// `;

// const StepWrapper = styled.div`
//   position: relative;
//   z-index: 1;
//   // flex: 1; /* Ensure each step takes equal space */
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
// `;

// const StepStyle = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: ${({ step }) => (step === "completed" ? "#adc925" : "#ffffff")};
//   border: 3px solid ${({ step }) => (step === "completed" ? "#adc925" : "#adc925")};
//   transition: 0.4s ease;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// const StepCount = styled.span`
//   font-size: 19px;
//   color: black;
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

// const StepsLabelContainer = styled.div`
//   position: absolute;
//   top: 66px;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

// const StepLabel = styled.span`
//   font-size: 19px;
//   color: #79266a;
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

// const ButtonsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin: 0 -15px;
//   margin-top: 50px;
//   margin-bottom: 20px;
// `;

// const ButtonStyle = styled.button`
//   border-radius: 4px;
//   border: 0;
//   background: #79266a;
//   color: #ffffff;
//   cursor: pointer;
//   padding: 16px;
//   width: 90px;
//   :active {
//     transform: scale(0.98);
//     border:#79266a;
//   }
//   :disabled {
//     background: #f3e7f3;
//     color: #000000;
//     cursor: not-allowed;
//   }
// `;

// const CheckMark = styled.div`
//   font-size: 26px;
//   font-weight: 600;
//   color: ${({ completed }) => (completed ? 'white' : 'white')}; /* White tick color for completed step */
//   -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
//   -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
//   transform: scaleX(-1) rotate(-46deg);
// `;

// const steps = [
//   {
//     label: "Step1",
//     step: 1,
//   },
//   {
//     label: "Step2",
//     step: 2,
//   },
//   {
//     label: "Step3",
//     step: 3,
//   },

// ];

// const CreateNewOfflineAssesment = () => {
//   const history = useNavigate();
//   const [activeStep, setActiveStep] = useState(1);

//   const nextStep = () => {
//     // Perform validation before proceeding to the next step
//     const validationErrors = {};

//     // Step 1 validation checks (if any)
//     if (activeStep === 1) {
//       if (!formData.examName.trim()) {
//         validationErrors.examName = "Exam name is required";
//       }
//       if (!formData.academicYear.trim()) {
//         validationErrors.academicYear = "Academic year is required";
//       }
//       if (!formData.terms.trim()) {
//         validationErrors.terms = "Terms are required";
//       }
//       if (!formData.startDate.trim()) {
//         validationErrors.startDate = "Starting date is required";
//       }
//       if (!formData.endDate.trim()) {
//         validationErrors.endDate = "Ending date is required";
//       }
//     }

//     // Step 2 validation checks
//     if (activeStep === 2) {
//       // Example validation check for selectedGrade and selectedSubject
//    if (Object.keys(selectedSubjects).length === 0) {
//         validationErrors.subjectGrade = "Please select grade and at least one subject.";
//       }
//     }
//     if (activeStep === 3) {
//       if (!formData.startTime.trim()) {
//         validationErrors.startTime = "Starting time is required";
//       }
//       if (!formData.endTime.trim()) {
//         validationErrors.endTime = "Starting date is required";
//       }
//       if (!formData.duration.trim()) {
//         validationErrors.duration = "duration are required";
//       }
//       if (!formData.maxMark.trim()) {
//         validationErrors.maxMark = "maxMark are required";
//       }
//     }

//     // If there are validation errors, set the errors state and return
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return; // Do not proceed to the next step
//     }
//     if (activeStep === totalSteps) {
//       // Navigate to the "/OfflineAssessment" route
//       history("/OfflineAssessment");
//   } else {
//       setActiveStep(activeStep + 1);
//   }

//     // If no validation errors, proceed to the next step
//     setActiveStep(activeStep + 1);
//   };

//   const prevStep = () => {
//     setActiveStep(activeStep - 1);
//   };

//   const totalSteps = steps.length;

//   const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

//   const [formData, setFormData] = useState({
//     examName: "",
//     academicYear: "",
//     terms: "",
//     startDate: "",
//     endDate: "",
//     startTime: "",
//     endTime: "",
//     duration: "",
//     maxMark: "",
//   });

//   // State variable to hold form validation errors
//   const [errors, setErrors] = useState({});

//   // Function to handle form input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     setErrors({
//       ...errors,
//       [name]: "",
//     });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const validationErrors = {};
//     // Your validation logic
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       console.log("Form submitted:", formData);
//       setFormData({
//         examName: "",
//         academicYear: "",
//         terms: "",
//         startDate: "",
//         endDate: "",
//         startTime: "",
//         endTime: "",
//         duration: "",
//         maxMark: "",
//       });
//       setErrors({});
//     }
//   };

//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubjects, setSelectedSubjects] = useState([]);
//   const [subjects, setSubjects] = useState({
//     "10A": ["Maths", "Physics", "Chemistry"],
//     "10B": ["Biology", "History", "Geography"],
//     "10C": ["Biology", "History", "Geography"],
//     "10D": ["Biology", "History", "Geography"],
//     // Add subjects for other grades as needed
//   });

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade((prev) => (prev === grade ? "" : grade));
//     setSelectedSubjects([]);
//   };

//   const handleSubjectClick = (subject) => {
//     setSelectedSubjects((prev) =>
//       prev.includes(subject)
//         ? prev.filter((item) => item !== subject)
//         : [...prev, subject]
//     );
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">
//           <div className="card">
//           <div className="card-header pb-0">
//           <h5 className="flex-grow-1">Create Offline Assesment</h5>
//           </div>
//             <MainContainer>
//               <StepContainer width={width}>
//                 {steps.map(({ step, label }) => (
//                   <StepWrapper key={step}>
//                     <StepStyle
//                       step={activeStep >= step ? "completed" : "incomplete"}
//                     >
//                       {activeStep > step ? (
//                         <CheckMark>L</CheckMark>
//                       ) : (
//                         <StepCount>{step}</StepCount>
//                       )}
//                     </StepStyle>
//                     <StepsLabelContainer>
//                       <StepLabel key={step}>{label}</StepLabel>
//                     </StepsLabelContainer>
//                   </StepWrapper>
//                 ))}
//               </StepContainer>

//               {activeStep === 1 && (
//                 <div className="col-sm-12">
//                   <div className="margin_top">
//                     <div className="card-body header-card">
//                       <form
//                         className="form-bookmark needs-validation"
//                         id="bookmark-form"
//                         noValidate
//                         onSubmit={handleSubmit}
//                       >
//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="exampleDataList">Name</label>
//                             <input
//                               className={`form-control ${
//                                 errors.examName ? "is-invalid" : ""
//                               }`}
//                               list="exam"
//                               id="exampleDataList"
//                               name="examName"
//                               value={formData.examName}
//                               onChange={handleInputChange}
//                               placeholder="Type the exam name"
//                               required
//                             />
//                             {errors.examName && (
//                               <div className="invalid-feedback">
//                                 {errors.examName}
//                               </div>
//                             )}
//                             <datalist id="exam">
//                               <option value="Exam1">Exam1</option>
//                               <option value="Exam2">Exam2</option>
//                               <option value="Exam3">Exam3</option>
//                             </datalist>
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label htmlFor="exampleDataList">
//                               Academic Year
//                             </label>
//                             <input
//                               className={`form-control ${
//                                 errors.academicYear ? "is-invalid" : ""
//                               }`}
//                               list="datalistOptions"
//                               id="exampleDataList"
//                               name="academicYear"
//                               value={formData.academicYear}
//                               onChange={handleInputChange}
//                               placeholder="Type the year"
//                               required
//                             />
//                             {errors.academicYear && (
//                               <div className="invalid-feedback">
//                                 {errors.academicYear}
//                               </div>
//                             )}
//                             <datalist id="datalistOptions">
//                               <option value="2022">2022</option>
//                               <option value="2023">2023</option>
//                               <option value="2024">2024</option>
//                             </datalist>
//                           </div>
//                         </div>

//                         <div className="form-row">
//                           <div className="form-group col-md-12">
//                             <label htmlFor="sub-task">Terms</label>
//                             <input
//                               className={`form-control ${
//                                 errors.terms ? "is-invalid" : ""
//                               }`}
//                               type="text"
//                               name="terms"
//                               value={formData.terms}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.terms && (
//                               <div className="invalid-feedback">
//                                 {errors.terms}
//                               </div>
//                             )}
//                           </div>
//                         </div>

//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="task-title">Starting Date</label>
//                             <input
//                               className={`datepicker-here form-control digits ${
//                                 errors.startDate ? "is-invalid" : ""
//                               }`}
//                               type="date"
//                               data-language="en"
//                               placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="startDate"
//                               value={formData.startDate}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.startDate && (
//                               <div className="invalid-feedback">
//                                 {errors.startDate}
//                               </div>
//                             )}
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label htmlFor="task-title">Ending Date</label>
//                             <input
//                               className={`datepicker-here form-control digits ${
//                                 errors.endDate ? "is-invalid" : ""
//                               }`}
//                               type="date"
//                               data-language="en"
//                               placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="endDate"
//                               value={formData.endDate}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.endDate && (
//                               <div className="invalid-feedback">
//                                 {errors.endDate}
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {activeStep === 2 && (
//                 <div className="margin_top1">
//                   <h6 className="grade">Select Grade</h6>
//                   <div className="grade-checkboxes">
//                     {Object.keys(grades).map((grade) => (
//                       <div key={grade} className="grade-checkbox">
//                         <label>
//                           <input
//                             type="checkbox"
//                             checked={grades[grade]}
//                             onChange={() => handleGradeClick(grade)}
//                           />
//                           {"   "}
//                           {grade}
//                         </label>
//                         {selectedGrade === grade && (
//                           <div>
//                             <h6>Select Subject</h6>
//                             {subjects[grade].map((subject) => (
//                               <div key={subject} className="subject-checkbox">
//                                 <label>
//                                   <input
//                                     type="checkbox"
//                                     checked={selectedSubjects.includes(subject)}
//                                     onChange={() => handleSubjectClick(subject)}
//                                   />
//                                   {"   "}
//                                   {subject}
//                                 </label>
//                               </div>
//                             ))}
//                           </div>
//                         )}
//                       </div>
//                     ))}
//                   </div>
//                   {selectedSubjects ? (
//                     <div className="error-message">{errors.subjectGrade}</div>
//                   ) : null}
//                 </div>
//               )}

//               {activeStep === 3 && (
//                 <div className="col-sm-12">
//                   <div className="margin_top">
//                     <div className="card-body header-card">
//                       <form
//                         className="form-bookmark needs-validation"
//                         id="bookmark-form"
//                         noValidate
//                         onSubmit={handleSubmit}
//                       >
//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="start-time">Start Time</label>
//                             <input
//                               className={`timepicker-here form-control digits ${
//                                 errors.startTime ? "is-invalid" : ""
//                               }`}
//                               type="time"
//                               data-language="en"
//                               // placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="startTime"
//                               value={formData.startTime}
//                               onChange={handleInputChange}
//                             />
//                             {errors.startTime && (
//                               <div className="invalid-feedback">
//                                 {errors.startTime}
//                               </div>
//                             )}
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label for="task-title">Ending Tme</label>
//                             <input
//                               className={`Timepicker-here form-control digits ${
//                                 errors.endTime ? "is-invalid" : ""
//                               }`}
//                               type="time"
//                               data-language="en"
//                               // placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="endTime"
//                               value={formData.endTime}
//                               onChange={handleInputChange}
//                             />
//                             {errors.endTime && (
//                               <div className="invalid-feedback">
//                                 {errors.endTime}
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                         <div className="row">
//                         <div className="form-group col-md-6">
//                           <label for="sub-task">Duration</label>
//                           <input
//                             className={`form-control ${
//                               errors.duration ? "is-invalid" : ""
//                             }`}
//                             type="text"
//                             name="duration"
//                             value={formData.duration}
//                             onChange={handleInputChange}
//                           />
//                           {errors.duration && (
//                             <div className="invalid-feedback">
//                               {errors.duration}
//                             </div>
//                           )}
//                         </div>
//                         <div className="form-group col-md-6">
//                           <label for="sub-task">Max Mark</label>
//                           <input
//                             className={`form-control ${
//                               errors.maxMark ? "is-invalid" : ""
//                             }`}
//                             type="text"
//                             name="maxMark"
//                             value={formData.maxMark}
//                             onChange={handleInputChange}
//                           />
//                           {errors.maxMark && (
//                             <div className="invalid-feedback">
//                               {errors.maxMark}
//                             </div>
//                           )}
//                         </div>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               )}

//               <ButtonsContainer>
//                 <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
//                   Previous
//                 </ButtonStyle>
//                 <ButtonStyle
//                   onClick={nextStep}

//                   className={errors.subjectGrade ? "error-button" : ""}
//                 >
//                   Next
//                 </ButtonStyle>

//               </ButtonsContainer>
//             </MainContainer>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreateNewOfflineAssesment;

import React, { useState } from "react";
import styled from "styled-components";
import "../../assets/css/CreateExam.css";
import "../../assets/css/OfflineAssesment.css";
import { useNavigate } from "react-router-dom";

const MainContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 0px;
`;

const StepContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  position: relative;
  :before {
    content: "";
    position: absolute;
    background: #f3e7f3;
    height: 5px;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: "";
    position: absolute;
    background: #adc925;
    height: 4px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

const StepWrapper = styled.div`
  position: relative;
  z-index: 1;
  // flex: 1; /* Ensure each step takes equal space */
  // display: flex;
  // justify-content: center;
  // align-items: center;
`;

const StepStyle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ step }) =>
    step === "completed" ? "#adc925" : "#ffffff"};
  border: 3px solid
    ${({ step }) => (step === "completed" ? "#adc925" : "#adc925")};
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StepCount = styled.span`
  font-size: 19px;
  color: #78266a;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const StepsLabelContainer = styled.div`
  position: absolute;
  top: 66px;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StepLabel = styled.span`
  font-size: 19px;
  color: #79266a;
  @media (max-width: 600px) {
    font-size: 16px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 50px;
  margin-bottom: 20px;
`;

const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: #79266a;
  color: #ffffff;
  cursor: pointer;
  padding: 16px;
  width: 90px;
  :active {
    transform: scale(0.98);
    border: #79266a;
  }
  :disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
`;

const CheckMark = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: ${({ completed }) =>
    completed ? "white" : "white"}; /* White tick color for completed step */
  -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
  -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
  transform: scaleX(-1) rotate(-46deg);
`;

const steps = [
  {
    label: "Step1",
    step: 1,
  },
  {
    label: "Step2",
    step: 2,
  },
  {
    label: "Step3",
    step: 3,
  },
];

const CreateNewOfflineAssesment = () => {
  const history = useNavigate();
  const [activeStep, setActiveStep] = useState(1);

  const nextStep = () => {
    // Perform validation before proceeding to the next step
    const validationErrors = {};
    // Step 1 validation checks (if any)
    if (activeStep === 1) {
      if (!formData.examName.trim()) {
        validationErrors.examName = "Exam name is required";
      }
      if (!formData.academicYear.trim()) {
        validationErrors.academicYear = "Academic year is required";
      }
      if (!formData.terms.trim()) {
        validationErrors.terms = "Terms are required";
      }
      if (!formData.startDate.trim()) {
        validationErrors.startDate = "Starting date is required";
      }
      if (!formData.endDate.trim()) {
        validationErrors.endDate = "Ending date is required";
      }
    }

    // Step 2 validation checks
    if (activeStep === 2) {
      // Example validation check for selectedGrade and selectedSubject
      if (Object.keys(selectedSubjects).length === 0) {
        validationErrors.subjectGrade =
          "Please select grade and at least one subject.";
      }
    }

    if (activeStep === 3) {
      selectedSubjects.forEach((subject, index) => {
        if (!subject.startTime.trim()) {
          validationErrors[`startTime-${index}`] = "Start time is required";
        }
        if (!subject.endTime.trim()) {
          validationErrors[`endTime-${index}`] = "End time is required";
        }
        if (!subject.duration.trim()) {
          validationErrors[`duration-${index}`] = "Duration is required";
        }
        if (!subject.maxMark.trim()) {
          validationErrors[`maxMark-${index}`] = "Max mark is required";
        }
      });
    }

    // If there are validation errors, set the errors state and return
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Do not proceed to the next step
    }
    if (activeStep === totalSteps) {
      // Navigate to the "/OfflineAssessment" route
      history("/OfflineAssessment");
    } else {
      setActiveStep(activeStep + 1);
    }

    // If no validation errors, proceed to the next step
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  const [formData, setFormData] = useState({
    examName: "",
    academicYear: "",
    terms: "",
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    duration: "",
    maxMark: "",
  });

  // State variable to hold form validation errors
  const [errors, setErrors] = useState({});

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = {};
    // Your validation logic
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      console.log("Form submitted:", formData);
      setFormData({
        examName: "",
        academicYear: "",
        terms: "",
        startDate: "",
        endDate: "",
        startTime: "",
        endTime: "",
        duration: "",
        maxMark: "",
      });
      setErrors({});
    }
  };

  const [grades, setGrades] = useState({
    "10A": false,
    "10B": false,
    "10C": false,
    "10D": false,
  });
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [subjects, setSubjects] = useState({
    "10A": ["Maths", "Physics", "Chemistry"],
    "10B": ["Biology", "History", "Geography"],
    "10C": ["Biology", "History", "Geography"],
    "10D": ["Biology", "History", "Geography"],
    // Add subjects for other grades as needed
  });

  const handleGradeClick = (grade) => {
    setGrades((prevGrades) => {
      const updatedGrades = {};
      for (const key in prevGrades) {
        updatedGrades[key] = key === grade ? !prevGrades[key] : false;
      }
      return updatedGrades;
    });
    setSelectedGrade((prev) => (prev === grade ? "" : grade));
    setSelectedSubjects([]);
  };

  const handleSubjectClick = (subject) => {
    setSelectedSubjects((prev) =>
      prev.includes(subject)
        ? prev.filter((item) => item !== subject)
        : [...prev, subject]
    );
  };

  const handleSubjectClick1 = (subject) => {
    setSelectedSubjects((prev) => {
      if (prev.find((s) => s.subject === subject)) {
        // Deselect subject
        return prev.filter((s) => s.subject !== subject);
      } else {
        // Select subject and initialize its details
        return [
          ...prev,
          {
            subject: subject,
            startTime: "",
            endTime: "",
            duration: "",
            maxMark: "",
          },
        ];
      }
    });
  };

  const handleSubjectInputChange = (index, e) => {
    const { name, value } = e.target;
    setSelectedSubjects((prev) =>
      prev.map((subject, i) =>
        i === index ? { ...subject, [name]: value } : subject
      )
    );

    // Clear the error message associated with the input field being edited
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[`${name}-${index}`];
      return updatedErrors;
    });
  };

  // const handleSubjectInputChange = (index, e) => {
  //   const { name, value } = e.target;
  //   setSelectedSubjects((prev) =>
  //     prev.map((subject, i) =>
  //       i === index ? { ...subject, [name]: value } : subject
  //     )
  //   );

  // };

  const renderSubjectDetailsInputs = () => {
    return selectedSubjects.map((subject, index) => (
      <div key={index}>
        <h6>{subject.subject}</h6>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor={`start-time-${index}`}>
              {subject.subject} Start Time
            </label>
            <input
              className={`timepicker-here form-control digits ${
                errors[`startTime-${index}`] ? "is-invalid" : ""
              }`}
              // className="form-control"
              type="time"
              name="startTime"
              value={subject.startTime}
              onChange={(e) => handleSubjectInputChange(index, e)}
            />
            {errors[`startTime-${index}`] && (
              <span className="invalid-feedback">
                {errors[`startTime-${index}`]}
              </span>
            )}
          </div>
          <div className="form-group col-md-6">
            <label htmlFor={`end-time-${index}`}>
              {subject.subject} Ending Time
            </label>
            <input
              className={`timepicker-here form-control digits ${
                errors[`endTime-${index}`] ? "is-invalid" : ""
              }`}
              // className="form-control"
              type="time"
              name="endTime"
              value={subject.endTime}
              onChange={(e) => handleSubjectInputChange(index, e)}
            />
            {errors[`endTime-${index}`] && (
              <span className="invalid-feedback">
                {errors[`endTime-${index}`]}
              </span>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor={`duration-${index}`}>
              {subject.subject} Duration
            </label>
            <input
              className={`form-control ${
                errors[`duration-${index}`] ? "is-invalid" : ""
              }`}
              // className="form-control"
              type="text"
              name="duration"
              value={subject.duration}
              onChange={(e) => handleSubjectInputChange(index, e)}
            />
            {errors[`duration-${index}`] && (
              <span className="invalid-feedback">
                {errors[`duration-${index}`]}
              </span>
            )}
          </div>

          <div className="form-group col-md-6">
            <label htmlFor={`max-mark-${index}`}>
              {subject.subject} Max Mark
            </label>
            <input
              className={`form-control ${
                errors[`maxMark-${index}`] ? "is-invalid" : ""
              }`}
              // className="form-control"
              type="text"
              name="maxMark"
              value={subject.maxMark}
              onChange={(e) => handleSubjectInputChange(index, e)}
            />
            {errors[`maxMark-${index}`] && (
              <span className="invalid-feedback">
                {errors[`maxMark-${index}`]}
              </span>
            )}
          </div>
        </div>
      </div>
    ));
  };

  return (
    <div className="container-fluid">
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <h5 className="flex-grow-1">Create Offline Assesment</h5>
            </div>
            <MainContainer>
              <StepContainer width={width}>
                {steps.map(({ step, label }) => (
                  <StepWrapper key={step}>
                    <StepStyle
                      step={activeStep >= step ? "completed" : "incomplete"}
                    >
                      {activeStep > step ? (
                        <CheckMark>L</CheckMark>
                      ) : (
                        <StepCount>{step}</StepCount>
                      )}
                    </StepStyle>
                    <StepsLabelContainer>
                      <StepLabel key={step}>{label}</StepLabel>
                    </StepsLabelContainer>
                  </StepWrapper>
                ))}
              </StepContainer>

              {activeStep === 1 && (
                <div className="col-md-12">
                  <div className="margin_top">
                    <div style={{ marginTop: "40px" }}>
                      <form
                        className="form-bookmark needs-validation"
                        id="bookmark-form"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleDataList">Name</label>
                            <input
                              className={`form-control ${
                                errors.examName ? "is-invalid" : ""
                              }`}
                              list="exam"
                              id="exampleDataList"
                              name="examName"
                              value={formData.examName}
                              onChange={handleInputChange}
                              placeholder="Type the exam name"
                              required
                            />

                            {errors.examName && (
                              <div className="invalid-feedback">
                                {errors.examName}
                              </div>
                            )}
                            <datalist id="exam">
                              <option value="Exam1">Exam1</option>
                              <option value="Exam2">Exam2</option>
                              <option value="Exam3">Exam3</option>
                            </datalist>
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="exampleDataList">
                              Academic Year
                            </label>
                            <input
                              className={`form-control ${
                                errors.academicYear ? "is-invalid" : ""
                              }`}
                              list="datalistOptions"
                              id="exampleDataList"
                              name="academicYear"
                              value={formData.academicYear}
                              onChange={handleInputChange}
                              placeholder="Type the year"
                              required
                            />
                            {errors.academicYear && (
                              <div className="invalid-feedback">
                                {errors.academicYear}
                              </div>
                            )}
                            <datalist id="datalistOptions">
                              <option value="2022">2022</option>
                              <option value="2023">2023</option>
                              <option value="2024">2024</option>
                            </datalist>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group col-md-6">
                            <label htmlFor="task-title">Starting Date</label>
                            <input
                              className={`datepicker-here form-control digits ${
                                errors.startDate ? "is-invalid" : ""
                              }`}
                              type="date"
                              data-language="en"
                              placeholder="MM-DD-YYYY"
                              data-position="top left"
                              name="startDate"
                              value={formData.startDate}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.startDate && (
                              <div className="invalid-feedback">
                                {errors.startDate}
                              </div>
                            )}
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="task-title">Ending Date</label>
                            <input
                              className={`datepicker-here form-control digits ${
                                errors.endDate ? "is-invalid" : ""
                              }`}
                              type="date"
                              data-language="en"
                              placeholder="MM-DD-YYYY"
                              data-position="top left"
                              name="endDate"
                              value={formData.endDate}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.endDate && (
                              <div className="invalid-feedback">
                                {errors.endDate}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="sub-task">Terms</label>
                            <input
                              className={`form-control ${
                                errors.terms ? "is-invalid" : ""
                              }`}
                              type="text"
                              name="terms"
                              value={formData.terms}
                              onChange={handleInputChange}
                              required
                            />
                            {errors.terms && (
                              <div className="invalid-feedback">
                                {errors.terms}
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div className="margin_top1">
                  <h6 className="grade">Select Grade</h6>
                  <div className="grade-checkboxes">
                    {Object.keys(grades).map((grade) => (
                      <div key={grade} className="grade-checkbox">
                        <label>
                          <input
                            type="checkbox"
                            checked={grades[grade]}
                            onChange={() => handleGradeClick(grade)}
                          />
                          {"   "}
                          {grade}
                        </label>
                        {selectedGrade === grade && (
                          <div>
                            <h6>Select Subject</h6>
                            <div
                              className="row"
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                display: "flex",
                              }}
                            >
                              {subjects[selectedGrade].map((subject) => (
                                <div className="row col-md-4">
                                  <div
                                    key={subject}
                                    className="subject-checkbox"
                                  >
                                    <label>
                                      <input
                                        type="checkbox"
                                        checked={selectedSubjects.find(
                                          (s) => s.subject === subject
                                        )}
                                        onChange={() =>
                                          handleSubjectClick1(subject)
                                        }
                                      />
                                      {"   "}
                                      {subject}
                                    </label>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                  {selectedSubjects.length === 0 && (
                    <div className="error-message">{errors.subjectGrade}</div>
                  )}
                </div>
              )}

              {activeStep === 3 && (
                <div className="col-sm-12">
                  <div className="margin_top">
                    <div className="card-body header-card">
                      <form
                        className="form-bookmark needs-validation"
                        id="bookmark-form"
                        noValidate
                        onSubmit={handleSubmit}
                      >
                        {renderSubjectDetailsInputs()}
                      </form>
                    </div>
                  </div>
                </div>
              )}

              <ButtonsContainer>
                <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
                  Previous
                </ButtonStyle>
                <ButtonStyle
                  onClick={nextStep}
                  className={errors.subjectGrade ? "error-button" : ""}
                >
                  Next
                </ButtonStyle>
              </ButtonsContainer>
            </MainContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNewOfflineAssesment;

// import React, { useState } from "react";
// import styled from "styled-components";
// import "../../assets/css/CreateExam.css";
// import "../../assets/css/OfflineAssesment.css";
// import { useNavigate} from "react-router-dom";

// const MainContainer = styled.div`
//   width: 100%;
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 0 16px;
// `;

// const StepContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 40px;
//   position: relative;
//   :before {
//     content: "";
//     position: absolute;
//     background: #f3e7f3;
//     height: 5px;
//     width: 100%;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 0;
//   }
//   :after {
//     content: "";
//     position: absolute;
//     background: #adc925;
//     height: 4px;
//     width: ${({ width }) => width};
//     top: 50%;
//     transition: 0.4s ease;
//     transform: translateY(-50%);
//     left: 0;
//   }
// `;

// const StepWrapper = styled.div`
//   position: relative;
//   z-index: 1;
//   // flex: 1; /* Ensure each step takes equal space */
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
// `;

// const StepStyle = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: ${({ step }) => (step === "completed" ? "#adc925" : "#ffffff")};
//   border: 3px solid ${({ step }) => (step === "completed" ? "#adc925" : "#adc925")};
//   transition: 0.4s ease;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// const StepCount = styled.span`
//   font-size: 19px;
//   color: black;
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

// const StepsLabelContainer = styled.div`
//   position: absolute;
//   top: 66px;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

// const StepLabel = styled.span`
//   font-size: 19px;
//   color: #79266a;
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

// const ButtonsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin: 0 -15px;
//   margin-top: 50px;
//   margin-bottom: 20px;
// `;

// const ButtonStyle = styled.button`
//   border-radius: 4px;
//   border: 0;
//   background: #79266a;
//   color: #ffffff;
//   cursor: pointer;
//   padding: 16px;
//   width: 90px;
//   :active {
//     transform: scale(0.98);
//   }
//   :disabled {
//     background: #f3e7f3;
//     color: #000000;
//     cursor: not-allowed;
//   }
// `;

// const CheckMark = styled.div`
//   font-size: 26px;
//   font-weight: 600;
//   color: ${({ completed }) => (completed ? 'white' : 'white')}; /* White tick color for completed step */
//   -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
//   -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
//   transform: scaleX(-1) rotate(-46deg);
// `;

// const steps = [
//   {
//     label: "Step1",
//     step: 1,
//   },
//   {
//     label: "Step2",
//     step: 2,
//   },
//   {
//     label: "Step3",
//     step: 3,
//   },

// ];

// const CreateNewOfflineAssesment = () => {
//   const history = useNavigate();
//   const [activeStep, setActiveStep] = useState(1);

//   const nextStep = () => {
//     // Perform validation before proceeding to the next step
//     const validationErrors = {};

//     // Step 1 validation checks (if any)
//     if (activeStep === 1) {
//       if (!formData.examName.trim()) {
//         validationErrors.examName = "Exam name is required";
//       }
//       if (!formData.academicYear.trim()) {
//         validationErrors.academicYear = "Academic year is required";
//       }
//       if (!formData.terms.trim()) {
//         validationErrors.terms = "Terms are required";
//       }
//       if (!formData.startDate.trim()) {
//         validationErrors.startDate = "Starting date is required";
//       }
//       if (!formData.endDate.trim()) {
//         validationErrors.endDate = "Ending date is required";
//       }
//     }

//     // Step 2 validation checks
//     if (activeStep === 2) {
//       // Example validation check for selectedGrade and selectedSubject
//       if (!selectedGrade || !selectedSubject) {
//         validationErrors.subjectGrade = "Please select both grade and subject.";
//       }
//     }
//     if (activeStep === 3) {
//       if (!formData.startTime.trim()) {
//         validationErrors.startTime = "Starting time is required";
//       }
//       if (!formData.endTime.trim()) {
//         validationErrors.endTime = "Starting date is required";
//       }
//       if (!formData.duration.trim()) {
//         validationErrors.duration = "duration are required";
//       }
//       if (!formData.maxMark.trim()) {
//         validationErrors.maxMark = "maxMark are required";
//       }
//     }

//     // If there are validation errors, set the errors state and return
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return; // Do not proceed to the next step
//     }
//     if (activeStep === totalSteps) {
//       // Navigate to the "/OfflineAssessment" route
//       history("/OfflineAssessment");
//   } else {
//       setActiveStep(activeStep + 1);
//   }

//     // If no validation errors, proceed to the next step
//     setActiveStep(activeStep + 1);
//   };

//   const prevStep = () => {
//     setActiveStep(activeStep - 1);
//   };

//   const totalSteps = steps.length;

//   const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

//   const [formData, setFormData] = useState({
//     examName: "",
//     academicYear: "",
//     terms: "",
//     startDate: "",
//     endDate: "",
//     startTime: "",
//     endTime: "",
//     duration: "",
//     maxMark: "",
//   });

//   // State variable to hold form validation errors
//   const [errors, setErrors] = useState({});

//   // Function to handle form input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     setErrors({
//       ...errors,
//       [name]: "",
//     });
//   };

//   // Function to handle form submission
//   // const handleSubmit = (e) => {
//   //   e.preventDefault();
//   //   // Perform validation
//   //   const validationErrors = {};
//   //   if (!formData.examName.trim()) {
//   //     validationErrors.examName = "Exam name is required";
//   //   }
//   //   if (!formData.academicYear.trim()) {
//   //     validationErrors.academicYear = "Academic year is required";
//   //   }
//   //   if (!formData.terms.trim()) {
//   //     validationErrors.terms = "Terms are required";
//   //   }
//   //   if (!formData.startDate.trim()) {
//   //     validationErrors.startDate = "Starting date is required";
//   //   }
//   //   if (!formData.endDate.trim()) {
//   //     validationErrors.endDate = "Ending date is required";
//   //   }
//   //   // If there are validation errors, set the errors state
//   //   if (Object.keys(validationErrors).length > 0) {
//   //     setErrors(validationErrors);
//   //   } else {
//   //     // Form is valid, you can proceed with form submission
//   //     // Here, you can perform further actions like API calls, etc.
//   //     console.log("Form submitted:", formData);
//   //     // Clear form data
//   //     setFormData({
//   //       examName: "",
//   //       academicYear: "",
//   //       terms: "",
//   //       startDate: "",
//   //       endDate: "",
//   //     });
//   //     // Clear errors
//   //     setErrors({});
//   //   }
//   // };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const validationErrors = {};
//     // Your validation logic
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       console.log("Form submitted:", formData);
//       setFormData({
//         examName: "",
//         academicYear: "",
//         terms: "",
//         startDate: "",
//         endDate: "",
//         startTime: "",
//         endTime: "",
//         duration: "",
//         maxMark: "",
//       });
//       setErrors({});
//     }
//   };

//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState("");

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade((prev) => (prev === grade ? "" : grade));
//     setSelectedSubject("");
//   };

//   const handleSubjectChange = (e) => {
//     setSelectedSubject(e.target.value);
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">
//           <div className="card">
//           <div className="card-header pb-0">
//           <h5 className="flex-grow-1">Create Offline Assesment</h5>
//           </div>
//             <MainContainer>
//               <StepContainer width={width}>
//                 {steps.map(({ step, label }) => (
//                   <StepWrapper key={step}>
//                     <StepStyle
//                       step={activeStep >= step ? "completed" : "incomplete"}
//                     >
//                       {activeStep > step ? (
//                         <CheckMark>L</CheckMark>
//                       ) : (
//                         <StepCount>{step}</StepCount>
//                       )}
//                     </StepStyle>
//                     <StepsLabelContainer>
//                       <StepLabel key={step}>{label}</StepLabel>
//                     </StepsLabelContainer>
//                   </StepWrapper>
//                 ))}
//               </StepContainer>

//               {activeStep === 1 && (
//                 <div className="col-sm-12">
//                   <div className="margin_top">
//                     <div className="card-body header-card">
//                       <form
//                         className="form-bookmark needs-validation"
//                         id="bookmark-form"
//                         noValidate
//                         onSubmit={handleSubmit}
//                       >
//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="exampleDataList">Name</label>
//                             <input
//                               className={`form-control ${
//                                 errors.examName ? "is-invalid" : ""
//                               }`}
//                               list="exam"
//                               id="exampleDataList"
//                               name="examName"
//                               value={formData.examName}
//                               onChange={handleInputChange}
//                               placeholder="Type the exam name"
//                               required
//                             />
//                             {errors.examName && (
//                               <div className="invalid-feedback">
//                                 {errors.examName}
//                               </div>
//                             )}
//                             <datalist id="exam">
//                               <option value="Exam1">Exam1</option>
//                               <option value="Exam2">Exam2</option>
//                               <option value="Exam3">Exam3</option>
//                             </datalist>
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label htmlFor="exampleDataList">
//                               Academic Year
//                             </label>
//                             <input
//                               className={`form-control ${
//                                 errors.academicYear ? "is-invalid" : ""
//                               }`}
//                               list="datalistOptions"
//                               id="exampleDataList"
//                               name="academicYear"
//                               value={formData.academicYear}
//                               onChange={handleInputChange}
//                               placeholder="Type the year"
//                               required
//                             />
//                             {errors.academicYear && (
//                               <div className="invalid-feedback">
//                                 {errors.academicYear}
//                               </div>
//                             )}
//                             <datalist id="datalistOptions">
//                               <option value="2022">2022</option>
//                               <option value="2023">2023</option>
//                               <option value="2024">2024</option>
//                             </datalist>
//                           </div>
//                         </div>

//                         <div className="form-row">
//                           <div className="form-group col-md-12">
//                             <label htmlFor="sub-task">Terms</label>
//                             <input
//                               className={`form-control ${
//                                 errors.terms ? "is-invalid" : ""
//                               }`}
//                               type="text"
//                               name="terms"
//                               value={formData.terms}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.terms && (
//                               <div className="invalid-feedback">
//                                 {errors.terms}
//                               </div>
//                             )}
//                           </div>
//                         </div>

//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="task-title">Starting Date</label>
//                             <input
//                               className={`datepicker-here form-control digits ${
//                                 errors.startDate ? "is-invalid" : ""
//                               }`}
//                               type="date"
//                               data-language="en"
//                               placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="startDate"
//                               value={formData.startDate}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.startDate && (
//                               <div className="invalid-feedback">
//                                 {errors.startDate}
//                               </div>
//                             )}
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label htmlFor="task-title">Ending Date</label>
//                             <input
//                               className={`datepicker-here form-control digits ${
//                                 errors.endDate ? "is-invalid" : ""
//                               }`}
//                               type="date"
//                               data-language="en"
//                               placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="endDate"
//                               value={formData.endDate}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.endDate && (
//                               <div className="invalid-feedback">
//                                 {errors.endDate}
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {activeStep === 2 && (
//                 <div className="margin_top1">
//                   <h6 className="grade">select Grade</h6>
//                   <div className="grade-checkboxes">
//                     {Object.keys(grades).map((grade) => (
//                       <div key={grade} className="grade-checkbox">
//                         <label>
//                           <input
//                             type="checkbox"
//                             checked={grades[grade]}
//                             onChange={() => handleGradeClick(grade)}
//                           />
//                           {"   "}
//                           {grade}
//                         </label>
//                         {selectedGrade === grade && (
//                           <div className="form-group">
//                             <label htmlFor="task-title">
//                               Selected Subject for {grade}
//                             </label>
//                             <select
//                               value={selectedSubject}
//                               onChange={handleSubjectChange}
//                               className="js-example-basic-single form-select"
//                             >
//                               <option value="">Select Subject</option>
//                               <option value="Maths">Maths</option>
//                               <option value="Physics">Physics</option>
//                             </select>
//                           </div>
//                         )}
//                       </div>
//                     ))}
//                   </div>
//                   {!selectedGrade || !selectedSubject ? (
//                     <div className="error-message">{errors.subjectGrade}</div>
//                   ) : null}
//                 </div>
//               )}

//               {activeStep === 3 && (
//                 <div className="col-sm-12">
//                   <div className="margin_top">
//                     <div className="card-body header-card">
//                       <form
//                         className="form-bookmark needs-validation"
//                         id="bookmark-form"
//                         noValidate
//                         onSubmit={handleSubmit}
//                       >
//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="start-time">Start Time</label>
//                             <input
//                               className={`timepicker-here form-control digits ${
//                                 errors.startTime ? "is-invalid" : ""
//                               }`}
//                               type="time"
//                               data-language="en"
//                               // placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="startTime"
//                               value={formData.startTime}
//                               onChange={handleInputChange}
//                             />
//                             {errors.startTime && (
//                               <div className="invalid-feedback">
//                                 {errors.startTime}
//                               </div>
//                             )}
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label for="task-title">Ending Date</label>
//                             <input
//                               className={`datepicker-here form-control digits ${
//                                 errors.endTime ? "is-invalid" : ""
//                               }`}
//                               type="date"
//                               data-language="en"
//                               placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="endTime"
//                               value={formData.endTime}
//                               onChange={handleInputChange}
//                             />
//                             {errors.endTime && (
//                               <div className="invalid-feedback">
//                                 {errors.endTime}
//                               </div>
//                             )}
//                           </div>
//                         </div>

//                         <div className="form-group col-md-12">
//                           <label for="sub-task">Duration</label>
//                           <input
//                             className={`form-control ${
//                               errors.duration ? "is-invalid" : ""
//                             }`}
//                             type="text"
//                             name="duration"
//                             value={formData.duration}
//                             onChange={handleInputChange}
//                           />
//                           {errors.duration && (
//                             <div className="invalid-feedback">
//                               {errors.duration}
//                             </div>
//                           )}
//                         </div>
//                         <div className="form-group col-md-12">
//                           <label for="sub-task">Max Mark</label>
//                           <input
//                             className={`form-control ${
//                               errors.maxMark ? "is-invalid" : ""
//                             }`}
//                             type="text"
//                             name="maxMark"
//                             value={formData.maxMark}
//                             onChange={handleInputChange}
//                           />
//                           {errors.maxMark && (
//                             <div className="invalid-feedback">
//                               {errors.maxMark}
//                             </div>
//                           )}
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               )}

//               <ButtonsContainer>
//                 <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
//                   Previous
//                 </ButtonStyle>
//                 <ButtonStyle
//                   onClick={nextStep}

//                   className={errors.subjectGrade ? "error-button" : ""}
//                 >
//                   Next
//                 </ButtonStyle>
//               </ButtonsContainer>
//             </MainContainer>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreateNewOfflineAssesment;
