import React, { useEffect } from "react";
import { ArrowLeft, Minus, Plus } from "react-feather";
import { Link } from "react-router-dom";

function CreateQuestion() {
  useEffect(() => {
    document.title = "Edsys | CreateQuestion ";
  }, []);
  return (
    <div class="create-ques">
      {/* <!-- Container-fluid starts--> */}
      <div class="row assignment-sec">
        <div class="col-sm-12">
          <div class="card br-15 assessment-sec">
            <div class="card-header pb-0">
              <h5>
                <Link to="/AddQuestion">
                  <ArrowLeft />
                </Link>{" "}
                Create New Question
              </h5>
            </div>
            <div class="card-body datetime-picker">
              <form
                class="form-bookmark needs-validation"
                id="bookmark-form"
                novalidate=""
              >
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="task-title">Select Question Type</label>
                    <select class="js-example-basic-single form-select">
                      <option value="task">
                        Multiple Choice Questions (Multiple Answer)
                      </option>
                      <option value="task">
                        Multiple Choice Questions (Single Answer)
                      </option>
                      <option value="task">Match the following</option>
                      <option value="task">Sort the following</option>
                    </select>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Assessment Title</label>
                    <input
                      class="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="sub-task">Instructions</label>
                    <textarea
                      class="form-control"
                      required=""
                      autocomplete="off"
                    >
                      {" "}
                    </textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Question</label>
                    <input
                      class="form-control"
                      id="task-title"
                      type="text"
                      required=""
                      autocomplete="off"
                    />
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Create Option</label>
                    <div class="multi-field-wrapper">
                      <div class="multi-fields">
                        <div class="multi-field d-flex">
                          <input
                            class="form-control"
                            type="text"
                            name="stuff[]"
                          />
                          <div class="remove-field">
                            <Minus />
                          </div>
                        </div>
                      </div>
                      <div type="button" class="add-field">
                        <Plus />
                        <span>Add Option</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col">
                      <label for="task-title">Select Correct Answer</label>
                      <select class="js-example-basic-multiple col-sm-12 form-select">
                        <option value="AL">Option 1</option>
                        <option value="WY">Option 2</option>
                        <option value="WY">Option 3</option>
                        <option value="WY">Option 4</option>
                      </select>
                    </div>
                    <div class="form-group col">
                      <label for="task-title">Score</label>
                      <input
                        class="form-control"
                        id="task-title"
                        type="text"
                        required=""
                        autocomplete="off"
                      />
                    </div>
                  </div>
                  <div class="form-group col-md-12 add-qn">
                    <a href="#">Add Question</a>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="sub-task">Answer Clarification</label>
                    <textarea
                      class="form-control"
                      required=""
                      autocomplete="off"
                    >
                      {" "}
                    </textarea>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="task-title">Dificuty Level</label>
                    <div class="row">
                      <div class="col-md-4">
                        <div class="radio-button">
                          <div class="inputGroup">
                            <input id="radio1" name="radio" type="radio" />
                            <label for="radio1">Easy</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="radio-button">
                          <div class="inputGroup">
                            <input id="radio2" name="radio" type="radio" />
                            <label for="radio2">Normal</label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="radio-button">
                          <div class="inputGroup">
                            <input id="radio3" name="radio" type="radio" />
                            <label for="radio3">Dificult</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-footer d-flex justify-content-end">
              <button
                class="btn btn-secondary"
                type="button"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <Link to="/AddQuestion">
                <button
                  class="btn btn-primary ml-2"
                  id="Bookmark"
                  type="submit"
                >
                  Submit
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}
export default CreateQuestion;
