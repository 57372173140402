import React from 'react'
import assignment_data from '../api/assignment_data.json'

function AssignmentsTable() {
    return (
        <div className="row edsys-table">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <h5>Assignments</h5>
            </div>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Title	</th>
                    <th scope="col">Grade</th>
                    <th scope="col">Pending</th>
                    <th scope="col">Submitted</th>
                    <th scope="col">Rejected</th>
                    <th scope="col">Reviewed</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                {assignment_data.map((postDetail, index) => {
        return (
                  <tr>
                    <th scope="row">{postDetail.sr_no}</th>
                    <td>{postDetail.assignment_title}</td>
                    <td>{postDetail.grade}</td>
                    <td>{postDetail.assignment_pending}</td>
                    <td>{postDetail.assignment_submitted}</td>
                    <td>{postDetail.assignment_rejected}</td>
                    <td>{postDetail.assignment_reviewed}</td>
                    <td>{postDetail.assignment_total}</td>
                  </tr>
                 );
                })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        </div>
    )
}

export default AssignmentsTable
