import React from "react";
import UserPic from "../../assets/images/dashboard/1.png";

const LeaveReq = () => {
  return (
    <>
      <div className=" card library announcementnew p-4">
        <h4>Leave Approved</h4>
        {/* <!-- Container-fluid starts--> */}
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <div className="my-books">
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        padding: "16px",
                        borderRadius: 10,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      className="shbg col-md-12"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <img
                          src={UserPic}
                          alt=""
                          style={{ height: "25px", width: "25px" }}
                        />
                        <p
                          style={{
                            fontSize: "12px",
                            color: "white",
                            marginLeft: "5px",
                          }}
                        >
                          Rinku
                        </p>
                      </div>
                      <p style={{ fontSize: "12px", color: "#72A44B" }}>
                        8 leaves
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        backgroundColor: "#FFFBE1",
                        marginTop: "20px",
                        borderRadius: "10px",
                        borderWidth: 5,
                        borderColor: "#EF5934",
                        border: "2px solid #EF5934",
                        borderTopWidth: 0,
                        borderLeftWidth: 5,
                        borderRight: 0,
                        borderBottom: 0,
                        padding: "10px",
                        flexDirection: "column",
                      }}
                    >
                      <p style={{ fontSize: "16px", fontWeight: "500" }}>
                        Leave Status
                      </p>
                      <div
                        style={{
                          display: "flex",
                          marginTop: 1,
                          border: "0.1px solid #707070", // Specify your border properties

                          opacity: 0.1, // Specify your height
                        }}
                      ></div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginTop: "10px",
                        }}
                      >
                        <p>Planned</p>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p style={{ color: "#772569" }}>9 / </p>
                          <p>10</p>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginTop: "-15px",
                        }}
                      >
                        <p>Sickleaves</p>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <p style={{ color: "#772569" }}>0 / </p>
                          <p>10</p>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ width: "50%" }}>From</p>
                      <p style={{ width: "50%" }}>To</p>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <input
                        type="text"
                        placeholder="2023-09-06"
                        class="form-control"
                        style={{
                          backgroundColor: "white",
                          marginRight: "5px",
                          width: "50%",
                          borderRadius: "50px",
                          height: "40px",
                          alignItems: "center",
                          paddingTop: "10px",
                          textAlign: "center",
                        }}
                      />
                      <input
                        type="text"
                        placeholder="2023-09-06"
                        class="form-control"
                        style={{
                          backgroundColor: "white",
                          marginRight: "5px",
                          width: "50%",
                          borderRadius: "50px",
                          height: "40px",
                          alignItems: "center",
                          paddingTop: "10px",
                          textAlign: "center",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ width: "50%" }}>Applied Date</p>
                      <p style={{ width: "50%" }}>No.of Days</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="2023-09-05"
                        class="form-control"
                        style={{
                          backgroundColor: "white",
                          marginRight: "5px",
                          width: "50%",
                          borderRadius: "50px",
                          height: "40px",
                          alignItems: "center",
                          paddingTop: "10px",
                          textAlign: "center",
                        }}
                      />
                      <input
                        type="text"
                        placeholder="1"
                        class="form-control"
                        style={{
                          backgroundColor: "white",
                          marginRight: "5px",
                          width: "50%",
                          borderRadius: "50px",
                          height: "40px",
                          alignItems: "center",
                          paddingTop: "10px",
                          textAlign: "center",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ width: "50%" }}>Leave Type</p>
                      <p style={{ width: "50%" }}>Leave Category</p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                      }}
                    >
                      <input
                        type="text"
                        placeholder="full-day"
                        class="form-control"
                        style={{
                          backgroundColor: "white",
                          marginRight: "5px",
                          width: "50%",
                          borderRadius: "50px",
                          height: "40px",
                          alignItems: "center",
                          paddingTop: "10px",
                          textAlign: "center",
                        }}
                      />
                      <input
                        type="text"
                        placeholder="Planned"
                        class="form-control"
                        style={{
                          backgroundColor: "white",
                          marginRight: "5px",
                          width: "50%",
                          borderRadius: "50px",
                          height: "40px",
                          alignItems: "center",
                          paddingTop: "10px",
                          textAlign: "center",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: "10px",
                      }}
                    >
                      <p style={{ width: "100%" }}>Leave Reason</p>
                    </div>
                    <input
                      type="text"
                      placeholder="Sick"
                      class="form-control"
                      style={{
                        backgroundColor: "white",
                        marginRight: "5px",
                        width: "100%",
                        borderRadius: "50px",
                        height: "40px",
                        alignItems: "center",
                        paddingTop: "10px",
                        // textAlign: "center",
                        marginBottom: "30px",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Container-fluid Ends--> */}
      </div>
    </>
  );
};

export default LeaveReq;
