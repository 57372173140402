import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import '../src/assets/css/main.css';
import '../src/components/ExternalScripts';
ReactDOM.render(
  <>
    <App />
   </>
,
  document.getElementById('root')
);


