
import React from 'react';
import '../../assets/css/select2.css'

const AddEditTodo = props => {
const displayStyle={
  display:"block"
}


    return (
        <div className="modal fade modal-bookmark show testingll" style={displayStyle}  id="dvAddEditTodo" tabIndex="-1" role="dialog" aria-labelledby="dvAddEditTodoLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">Add Task</h5>
              <button className="btn-close" type="button" onClick={props.onClose} data-bs-dismiss="modal" aria-label="Close">                                                 </button>
            </div>
            <div className="modal-body datetime-picker">
              <form className="form-bookmark needs-validation" id="bookmark-form" noValidate="">
                <div className="form-row">
                  <div className="form-group col-md-12">
                    <label htmlFor="task-title">Task Title</label>
                    <input className="form-control" id="task-title" type="text" required="" autocomplete="off" />
                  </div>
                  <div className="form-group col-md-12">
                    <label htmlFor="sub-task">Sub task</label>
                    <input className="form-control" id="sub-task" type="text" required="" autocomplete="off" />
                  </div>
                  <div className="form-group col-md-12">
                    <div className="d-flex date-details">
                      <div className="d-inline-block">
                        <label className="d-block mb-0">
                        <input className="checkbox_animated" type="checkbox" />Remind on
                        </label>
                      </div>
                      <div className="d-inline-block">
                        <input className="datepicker-here form-control digits" type="text" data-language="en" placeholder="Date" />
                      </div>
                      <div className="d-inline-block">
                        <div className="input-group date" id="dt-time" data-target-input="nearest">
                          <input className="form-control datetimepicker-input digits" type="text" data-target="#dt-time" />
                          <div className="input-group-text" data-target="#dt-time" data-toggle="datetimepicker"><i className="fa fa-clock-o"></i></div>
                        </div>
                      </div>
                      <div className="d-inline-block">
                        <label className="d-block mb-0">
                        <input className="checkbox_animated" type="checkbox" />Notification
                        </label>
                      </div>
                      <div className="d-inline-block">
                        <label className="d-block mb-0">
                        <input className="checkbox_animated" type="checkbox" />Mail
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col">
                      <select className="js-example-basic-single form-select">
                        <option value="task">My Task</option>
                      </select>
                    </div>
                    <div className="form-group col">
                      <select className="js-example-disabled-results form-select" id="bm-collection">
                        <option value="general">General</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group col-md-12 my-0">
                    <textarea className="form-control" required="" autoComplete='off' value={""}> </textarea>
                  </div>
                </div>
                <input id="index_var" type="hidden" value="6" />
                <button className="btn btn-secondary" id="Bookmark"  type="submit">Save</button>
                <button className="btn btn-primary" type="button" style={{marginLeft:"4px"}} data-bs-dismiss="modal">Cancel</button>
              </form>
            </div>
          </div>
        </div>
        </div>
    )
}

export default AddEditTodo

  // <!-- Modal -->
{/* <div class="modal fade show" style={displayStyle} id="dvAddEditTodo" tabindex="-1" role="dialog" aria-labelledby="dvAddEditTodoLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="dvAddEditTodoLabel">Modal title</h5>
        <button type="button" onClick={props.onClose}  class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div> */}