
import b4 from '../../../../assets/images/user/b4.png'
import icon1 from '../../../../assets/images/assignment/review-icon-1.svg'
import { Clock, UploadCloud } from 'react-feather'

import React, { useState, useEffect } from 'react'
const Tabs1 = (props) => {
    const [happy, setHappy] = useState(false);
    const [sad, setSad] = useState(false);
    const [good, setGood] = useState(false);
    const [ok, setOk] = useState(false);
    const [angry, setAngry] = useState(false);


    return (
        <>
            <div >
                <div className="row rating-card">
                    <div className="col-md-6">
                        <div className="card br-15">
                            <div className="card-body">
                                <div className="review-card">
                                    <img className="title-icon" src={icon1} />
                                    <div className="review-title">Completeness</div>
                                    <div className="rating-container rating-blk digits">
                                        <select id="u-rating-1to10" name="rating" autocomplete="off">
                                            <option value="1" selected="selected">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                            <option value="10">10</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card br-15">
                            <div className="card-body">
                                <div className="review-card">
                                    <img className="title-icon" src={icon1} />
                                    <div className="review-title">Creativity</div>
                                    <div className="creativity-rating">
                                        <ul className="feedback">
                                            <li className={`angry ${angry?"active":null}`} id="angry" onClick={() =>{
                                                setAngry(!angry);
                                                setHappy(false);
                                                setOk(false);
                                                setSad(false);
                                                setGood(false);
                                            }
                                               }>
                                                <div>
                                                    <svg className="eye left">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                    <svg className="eye right">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                    <svg className="mouth">
                                                        <use xlinkHref="#mouth" />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className={`sad ${sad?"active":null}`} id="sad" onClick={() =>{
                                                setSad(!sad);
                                                setHappy(false);
                                                setOk(false);
                                                setAngry(false); 
                                                setGood(false);
                                            }
                                               }>
                                                <div>
                                                    <svg className="eye left">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                    <svg className="eye right">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                    <svg className="mouth">
                                                        <use xlinkHref="#mouth" />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li  className={`ok ${ok?"active":null}`} id="ok" onClick={() =>{
                                                setSad(false);
                                                setHappy(false);
                                                setOk(!ok);
                                                setAngry(false); 
                                                setGood(false);
                                            }
                                               }>
                                                <div></div>
                                            </li>
                                            <li className={`good ${good?"active":null}`} id="good" onClick={() =>{
                                                setSad(false);
                                                setHappy(false);
                                                setOk(false);
                                                setAngry(false); 
                                                setGood(!good);
                                            }
                                               }>
                                                <div>
                                                    <svg className="eye left">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                    <svg className="eye right">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                    <svg className="mouth">
                                                        <use xlinkHref="#mouth" />
                                                    </svg>
                                                </div>
                                            </li>
                                            <li className={`happy ${happy?"active":null}`}  id="happy"  onClick={()=>{
                                                setHappy(!happy)
                                                setSad(false);
                                                setOk(false);
                                                setAngry(false); 
                                                setGood(false);
                                            }}>
                                                <div>
                                                    <svg className="eye left">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                    <svg className="eye right">
                                                        <use xlinkHref="#eye" />
                                                    </svg>
                                                </div>
                                            </li>
                                        </ul>

                                        <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                                            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
                                                <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
                                            </symbol>
                                            <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
                                                <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
                                            </symbol>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card br-15">
                            <div className="card-body">
                                <div className="review-card">
                                    <img className="title-icon" src={icon1} />
                                    <div className="review-title">Neatness</div>
                                    <div className="slidecontainer">
                                        <div className="range-slderi">
                                            <input className="range-slider__range" type="range" value="250" min="0" max="500" step="50" />
                                            <span className="range-slider__value rangeSlider">250</span>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card br-15">
                            <div className="card-body">
                                <div className="review-card">
                                    <img className="title-icon" src={icon1} />
                                    <div className="review-title">Overall Rating</div>
                                    <div style={{ marginTop: '8px' }} className="rating-container">
                                        <select id="u-rating-fontawesome" name="rating" autocomplete="off">
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const Tabs2 = (props) => {

    return (
        <>
            {/* {Changes here,className='tab'} */}
            <div >
                <div className="row">
                    <div className="col-12">
                        <div className="mb-5 ass-comment">
                            <label className="form-label" for="exampleFormControlTextarea4">Comments</label>
                            <textarea className="form-control" id="exampleFormControlTextarea4" rows="3"></textarea>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-5">
                            <label>Upload Answer Feedback</label>
                            <div className="dropzone" id="singleFileUpload" action="/upload.php">
                                <div className="dz-message needsclick"><UploadCloud className='uploadCloud' />
                                    <h6>Drop files here or click to upload.</h6><span className="note needsclick">(This is just a demo dropzone. Selected files are <strong>not</strong> actually uploaded.)</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
const Tabs3 = (props) => {

    return (
        <>
            <div className="row final-ass-fback">
                <div className="col-md-12">
                    <label className="form-label" for="feedbackRadioButton">Feedback</label>
                </div>
                <div className="col-md-6">
                    <div className="inputGroup">
                        <input id="radio1" name="radio" type="radio" />
                        <label for="radio1">Excellent</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputGroup">
                        <input id="radio2" name="radio" type="radio" />
                        <label for="radio2">Good</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputGroup">
                        <input id="radio3" name="radio" type="radio" />
                        <label for="radio3">Very Good</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputGroup">
                        <input id="radio4" name="radio" type="radio" />
                        <label for="radio4">Average</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputGroup">
                        <input id="radio5" name="radio" type="radio" />
                        <label for="radio5">Poor</label>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="inputGroup">
                        <input id="radio6" name="radio" type="radio" />
                        <label for="radio6">Very Poor</label>
                    </div>
                </div>
            </div>

        </>
    );
}
function ReviewAssignment() {
    useEffect(() => {
        document.title = "Edsys | ReviewAssignment "
    }, [])
    const [state, setState] = useState(0);
    const myData = [1];
    return (
        <div className="page-body">
            {/* <!-- Container-fluid starts--> */}
            <div className="row view-assignment">
                <div className="col-12">
                    <div className="card br-15">
                        <div className="card-body header-card">
                            <div className="student-header">
                                <div className="details-banner">
                                    <h3>Assignment Title 01</h3>
                                    <h5><Clock />06:52 pm</h5>
                                    <div className="sub-date">28 Oct 2021, Thu</div>
                                </div>
                            </div>
                            <div className="student-image">
                                <div className="student-avatar">
                                    <img src={b4} />
                                    <div className="class">6th D</div>
                                </div>
                                <div className="student-name">Maria George</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="card br-15">
                        <div className="card-header br-15 pb-0">
                            <h5>Review Section</h5>
                        </div>
                        <div className="card-body ass-form">
                            <form className="form-wizard" id="regForm" action="#" method="POST">
                                <div>
                                    {myData.map((item, index) => {

                                        return (
                                            <>
                                                {state == 0 ?
                                                    <Tabs1 tabsData={item} /> :
                                                    null
                                                }
                                                {state == 1 ?
                                                    <Tabs2 tabsData={item} /> :

                                                    null
                                                }
                                                {state == 2 ?
                                                    <Tabs3 tabsData={item} /> :

                                                    null
                                                }

                                            </>
                                        );
                                    }

                                    )}

                                </div>

                                <div>
                                    <div className="text-end btn-mb">
                                        <button className="btn btn-secondary"  style={{marginRight:"5px"}} id="prevBtn" type="button" disabled={state === 0 ? true : false} onClick={() => { setState(state - 1) }} >Previous</button>
                                        <button className="btn btn-primary" id="nextBtn" type="button" disabled={state === 2 ? true : false} onClick={() => { setState(state + 1) }}>Next</button>
                                    </div>
                                </div>
                                {/* <!-- Circles which indicates the steps of the form:--> */}
                                <div className="text-center">
                                    <span className="step"></span>
                                    <span className="step"></span>
                                    <span className="step"></span>
                                </div>
                                {/* <!-- Circles which indicates the steps of the form:--> */}
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </div>
    )
}

export default ReviewAssignment
