import React, { useEffect } from "react";

function MySetting() {
  useEffect(() => {
    document.title = "Edsys | Settings ";
  }, []);
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div class="row notif-setting">
        <h4>My Notification Settings</h4>
        <div class="col-md-12">
          <div class="card br-15">
            <div class="card-body row">
              <div class="col-sm-12 col-lg-12 col-xl-12">
                <div class="table-responsive">
                  <table class="table table-styling">
                    <thead>
                      <tr>
                        <th scope="col">Type</th>
                        <th scope="col">Push Notification</th>
                        <th scope="col">Email</th>
                        <th scope="col">SMS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Leave</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch ">
                              <input type="checkbox" checked="true" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="true" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Announcement</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="trues" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Holiday</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="true" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Promotion</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Message</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Assignment</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Assessment</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Classroom Attendance</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Timetable</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Configuration Checker</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Academic Year</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Syllabus</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Staff Leave</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Notification Sound</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">HOD</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Class Teacher</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">E-Learning</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Profile</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">School Hour</th>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" checked="" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                        <td>
                          <div class="switch-md icon-state">
                            <label class="switch">
                              <input type="checkbox" />
                              <span class="switch-state"></span>
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}
export default MySetting;
