import React from "react";

function PunchDetails() {
  return (
    <div class="col-md-7 punch-review">
      <h4>Punch Details</h4>
      <div
        class="select-month d-flex nav nav-pills"
        id="v-pills-tab"
        role="tablist"
        data-bs-spy="scroll"
        data-bs-target="#month-selector"
        data-bs-offset="0"
      >
        <div
          class="months"
          id="pills-jan-tab"
          data-bs-toggle="pill"
          href="#pills-jan"
          role="tab"
          aria-controls="pills-jan"
          aria-selected="true"
        >
          Jan
        </div>
        <div
          class="months"
          id="pills-feb-tab"
          data-bs-toggle="pill"
          href="#pills-feb"
          role="tab"
          aria-controls="pills-feb"
          aria-selected="false"
        >
          Feb
        </div>
        <div
          class="months active"
          id="pills-mar-tab"
          data-bs-toggle="pill"
          href="#pills-mar"
          role="tab"
          aria-controls="pills-mar"
          aria-selected="false"
        >
          Mar
        </div>
        <div
          class="months"
          id="pills-apr-tab"
          data-bs-toggle="pill"
          href="#pills-apr"
          role="tab"
          aria-controls="pills-apr"
          aria-selected="true"
        >
          Apr
        </div>
        <div
          class="months"
          id="pills-may-tab"
          data-bs-toggle="pill"
          href="#pills-may"
          role="tab"
          aria-controls="pills-may"
          aria-selected="false"
        >
          May
        </div>
        <div
          class="months"
          id="pills-jun-tab"
          data-bs-toggle="pill"
          href="#pills-jun"
          role="tab"
          aria-controls="pills-jun"
          aria-selected="false"
        >
          Jun
        </div>
        <div
          class="months"
          id="pills-jul-tab"
          data-bs-toggle="pill"
          href="#pills-jul"
          role="tab"
          aria-controls="pills-jul"
          aria-selected="false"
        >
          Jul
        </div>
        <div
          class="months"
          id="pills-aug-tab"
          data-bs-toggle="pill"
          href="#pills-aug"
          role="tab"
          aria-controls="pills-aug"
          aria-selected="false"
        >
          Aug
        </div>
        <div
          class="months"
          id="pills-sep-tab"
          data-bs-toggle="pill"
          href="#pills-sep"
          role="tab"
          aria-controls="pills-sep"
          aria-selected="true"
        >
          Sep
        </div>
        <div
          class="months"
          id="pills-oct-tab"
          data-bs-toggle="pill"
          href="#pills-oct"
          role="tab"
          aria-controls="pills-oct"
          aria-selected="false"
        >
          Oct
        </div>
        <div
          class="months"
          id="pills-nov-tab"
          data-bs-toggle="pill"
          href="#pills-nov"
          role="tab"
          aria-controls="pills-nov"
          aria-selected="false"
        >
          Nov
        </div>
        <div
          class="months"
          id="pills-dec-tab"
          data-bs-toggle="pill"
          href="#pills-dec"
          role="tab"
          aria-controls="pills-dec"
          aria-selected="false"
        >
          Dec
        </div>
      </div>
      <div class="tab-content" id="pills-tabContent">
        <div
          class="tab-pane fade show active"
          id="pills-mar"
          role="tabpanel"
          aria-labelledby="pills-mar-tab"
        >
          <div id="march-2021" class="punch-status">
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">01</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar holiday"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">WEEKEND</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">02</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">03</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">04</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar leave"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">Leave</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">05</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "50%",
                        ariaValuenow: "50",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                    <div
                      class="progress-bar leave"
                      role="progressbar"
                      style={{
                        width: "50%",
                        ariaValuenow: "50",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:30 AM</div>
                    <div class="end-time">01 : 00 PM</div>
                    <div class="leave-stat">Second Half Leave</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">06</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "25%",
                        ariaValuenow: "65",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:30 AM</div>
                    <div class="end-time">-- : --</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">07</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">08</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">09</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">10</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">11</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">12</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">13</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">14</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">15</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">16</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">17</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">18</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">19</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">20</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">21</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">22</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">23</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">24</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">25</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">26</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">27</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">28</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">29</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">30</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
            <div class="row g-0">
              <div class="col-3">
                <div class="date-tile">
                  <div class="date">31</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-md-9"></div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-jan"
          role="tabpanel"
          aria-labelledby="pills-jan-tab"
        >
          <div id="march-2021" class="punch-status">
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">01</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar holiday"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">WEEKEND</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">02</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">03</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">04</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar leave"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">Leave</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">05</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "50%",
                        ariaValuenow: "50",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                    <div
                      class="progress-bar leave"
                      role="progressbar"
                      style={{
                        width: "50%",
                        ariaValuenow: "50",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:30 AM</div>
                    <div class="end-time">01 : 00 PM</div>
                    <div class="leave-stat">Second Half Leave</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">06</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">07</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">08</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">09</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">10</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">11</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">12</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">13</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">14</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">15</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">16</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">17</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">18</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">19</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">20</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">21</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">22</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">23</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">24</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">25</div>
                  <div class="day">WED</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">26</div>
                  <div class="day">THU</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">27</div>
                  <div class="day">FRI</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">28</div>
                  <div class="day">SAT</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">29</div>
                  <div class="day">SUN</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">30</div>
                  <div class="day">MON</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row g-0">
              <div class="col-1">
                <div class="date-tile">
                  <div class="date">31</div>
                  <div class="day">TUE</div>
                </div>
              </div>
              <div class="col-11">
                <div class="punch-progress">
                  <div class="progress">
                    <div
                      class="progress-bar attend"
                      role="progressbar"
                      style={{
                        width: "100%",
                        ariaValuenow: "100",
                        ariaValuemin: "0",
                        ariaValuemax: "100",
                      }}
                    ></div>
                  </div>
                  <div class="d-flex justify-content-between working-hrs">
                    <div class="start-time">09:46 AM</div>
                    <div class="end-time">05:00 PM</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="tab-pane fade"
          id="pills-feb"
          role="tabpanel"
          aria-labelledby="pills-feb-tab"
        >
          <p class="mb-0 m-t-30">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum
          </p>
        </div>
      </div>
    </div>
  );
}

export default PunchDetails;
