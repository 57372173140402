import React from "react";
// import "../../../assets/css/select2.css";

const SmsModel = (props) => {
  const displayStyle = {
    display: "block",
  };
  return (
    <div
      className="modal fade modal-bookmark show testingll"
      style={displayStyle}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Compose Email to Parent
            </h5>
            <button
              className="btn-close"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {" "}
            </button>
          </div>
          <div className="modal-body datetime-picker modal-scroll">
            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label for="task-title">Grade</label>
                  <select className="js-example-basic-single form-select">
                    <option value="lesson1">select All</option>
                    <option value="lesson2">6th A</option>
                    <option value="lesson2">6th B</option>

                    <option value="lesson2">6th C</option>
                  </select>
                </div>

                <div className="form-group col-md-12">
                  <label for="task-title">Student Name</label>
                  <select className="js-example-basic-single form-select">
                    <option value="lesson1">select All</option>
                    <option value="lesson2">name</option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label for="task-title">Subject</label>
                  <input
                    class="form-control"
                    id="task-title"
                    type="text"
                    required=""
                    placeholder="Subject"
                    autocomplete="off"
                  />
                </div>
                <div className="form-group col-md-12">
                  <label for="sub-task">Message</label>
                  <textarea
                    className="form-control"
                    required=""
                    autocomplete="off"
                  >
                    {" "}
                  </textarea>
                </div>
                <div class="mb-3 ">
                  <label for="formFile" class="form-label">
                   Attachments
                  </label>
                  <input class="form-control" type="file" id="formFile" />
                  
                </div>
              </div>
            </form>
          </div>

          <div className="modal-footer">
            {/* <button
              className="btn btn-secondary"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
            >
              Cancel
            </button> */}
            <button
              onclick="location.href='add-questions.html';"
              className="btn btn-primary"
              id="Bookmark"
              type="submit"
            >
              SEND
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SmsModel;
