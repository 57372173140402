import React, { useEffect } from "react";
import shape from "../../assets/images/authentication/shape.svg";
import edsysLogo from "../../assets/images/logo/edsysLogo.svg";
import shape1 from "../../assets/images/authentication/shape-1.svg";
import { Link } from "react-router-dom";

function LoginTwo() {
  useEffect(() => {
    document.title = "Edsys | Sign In ";
  }, []);
  return (
    <section>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-12">
            <div className="login-card">
              <img className="shape-top" src={shape} />
              <img className="login-logo" src={edsysLogo} />
              <img className="shape-center" src={shape1} />
              <form
                className="theme-form login-form"
                style={{ marginLeft: "350px" }}
              >
                <h4>Login</h4>
                <h6> Log in to your account.</h6>
                <div className="form-group">
                  <label>Email Address</label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="icon-email"></i>
                    </span>
                    <input
                      className="form-control"
                      type="email"
                      required=""
                      placeholder="Test@gmail.com"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="icon-lock"></i>
                    </span>
                    <input
                      className="form-control"
                      type="password"
                      name="login[password]"
                      required=""
                      placeholder="*********"
                    />
                    <div className="show-hide">
                      <span className="show"> </span>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="checkbox">
                    <input id="checkbox1" type="checkbox" />
                    <label for="checkbox1">Remember password</label>
                  </div>
                  <a className="link" href="forget-password.html">
                    Forgot password?
                  </a>
                </div>
                <div className="form-group">
                  <Link to="/">
                    <button className="btn btn-primary btn-block" type="submit">
                      Sign in
                    </button>
                  </Link>
                </div>

                <p>
                  Don't have account?{" "}
                  <Link to="/LoginIn">
                    <a className="ms-2">Create Account</a>{" "}
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LoginTwo;
