import React, { useState, useEffect } from "react";
import subjecticon from "../../assets/images/assignment/subtopicicon.svg";
import SmsCardData from "../../api/Sms_card_data.json";
// import "../../assets/css/select2.css";s
import SmsModel from "./SmsModel";
import { Edit3, Trash2, PlusCircle } from "react-feather";
// import dashboardAvatar from '../assets/images/dashboard/1.png';
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import { HTML5_FMT } from "moment";
import { Link } from "react-router-dom";

const Sms = ({ dark }) => {
  const [isTodoModelShow, setIsTodoModelShow] = useState(false);

  const openCloseTodoModel = () => {
    // setInitialState();
    setIsTodoModelShow(!isTodoModelShow);
  };

  return (
    <div className="">
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">SMS</h5>
                <a
                  type="button"
                  data-bs-toggle="modal"
                  onClick={openCloseTodoModel}
                  data-bs-target="#exampleModal"
                  className="btn btn-edsys-primary btn1"
                >
                  <PlusCircle className="me-2" />
                  <span>Create New SMS</span>
                </a>
                {/* Modal start */}
                {isTodoModelShow ? (
                  <SmsModel onClose={openCloseTodoModel} />
                ) : null}
                {/* modal End */}
              </div>
            </div>
            {/* <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">SMS</h5>

                <button
                  type="button"
                  class="btn btn-edsys-primary btn1"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Create New Sms
                </button>

                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                          Create SMS to Parent
                        </h5>
                       
                        <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close">
                      
                    </button>
                      </div>

                      <div className="modal-body">
                        <div className="form-group col-md-12">
                          <label for="task-title">Grade</label>
                          <select className="js-example-basic-single form-select">
                            <option value="lesson1">select All</option>
                            <option value="lesson2">6th A</option>
                            <option value="lesson2">6th B</option>

                            <option value="lesson2">6th C</option>
                          </select>
                        </div>
                        <div className="form-group col-md-12">
                          <label for="task-title">Student Name</label>
                          <select className="js-example-basic-single form-select">
                            <option value="lesson1">select All</option>
                            <option value="lesson2">name</option>
                          </select>
                        </div>
                        <div className="form-group col-md-12">
                          <label for="sub-task">Message</label>
                          <textarea
                            className="form-control"
                            required=""
                            autocomplete="off"
                          >
                            {" "}
                          </textarea>
                        </div>
                      </div>

                    
                      <div class="modal-footer">
                        <button type="button" class="btn btn-primary">
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="card-body">
              <div className="row">
                {SmsCardData.map((postDetail, index) => (
                  <div className="col-md-6" key={index}>
                    <Link to="/SmsDetails">
                      <div className={postDetail.card_bgcolor}>
                        <div className="card-header d-flex justify-content-between">
                          <img
                            className="img-90 rounded-circle"
                            src={dashboardAvatar}
                            alt="image"
                            style={{ height: "50px", width: "50px" }}
                          />
                          <div className="class-body">
                            {postDetail.Participants}
                          </div>
                        </div>

                        <div className="card-body">
                          {postDetail.assignment_title}
                        </div>
                        <div className="card-footer d-flex justify-content-between">
                          <h5>{postDetail.date}</h5>
                          <div className="class">
                            {postDetail.division_grade}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sms;
