import React from 'react'
import course_data from '../api/data.json'
import {
    ArrowRight,
  } from "react-feather";

function CourseCountCard() {
   
    return (
        <>
{course_data.map((postDetail, index) => {
        return (
          <div className="col-md-6">
<div className="card">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <div className="row">
                      <div className="col-7">
                        <h3 className="counter">{postDetail.count}</h3>
                      </div>
                      <div className="col-5">
                        <div className={postDetail.course_icon_color}>
                          <i className={postDetail.course_icn}></i>
                        </div>
                      </div>
                    </div>
                    <div><h1>{postDetail.course_title}</h1> </div>
                  </div>
                </div>
                <div className="goto">
                  <ArrowRight />
                </div>
              </div>
            </div>
         </div>
         </div>
        );
      })}

        </>
    
    )
}

export default CourseCountCard
