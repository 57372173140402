import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import clockonicon from "../../assets/images/punch/clockonicon.svg";
import clockofficon from "../../assets/images/punch/clockofficon.svg";

import Moment from "react-moment";

function Punch() {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isDatePickerOpen, setDatePickerOpen] = useState(false);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setDatePickerOpen(false); // Close the date picker after selecting a date
  };

  const today = new Date();
  const minSelectableDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );

  return (
    <div className="col-md-5 text-center">
      <div className="punch-tile">
        <h4>Attendance</h4>
        <div
          className="clock"
          onClick={() => setDatePickerOpen(!isDatePickerOpen)}
        >
          <h1>
            <Moment format="ddd MMM DD YYYY">{selectedDate}</Moment>
          </h1>
        </div>
        {isDatePickerOpen && (
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            showYearDropdown
            showMonthDropdown
            dropdownMode="select"
            //minDate={minSelectableDate}
            maxDate={today} // Set the maximum selectable date to today
          />
        )}
        {/* rest of your component */}
        <div class="punch-button">
          <div class="total-hrs">TOTAL HOURS</div>
          <div class="start-time">00:00:00</div>
          <div class="punch-btn">Punch In</div>
        </div>
        <div class="row work-duration">
          <div class="col-6">
            <img class="p-in-img" src={clockonicon} />
            <div class="p-in">Punch In</div>
            <div class="p-in-time">-- : -- : --</div>
          </div>
          <div class="col-6 ms-auto text-end">
            <img class="p-out-img" src={clockofficon} />
            <div class="p-out">Punch Out</div>
            <div class="p-out-time">-- : -- : --</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Punch;
