
import React, { useState } from 'react';
import LectureDetails from './LectureDetails';

const TimetableListView = props => {
    const { Periods, Lectures } = props.data
    return (

        <div className="row assignment-sec">
            {Periods ? Periods.map((period, index) => {
                
                if (period.isInterval && index < Periods.length-1) {
                    return (
                        <div className="col-md-12">
                            <div className="break-row">
                                <span className="break-time">{period.startTime} - Break - {period.endTime}</span>
                            </div>
                        </div>
                    )
                }
                else {
                    var filteredlist = Lectures.filter((lecture) => lecture.Period === period.PeriodId)
                    if (filteredlist) {
                        return <LectureDetails lecture={filteredlist} period={period}  />
                    }
                }
            })


                : (<tr><td><div className="details-bookmark text-center"><span>No records found.</span></div></td></tr>)
            }
        </div>
    )
};

export default TimetableListView;




