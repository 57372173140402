import React,{useEffect} from 'react';
import bg_1 from '../../../assets/images/assignment/bg_1.svg'

 function AssignmentDetails2() {
  useEffect(() => {
    document.title = "Edsys | Assignment Details2 "
  }, [])
  return (
    <div class="page-body">
    {/* <!-- Container-fluid starts--> */}
    <div class="row assignment-details">
    <div className="col-12">
          <div className="knowledgebase-bg b-center bg-size" style={{ background: "url('assets/images/assignment/bg_1.svg')", backgroundSize: "cover", backgroundPosition: "center center", display: "block" }}><img className="bg-img-cover bg-center" src={bg_1} alt="looginpage" style={{ display: "none" }} /></div>
          <div className="knowledgebase-search">
            <div className="details-banner">
              <h3>Assignment Title 01</h3>
              <h5>Physics</h5>
              <h6>Assigned by<span>Mathew James</span></h6>
              <div className="sub-date">28 Oct 2021, Thu</div>
            </div>
          </div>
        </div>
      <div class="col-12 edsys-slider">
          <div class="carousel" data-timer="none">
            <div class="carousel-deck">
              <div class="carousel-slide">Slide 1</div>
              <div class="carousel-slide">Slide 2<br /><a href="http://hamiltondraws.com" target="_blank">A clickable link</a></div>
              <div class="carousel-slide">Slide 3</div>
              <div class="carousel-slide">Slide 4</div>
              <div class="carousel-slide">Slide 5</div>
            </div>
          </div>      
        </div>
     </div>
    {/* <!-- Container-fluid Ends--> */}
  </div>
  )
}
export default AssignmentDetails2