import React, { useState } from "react";
import "../../assets/css/bootstrap.css";
import "../../assets/css/CreateExam.css"; // Import your CSS file for additional styling

function CreateExam() {
  const [grades, setGrades] = useState({
    "10A": false,
    "10B": false,
    "10C": false,
    "10D": false,
  });
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");

  //   const handleGradeClick = (grade) => {
  //     setGrades(prevGrades => ({
  //       ...prevGrades,
  //       [grade]: !prevGrades[grade]
  //     }));
  //     setSelectedGrade(grade);
  //     setSelectedSubject('');
  //   };

  const handleGradeClick = (grade) => {
    setGrades((prevGrades) => {
      const updatedGrades = {};
      for (const key in prevGrades) {
        updatedGrades[key] = key === grade ? !prevGrades[key] : false;
      }
      return updatedGrades;
    });
    setSelectedGrade((prev) => (prev === grade ? "" : grade));
    setSelectedSubject("");
  };
  const handleSubjectChange = (e) => {
    setSelectedSubject(e.target.value);
  };

  const [selectedExam, setSelectedExam] = useState("");
  const [newExamName, setNewExamName] = useState("");
  const [exams, setExams] = useState(["Exam 1", "Exam 2", "Exam 3"]);

  const handleExamChange = (event) => {
    setSelectedExam(event.target.value);
  };

  // Function to handle changes in the input field
  const handleInputChange = (event) => {
    setNewExamName(event.target.value);
  };

  // Function to add a new exam to the dropdown
  const addNewExam = () => {
    if (newExamName.trim() !== "") {
      setExams([...exams, newExamName]);
      setNewExamName("");
    }
  };

  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");

  const handleStartTimeChange = (event) => {
    setStartTime(event.target.value);
  };

  // Function to handle changes in the end time input field
  const handleEndTimeChange = (event) => {
    setEndTime(event.target.value);
  };

  return (
    <div className="container-fluid">
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="mt-2">
              STEP 1
              <div className="card-body header-card">
                <form
                  className="form-bookmark needs-validation"
                  id="bookmark-form"
                  novalidate=""
                >
                  {/* <div className="form-row">

                     <div className="form-group col-md-12">
                      <label htmlFor="task-title">Name Exam</label>
                      <select
                        className="js-example-basic-single form-select"
                        value={selectedExam}
                        onChange={handleExamChange}
                      >
                        <option value="">Select Exam Name</option>
                        {exams.map((exam, index) => (
                          <option key={index} value={exam}>
                            {exam}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label htmlFor="new-exam">Add New Exam</label>
                      <input
                        type="text"
                        className="form-control"
                        id="new-exam"
                        value={newExamName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={addNewExam}
                      >
                        Add Exam
                      </button>
                    </div> */}

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label for="exampleDataList">Name Exam</label>
                      <input
                        class="form-control"
                        list="exam"
                        id="exampleDataList"
                        placeholder="Type the exam name"
                      />

                      <datalist id="exam">
                        <option value="Exam1">Exam1</option>
                        <option value="Exam2">Exam2</option>
                        <option value="Exam3">Exam3</option>
                      </datalist>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label for="exampleDataList">Academic Year</label>
                      <input
                        class="form-control"
                        list="datalistOptions"
                        id="exampleDataList"
                        placeholder="Type the year"
                      />

                      <datalist id="datalistOptions">
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                      </datalist>
                    </div>

                    {/* <div className="form-group col-md-12">
                      <label for="task-title">Academic Year</label>
                      <select className="js-example-basic-single form-select">
                        <option value="">Select Academic Year</option>
                        <option value="2022">2022</option>
                        <option value="2023">2023</option>
                        <option value="2024">2024</option>
                      </select>
                    </div> */}
                    <div className="form-group col-md-12">
                      <label for="sub-task">Terms</label>
                      <input
                        className="form-control"
                        type="text"
                        name="terms"
                      />
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label for="task-title">Starting Date</label>
                        <input
                          className="datepicker-here form-control digits"
                          type="date"
                          data-language="en"
                          placeholder="MM-DD-YYYY"
                          data-position="top left"
                        />
                      </div>
                      <div className="form-group col-md-6">
                        <label for="task-title">Ending Date</label>
                        <input
                          className="datepicker-here form-control digits"
                          type="date"
                          data-language="en"
                          placeholder="MM-DD-YYYY"
                          data-position="top left"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div>
              STEP 2
              <div>
                <h6 className="grade">select Grade</h6>
                <div className="grade-checkboxes">
                  {Object.keys(grades).map((grade) => (
                    <div key={grade} className="grade-checkbox">
                      <label>
                        <input
                          type="checkbox"
                          checked={grades[grade]}
                          onChange={() => handleGradeClick(grade)}
                        />
                        {grade}
                      </label>
                      {selectedGrade === grade && (
                        <div className="selected-subject">
                          <h6>Selected Subject for {grade}</h6>
                          <select
                            value={selectedSubject}
                            onChange={handleSubjectChange}
                          >
                            <option value="">Select Subject</option>
                            <option value="Maths">Maths</option>
                            <option value="Physics">Physics</option>
                          </select>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              STEP 3
              <div className="card-body header-card">
                <div className="form-group col-md-12s">
                  <label htmlFor="start-time">Start Time</label>
                  <input
                    type="time"
                    className="form-control"
                    id="start-time"
                    value={startTime}
                    onChange={handleStartTimeChange}
                  />
                </div>

                <div className="form-group col-md-12">
                  <label for="sub-task">Duration</label>
                  <input className="form-control" type="text" name="terms" />
                </div>
                <div className="form-group col-md-12">
                  <label for="sub-task">Max Mark</label>
                  <input className="form-control" type="text" name="terms" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateExam;

// import React, { useState } from "react";
// import "../../assets/css/bootstrap.css";
// import "../../assets/css/CreateExam.css";

// function CreateExam() {
//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState("");

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade((prev) => (prev === grade ? "" : grade));
//     setSelectedSubject("");
//   };
//   const handleSubjectChange = (e) => {
//     setSelectedSubject(e.target.value);
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">
//           <div className="card">
//             <div>
//               STEP 2
//               <div>
//                 <h6 className="grade">select Grade</h6>
//                 <div className="grade-checkboxes">
//                   {Object.keys(grades).map((grade) => (
//                     <div key={grade} className="grade-checkbox">
//                       <label>
//                         <input
//                           type="checkbox"
//                           checked={grades[grade]}
//                           onChange={() => handleGradeClick(grade)}
//                         />
//                         {grade}
//                       </label>
//                       {selectedGrade === grade && (
//                         <div className="selected-subject">
//                           <h6>Selected Subject for {grade}</h6>
//                           <select
//                             value={selectedSubject}
//                             onChange={handleSubjectChange}
//                           >
//                             <option value="">Select Subject</option>
//                             <option value="Maths">Maths</option>
//                             <option value="Physics">Physics</option>
//                           </select>
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreateExam;

// import React from "react";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import "../../assets/css/CreateExam.css";

// const step1Content = <h1>Hello</h1>;
// const step2Content = <h1>Hai</h1>;
// const step3Content = <h1>Hello World</h1>;
// const step4Content = <h1>Welcome</h1>;

// function step2Validator() {
//   return true; // Returning true means the validation passed
// }

// function step3Validator() {
//   return true; // Returning true means the validation passed
// }

// function onFormSubmit() {
//   // Handle submit logic here
// }

// const App = () => (
//   <div className="container-fluid">
//     <div className="card">
//       <StepProgressBar
//         startingStep={0}
//         onSubmit={onFormSubmit}
//         steps={[
//           {
//             label: "Step 1",
//             // subtitle: '25%',
//             name: "hello",
//             content: step1Content,
//           },
//           {
//             label: "Step 2",
//             // subtitle: '50%',
//             name: "hai",
//             content: step2Content,
//             validator: step2Validator,
//           },
//           {
//             label: "Step 3",
//             // subtitle: '75%',
//             name: "helloworld",
//             content: step3Content,
//             validator: step3Validator,
//           },
//           {
//             label: "Step 4",
//             // subtitle: '100%',
//             name: "welcome",
//             content: step4Content,
//           },
//         ]}
//       />
//     </div>
//   </div>
// );

// export default App;

// import React, { useState } from "react";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import "../../assets/css/CreateExam.css";

// const App = () => {
//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState("");

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade(grade);
//     setSelectedSubject(""); // Reset selected subject when a grade is clicked
//   };

//   const handleSubjectChange = (e) => {
//     setSelectedSubject(e.target.value);
//   };

//   const step1Content = <h1>h</h1>;

//   const step2Content = (
//     <div>
//       <h6 className="grade">Select Grade</h6>
//       <div className="grade-checkboxes">
//         {Object.keys(grades).map((grade) => (
//           <div key={grade} className="grade-checkbox">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={grades[grade]}
//                 onChange={() => handleGradeClick(grade)}
//               />
//               {grade}
//             </label>
//             {selectedGrade === grade && (
//               <div className="selected-subject">
//                 <h6>Selected Subject for {grade}</h6>
//                 <select value={selectedSubject} onChange={handleSubjectChange}>
//                   <option value="">Select Subject</option>
//                   <option value="Maths">Maths</option>
//                   <option value="Physics">Physics</option>
//                 </select>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
//   const step3Content = <h1>hlo</h1>;
//   // const step4Content = <h1>Welcome</h1>;

//   function step2Validator() {
//     return true; // Returning true means the validation passed
//   }

//   // function step3Validator() {
//   //   return true; // Returning true means the validation passed
//   // }

//   function onFormSubmit() {
//     // Handle submit logic here
//   }

//   return (
//     <div className="container-fluid">
//       <div className="card">
//         <StepProgressBar
//           startingStep={0}
//           onSubmit={onFormSubmit}
//           steps={[
//             {
//               label: "Step 1",
//               name: "hello",
//               content: step1Content,
//             },
//             {
//               label: "Step 2",
//               name: "hai",
//               content: step2Content,
//               validator: step2Validator,
//             },
//             {
//               label: "Step 3",
//               name: "helloworld",
//               content: step3Content,
//               // validator: step3Validator,
//             },
//             // {
//             //   label: "Step 4",
//             //   name: "welcome",
//             //   content: step4Content,
//             // },
//           ]}
//         />
//       </div>
//     </div>
//   );
// };

// export default App;

// import React, { useState } from "react";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import "../../assets/css/CreateExam.css";

// const CreateExam = () => {

//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState("");
//   const [startTime, setStartTime] = useState("");

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade((prev) => (prev === grade ? "" : grade));
//     setSelectedSubject("");
//   };

//   const handleSubjectChange = (e) => {
//     setSelectedSubject(e.target.value);
//   };

//   const handleStartTimeChange = (event) => {
//     setStartTime(event.target.value);
//   };

//   const step1Content = (
//     <div className="mt-2">
//       <div className="card-body header-card">
//         <form
//           className="form-bookmark needs-validation"
//           id="bookmark-form"
//           novalidate=""
//         >
//           <div className="form-row">
//             <div className="form-group col-md-12">
//               <label for="exampleDataList">Name Exam</label>
//               <input
//                 class="form-control"
//                 list="exam"
//                 id="exampleDataList"
//                 placeholder="Type the exam name"
//               />

//               <datalist id="exam">
//                 <option value="Exam1">Exam1</option>
//                 <option value="Exam2">Exam2</option>
//                 <option value="Exam3">Exam3</option>
//               </datalist>
//             </div>
//           </div>

//           <div className="form-row">
//             <div className="form-group col-md-12">
//               <label for="exampleDataList">Academic Year</label>
//               <input
//                 class="form-control"
//                 list="datalistOptions"
//                 id="exampleDataList"
//                 placeholder="Type the year"
//               />

//               <datalist id="datalistOptions">
//                 <option value="2022">2022</option>
//                 <option value="2023">2023</option>
//                 <option value="2024">2024</option>
//               </datalist>
//             </div>

//             <div className="form-group col-md-12">
//               <label for="sub-task">Terms</label>
//               <input className="form-control" type="text" name="terms" />
//             </div>

//             <div className="row">
//               <div className="form-group col-md-6">
//                 <label for="task-title">Starting Date</label>
//                 <input
//                   className="datepicker-here form-control digits"
//                   type="date"
//                   data-language="en"
//                   placeholder="MM-DD-YYYY"
//                   data-position="top left"
//                 />
//               </div>
//               <div className="form-group col-md-6">
//                 <label for="task-title">Ending Date</label>
//                 <input
//                   className="datepicker-here form-control digits"
//                   type="date"
//                   data-language="en"
//                   placeholder="MM-DD-YYYY"
//                   data-position="top left"
//                 />
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
//   const step2Content = (
//     <div className="mt-2">
//       <h6 className="grade">select Grade</h6>
//       <div className="grade-checkboxes">
//         {Object.keys(grades).map((grade) => (
//           <div key={grade} className="grade-checkbox">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={grades[grade]}
//                 onChange={() => handleGradeClick(grade)}
//               />
//               {grade}
//             </label>
//             {selectedGrade === grade && (
//               <div className="selected-subject">
//                 <h6>Selected Subject for {grade}</h6>
//                 <select value={selectedSubject} onChange={handleSubjectChange}>
//                   <option value="">Select Subject</option>
//                   <option value="Maths">Maths</option>
//                   <option value="Physics">Physics</option>
//                 </select>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
//   const step3Content = (
//     <div className="card-body header-card">
//     <div className="form-group col-md-12s">
//       <label htmlFor="start-time">Start Time</label>
//       <input
//         type="time"
//         className="form-control"
//         id="start-time"
//         value={startTime}
//         onChange={handleStartTimeChange}
//       />
//     </div>

//     <div className="form-group col-md-12">
//       <label htmlFor="duration">Duration</label>
//       <input
//         className="form-control"
//         type="text"
//         id="duration"

//       />
//     </div>
//     <div className="form-group col-md-12">
//       <label htmlFor="max-mark">Max Mark</label>
//       <input
//         className="form-control"
//         type="text"
//         id="max-mark"

//       />
//     </div>
//   </div>
//   );
//   const step4Content = <h1>Welcome</h1>;

//   function step2Validator() {
//     return true; // Returning true means the validation passed
//   }

//   function step3Validator() {
//     return true; // Returning true means the validation passed
//   }

//   function onFormSubmit() {
//     // Handle submit logic here
//   }

//   return (
//     <div className="container-fluid">
//       <div className="card">
//         <StepProgressBar
//           startingStep={0}
//           onSubmit={onFormSubmit}
//           steps={[
//             {
//               label: "Step 1",
//               // name: "hello",
//               content: step1Content,
//             },
//             {
//               label: "Step 2",
//               // name: "hai",
//               content: step2Content,
//               validator: step2Validator,
//             },
//             {
//               label: "Step 3",
//               // name: "helloworld",
//               content: step3Content,
//               validator: step3Validator,
//             },
//             {
//               label: "Step 4",
//               // name: "welcome",
//               content: step4Content,
//             },
//           ]}
//         />
//       </div>
//     </div>
//   );
// };

// export default CreateExam;

// // import React, { useState } from "react";
// // import StepProgressBar from "react-step-progress";
// // import "react-step-progress/dist/index.css";
// // import "../../assets/css/CreateExam.css";

// // const CreateExam = () => {
// //   const [selectedExam, setSelectedExam] = useState("");
// //   const [newExamName, setNewExamName] = useState("");
// //   const [exams, setExams] = useState(["Exam 1", "Exam 2", "Exam 3"]);

// //   const handleExamChange = (event) => {
// //     setSelectedExam(event.target.value);
// //   };

// //   // Function to handle changes in the input field
// //   const handleInputChange = (event) => {
// //     setNewExamName(event.target.value);
// //   };

// //   // Function to add a new exam to the dropdown
// //   const addNewExam = () => {
// //     if (newExamName.trim() !== "") {
// //       setExams([...exams, newExamName]);
// //       setNewExamName("");
// //     }
// //   };

// //   const step1Content = (
// //     <div className="mt-2">
// //       STEP 1
// //       <div className="card-body header-card">
// //         <form
// //           className="form-bookmark needs-validation"
// //           id="bookmark-form"
// //           novalidate=""
// //         >
// //           <div className="form-row">
// //             <div className="form-group col-md-12">
// //               <label htmlFor="task-title">Name Exam</label>
// //               <select
// //                 className="js-example-basic-single form-select"
// //                 value={selectedExam}
// //                 onChange={handleExamChange}
// //               >
// //                 <option value="">Select Exam Name</option>
// //                 {exams.map((exam, index) => (
// //                   <option key={index} value={exam}>
// //                     {exam}
// //                   </option>
// //                 ))}
// //               </select>
// //             </div>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group col-md-12">
// //               <label htmlFor="new-exam">Add New Exam</label>
// //               <input
// //                 type="text"
// //                 className="form-control"
// //                 id="new-exam"
// //                 value={newExamName}
// //                 onChange={handleInputChange}
// //               />
// //             </div>
// //           </div>
// //           <div className="form-group col-md-12">
// //             <button
// //               type="button"
// //               className="btn btn-primary"
// //               onClick={addNewExam}
// //             >
// //               Add Exam
// //             </button>
// //           </div>
// //           <div className="form-row">
// //             <div className="form-group col-md-12">
// //               <label for="task-title">Academic Year</label>
// //               <select className="js-example-basic-single form-select">
// //                 <option value="">Select Academic Year</option>
// //                 <option value="2022">2022</option>
// //                 <option value="2023">2023</option>
// //                 <option value="2024">2024</option>
// //               </select>
// //             </div>
// //             <div className="form-group col-md-12">
// //               <label for="sub-task">Terms</label>
// //               <input className="form-control" type="text" name="terms" />
// //             </div>

// //             <div className="row">
// //               <div className="form-group col-md-6">
// //                 <label for="task-title">Starting Date</label>
// //                 <input
// //                   className="datepicker-here form-control digits"
// //                   type="date"
// //                   data-language="en"
// //                   placeholder="MM-DD-YYYY"
// //                   data-position="top left"
// //                 />
// //               </div>
// //               <div className="form-group col-md-6">
// //                 <label for="task-title">Ending Date</label>
// //                 <input
// //                   className="datepicker-here form-control digits"
// //                   type="date"
// //                   data-language="en"
// //                   placeholder="MM-DD-YYYY"
// //                   data-position="top left"
// //                 />
// //               </div>
// //             </div>
// //           </div>
// //         </form>
// //       </div>
// //     </div>
// //   );

// //   const step2Content = <h1>Hai</h1>;
// //   const step3Content = <h1>Hello World</h1>;
// //   const step4Content = <h1>Welcome</h1>;

// //   function step2Validator() {
// //     return true; // Returning true means the validation passed
// //   }

// //   function step3Validator() {
// //     return true; // Returning true means the validation passed
// //   }

// //   function onFormSubmit() {
// //     // Handle submit logic here
// //   }

// //   return (
// //     <div className="container-fluid">
// //       <div className="card">
// //         <StepProgressBar
// //           startingStep={0}
// //           onSubmit={onFormSubmit}
// //           steps={[
// //             {
// //               label: "Step 1",
// //               name: "hello",
// //               content: step1Content,
// //             },
// //             {
// //               label: "Step 2",
// //               name: "hai",
// //               content: step2Content,
// //               validator: step2Validator,
// //             },
// //             {
// //               label: "Step 3",
// //               name: "helloworld",
// //               content: step3Content,
// //               validator: step3Validator,
// //             },
// //             {
// //               label: "Step 4",
// //               name: "welcome",
// //               content: step4Content,
// //             },
// //           ]}
// //         />
// //       </div>
// //     </div>
// //   );
// // };

// // export default CreateExam;

// import React, { useState } from "react";
// import "../../assets/css/bootstrap.css";
// import "../../assets/css/CreateExam.css"; // Import your CSS file for additional styling

// function CreateExam() {
//   // State variables to hold form data and validation errors
//   const [formData, setFormData] = useState({
//     examName: "",
//     academicYear: "",
//     terms: "",
//     startDate: "",
//     endDate: "",
//   });

//   // State variable to hold form validation errors
//   const [errors, setErrors] = useState({});

//   // Function to handle form input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // Function to handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Perform validation
//     const validationErrors = {};
//     if (!formData.examName.trim()) {
//       validationErrors.examName = "Exam name is required";
//     }
//     if (!formData.academicYear.trim()) {
//       validationErrors.academicYear = "Academic year is required";
//     }
//     if (!formData.terms.trim()) {
//       validationErrors.terms = "Terms are required";
//     }
//     if (!formData.startDate.trim()) {
//       validationErrors.startDate = "Starting date is required";
//     }
//     if (!formData.endDate.trim()) {
//       validationErrors.endDate = "Ending date is required";
//     }
//     // If there are validation errors, set the errors state
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       // Form is valid, you can proceed with form submission
//       // Here, you can perform further actions like API calls, etc.
//       console.log("Form submitted:", formData);
//       // Clear form data
//       setFormData({
//         examName: "",
//         academicYear: "",
//         terms: "",
//         startDate: "",
//         endDate: "",
//       });
//       // Clear errors
//       setErrors({});
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">
//           <div className="mt-2">
//             <div className="card-body header-card">
//               <form
//                 className="form-bookmark needs-validation"
//                 id="bookmark-form"
//                 noValidate
//                 onSubmit={handleSubmit}
//               >
//                 <div className="form-row">
//                   <div className="form-group col-md-12">
//                     <label htmlFor="exampleDataList">Name</label>
//                     <input
//                       className={`form-control ${
//                         errors.examName ? "is-invalid" : ""
//                       }`}
//                       list="exam"
//                       id="exampleDataList"
//                       name="examName"
//                       value={formData.examName}
//                       onChange={handleInputChange}
//                       placeholder="Type the exam name"
//                       required
//                     />
//                     {errors.examName && (
//                       <div className="invalid-feedback">{errors.examName}</div>
//                     )}
//                     <datalist id="exam">
//                       <option value="Exam1">Exam1</option>
//                       <option value="Exam2">Exam2</option>
//                       <option value="Exam3">Exam3</option>
//                     </datalist>
//                   </div>
//                 </div>

//                 <div className="form-row">
//                   <div className="form-group col-md-12">
//                     <label htmlFor="exampleDataList">Academic Year</label>
//                     <input
//                       className={`form-control ${
//                         errors.academicYear ? "is-invalid" : ""
//                       }`}
//                       list="datalistOptions"
//                       id="exampleDataList"
//                       name="academicYear"
//                       value={formData.academicYear}
//                       onChange={handleInputChange}
//                       placeholder="Type the year"
//                       required
//                     />
//                     {errors.academicYear && (
//                       <div className="invalid-feedback">
//                         {errors.academicYear}
//                       </div>
//                     )}
//                     <datalist id="datalistOptions">
//                       <option value="2022">2022</option>
//                       <option value="2023">2023</option>
//                       <option value="2024">2024</option>
//                     </datalist>
//                   </div>
//                 </div>

//                 <div className="form-row">
//                   <div className="form-group col-md-12">
//                     <label htmlFor="sub-task">Terms</label>
//                     <input
//                       className={`form-control ${
//                         errors.terms ? "is-invalid" : ""
//                       }`}
//                       type="text"
//                       name="terms"
//                       value={formData.terms}
//                       onChange={handleInputChange}
//                       required
//                     />
//                     {errors.terms && (
//                       <div className="invalid-feedback">{errors.terms}</div>
//                     )}
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="form-group col-md-6">
//                     <label htmlFor="task-title">Starting Date</label>
//                     <input
//                       className={`datepicker-here form-control digits ${
//                         errors.startDate ? "is-invalid" : ""
//                       }`}
//                       type="date"
//                       data-language="en"
//                       placeholder="MM-DD-YYYY"
//                       data-position="top left"
//                       name="startDate"
//                       value={formData.startDate}
//                       onChange={handleInputChange}
//                       required
//                     />
//                     {errors.startDate && (
//                       <div className="invalid-feedback">{errors.startDate}</div>
//                     )}
//                   </div>
//                   <div className="form-group col-md-6">
//                     <label htmlFor="task-title">Ending Date</label>
//                     <input
//                       className={`datepicker-here form-control digits ${
//                         errors.endDate ? "is-invalid" : ""
//                       }`}
//                       type="date"
//                       data-language="en"
//                       placeholder="MM-DD-YYYY"
//                       data-position="top left"
//                       name="endDate"
//                       value={formData.endDate}
//                       onChange={handleInputChange}
//                       required
//                     />
//                     {errors.endDate && (
//                       <div className="invalid-feedback">{errors.endDate}</div>
//                     )}
//                   </div>
//                 </div>
//                <button className="button_ds">Next</button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreateExam;

// import React, { useState } from "react";

// function CreateExam() {
//   // State variables to hold form data and validation errors
//   const [formData, setFormData] = useState({
//     examName: "",
//     academicYear: "",
//     terms: "",
//     startDate: "",
//     endDate: "",
//   });

//   // State variable to hold form validation errors
//   const [errors, setErrors] = useState({});

//   // Function to handle form input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   // Function to handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     // Perform validation
//     const validationErrors = {};
//     if (!formData.examName.trim()) {
//       validationErrors.examName = "Exam name is required";
//     }
//     if (!formData.academicYear.trim()) {
//       validationErrors.academicYear = "Academic year is required";
//     }
//     if (!formData.terms.trim()) {
//       validationErrors.terms = "Terms are required";
//     }
//     if (!formData.startDate.trim()) {
//       validationErrors.startDate = "Starting date is required";
//     }
//     if (!formData.endDate.trim()) {
//       validationErrors.endDate = "Ending date is required";
//     }
//     // If there are validation errors, set the errors state
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       // Form is valid, you can proceed with form submission
//       // Here, you can perform further actions like API calls, etc.
//       console.log("Form submitted:", formData);
//       // Clear form data
//       setFormData({
//         examName: "",
//         academicYear: "",
//         terms: "",
//         startDate: "",
//         endDate: "",
//       });
//       // Clear errors
//       setErrors({});
//     }
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">
//           <div className="mt-2">
//             <div className="card-body header-card">
//               <form
//                 className="form-bookmark needs-validation"
//                 id="bookmark-form"
//                 noValidate
//                 onSubmit={handleSubmit}
//               >
//                 <div className="form-row">
//                   <div className="form-group col-md-12">
//                     <label htmlFor="exampleDataList">Name</label>
//                     <input
//                       className={`form-control ${
//                         errors.examName ? "is-invalid" : ""
//                       }`}
//                       list="exam"
//                       id="exampleDataList"
//                       name="examName"
//                       value={formData.examName}
//                       onChange={handleInputChange}
//                       placeholder="Type the exam name"
//                       required
//                     />
//                     {errors.examName && (
//                       <div className="invalid-feedback">{errors.examName}</div>
//                     )}
//                     <datalist id="exam">
//                       <option value="Exam1">Exam1</option>
//                       <option value="Exam2">Exam2</option>
//                       <option value="Exam3">Exam3</option>
//                     </datalist>
//                   </div>
//                 </div>

//                 <div className="form-row">
//                   <div className="form-group col-md-12">
//                     <label htmlFor="exampleDataList">Academic Year</label>
//                     <input
//                       className={`form-control ${
//                         errors.academicYear ? "is-invalid" : ""
//                       }`}
//                       list="datalistOptions"
//                       id="exampleDataList"
//                       name="academicYear"
//                       value={formData.academicYear}
//                       onChange={handleInputChange}
//                       placeholder="Type the year"
//                       required
//                     />
//                     {errors.academicYear && (
//                       <div className="invalid-feedback">
//                         {errors.academicYear}
//                       </div>
//                     )}
//                     <datalist id="datalistOptions">
//                       <option value="2022">2022</option>
//                       <option value="2023">2023</option>
//                       <option value="2024">2024</option>
//                     </datalist>
//                   </div>
//                 </div>

//                 <div className="form-row">
//                   <div className="form-group col-md-12">
//                     <label htmlFor="sub-task">Terms</label>
//                     <input
//                       className={`form-control ${
//                         errors.terms ? "is-invalid" : ""
//                       }`}
//                       type="text"
//                       name="terms"
//                       value={formData.terms}
//                       onChange={handleInputChange}
//                       required
//                     />
//                     {errors.terms && (
//                       <div className="invalid-feedback">{errors.terms}</div>
//                     )}
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="form-group col-md-6">
//                     <label htmlFor="task-title">Starting Date</label>
//                     <input
//                       className={`datepicker-here form-control digits ${
//                         errors.startDate ? "is-invalid" : ""
//                       }`}
//                       type="date"
//                       data-language="en"
//                       placeholder="MM-DD-YYYY"
//                       data-position="top left"
//                       name="startDate"
//                       value={formData.startDate}
//                       onChange={handleInputChange}
//                       required
//                     />
//                     {errors.startDate && (
//                       <div className="invalid-feedback">{errors.startDate}</div>
//                     )}
//                   </div>
//                   <div className="form-group col-md-6">
//                     <label htmlFor="task-title">Ending Date</label>
//                     <input
//                       className={`datepicker-here form-control digits ${
//                         errors.endDate ? "is-invalid" : ""
//                       }`}
//                       type="date"
//                       data-language="en"
//                       placeholder="MM-DD-YYYY"
//                       data-position="top left"
//                       name="endDate"
//                       value={formData.endDate}
//                       onChange={handleInputChange}
//                       required
//                     />
//                     {errors.endDate && (
//                       <div className="invalid-feedback">{errors.endDate}</div>
//                     )}
//                   </div>
//                 </div>
//                <button className="button_ds">Next</button>
//               </form>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreateExam;

// import React, { useState, forwardRef, useImperativeHandle } from "react";
// import "../../assets/css/CreateExam.css";

// const CreateExam = forwardRef((props, ref) => {
//   const [formData, setFormData] = useState({
//     examName: "",
//     academicYear: "",
//     terms: "",
//     startDate: "",
//     endDate: "",
//   });

//   const [errors, setErrors] = useState({});

//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const validate = () => {
//     const validationErrors = {};
//     if (!formData.examName.trim()) {
//       validationErrors.examName = "Exam name is required";
//     }
//     if (!formData.academicYear.trim()) {
//       validationErrors.academicYear = "Academic year is required";
//     }
//     if (!formData.terms.trim()) {
//       validationErrors.terms = "Terms are required";
//     }
//     if (!formData.startDate.trim()) {
//       validationErrors.startDate = "Starting date is required";
//     }
//     if (!formData.endDate.trim()) {
//       validationErrors.endDate = "Ending date is required";
//     }
//     setErrors(validationErrors);
//     return Object.keys(validationErrors).length === 0;
//   };

//   // Expose the validate function through the ref
//   useImperativeHandle(ref, () => ({
//     validate: () => validate(),
//   }));

//   return (
//     <div>
//       <div className="row">
//         <div className="form-group col-md-6">
//           <label htmlFor="exampleDataList">Name</label>
//           <input
//             className={`form-control ${errors.examName ? "is-invalid" : ""}`}
//             list="exam"
//             id="exampleDataList"
//             name="examName"
//             value={formData.examName}
//             onChange={handleInputChange}
//             placeholder="Type the exam name"
//             required
//           />
//           {errors.examName && (
//             <div className="invalid-feedback">{errors.examName}</div>
//           )}
//           <datalist id="exam" className="datalist">
//             <option value="Exam1">Exam1</option>
//             <option value="Exam2">Exam2</option>
//             <option value="Exam3">Exam3</option>
//           </datalist>
//         </div>
//         <div className="form-group col-md-6">
//           <label htmlFor="exampleDataList">Academic Year</label>
//           <input
//             className={`form-control ${
//               errors.academicYear ? "is-invalid" : ""
//             }`}
//             list="datalistOptions"
//             id="exampleDataList"
//             name="academicYear"
//             value={formData.academicYear}
//             onChange={handleInputChange}
//             placeholder="Type the year"
//             required
//           />
//           {errors.academicYear && (
//             <div className="invalid-feedback">{errors.academicYear}</div>
//           )}
//           <datalist id="datalistOptions">
//             <option value="2022">2022</option>
//             <option value="2023">2023</option>
//             <option value="2024">2024</option>
//           </datalist>
//         </div>
//       </div>

//       <div className="row">
//         <div className="form-group col-md-6">
//           <label htmlFor="task-title">Starting Date</label>
//           <input
//             className={`datepicker-here form-control digits ${
//               errors.startDate ? "is-invalid" : ""
//             }`}
//             type="date"
//             data-language="en"
//             placeholder="MM-DD-YYYY"
//             data-position="top left"
//             name="startDate"
//             value={formData.startDate}
//             onChange={handleInputChange}
//             required
//           />
//           {errors.startDate && (
//             <div className="invalid-feedback">{errors.startDate}</div>
//           )}
//         </div>
//         <div className="form-group col-md-6">
//           <label htmlFor="task-title">Ending Date</label>
//           <input
//             className={`datepicker-here form-control digits ${
//               errors.endDate ? "is-invalid" : ""
//             }`}
//             type="date"
//             data-language="en"
//             placeholder="MM-DD-YYYY"
//             data-position="top left"
//             name="endDate"
//             value={formData.endDate}
//             onChange={handleInputChange}
//             required
//           />
//           {errors.endDate && (
//             <div className="invalid-feedback">{errors.endDate}</div>
//           )}
//         </div>
//       </div>
//       <div className="form-row">
//         <div className="form-group col-md-12">
//           <label htmlFor="sub-task">Terms</label>
//           <input
//             className={`form-control ${errors.terms ? "is-invalid" : ""}`}
//             type="text"
//             name="terms"
//             value={formData.terms}
//             onChange={handleInputChange}
//             required
//           />
//           {errors.terms && (
//             <div className="invalid-feedback">{errors.terms}</div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// });

// export default CreateExam;

  

{/* <div className="col-sm-12">
<div className="margin_top">
  <div className="card-body header-card">
  <div className="row">
  <div className="form-group col-md-6">
      <label htmlFor="start-time">Start Time</label>
      <input
        className="timepicker-here form-control digits"
        type="time"
        data-language="en"
        // placeholder="MM-DD-YYYY"
        data-position="top left"
      />
    </div>
    <div className="form-group col-md-6">
      <label for="task-title">Ending Date</label>
      <input
        className="datepicker-here form-control digits"
        type="date"
        data-language="en"
        placeholder="MM-DD-YYYY"
        data-position="top left"
      />
    </div>
  </div>
  

    <div className="form-group col-md-12">
      <label for="sub-task">Duration</label>
      <input
        className="form-control"
        type="text"
        name="terms"
      />
    </div>
    <div className="form-group col-md-12">
      <label for="sub-task">Max Mark</label>
      <input
        className="form-control"
        type="text"
        name="terms"
      />
    </div>
  </div>
</div>
</div> */}


//  <nav class="navbar navbar-expand-lg navbar-light bg-light">
 
    
//  <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
//    <span class="navbar-toggler-icon"></span>
//  </button>
//  <div class="collapse navbar-collapse" id="navbarSupportedContent">
  
  
//        <a class="">Home</a>
 
 
 
 
 
 
//  </div>
//  </nav> 





// import React, { useState } from "react";
// import styled from "styled-components";
// import "../../assets/css/CreateExam.css";
// import "../../assets/css/OfflineAssesment.css";
// import { useNavigate} from "react-router-dom";

// const MainContainer = styled.div`
//   width: 100%;
//   max-width: 800px;
//   margin: 0 auto;
//   padding: 0 16px;
// `;

// const StepContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin-top: 40px;
//   position: relative;
//   :before {
//     content: "";
//     position: absolute;
//     background: #f3e7f3;
//     height: 5px;
//     width: 100%;
//     top: 50%;
//     transform: translateY(-50%);
//     left: 0;
//   }
//   :after {
//     content: "";
//     position: absolute;
//     background: #adc925;
//     height: 4px;
//     width: ${({ width }) => width};
//     top: 50%;
//     transition: 0.4s ease;
//     transform: translateY(-50%);
//     left: 0;
//   }
// `;

// const StepWrapper = styled.div`
//   position: relative;
//   z-index: 1;
//   // flex: 1; /* Ensure each step takes equal space */
//   // display: flex;
//   // justify-content: center;
//   // align-items: center;
// `;

// const StepStyle = styled.div`
//   width: 40px;
//   height: 40px;
//   border-radius: 50%;
//   background-color: ${({ step }) => (step === "completed" ? "#adc925" : "#ffffff")};
//   border: 3px solid ${({ step }) => (step === "completed" ? "#adc925" : "#adc925")};
//   transition: 0.4s ease;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `


// const StepCount = styled.span`
//   font-size: 19px;
//   color: black;
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

// const StepsLabelContainer = styled.div`
//   position: absolute;
//   top: 66px;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

// const StepLabel = styled.span`
//   font-size: 19px;
//   color: #79266a;
//   @media (max-width: 600px) {
//     font-size: 16px;
//   }
// `;

// const ButtonsContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   margin: 0 -15px;
//   margin-top: 50px;
//   margin-bottom: 20px;
// `;

// const ButtonStyle = styled.button`
//   border-radius: 4px;
//   border: 0;
//   background: #79266a;
//   color: #ffffff;
//   cursor: pointer;
//   padding: 16px;
//   width: 90px;
//   :active {
//     transform: scale(0.98);
//   }
//   :disabled {
//     background: #f3e7f3;
//     color: #000000;
//     cursor: not-allowed;
//   }
// `;

// const CheckMark = styled.div`
//   font-size: 26px;
//   font-weight: 600;
//   color: ${({ completed }) => (completed ? 'white' : 'white')}; /* White tick color for completed step */
//   -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
//   -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
//   transform: scaleX(-1) rotate(-46deg);
// `;

// const steps = [
//   {
//     label: "Step1",
//     step: 1,
//   },
//   {
//     label: "Step2",
//     step: 2,
//   },
//   {
//     label: "Step3",
//     step: 3,
//   },
 
// ];

// const CreateNewOfflineAssesment = () => {
//   const history = useNavigate();
//   const [activeStep, setActiveStep] = useState(1);


//   const nextStep = () => {
//     // Perform validation before proceeding to the next step
//     const validationErrors = {};

//     // Step 1 validation checks (if any)
//     if (activeStep === 1) {
//       if (!formData.examName.trim()) {
//         validationErrors.examName = "Exam name is required";
//       }
//       if (!formData.academicYear.trim()) {
//         validationErrors.academicYear = "Academic year is required";
//       }
//       if (!formData.terms.trim()) {
//         validationErrors.terms = "Terms are required";
//       }
//       if (!formData.startDate.trim()) {
//         validationErrors.startDate = "Starting date is required";
//       }
//       if (!formData.endDate.trim()) {
//         validationErrors.endDate = "Ending date is required";
//       }
//     }

//     // Step 2 validation checks
//     if (activeStep === 2) {
//       // Example validation check for selectedGrade and selectedSubject
//    if (Object.keys(selectedSubjects).length === 0) {
//         validationErrors.subjectGrade = "Please select grade and at least one subject.";
//       }
//     }
//     if (activeStep === 3) {
//       if (!formData.startTime.trim()) {
//         validationErrors.startTime = "Starting time is required";
//       }
//       if (!formData.endTime.trim()) {
//         validationErrors.endTime = "Starting date is required";
//       }
//       if (!formData.duration.trim()) {
//         validationErrors.duration = "duration are required";
//       }
//       if (!formData.maxMark.trim()) {
//         validationErrors.maxMark = "maxMark are required";
//       }
//     }

//     // If there are validation errors, set the errors state and return
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//       return; // Do not proceed to the next step
//     }
//     if (activeStep === totalSteps) {
//       // Navigate to the "/OfflineAssessment" route
//       history("/OfflineAssessment");
//   } else {
//       setActiveStep(activeStep + 1);
//   }

//     // If no validation errors, proceed to the next step
//     setActiveStep(activeStep + 1);
//   };

//   const prevStep = () => {
//     setActiveStep(activeStep - 1);
//   };

//   const totalSteps = steps.length;

//   const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

//   const [formData, setFormData] = useState({
//     examName: "",
//     academicYear: "",
//     terms: "",
//     startDate: "",
//     endDate: "",
//     startTime: "",
//     endTime: "",
//     duration: "",
//     maxMark: "",
//   });

//   // State variable to hold form validation errors
//   const [errors, setErrors] = useState({});

//   // Function to handle form input changes
//   const handleInputChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//     setErrors({
//       ...errors,
//       [name]: "",
//     });
//   };


//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const validationErrors = {};
//     // Your validation logic
//     if (Object.keys(validationErrors).length > 0) {
//       setErrors(validationErrors);
//     } else {
//       console.log("Form submitted:", formData);
//       setFormData({
//         examName: "",
//         academicYear: "",
//         terms: "",
//         startDate: "",
//         endDate: "",
//         startTime: "",
//         endTime: "",
//         duration: "",
//         maxMark: "",
//       });
//       setErrors({});
//     }
//   };

//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubjects, setSelectedSubjects] = useState([]);
//   const [subjects, setSubjects] = useState({
//     "10A": ["Maths", "Physics", "Chemistry"],
//     "10B": ["Biology", "History", "Geography"],
//     "10C": ["Biology", "History", "Geography"],
//     "10D": ["Biology", "History", "Geography"],
//     // Add subjects for other grades as needed
//   });

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade((prev) => (prev === grade ? "" : grade));
//     setSelectedSubjects([]);
//   };

//   const handleSubjectClick = (subject) => {
//     setSelectedSubjects((prev) =>
//       prev.includes(subject)
//         ? prev.filter((item) => item !== subject)
//         : [...prev, subject]
//     );
//   };

//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">
//           <div className="card">
//           <div className="card-header pb-0">
//           <h5 className="flex-grow-1">Create Offline Assesment</h5>
//           </div>
//             <MainContainer>
//               <StepContainer width={width}>
//                 {steps.map(({ step, label }) => (
//                   <StepWrapper key={step}>
//                     <StepStyle
//                       step={activeStep >= step ? "completed" : "incomplete"}
//                     >
//                       {activeStep > step ? (
//                         <CheckMark>L</CheckMark>
//                       ) : (
//                         <StepCount>{step}</StepCount>
//                       )}
//                     </StepStyle>
//                     <StepsLabelContainer>
//                       <StepLabel key={step}>{label}</StepLabel>
//                     </StepsLabelContainer>
//                   </StepWrapper>
//                 ))}
//               </StepContainer>

//               {activeStep === 1 && (
//                 <div className="col-sm-12">
//                   <div className="margin_top">
//                     <div className="card-body header-card">
//                       <form
//                         className="form-bookmark needs-validation"
//                         id="bookmark-form"
//                         noValidate
//                         onSubmit={handleSubmit}
//                       >
//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="exampleDataList">Name</label>
//                             <input
//                               className={`form-control ${
//                                 errors.examName ? "is-invalid" : ""
//                               }`}
//                               list="exam"
//                               id="exampleDataList"
//                               name="examName"
//                               value={formData.examName}
//                               onChange={handleInputChange}
//                               placeholder="Type the exam name"
//                               required
//                             />
//                             {errors.examName && (
//                               <div className="invalid-feedback">
//                                 {errors.examName}
//                               </div>
//                             )}
//                             <datalist id="exam">
//                               <option value="Exam1">Exam1</option>
//                               <option value="Exam2">Exam2</option>
//                               <option value="Exam3">Exam3</option>
//                             </datalist>
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label htmlFor="exampleDataList">
//                               Academic Year
//                             </label>
//                             <input
//                               className={`form-control ${
//                                 errors.academicYear ? "is-invalid" : ""
//                               }`}
//                               list="datalistOptions"
//                               id="exampleDataList"
//                               name="academicYear"
//                               value={formData.academicYear}
//                               onChange={handleInputChange}
//                               placeholder="Type the year"
//                               required
//                             />
//                             {errors.academicYear && (
//                               <div className="invalid-feedback">
//                                 {errors.academicYear}
//                               </div>
//                             )}
//                             <datalist id="datalistOptions">
//                               <option value="2022">2022</option>
//                               <option value="2023">2023</option>
//                               <option value="2024">2024</option>
//                             </datalist>
//                           </div>
//                         </div>

//                         <div className="form-row">
//                           <div className="form-group col-md-12">
//                             <label htmlFor="sub-task">Terms</label>
//                             <input
//                               className={`form-control ${
//                                 errors.terms ? "is-invalid" : ""
//                               }`}
//                               type="text"
//                               name="terms"
//                               value={formData.terms}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.terms && (
//                               <div className="invalid-feedback">
//                                 {errors.terms}
//                               </div>
//                             )}
//                           </div>
//                         </div>

//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="task-title">Starting Date</label>
//                             <input
//                               className={`datepicker-here form-control digits ${
//                                 errors.startDate ? "is-invalid" : ""
//                               }`}
//                               type="date"
//                               data-language="en"
//                               placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="startDate"
//                               value={formData.startDate}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.startDate && (
//                               <div className="invalid-feedback">
//                                 {errors.startDate}
//                               </div>
//                             )}
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label htmlFor="task-title">Ending Date</label>
//                             <input
//                               className={`datepicker-here form-control digits ${
//                                 errors.endDate ? "is-invalid" : ""
//                               }`}
//                               type="date"
//                               data-language="en"
//                               placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="endDate"
//                               value={formData.endDate}
//                               onChange={handleInputChange}
//                               required
//                             />
//                             {errors.endDate && (
//                               <div className="invalid-feedback">
//                                 {errors.endDate}
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               )}
//               {activeStep === 2 && (
//                 <div className="margin_top1">
//                   <h6 className="grade">Select Grade</h6>
//                   <div className="grade-checkboxes">
//                     {Object.keys(grades).map((grade) => (
//                       <div key={grade} className="grade-checkbox">
//                         <label>
//                           <input
//                             type="checkbox"
//                             checked={grades[grade]}
//                             onChange={() => handleGradeClick(grade)}
//                           />
//                           {"   "}
//                           {grade}
//                         </label>
//                         {selectedGrade === grade && (
//                           <div>
//                             <h6>Select Subject</h6>
//                             {subjects[grade].map((subject) => (
//                               <div key={subject} className="subject-checkbox">
//                                 <label>
//                                   <input
//                                     type="checkbox"
//                                     checked={selectedSubjects.includes(subject)}
//                                     onChange={() => handleSubjectClick(subject)}
//                                   />
//                                   {"   "}
//                                   {subject}
//                                 </label>
//                               </div>
//                             ))}
//                           </div>
//                         )}
//                       </div>
//                     ))}
//                   </div>
//                   {selectedSubjects ? (
//                     <div className="error-message">{errors.subjectGrade}</div>
//                   ) : null}
//                 </div>
//               )}

//               {activeStep === 3 && (
//                 <div className="col-sm-12">
//                   <div className="margin_top">
//                     <div className="card-body header-card">
//                       <form
//                         className="form-bookmark needs-validation"
//                         id="bookmark-form"
//                         noValidate
//                         onSubmit={handleSubmit}
//                       >
//                         <div className="row">
//                           <div className="form-group col-md-6">
//                             <label htmlFor="start-time">Start Time</label>
//                             <input
//                               className={`timepicker-here form-control digits ${
//                                 errors.startTime ? "is-invalid" : ""
//                               }`}
//                               type="time"
//                               data-language="en"
//                               // placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="startTime"
//                               value={formData.startTime}
//                               onChange={handleInputChange}
//                             />
//                             {errors.startTime && (
//                               <div className="invalid-feedback">
//                                 {errors.startTime}
//                               </div>
//                             )}
//                           </div>
//                           <div className="form-group col-md-6">
//                             <label for="task-title">Ending Tme</label>
//                             <input
//                               className={`Timepicker-here form-control digits ${
//                                 errors.endTime ? "is-invalid" : ""
//                               }`}
//                               type="time"
//                               data-language="en"
//                               // placeholder="MM-DD-YYYY"
//                               data-position="top left"
//                               name="endTime"
//                               value={formData.endTime}
//                               onChange={handleInputChange}
//                             />
//                             {errors.endTime && (
//                               <div className="invalid-feedback">
//                                 {errors.endTime}
//                               </div>
//                             )}
//                           </div>
//                         </div>
//                         <div className="row">
//                         <div className="form-group col-md-6">
//                           <label for="sub-task">Duration</label>
//                           <input
//                             className={`form-control ${
//                               errors.duration ? "is-invalid" : ""
//                             }`}
//                             type="text"
//                             name="duration"
//                             value={formData.duration}
//                             onChange={handleInputChange}
//                           />
//                           {errors.duration && (
//                             <div className="invalid-feedback">
//                               {errors.duration}
//                             </div>
//                           )}
//                         </div>
//                         <div className="form-group col-md-6">
//                           <label for="sub-task">Max Mark</label>
//                           <input
//                             className={`form-control ${
//                               errors.maxMark ? "is-invalid" : ""
//                             }`}
//                             type="text"
//                             name="maxMark"
//                             value={formData.maxMark}
//                             onChange={handleInputChange}
//                           />
//                           {errors.maxMark && (
//                             <div className="invalid-feedback">
//                               {errors.maxMark}
//                             </div>
//                           )}
//                         </div>
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                 </div>
//               )}

//               <ButtonsContainer>
//                 <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>
//                   Previous
//                 </ButtonStyle>
//                 <ButtonStyle
//                   onClick={nextStep}
              
                  
//                   className={errors.subjectGrade ? "error-button" : ""}
//                 >
//                   Next
//                 </ButtonStyle>
              
//               </ButtonsContainer>
//             </MainContainer>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CreateNewOfflineAssesment;