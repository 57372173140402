import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import bg_1 from "../../../assets/images/assignment/bg_1.svg";
import b1 from "../../../assets/images/user/b1.png";
import b2 from "../../../assets/images/user/b2.png";
import b3 from "../../../assets/images/user/b3.png";
import b4 from "../../../assets/images/user/b4.png";
import b5 from "../../../assets/images/user/b5.png";
import b6 from "../../../assets/images/user/b6.png";
import b7 from "../../../assets/images/user/b7.png";
import b8 from "../../../assets/images/user/b8.png";
import { Check } from "react-feather";

function AssignmentDetails() {
  useEffect(() => {
    document.title = "Edsys | Assignment Details ";
  }, []);
  //   const displayStyle={
  //     borderRadius: "15px",
  //     backgroundImage: "url(images/assignment/bg_1.svg)",
  //     backgroundSize: "cover",
  //     backgroundPosition: "center center",
  //     display: "block"
  //     borderRadius: "15px",

  // }
  const studData = [
    {
      id: 1,
      rank: 1,
      name: "Lucy Tom",
      icon_set: "../assets/images/user/b1.png",
      point: 49,
      pointOf: 50,
    },
    {
      id: 2,
      rank: 2,
      name: "Lucy Tom",
      icon_set: "../assets/images/user/b2.png",
      point: 46,
      pointOf: 50,
    },
    {
      id: 3,
      rank: 3,
      name: "Thomas Abraham",
      icon_set: "../assets/images/user/b3.png",
      point: 42,
      pointOf: 50,
    },
    {
      id: 4,
      rank: 4,
      name: "Janet James",
      icon_set: "../assets/images/user/b4.png",
      point: 40,
      pointOf: 50,
    },

    {
      id: 5,
      rank: 5,
      name: "Ganesh",
      icon_set: "../assets/images/user/b5.png",
      point: 38,
      pointOf: 50,
    },

    {
      id: 6,
      rank: 6,
      name: "Linga Lopez",
      icon_set: "../assets/images/user/b6.png",
      point: 36,
      pointOf: 50,
    },
  ];
  return (
    <div>
      {/* Container-fluid starts */}
      <div className="row assignment-details">
        <div className="col-12">
          <div
            className="knowledgebase-bg b-center bg-size"
            style={{
              background: "url('assets/images/assignment/bg_1.svg')",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              display: "block",
            }}
          >
            <img
              className="bg-img-cover bg-center"
              src={bg_1}
              alt="looginpage"
              style={{ display: "none" }}
            />
          </div>
          <div className="knowledgebase-search">
            <div className="details-banner">
              <h3>Assignment Title 01</h3>
              <h5>Physics</h5>
              <h6>
                Assigned by<span>Mathew James</span>
              </h6>
              <div className="sub-date">28 Oct 2021, Thu</div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <div className="card br-15">
            <div className="card-header pb-0 br-15">
              <h5 className="pull-left">Students</h5>
            </div>
            <div className="card-body">
              <div className="tabbed-card">
                <ul
                  className="pull-right nav nav-pills nav-primary"
                  id="pills-clrtab1"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="pills-all-tab1"
                      data-bs-toggle="pill"
                      href="#pills-all"
                      role="tab"
                      aria-controls="pills-all"
                      aria-selected="true"
                    >
                      ALL
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-reviewed-tab1"
                      data-bs-toggle="pill"
                      href="#pills-reviewed"
                      role="tab"
                      aria-controls="pills-reviewed"
                      aria-selected="false"
                    >
                      REVIEWED
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-submitted-tab1"
                      data-bs-toggle="pill"
                      href="#pills-submitted"
                      role="tab"
                      aria-controls="pills-submitted"
                      aria-selected="false"
                    >
                      SUBMITTED
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="pills-pending-tab1"
                      data-bs-toggle="pill"
                      href="#pills-pending"
                      role="tab"
                      aria-controls="pills-pending"
                      aria-selected="false"
                    >
                      PENDING
                    </a>
                  </li>
                </ul>
                <div className="tab-content" id="pills-clrtabContent1">
                  <div
                    className="tab-pane fade show active"
                    id="pills-all"
                    role="tabpanel"
                    aria-labelledby="pills-all-tab1"
                  >
                    <div className="assignment-status">
                      {studData.map((studData) => (
                        <div className="student">
                          <div
                            className="d-flex align-items-center"
                            key={studData}
                          >
                            <div className="flex-shrink-0 user-image">
                              <img src={studData.icon_set} alt="..." />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="full-name">{studData.name}</div>
                            </div>

                            <div
                              onclick="location.href='view-assignment.html';"
                              className="status submitted"
                            >
                              <Check className="me-2" data-feather="check" />
                              Submitted
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-reviewed"
                    role="tabpanel"
                    aria-labelledby="pills-reviewed-tab1"
                  >
                    <div className="assignment-status">
                      {studData.map((studData) => (
                        <div className="student">
                          <div
                            className="d-flex align-items-center"
                            key={studData}
                          >
                            <div className="flex-shrink-0 user-image">
                              <img src={studData.icon_set} alt="..." />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="full-name">{studData.name}</div>
                            </div>
                            <Link to="/ViewReviewedAssignment/1">
                              <div className="status reviewed">
                                <Check className="me-2" />
                                reviewed
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-submitted"
                    role="tabpanel"
                    aria-labelledby="pills-submitted-tab1"
                  >
                    <div className="assignment-status">
                      <div className="assignment-status">
                        {studData.map((studData) => (
                          <div className="student">
                            <div
                              className="d-flex align-items-center"
                              key={studData}
                            >
                              <div className="flex-shrink-0 user-image">
                                <img src={studData.icon_set} alt="..." />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <div className="full-name">{studData.name}</div>
                              </div>
                              <Link to="/ViewReviewedAssignment/">
                                <div
                                  onclick="location.href='view-assignment.html';"
                                  className="status submitted"
                                >
                                  <Check
                                    className="me-2"
                                    data-feather="check"
                                  />
                                  Submitted
                                </div>
                              </Link>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-pending"
                    role="tabpanel"
                    aria-labelledby="pills-pending-tab1"
                  >
                    <div className="assignment-status">
                      {studData.map((studData) => (
                        <div className="student">
                          <div
                            className="d-flex align-items-center"
                            key={studData}
                          >
                            <div className="flex-shrink-0 user-image">
                              <img src={studData.icon_set} alt="..." />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <div className="full-name">{studData.name}</div>
                            </div>
                            <div
                              onclick="location.href='view-assignment.html';"
                              className="status pending"
                            >
                              <Check className="me-2" />
                              Pending
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Container-fluid Ends */}
    </div>
  );
}

export default AssignmentDetails;
