import React from 'react'
import '../assets/css/main.css';
import Clock from 'react-live-clock'
import Moment from 'react-moment';
import clockonicon from '../assets/images/punch/clockonicon.svg';
import clockofficon from '../assets/images/punch/clockofficon.svg';


function WelcomeCard() {
    var showdate =new Date();
    var displaytodaysdate=showdate.getDate()+'/'+(showdate.getMonth()+1)+'/'+showdate.getFullYear();
    var dt=showdate.toDateString();
    return (
//         <div className="card profile-greeting">
//                 <div className="card-body text-center edsys-check-in p-t-3">
//                   <h3 className="font-light">Wellcome Back, Mary!!</h3>
//                   <p>Welcome to the Edsys! we are glad that you are visit this dashboard.</p>
//                   <div className="check-in-block">
//                     <div className="row">
//                       <div className="col-6">
//                         <div className="check-in">
//                           Check In <div>10:00 am</div>
//                         </div>
//                       </div>
//                       <div className="col-6">
//                         <div className="check-out">
//                           Check Out <div>4:00 pm</div>
//                         </div>
//                       </div>
//                     </div>
//                   </div>

// {/* clock start here */}


//                   <div className="clock">
                    
//                    <h1>{dt}</h1> 
//                     <Clock format={'HH : mm : ss'} ticking={true} timezone={'IST'}/>
                    
//                   </div>
//                   <button className="btn btn-light">Check Out</button>
//                 </div>
//                 <div className="confetti">
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
//                   <div className="confetti-piece"></div>
              
//                 </div>
//               </div>


<div className="card profile-greeting">
<div className="card-body text-center edsys-check-in p-t-3">
  <h3 className="font-light">Welcome Back, Mary!!</h3>
  <p>Welcome to the Edsys! we are glad that you are visit this dashboard.</p>
  <div className="clock">
  <h1>{dt}</h1> 
                  <Clock format={'HH : mm : ss'} ticking={true} timezone={'IST'}/>
  </div>
  <div className="punch-button">
    <div className="total-hrs">TOTAL HOURS</div>
    <div className="start-time">00:00:00</div>
    <div className="punch-btn">Punch In</div>
</div>
<div className="row work-duration">
  <div className="col-md-4">
      <img className="p-in-img" src={clockonicon} />
      <div className="p-in">Punch In</div>
      <div className="p-in-time">-- : -- : --</div>
  </div>
  <div className="col-md-4 ms-auto text-end">
      <img className="p-out-img" src={clockofficon} />
      <div className="p-out">Punch Out</div>
      <div className="p-out-time">-- : -- : --</div>
  </div>
</div>
  
</div>
<div className="confetti">
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  <div className="confetti-piece"></div>
  
</div>
</div>
    )
}

export default WelcomeCard
