import React, { useState } from 'react';

const Period = props => {
    debugger
    const data = props.data
    return (
        <div class="tt-col">
            <div class="tt-desc tt-desc-inner">
                <img src="assets/images/svg/clock.svg" />
                <div class="title">Period {data.PeriodId} </div>
                <div class="duration">
                    <div class="start">{data.startTime}</div>
                    <div class="end">{data.endTime}</div>
                </div>
            </div>
        </div>
    )
};

export default Period;





