import React, { useEffect } from "react";
import "../assets/css/main.css";
import WelcomeCard from "../components/WelcomeCard";
import CourseCountCard from "../components/CourseCountCard";
import AssignmentsTable from "../components/AssignmentsTable";
import LessonsSlider from "../components/LessonsSlider";

function Dashboard() {
  useEffect(() => {
    document.title = "Edsys";
  }, []);
  return (
    <div>
      {/* Container-fluid starts  */}
      <div className="row">
        <div className="col-md-6">
          <WelcomeCard />
        </div>
        <div className="col-md-6">
          <div className="row my-status">
            <CourseCountCard />
          </div>
        </div>
      </div>

      <LessonsSlider />

      <AssignmentsTable />

      <AssignmentsTable />

      {/* Container-fluid Ends */}
    </div>
  );
}

export default Dashboard;
