import React from 'react';

const LectureDetails = props => {
    // debugger;
    var lecture = props.lecture
    var period = props.period
    const renderLectureDetails = () => {
        return (
            lecture ? (lecture).map((item, index) => {
                var cardColor = period.PeriodId
                if (cardColor > 6)
                    cardColor = Math.floor(Math.random() * 6) + 1;
                return (
                    <div className="col-md-12">
                        <div className={"card sub-" + cardColor + " stack-card"}>
                            <div className="card-header">
                                <div className="d-flex">
                                    <img className="flex-grow-0" src="assets/images/assignment/subtopicicon.svg" />
                                    <h5 className="flex-grow-1">{item.subject}</h5>
                                    <div className="class">period {period.PeriodId}</div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <div className="d-flex">
                                    <img className="flex-grow-0" src={item.imgPath} />
                                    <div className="flex-grow-1 name">{item.name}</div>
                                    <div className="time">{period.startTime} - {period.endTime}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                )

            }) : null
        )

    }
    return (
        <>
            {renderLectureDetails()}
        </>
    )
};

export default LectureDetails;




