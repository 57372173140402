import React from 'react'
import b3 from '../../assets/images/user/b3.png'

const StudentModal = props => {
    const displayStyle={
        display:"block"
    }
    return (
         
        <div class="modal fade modal-bookmark show testingll" id="exampleModal1" style={displayStyle} tabindex="-1" role="dialog" aria-labelledby="exampleModal1Label" aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
                <div class="d-flex align-items-center">
                    <div class="p-image flex-grow-0">
                        <img src={b3}/>
                    </div>
                    <div class="student-name flex-grow-1">Alexander Miller</div>
                    <div class="no-leave">4 Days</div>
                </div>
            </div>
            <div class="modal-body modal-scroll">
              <div class="row">
                  <div class="col-6">
                      <div class="fixed-form">
                          <div class="al-label">From</div>
                          <div class="al-input">01-12-2021</div>
                      </div>
                  </div>
                  <div class="col-6">
                    <div class="fixed-form">
                        <div class="al-label">To</div>
                        <div class="al-input">05-12-2021</div>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <div class="fixed-form">
                          <div class="al-label">Applied Date</div>
                          <div class="al-input">28-11-2021</div>
                      </div>
                  </div>
                  <div class="col-6">
                    <div class="fixed-form">
                        <div class="al-label">No. of Days</div>
                        <div class="al-input">5 Days</div>
                    </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <div class="fixed-form">
                          <div class="al-label">Leave Type</div>
                          <div class="al-input">Full Day</div>
                      </div>
                  </div>
                  <div class="col-6">
                    <div class="fixed-form">
                        <div class="al-label">Leave Category</div>
                        <div class="al-input">Planned</div>
                    </div>
                  </div>
              </div>
              <div class="row">
                <div class="col-12">
                    <div class="fixed-form">
                        <div class="al-label">Leave Reason</div>
                        <div class="al-text-area">Going to native place</div>
                    </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                    <div class="fixed-form">
                        <div class="al-label">Leave Status</div>
                        <div class="al-approved">Approved</div>
                        {/* <!-- <div class="al-pending">Pending</div> --> */}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
        
    )
}
export default  StudentModal