import React, { useState } from 'react';

const Lecture = props => {
    // debugger
    const data = props.data
    return (
        <div class={"tt-col tt-content " + props.weekDayName}>
            <div class="tt-desc tt-desc-inner">
                <img src={data.imgPath} />
                <div class="title">{data.name}</div>
                <div class="duration">
                    <div class="status">No Duty</div>
                </div>
            </div>
        </div>
    )
};
export default Lecture;





