import React, { Component } from "react";
import b1 from "../../../assets/images/user/b1.png";
import b2 from "../..//../assets/images/user/b2.png";
import b3 from "../..//../assets/images/user/b3.png";
import b4 from "../..//../assets/images/user/b4.png";
import b5 from "../..//../assets/images/user/b5.png";
import b6 from "../..//../assets/images/user/b6.png";
import b7 from "../..//../assets/images/user/b7.png";
import b8 from "../..//../assets/images/user/b8.png";
import downICon from "../..//../assets/images/svg/downicon.svg";
import {
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  CheckSquare,
  XSquare,
} from "react-feather";
import PresentTab from "./PresentTab";
import AbsentTab from "./AbsentTab";

export class Attendance extends Component {
  //#region constructor
  constructor(props) {
    super(props);
    debugger;
    this.state = {
      isAbsentTab: false,
      isPresentTab: false,

      // isFromReview: window.location.href.split('/')[window.location.href.split('/').length - 1]

      //this.props.isFromReview?this.props.isFromReview:true
    };
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "Edsys | Attendance";
  }
  //#endregion
  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isAbsentTab: false,
      isPresentTab: false,
    });
  };
  openAbsentTab = () => {
    // this.setInitialState();
    console.log("its work");
    this.setState({ isAbsentTab: true }, () => {});
  };
  openPresentTab = () => {
    // this.setInitialState();
    this.setState({ isPresentTab: true }, () => {});
  };

  render() {
    const studData = [
      {
        id: 1,
        name: "Lucy Tom",
        icon_set: "../assets/images/user/b1.png",
      },
      {
        id: 2,
        name: "Lucy Tom",
        icon_set: "../assets/images/user/b2.png",
      },
      {
        id: 3,
        name: "Thomas Abraham",
        icon_set: "../assets/images/user/b3.png",
      },
      {
        id: 4,
        name: "Janet James",
        icon_set: "../assets/images/user/b4.png",
      },

      {
        id: 5,
        name: "Ganesh",
        icon_set: "../assets/images/user/b5.png",
      },

      {
        id: 6,
        name: "Linga Lopez",
        icon_set: "../assets/images/user/b6.png",
      },
    ];
    return (
      <div>
        {/* <!-- Container-fluid starts--> */}
        <div class="row">
          <div class="col-sm-12">
            <div class="card br-15">
              <div class="card-header br-15 pb-0">
                <h5>Attendance</h5>
              </div>
              <div class="card-body attend-sec ">
                <ul
                  class="nav nav-tabs search-list"
                  id="top-tab"
                  role="tablist"
                >
                  <li class="nav-item">
                    <a
                      class="nav-link nav-link1 "
                      id="all-link"
                      onClick={this.openPresentTab}
                      data-bs-toggle="tab"
                      href="#all-links"
                      role="tab"
                      aria-selected="true"
                    >
                      <CheckSquare />
                      Present
                    </a>
                    <div class="material-border"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link nav-link1 atnd active"
                      id="image-link"
                      data-bs-toggle="tab"
                      href="#image-links"
                      role="tab"
                      aria-selected="false"
                    >
                      <i class="icofont icofont-tasks"></i>Attendance
                    </a>
                    <div class="material-border"></div>
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link nav-link1"
                      id="video-link"
                      onClick={this.openAbsentTab}
                      data-bs-toggle="tab"
                      href="#video-links"
                      role="tab"
                      aria-selected="false"
                    >
                      <XSquare />
                      Absent
                    </a>
                    <div class="material-border"></div>
                  </li>
                </ul>
                <div class="tab-content" id="top-tabContent">
                  <div
                    class="search-links tab-pane fade"
                    id="all-links"
                    role="tabpanel"
                    aria-labelledby="all-link"
                  >
                    {this.state.isPresentTab ? (
                      <PresentTab onClose={this.openPresentTab} />
                    ) : null}
                  </div>
                  <div
                    class="tab-pane fade show active"
                    id="image-links"
                    role="tabpanel"
                    aria-labelledby="image-link"
                  >
                    <div
                      class="mark-sec"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseicon"
                      aria-expanded="true"
                      aria-controls="collapse11"
                    >
                      <img src={downICon} />
                      <div>
                        <ChevronDown />{" "}
                      </div>
                    </div>
                    <div
                      class="collapse"
                      id="collapseicon"
                      aria-labelledby="collapseicon"
                      data-bs-parent="#accordionoc"
                    >
                      <div class="mark-all-sec">
                        <div class="option-group">
                          <div class="option-container">
                            <input
                              class="option-input"
                              checked
                              id="option-1"
                              type="radio"
                              name="options"
                            />
                            <input
                              class="option-input"
                              id="option-2"
                              type="radio"
                              name="options"
                            />

                            <label class="option" for="option-1">
                              <span class="option__indicator"></span>
                              <span class="option__label">
                                <sub>mark all</sub>Absent
                              </span>
                            </label>

                            <label class="option" for="option-2">
                              <span class="option__indicator"></span>
                              <span class="option__label">
                                <sub>mark all</sub>Present
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex flex-wrap mb-4">
                      <div onClick="location.href='#alph-a';" class="alphabets">
                        A
                      </div>
                      <div onClick="location.href='#alph-b';" class="alphabets">
                        B
                      </div>
                      <div onClick="location.href='#alph-c';" class="alphabets">
                        C
                      </div>
                      <div onClick="location.href='#alph-d';" class="alphabets">
                        D
                      </div>
                      <div onClick="location.href='#alph-e';" class="alphabets">
                        E
                      </div>
                      <div onClick="location.href='#alph-f';" class="alphabets">
                        F
                      </div>
                      <div onClick="location.href='#alph-g';" class="alphabets">
                        G
                      </div>
                      <div onClick="location.href='#alph-h';" class="alphabets">
                        H
                      </div>
                      <div onClick="location.href='#alph-i';" class="alphabets">
                        I
                      </div>
                      <div onClick="location.href='#alph-j';" class="alphabets">
                        J
                      </div>
                      <div onClick="location.href='#alph-k';" class="alphabets">
                        K
                      </div>
                      <div onClick="location.href='#alph-l';" class="alphabets">
                        L
                      </div>
                      <div onClick="location.href='#alph-m';" class="alphabets">
                        M
                      </div>
                      <div onClick="location.href='#alph-n';" class="alphabets">
                        N
                      </div>
                      <div onClick="location.href='#alph-o';" class="alphabets">
                        O
                      </div>
                      <div onClick="location.href='#alph-p';" class="alphabets">
                        P
                      </div>
                      <div onClick="location.href='#alph-q';" class="alphabets">
                        Q
                      </div>
                      <div onClick="location.href='#alph-r';" class="alphabets">
                        R
                      </div>
                      <div onClick="location.href='#alph-s';" class="alphabets">
                        S
                      </div>
                      <div onClick="location.href='#alph-t';" class="alphabets">
                        T
                      </div>
                      <div onClick="location.href='#alph-u';" class="alphabets">
                        U
                      </div>
                      <div onClick="location.href='#alph-v';" class="alphabets">
                        V
                      </div>
                      <div onClick="location.href='#alph-w';" class="alphabets">
                        W
                      </div>
                      <div onClick="location.href='#alph-x';" class="alphabets">
                        X
                      </div>
                      <div onClick="location.href='#alph-y';" class="alphabets">
                        Y
                      </div>
                      <div onClick="location.href='#alph-z';" class="alphabets">
                        Z
                      </div>
                    </div>
                    <div class="atnd-scroll">
                      <div class="students-sort">
                        <div id="alph-a" class="sort-alph">
                          A
                        </div>
                      </div>
                      <div class="std-container">
                        <ul class="responsive-table">
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b1} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Abigail Ellison
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on1"
                                  name="state-d1"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on1"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na1"
                                  name="state-d1"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na1"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  <ChevronLeft />
                                  <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off1"
                                  name="state-d1"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off1"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b2} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Adam Abraham
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on2"
                                  name="state-d2"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on2"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na2"
                                  name="state-d2"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na2"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off2"
                                  name="state-d2"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off2"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b3} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Alexandra Connor
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on3"
                                  name="state-d3"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on3"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na3"
                                  name="state-d3"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na3"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off3"
                                  name="state-d3"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off3"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b4} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Ava Jacob
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on4"
                                  name="state-d4"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on4"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na4"
                                  name="state-d4"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na4"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off4"
                                  name="state-d4"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off4"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="students-sort">
                        <div id="alph-b" class="sort-alph">
                          B
                        </div>
                      </div>
                      <div class="std-container">
                        <ul class="responsive-table">
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b5} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Bella Ellison
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on5"
                                  name="state-d5"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on5"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na5"
                                  name="state-d5"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na5"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  <ChevronLeft />
                                  <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off5"
                                  name="state-d5"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off5"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b6} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Blake Frank
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on6"
                                  name="state-d6"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on6"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na6"
                                  name="state-d6"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na6"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off6"
                                  name="state-d2"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off6"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b7} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Bernadette Connor
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on7"
                                  name="state-d7"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on7"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na7"
                                  name="state-d7"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na7"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off7"
                                  name="state-d7"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off7"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b8} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Betty Jacob
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on8"
                                  name="state-d8"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on8"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na8"
                                  name="state-d8"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na8"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off8"
                                  name="state-d8"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off8"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div class="students-sort">
                        <div id="alph-c" class="sort-alph">
                          C
                        </div>
                      </div>
                      <div class="std-container">
                        <ul class="responsive-table">
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b1} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Carolyn Ellison
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on9"
                                  name="state-d9"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on9"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na9"
                                  name="state-d9"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na5"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  <ChevronLeft />
                                  <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off9"
                                  name="state-d9"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off9"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b2} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Charles Frank
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on10"
                                  name="state-d10"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on10"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na10"
                                  name="state-d10"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na10"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off10"
                                  name="state-d10"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off10"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b3} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Chloe Connor
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on11"
                                  name="state-d11"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on11"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na11"
                                  name="state-d11"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na11"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off11"
                                  name="state-d11"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off11"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                          <li class="table-row">
                            <div
                              class="col col-1 avatar"
                              data-label="Student Image"
                            >
                              <img src={b4} />
                            </div>
                            <div
                              class="col col-9 std-name"
                              data-label="Student Name"
                            >
                              Claire Jacob
                            </div>
                            <div class="col col-2 t-switch" data-label="Action">
                              <div class="switch-toggle switch-3 switch-candy">
                                <input
                                  class="ab-pr"
                                  id="on12"
                                  name="state-d12"
                                  type="radio"
                                  checked=""
                                />
                                <label
                                  class="ab-pr absent"
                                  for="on12"
                                  onclick=""
                                >
                                  Absent
                                </label>

                                <input
                                  class="ab-pr"
                                  id="na12"
                                  name="state-d12"
                                  type="radio"
                                  checked="checked"
                                />
                                <label
                                  for="na12"
                                  class="disabled d-flex ab-pr"
                                  onclick=""
                                >
                                  {" "}
                                  <ChevronLeft /> <ChevronRight />
                                </label>

                                <input
                                  class="ab-pr"
                                  id="off12"
                                  name="state-d12"
                                  type="radio"
                                />
                                <label
                                  class="ab-pr present"
                                  for="off12"
                                  onclick=""
                                >
                                  Present
                                </label>

                                <a></a>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade"
                    id="video-links"
                    role="tabpanel"
                    aria-labelledby="video-link"
                  >
                    <div class="std-container">
                      {this.state.isPresentTab ? (
                        <AbsentTab onClose={this.openAbsentTab} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
    );
  }
}

export default Attendance;
