
import React from 'react';
import { Edit,Edit2,Edit3, Trash2, Link, MoreHorizontal } from "react-feather";


const TodoDetails = props => {
    var taskList = [{
        Id: 1,
        Title: "Task Name",
        Category: "General",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        logo: '{Edit3} from "react-feather"'
    }, {
        Id: 2,
        Title: "Task Name",
        Category: "General",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
    }, {
        Id: 3,
        Title: "Task Name",
        Category: "General",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
    }, {
        Id: 4,
        Title: "Task Name",
        Category: "General",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
    }, {
        Id: 5,
        Title: "Task Name",
        Category: "General",
        Description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been"
    }]
    var headerTitle = props.data.title
    return (
        <div className="email-right-aside bookmark-tabcontent">
            <div className="card email-body radius-left">
                <div className="ps-0">
                    <div className="tab-content">
                        <div className="fade tab-pane active show" id="pills-tasks" role="tabpanel" aria-labelledby="pills-tasks-tab">
                            <div className="card mb-0">
                                <div className="card-header d-flex">
                                    <h6 className="mb-0">{headerTitle}</h6>
                                    <a href="javascript:void(0)"><i className="me-2" data-feather="printer"></i>Print</a>
                                </div>
                                <div className="card-body p-0">
                                    <div className="taskadd">
                                        <div className="table-responsive">
                                            <table className="table">
                                                {taskList ? taskList.map((task, index) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <h6 className="task_title_0">{task.Title}</h6>
                                                                <p className="project_name_0">{task.Category}</p>
                                                            </td>
                                                            <td>
                                                                <p className="task_desc_0">{task.Description}</p>
                                                            </td>
                                                            <td>
                                                                {headerTitle == "Created by me" ? (
                                                                    <a className="me-2" href="javascript:void(0)"><Edit3/></a>
                                                                ) :
                                                                    (<>
                                                                    <span >
                                                                        <a className="me-2" href="javascript:void(0)"><Edit3 /></a></span>
                                                                       {/* <span> <a href="javascript:void(0)"><MoreHorizontal /></a></span> */}
                                                                       </>
                                                                       )
                                                                }
                                                            </td>
                                                            <td><a href="javascript:void(0)"><Trash2 /></a></td>
                                                        </tr>)
                                                }) : (<tr><td><div className="details-bookmark text-center"><span>No tasks found.</span></div></td></tr>)
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default TodoDetails;
