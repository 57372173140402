import React, { useState } from "react";
import "../../src/assets/css/main.css";
import "../../src/assets/css/style.css";
import "../assets/css/dark.css";

import Logo from "../assets/images/logo/logo.png";
import DarkLogo from "../assets/images/logo/dark-logo.png";
import User1 from "../assets/images/user/1.jpeg";
import User2 from "../assets/images/user/2.jpeg";
import User4 from "../assets/images/user/4.jpeg";

import {
  Maximize,
  ShoppingCart,
  Bell,
  Grid,
  Activity,
  CheckCircle,
  FileText,
  UserCheck,
  MoreHorizontal,
  MessageSquare,
  Unlock,
  AlignRight,
  LogOut,
  ArrowLeft,
  Settings,
  Home,
  Airplay,
  Box,
  ArrowRight,
  Moon,
} from "react-feather";
import dashboardAvatar from "../assets/images/dashboard/1.png";
import "./ExternalScripts";
import { Link } from "react-router-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NavMenu from "./NavMenu";
// import Sidebar from './Sidebar'
import Sidebar from "./Sidebar";

function Header() {
  const [click, setClick] = useState(false);
  const handleClick = () => {
    if (click == true) {
      document.getElementById("page_container").style.marginLeft = "290px";
      document.getElementById("the_footer").style.marginLeft = "290px";
      setClick(false);
    } else {
      document.getElementById("page_container").style.marginLeft = "0px";
      document.getElementById("the_footer").style.marginLeft = "0px";
      setClick(true);
    }
  };
  const closeMobileMenu = () => setClick(false);
  const [dark, setDark] = useState(true);
  const [FullScreen, setFullScreen] = useState(false);
  function DarkMode() {
    if (dark) {
      document.body.classList.add("dark-only");
    } else {
      document.body.classList.remove("dark-only");
    }
  }
  function MyFullScreen() {
    if (FullScreen === true) {
      document.exitFullscreen();
    } else {
      document.body.requestFullscreen();
    }
  }

  return (
    <div>
      {/* Page Header Start */}
      <div className="page-main-header">
        <div className="main-header-right row m-0">
          <div className="main-header-left">
            <div className="logo-wrapper">
              <a>
                <img className="img-fluid" src={Logo} alt="" />
              </a>
            </div>
            <div className="dark-logo-wrapper">
              <a>
                <img className="img-fluid" src={DarkLogo} alt="" />
              </a>
            </div>
            <div
              className={click ? "toggle-sidebar" : "toggle-sidebar"}
              onClick={handleClick}
            >
              {" "}
              <AlignRight />{" "}
            </div>
          </div>
          <div className="left-menu-header col">
            <ul>
              <li>
                <form className="form-inline search-form">
                  <div className="search-bg">
                    <i className="fa fa-search"></i>
                    <input
                      className="form-control-plaintext"
                      placeholder="Search here....."
                    />
                  </div>
                </form>
                <span className="d-sm-none mobile-search search-bg">
                  <i className="fa fa-search"></i>
                </span>
              </li>
            </ul>
          </div>
          <div className="nav-right col pull-right right-menu p-0">
            <ul className="nav-menus">
              <li>
                <a
                  type="button"
                  className="text-dark"
                  onClick={() => {
                    setFullScreen(!FullScreen);
                    MyFullScreen();
                  }}
                >
                  <Maximize />{" "}
                </a>
              </li>
              <li className="onhover-dropdown">
                <div className="notification-box">
                  <Bell /> <span className="dot-animated"></span>
                </div>
                <ul className="notification-dropdown onhover-show-div">
                  <li>
                    <p className="f-w-700 mb-0">
                      You have 3 Notifications
                      <span className="pull-right badge badge-primary badge-pill">
                        4
                      </span>
                    </p>
                  </li>
                  <li className="noti-primary">
                    <div className="media">
                      <span className="notification-bg bg-light-primary">
                        <Activity />
                      </span>
                      <div className="media-body">
                        <p>Lorem ipsum dolor</p>
                        <span>10 minutes ago</span>
                      </div>
                    </div>
                  </li>
                  <li className="noti-secondary">
                    <div className="media">
                      <span className="notification-bg bg-light-secondary">
                        <CheckCircle />
                      </span>
                      <div className="media-body">
                        <p>Lorem ipsum dolor</p>
                        <span>1 hour ago</span>
                      </div>
                    </div>
                  </li>
                  <li className="noti-success">
                    <div className="media">
                      <span className="notification-bg bg-light-success">
                        <FileText />{" "}
                      </span>
                      <div className="media-body">
                        <p>Lorem ipsum dolor</p>
                        <span>3 hour ago</span>
                      </div>
                    </div>
                  </li>
                  <li className="noti-danger">
                    <div className="media">
                      <span className="notification-bg bg-light-danger">
                        <UserCheck />{" "}
                      </span>
                      <div className="media-body">
                        <p>Lorem ipsum dolor</p>
                        <span>6 hour ago</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <a href="notification.html" className="d-flex">
                      <div className="flex-grow-1">
                        {" "}
                        <Link to="/Notification">
                          See all incoming activity
                        </Link>
                      </div>
                      <div className="p-0">
                        <i data-feather="arrow-right"></i>
                      </div>
                    </a>
                  </li>
                </ul>
              </li>
              <li
                onClick={() => {
                  setDark(!dark);
                  DarkMode();
                }}
              >
                <div className="mode">
                  <Moon type="button" />
                </div>
              </li>
              <li className="onhover-dropdown">
                <MessageSquare />
                <ul className="chat-dropdown onhover-show-div">
                  <li>
                    <div className="media">
                      <img
                        className="img-fluid rounded-circle me-3"
                        src={User1}
                        alt=""
                      />
                      <div className="media-body">
                        <span>Ain Chavez</span>
                        <p className="f-12 light-font">
                          Lorem Ipsum is simply dummy...
                        </p>
                      </div>
                      <p className="f-12">32 mins ago</p>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img
                        className="img-fluid rounded-circle me-3"
                        src={User2}
                        alt=""
                      />
                      <div className="media-body">
                        <span>Erica Hughes</span>
                        <p className="f-12 light-font">
                          Lorem Ipsum is simply dummy...
                        </p>
                      </div>
                      <p className="f-12">58 mins ago</p>
                    </div>
                  </li>
                  <li>
                    <div className="media">
                      <img
                        className="img-fluid rounded-circle me-3"
                        src={User4}
                        alt=""
                      />
                      <div className="media-body">
                        <span>Kori Thomas</span>
                        <p className="f-12 light-font">
                          Lorem Ipsum is simply dummy...
                        </p>
                      </div>
                      <p className="f-12">1 hr ago</p>
                    </div>
                  </li>
                  <li className="text-center">
                    {" "}
                    <a className="f-w-700" href="javascript:void(0)">
                      See All{" "}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <a className="text-dark" href="#">
                  {" "}
                  <Link to="/LoginIn">
                    {" "}
                    <Unlock />{" "}
                  </Link>
                </a>
              </li>

              <li className="onhover-dropdown p-0">
                <Link to="LoginTwo">
                  <button className="btn btn-primary-light" type="button">
                    <a href="login_two.html">
                      <LogOut /> Log out
                    </a>
                  </button>
                </Link>
              </li>
            </ul>
          </div>
          <div className="d-lg-none mobile-toggle pull-right w-auto">
            <i data-feather="more-horizontal"></i>
          </div>
        </div>
      </div>

      <div class="page-body-wrapper sidebar-icon">
        {/* <!-- Page Sidebar Start--> */}

        <header
          className={click ? "main-nav close_icon" : "main-nav"}
          onClick={closeMobileMenu}
        >
          <div className="sidebar-user text-center">
            <a className="setting-primary">
              {" "}
              <Link to="/MySetting">
                <Settings />{" "}
              </Link>
            </a>
            <img
              className="img-90 rounded-circle"
              src={dashboardAvatar}
              alt=""
            />

            <Link to="/UserProfile">
              <a>
                <h6 className="mt-3 f-14 f-w-600">Mary Alexander</h6>
              </a>
            </Link>
            <p className="mb-0 font-roboto">maryalexander@gmail.com</p>
            <ul>
              <li>
                <span>Male</span>
                <p>Gender</p>
              </li>
              <li>
                <span>CD-1122021</span>
                <p>Staff ID</p>
              </li>
            </ul>
          </div>

          <Sidebar />
        </header>
      </div>
    </div>
  );
}

export default Header;
