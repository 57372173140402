import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { PlusCircle } from "react-feather";


import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";

import SubjectCusDrop from "./subjectCusDrop";
function SubjectwiseList() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [students, setStudents] = useState([
    { id: 1, name: "Student 1", Subject:"Maths",  marks: "80" },
    { id: 2, name: "Student 2",Subject:"Maths", marks: "45" },
    { id: 3, name: "Student 3", Subject:"Maths",marks: "90" },
    { id: 4, name: "Student 4",Subject:"Maths", marks: "55" },
    { id: 5, name: "Student 5",Subject:"Maths", marks: "79" },
    { id: 6, name: "Student 6",Subject:"Maths", marks: "98" },
    { id: 7, name: "Student 7", Subject:"Maths",marks: "86" },
    { id: 8, name: "Student 8",Subject:"Maths", marks: "69" },
    { id: 9, name: "Student 9",Subject:"Maths", marks: "46" },
    { id: 10, name: "Student 10",Subject:"Maths", marks: "95" },
    { id: 11, name: "Student 11",Subject:"Maths", marks: "65" },
    { id: 12, name: "Student 12",Subject:"Maths", marks: "59" },
  ]);

  const handleMarksChange = (id, marks) => {
    // Update marks for the corresponding student
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === id ? { ...student, marks } : student
      )
    );
  };

  const [academicYear, setAcademicYear] = useState("");
  const [gradeDivision, setGradeDivision] = useState("");
  const [examTerm, setExamTerm] = useState("");
  const [subject, setSubject] = useState("");

  const [formValid, setFormValid] = useState(true);

  const handleButtonClick = () => {
    if (!academicYear || !gradeDivision || !examTerm || !subject) {
      // If any field is empty, set formValid to false
      setFormValid(false);
    } else {
      // All fields are filled, perform further action (e.g., navigate to mark list)
      console.log("Navigating to mark list...");
    }
  };

  const [selectedOption, setSelectedOption] = useState(null);

  // Options for the dropdown
  const options = [
    { value: "coffee", label: "Coffee" },
    { value: "tea", label: "Tea" },
    { value: "juice", label: "Juice" },
    // Add more options as needed
  ];

  // Handle change when an option is selected
  const handleSelectChange = (option) => {
    setSelectedOption(option);
  };
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
       

        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1"> View Subject wise List</h5>
                <Link to="/EditSubjectwiseList">
                  <a
                    type="button"
                    className="btn btn-edsys-primary "
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <PlusCircle className="me-2" />
                    <span style={{}}>Edit Subject wise List</span>
                  </a>
                </Link>
              </div>
            </div>
            <div className="offmarkcad col-md-12 row ">
              <div className="col-md-3">
                <SubjectCusDrop />
               
                <p className="p-10 "></p>
              </div>
              <div className="col-md-9">
                <div className="table">
                  <div className="thead">
                    <div className="tr tableheadstyle">
                      <div className="thsl">Sl. No</div>

                      <div className="thsn">Student Name</div>
                      <div className="thsn">Subject</div>

                      <div className="thm">Marks</div>
                      <div className="thm"> Total Marks</div>
                    </div>
                  </div>
                  <div className="tbody">
                    {students.map((student, index) => (
                      <div className="tr" key={student.id}>
                        <div className="tdsl">{index + 1}</div>

                        <div className="tdsn">
                          {" "}
                          <img
                            className="rounded-circle"
                            src={dashboardAvatar}
                            alt="image"
                            style={{
                              height: "25px",
                              width: "25x",
                              marginRight: "10px",
                            }}
                          />
                          {student.name}
                        </div>
                        <div className="tdm1">{student.Subject}</div>

                        <div className="tdm2">{student.marks}</div>
                        <div className="tdm2">100</div>
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",

                    flexDirection: "row",

                    justifyContent: "flex-end",
                    marginBottom: "20px",
                  }}
                >
                  <button
                    className="view-mark-list-button col-md-4"
                    // onClick={handleSubmit}
                  >
                    Publish
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubjectwiseList;
