
import React from 'react';

const AddEditTag = props => {
    return (
        <div className="modal fade modal-bookmark show testingll" style={{ "display": "block" }} id="createtag" tabindex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Create Tag</h5>
                        <button className="btn-close" type="button" onClick={props.onClose} data-bs-dismiss="modal" aria-label="Close">                                            </button>
                    </div>
                    <div className="modal-body">
                        <form className="form-bookmark needs-validation" novalidate="">
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label>Tag Name</label>
                                    <input className="form-control" type="text" required="" autocomplete="off" />
                                </div>
                                <div className="form-group col-md-12 mb-0">
                                    <label>Tag color</label>
                                    <input className="form-control fill-color" type="color" value="#24695c" />
                                </div>
                            </div>

                        </form>
                    </div>
                    <div className="modal-footer">
                            <button className="btn btn-secondary" type="button">Save</button>
                            <button className="btn btn-primary" type="button" onClick={props.onClose} data-bs-dismiss="modal">Cancel</button>
                        </div>
                </div>
            </div>
        </div>
    )
}
export default AddEditTag
