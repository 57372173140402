import React, { useState } from "react";
import "../../../src/assets/css/GradewiseList1.css";
// import "./GradewiseList.css";

const initialData = [
  {
    id: 1,
    name: "John Doe",
    physics: 85,
    chemistry: 90,
    math: 75,
    history: 95,
    biology: 80,
    geography: 85,
    english: 90,
    art: 85,
    music: 88,
  },
  {
    id: 2,
    name: "Jane Smith",
    physics: 90,
    chemistry: 85,
    math: 80,
    history: 92,
    biology: 85,
    geography: 90,
    english: 88,
    art: 90,
    music: 82,
  },
  {
    id: 3,
    name: "Bob Johnson",
    physics: 75,
    chemistry: 80,
    math: 85,
    history: 90,
    biology: 78,
    geography: 82,
    english: 85,
    art: 80,
    music: 85,
  },
  {
    id: 4,
    name: "Alice Brown",
    physics: 95,
    chemistry: 92,
    math: 88,
    history: 89,
    biology: 90,
    geography: 95,
    english: 92,
    art: 88,
    music: 90,
  },
  {
    id: 5,
    name: "Michael Johnson",
    physics: 80,
    chemistry: 85,
    math: 90,
    history: 85,
    biology: 92,
    geography: 78,
    english: 85,
    art: 82,
    music: 88,
  },
  {
    id: 6,
    name: "Emily Davis",
    physics: 88,
    chemistry: 90,
    math: 85,
    history: 92,
    biology: 85,
    geography: 90,
    english: 88,
    art: 90,
    music: 82,
  },
  {
    id: 7,
    name: "David Wilson",
    physics: 92,
    chemistry: 80,
    math: 75,
    history: 90,
    biology: 78,
    geography: 82,
    english: 85,
    art: 80,
    music: 85,
  },
];

const ResponsiveTable = ({ data, onDataChange, showAlert }) => {
  const subjects = [
    "Physics",
    "Chemistry",
    "Math",
    "History",
    "Biology",
    "Geography",
    "English",
    "Art",
    "Music",
  ];

  const handleInputChange = (id, subject, value) => {
    const newData = data.map((item) => {
      if (item.id === id) {
        const newValue = parseInt(value);
        if (isNaN(newValue) || newValue < 0 || newValue > 100) {
          showAlert("Marks should be between 0 and 100.");
          return item;
        }
        return { ...item, [subject]: newValue };
      }
      return item;
    });
    onDataChange(newData);
  };

  return (
    <div className="table-responsive">
      <table className="custom-table">
        <thead className="thead">
          <tr>
            <th>Sl. No</th>
            <th>Student Name</th>
            {subjects.map((subject) => (
              <th key={subject}>{subject} (100)</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index}>
              <td>{row.id}</td>
              <td>{row.name}</td>
              {subjects.map((subject) => (
                <td key={subject}>
                  <input
                    type="number"
                    value={row[subject.toLowerCase()]}
                    onChange={(e) =>
                      handleInputChange(
                        row.id,
                        subject.toLowerCase(),
                        e.target.value
                      )
                    }
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const AlertModal = ({ message, onClose }) => {
  return (
    <div className="modal" style={{ display: "block" }}>
      <div className="modal-content alert-modal">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <p>{message}</p>
        <div style={{ textAlign: "right" }}>
          <button onClick={onClose} className="btn btn-primary">
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const EditGradewiseList = () => {
  const [data, setData] = useState(initialData);
  const [alertMessage, setAlertMessage] = useState(null);

  const handleDataChange = (newData) => {
    setData(newData);
  };

  const showAlert = (message) => {
    setAlertMessage(message);
  };

  const closeAlert = () => {
    setAlertMessage(null);
  };

  return (
    <div className="card p-4">
      <h1>Edit Grade Wise List</h1>
      <ResponsiveTable
        data={data}
        onDataChange={handleDataChange}
        showAlert={showAlert}
      />
      {alertMessage && (
        <AlertModal message={alertMessage} onClose={closeAlert} />
      )}
      <div
        className="d-flex justify-content-flex-end"
        style={{ justifyContent: "flex-end", marginTop: "-20px" }}
      >
        <button className="view-mark-list-button">Submit</button>
      </div>
    </div>
  );
};
export default EditGradewiseList;
