import React, { useEffect } from 'react'
import userPic from "../../assets/images/dashboard/1.png"
import { Link } from 'react-router-dom'
function UserProfile() {
    useEffect(() => {
        document.title = "Edsys | User Profile "
    }, [])
    return (
        <div class="page-body">
            {/* <!-- Container-fluid starts--> */}
            <div class="row user-profile">
                <div class="col-sm-12">
                    <div class="my-profile-header">
                        <div class="row align-items-center">
                            <div class="col-auto profile-image">
                                <img src={userPic} />
                            </div>
                            <div class="col ml-md-n2 profile-user-info">
                                <h4 class="first-name mb-0">Mary</h4>
                                <h4 class="sur-name mb-0">Alexander</h4>
                                <div class="user-Location"><i class="fas fa-map-marker-alt"></i> Cochin, Kerala</div>
                                <div class="about-text">Lorem ipsum dolor sit amet.</div>
                            </div>
                        </div>
                    </div>
                    <div class="my-profile-menu d-flex">
                        <ul class="nav nav-pills flex-grow-1" id="pills-icontab" role="tablist">
                            <li class="nav-item"><a class="nav-link active nav-link3" id="pills-iconhome-tab" data-bs-toggle="pill" href="#pills-iconhome" role="tab" aria-controls="pills-iconhome" aria-selected="true"><i class="icofont icofont-user-male"></i>Personal Details</a></li>
                            <li class="nav-item"><a class="nav-link nav-link3" id="pills-iconcontact-tab" data-bs-toggle="pill" href="#pills-iconcontact" role="tab" aria-controls="pills-iconcontact" aria-selected="false"><i class="icofont icofont-settings"></i>Account Settings</a></li>
                        </ul>
                        <Link to="/EditProfile">
                            <a class="edit-profile">Edit Profile</a>
                        </Link>
                    </div>
                    <div class="tab-content" id="pills-icontabContent">
                        <div class="tab-pane fade active show" id="pills-iconhome" role="tabpanel" aria-labelledby="pills-iconhome-tab">
                            <div class="card mt-3">
                                <div class="card-header pb-0">
                                    <h5 class="m-b-0"><i class="icofont icofont-user-male mr-3"></i> Personal Details</h5>
                                </div>
                                <div class="card-body">
                                    <div class="table-responsive info-table">
                                        <table class="table text-nowrap">
                                            <tbody>
                                                <tr>
                                                    <td>Date Of Birth:</td>
                                                    <td class="font-medium text-dark-medium">17 - 08 - 1996</td>
                                                </tr>
                                                <tr>
                                                    <td>Gender:</td>
                                                    <td class="font-medium text-dark-medium">Female</td>
                                                </tr>
                                                <tr>
                                                    <td>E-Mail:</td>
                                                    <td class="font-medium text-dark-medium">maryalexander@gmail.com</td>
                                                </tr>
                                                <tr>
                                                    <td>Adress:</td>
                                                    <td class="font-medium text-dark-medium">House #10, Road #6, Cochin, Kerala</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone:</td>
                                                    <td class="font-medium text-dark-medium">+ 91 9856418123</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="tab-pane fade" id="pills-iconcontact" role="tabpanel" aria-labelledby="pills-iconcontact-tab">
                            <div class="card mt-2">
                                <div class="card-header pb-0">
                                    <h5 class="m-b-0"><i class="icofont icofont-settings"></i> Account Settings</h5>
                                </div>
                                <div class="mt-2 contact-form card-body">
                                    <div class="update-pw">Change Password</div>
                                    <form class="theme-form">
                                        <div class="form-icon"><i class="icofont icofont-ui-lock"></i></div>
                                        <div class="mb-3">
                                            <label for="exampleInputName1">Old Password</label>
                                            <input class="form-control" id="exampleInputName1" type="password" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="exampleInputName2">New Password</label>
                                            <input class="form-control" id="exampleInputName2" type="password" />
                                        </div>
                                        <div class="mb-3">
                                            <label for="exampleInputName3">Confirm Password</label>
                                            <input class="form-control" id="exampleInputName3" type="password" />
                                        </div>
                                        <div class="text-sm-end">
                                            <Link to="/EditProfile">
                                                <button class="btn btn-primary">Save Changes</button>
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <!-- Container-fluid Ends--> */}
        </div>
    )
}
export default UserProfile