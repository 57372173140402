import React from "react";
import { Link } from "react-router-dom";
import {
  Maximize,
  ShoppingCart,
  Bell,
  Grid,
  Activity,
  CheckCircle,
  FileText,
  UserCheck,
  MoreHorizontal,
  MessageSquare,
  Unlock,
  AlignRight,
  LogOut,
  ArrowLeft,
  Settings,
  Home,
  Airplay,
  Box,
  ArrowRight,
} from "react-feather";
import ReactDOM from "react-dom";
import styled from "styled-components";
import MultiMenus from "../components/MultiMenus";

function NavMenu() {
  // const Wrapper = styled.aside`
  // background: #ccc;
  // width: 300px;
  // `;

  // const menus = [
  // {
  //   label: "Menu 1"
  // },
  // {
  //   label: "Menu 2",
  //   submenu: [
  //     {
  //       label: "Sub Menu 1"
  //     },
  //     {
  //       label: "Sub Menu 2"
  //     }
  //   ]
  // },
  // {
  //   label: "Menu 3",
  //   submenu: [
  //     {
  //       label: "Sub Menu 1",
  //       submenu: [
  //         {
  //           label: "Boom 1"
  //         },
  //         {
  //           label: "Boom 2"
  //         }
  //       ]
  //     },
  //     {
  //       label: "Sub Menu 2",
  //       submenu: [
  //         {
  //           label: "Deep 1"
  //         },
  //         {
  //           label: "Deep 2",
  //           submenu: [
  //             {
  //               label: "Lorem 1"
  //             },
  //             {
  //               label: "Lorem 2",
  //               submenu: [
  //                 {
  //                   label: "Super Deep"
  //                 }
  //               ]
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       label: "Sub Menu 3"
  //     },
  //     {
  //       label: "Sub Menu 4",
  //       submenu: [
  //         {
  //           label: "Last 1"
  //         },
  //         {
  //           label: "Last 2"
  //         },
  //         {
  //           label: "Last 3"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: "RSS Menu 4"
  // }
  // ];

  return (
    <nav>
      {/* <Wrapper>
      <MultiMenus menus={menus} />
    </Wrapper> */}
      <div className="main-navbar">
        <div className="left-arrow" id="left-arrow">
          <ArrowLeft />
        </div>
        <div id="mainnav">
          <ul className="nav-menu custom-scrollbar">
            <li className="back-btn">
              <div className="mobile-back text-end">
                <span>Back</span>
                <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
              </div>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title link-nav">
                <Home />
                <span>Dashboard</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/MyAttendance" className="nav-link menu-title">
                <Airplay />
                <span>My Attendance</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/Wallet" className="nav-link menu-title">
                <Airplay />
                <span>Wallet</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/Demo" className="nav-link menu-title">
                <Airplay />
                <span>Demo</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/SubordinateLeave" className="nav-link menu-title">
                <Airplay />
                <span>SubordinateLeave</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/NoticeBoard" className="nav-link menu-title">
                <Airplay />
                <span>NoticeBoard</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title">
                <Airplay />
                <span>Announcement</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title">
                <Airplay />
                <span>Academics</span>
              </Link>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/">My Contents</Link>
                </li>
                <li>
                  <Link to="/">Assignments</Link>
                </li>
                <li>
                  <Link to="/">Assessments</Link>
                </li>
                <li>
                  <Link to="/">Channel List</Link>
                </li>
                <li>
                  <Link to="/">Calender</Link>
                </li>
                <li>
                  <Link to="/">Syllabus</Link>
                </li>
                <li>
                  <Link to="/">Syllabus Progress</Link>
                </li>
                <li>
                  <Link to="/">Video Conference</Link>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title link-nav">
                <Home />
                <span>Employee's Leaves</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title">
                <Box />
                <span>Students</span>
              </Link>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/">Student List</Link>
                </li>
                <li>
                  <Link to="/">Student Attendance</Link>
                </li>
                <li>
                  <Link to="/">Student Leaves</Link>
                </li>
              </ul>
            </li>
            <li className="dropdown">
              <Link
                to="/"
                className="nav-link menu-title link-nav"
                href="e-learning.html"
              >
                <Home />
                <span>E-Learning</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title link-nav">
                <Home />
                <span>Timetable</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title link-nav">
                <Home />
                <span>Reports</span>
              </Link>
            </li>
            <li className="dropdown">
              <Link to="/" className="nav-link menu-title link-nav">
                <Home />
                <span>To-Do</span>
              </Link>
            </li>
          </ul>
        </div>
        <div className="right-arrow" id="right-arrow">
          {" "}
          <ArrowRight />{" "}
        </div>
      </div>
    </nav>
  );
}

export default NavMenu;
