import React,{useEffect} from 'react'
import {Bell} from 'react-feather'

 function Notification() {
    useEffect(() => {
        document.title = "Edsys | Notifications "
      }, [])
    return (
        <div class="page-body">
          {/* <!-- Container-fluid starts--> */}
          <div class="row">
            <div class="col-md-12 xl-100">
                <div class="card br-15">
                    <div class="card-body">
                        <h5 class="mb-3"><span class="bell fa fa-bell"></span>Notification</h5>
                        <ul class="notification-sec">
                            <li class="red">
                                    <div class="d-flex">
                                        <div class="bellico">
                                            <Bell />
                                        </div>
                                        <div class="d-flex">
                                            <div class="message flex-grow-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel facilisis justo, id accumsan lectus.</div>
                                            <div class="notify-date">22nd March 2020, 09:30 AM</div>
                                        </div>
                                    </div>
                            </li>
                            <li class="yellow">
                                    <div class="d-flex">
                                        <div class="bellico">
                                            <Bell />
                                        </div>
                                        <div class="d-flex">
                                            <div class="message flex-grow-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel facilisis justo, id accumsan lectus.</div>
                                            <div class="notify-date">22nd March 2020, 09:30 AM</div>
                                        </div>
                                    </div>
                            </li>
                            <li class="blue">
                                    <div class="d-flex">
                                        <div class="bellico">
                                            <Bell />
                                        </div>
                                        <div class="d-flex">
                                            <div class="message flex-grow-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel facilisis justo, id accumsan lectus.</div>
                                            <div class="notify-date">22nd March 2020, 09:30 AM</div>
                                        </div>
                                    </div>
                            </li>
                            <li class="green">
                                <div class="d-flex">
                                        <div class="bellico">
                                            <Bell />
                                        </div>
                                        <div class="d-flex">
                                            <div class="message flex-grow-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam vel facilisis justo, id accumsan lectus.</div>
                                            <div class="notify-date">22nd March 2020, 09:30 AM</div>
                                        </div>
                                    </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
           </div>
          {/* <!-- Container-fluid Ends--> */}
        </div>
    )
}
export default  Notification