import React, { useEffect } from "react";
import reactDom from "react-dom";
import { Link } from "react-router-dom";
import Channel_Video from "../Channel_Video";

import { Play } from "react-feather";
import ChannelList_data from "../../../api/ChannelList_data.json";

function ChannelList() {
  useEffect(() => {
    document.title = "Edsys | Channel List ";
  }, []);

  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <h4>Channel List</h4>
      <div class="row channel-list">
        {ChannelList_data.map((postDetail, index) => {
          return (
            <div class="col-md-3">
              <div class="card br-15">
                <div class="card-body">
                  <div class="channel-video">
                    <img src={postDetail.Image_set} />
                    <Link to="/Channel_Video" className="nav-link me">
                      <Play class="play-button" />
                    </Link>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="channel-class">{postDetail.channel}</div>
                  <div class="video-title">{postDetail.title}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default ChannelList;
