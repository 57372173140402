import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { useNavigate } from "react-router-dom";
import announcement_icon from "../../assets/images/svg/announcement.svg";
import editcirclesh from "../../assets/images/svg/editcirclesh.svg";
import deletecirclesh from "../../assets/images/svg/deletecirclesh.svg";
import message_icon from "../../assets/images/svg/message_icon.svg";
import emergency_icon from "../../assets/images/svg/emergency_icon.svg";
import alert_icon from "../../assets/images/svg/alert_icon.svg";
import info_icon from "../../assets/images/svg/info_icon.svg";
import deletesh from "../../assets/images/svg/deletesh.svg";
import { PlusCircle } from "react-feather";

function NoticeBoard() {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);

  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row assignment-sec">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Notice Board</h5>
                <a
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  className="btn btn-edsys-primary"
                  onClick={() => setModalShow(true)}
                >
                  <PlusCircle className="me-2" />
                  <span>Create New Notice Board</span>
                </a>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4 cx-mb-20">
                  <div className="card sub-1 card-bg-1 stack-card">
                    <div class="card-header">
                      <div class="d-flex">
                        <img class="flex-grow-0" src={announcement_icon} />
                        <h6 class="flex-grow-1">Notice Board</h6>
                      </div>
                    </div>
                    <a class="card-body" href="#">
                      <p style={{ fontSize: "18px", fontWeight: "200" }}>
                        Content Notice Board
                      </p>
                    </a>
                    <div class="card-footer">
                      <div
                        class="d-sm-flex "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>28 Oct 2021, Thu</h5>
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <img
                            src={editcirclesh}
                            alt=""
                            onClick={() => setModalShow1(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                          />

                          <img
                            src={deletecirclesh}
                            alt=""
                            onClick={() => setModalShow2(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 cx-mb-20">
                  <div className="card sub-2 card-bg-2 stack-card">
                    <div class="card-header">
                      <div class="d-flex">
                        <img class="flex-grow-0" src={message_icon} />
                        <h6 class="flex-grow-1">Messages</h6>
                      </div>
                    </div>
                    <a class="card-body" href="#">
                      <p style={{ fontSize: "18px", fontWeight: "200" }}>
                        Content Notice Board
                      </p>
                    </a>
                    <div class="card-footer">
                      <div
                        class="d-sm-flex "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>28 Oct 2021, Thu</h5>
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <img
                            src={editcirclesh}
                            alt=""
                            onClick={() => setModalShow1(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                          />

                          <img
                            src={deletecirclesh}
                            alt=""
                            onClick={() => setModalShow2(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 cx-mb-20">
                  <div className="card sub-3 card-bg-3 stack-card">
                    <div class="card-header">
                      <div class="d-flex">
                        <img class="flex-grow-0" src={emergency_icon} />
                        <h6 class="flex-grow-1">Emergency</h6>
                      </div>
                    </div>
                    <a class="card-body" href="#">
                      <p style={{ fontSize: "18px", fontWeight: "200" }}>
                        Content Notice Board
                      </p>
                    </a>
                    <div class="card-footer">
                      <div
                        class="d-sm-flex "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>28 Oct 2021, Thu</h5>
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <img
                            src={editcirclesh}
                            alt=""
                            onClick={() => setModalShow1(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                          />

                          <img
                            src={deletecirclesh}
                            alt=""
                            onClick={() => setModalShow2(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 cx-mb-20">
                  <div className="card sub-4 card-bg-4 stack-card">
                    <div class="card-header">
                      <div class="d-flex">
                        <img class="flex-grow-0" src={alert_icon} />
                        <h6 class="flex-grow-1">Alerts</h6>
                      </div>
                    </div>
                    <a class="card-body" href="#">
                      <p style={{ fontSize: "18px", fontWeight: "200" }}>
                        Content Notice Board
                      </p>
                    </a>
                    <div class="card-footer">
                      <div
                        class="d-sm-flex "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>28 Oct 2021, Thu</h5>
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <img
                            src={editcirclesh}
                            alt=""
                            onClick={() => setModalShow1(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                          />

                          <img
                            src={deletecirclesh}
                            alt=""
                            onClick={() => setModalShow2(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 cx-mb-20">
                  <div className="card sub-5 card-bg-5 stack-card">
                    <div class="card-header">
                      <div class="d-flex">
                        <img class="flex-grow-0" src={info_icon} />
                        <h6 class="flex-grow-1">Information</h6>
                      </div>
                    </div>
                    <a class="card-body" href="#">
                      <p style={{ fontSize: "18px", fontWeight: "200" }}>
                        Content Notice Board
                      </p>
                    </a>
                    <div class="card-footer">
                      <div
                        class="d-sm-flex "
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>28 Oct 2021, Thu</h5>
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <img
                            src={editcirclesh}
                            alt=""
                            onClick={() => setModalShow1(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                            }}
                          />

                          <img
                            src={deletecirclesh}
                            alt=""
                            onClick={() => setModalShow2(true)}
                            style={{
                              height: "30px",
                              width: "30px",
                              marginLeft: "5px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Container-fluid Ends */}

      {/* Create New Announcement Modal Start */}
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Create Notice Board
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              // validated={validated}
              // onSubmit={handleSubmit}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Announcement Type <span>*</span>
                    </Form.Label>
                    <Form.Select required>
                      <option> Select Announcement Type</option>
                      <option> Announcement</option>
                      <option> Message</option>
                      <option> Emergency</option>
                      <option> Alerts</option>
                      <option> Information</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please choose a announcement type
                  </Form.Control.Feedback>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Announcement Content Type <span>*</span>
                    </Form.Label>
                    <Form.Select
                      required
                      // value={selected}
                      // onChange={handleChange}
                    >
                      <option defaultValue>
                        Select Announcement Content Type
                      </option>
                      <option value="Text"> Plain Text</option>
                      <option value="Image"> Image</option>
                      <option value="Audio"> Audio</option>
                      <option value="Pdf"> PDF</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please choose a content type
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom02">
                    <Form.Label>
                      Announcement Title <span>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      //   placeholder="Enter your text here..."
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Announcement content can not be blank
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Announcement Content <span>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      placeholder="Type Message here..."
                      style={{ height: "100px" }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Announcement content can not be blank
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="col-12 btnshpro"
            form="myForm"
            value="Update"
          >
            Publish
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalShow1}
        onHide={() => setModalShow1(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Edit Notice Board
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-form-wrapper">
            <Form
              noValidate
              // validated={validated}
              // onSubmit={handleSubmit}
              id="myForm"
            >
              <div className="row">
                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Announcement Type <span>*</span>
                    </Form.Label>
                    <Form.Select required>
                      <option> Select Announcement Type</option>
                      <option> Announcement</option>
                      <option> Message</option>
                      <option> Emergency</option>
                      <option> Alerts</option>
                      <option> Information</option>
                    </Form.Select>
                  </Form.Group>
                  <Form.Control.Feedback type="invalid">
                    Please choose a announcement type
                  </Form.Control.Feedback>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Announcement Content Type <span>*</span>
                    </Form.Label>
                    <Form.Select
                      required
                      // value={selected}
                      // onChange={handleChange}
                    >
                      <option defaultValue>
                        Select Announcement Content Type
                      </option>
                      <option value="Text"> Plain Text</option>
                      <option value="Image"> Image</option>
                      <option value="Audio"> Audio</option>
                      <option value="Pdf"> PDF</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      Please choose a content type
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Announcement Content <span>*</span>
                    </Form.Label>
                    <Form.Control required type="text" />
                    <Form.Control.Feedback type="invalid">
                      Announcement content can not be blank
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-md-12">
                  <Form.Group className="mb-3" controlId="validationCustom01">
                    <Form.Label>
                      Announcement Content <span>*</span>
                    </Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      placeholder="Type Message here..."
                      style={{ height: "100px" }}
                    />
                    <Form.Control.Feedback type="invalid">
                      Announcement content can not be blank
                    </Form.Control.Feedback>
                  </Form.Group>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="btn-wrapper border-top w-100">
            {/* <button onClick={() => setModalShow1(false)} className="cx-btn-1">
              Cancel
            </button> */}
            <button
              type="submit"
              className="col-12 btnshpro"
              form="myForm"
              value="Update"
            >
              Update
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      {/* Edit Announcement Modal End */}

      {/* Delete Announcement Modal Start */}
      <Modal
        show={modalShow2}
        onHide={() => setModalShow2(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div
          style={{
            display: "flex",
            margin: "30px",
          }}
          className="col-12"
        >
          <img src={deletesh} alt="" style={{ marginRight: "10px" }} />
          Are you sure you want to delete ?
        </div>
        <div
          className="btn-wrapper w-100"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <button
            onClick={() => setModalShow1(false)}
            className="col-5"
            style={{ height: "35px", borderRadius: "10px", margin: "10px" }}
          >
            Cancel
          </button>
          <button
            type="submit"
            // className="col-12 btnshpro"
            className="col-5"
            form="myForm"
            value="Update"
            style={{
              height: "35px",
              borderRadius: "10px",
              margin: "10px",
              backgroundColor: "#EF5934",
              color: "white",
            }}
          >
            Yes
          </button>
        </div>
      </Modal>
      {/* Delete Announcement Modal End */}
    </div>
  );
}

export default NoticeBoard;
