import React from "react";
// import React, { Suspense } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Routes,
  HashRouter,
} from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Dashboard from "../screens/Dashboard";
import MyAttendance from "../screens/myattendance/MyAttendance";
import Announcement from "../screens/announcement/Announcement";
import Assignment from "./academics/Assignment/Assignment";
import AssignmentDetails from "../screens/academics/Assignment/AssignmentDetails";
import Assessment from "./academics//Assesment/Assessment";
import AssessmentDetails from "./academics/Assesment/AssessmentDetails";
import ChannelList from "./academics/Channel/ChannelList";
import Channel_Video from "./academics/Channel/Channel_Video";
import Syllabus from "./academics/syllabus/Syllabus";
import No_Attends_List from "./academics/Assesment/No_Attends_List";
import RankList from "./academics/Assesment/RankList";
import ScoreDetails from "../screens/academics/Assesment/ScoreDetails";
import EmpLeaves from "./Leaves/EmpLeaves";
import ToDo from "./to_do/To_do";
import TimeTable from "./timetable/TimeTable";

import ApproveLeaves from "./Leaves/ApproveLeaves";
import StudentAttendance from "./student/StudentAttendance";
import StudentLeaves from "./student/StudentLeaves";
import StudentList from "./student/StudentList";
import E_Learning from "./E_Learning/E_Learning";
import E_LearningDetails from "./E_Learning/E_LearningDetails/E_LearningDetails";
import ImgVideoGallary from "./E_Learning/ImgVideoGallary";
import MyResourses from "./E_Learning/E_LearningDetails/MyResources.js";
import ViewReviewedAssignment from "./academics/Assignment/AssignmentDetail/ViewReviewedAssignment";
import ReviewAssignment from "./academics/Assignment/AssignmentDetail/ReviewAssignment";
import ViewAssignmnet from "./academics/Assignment/AssignmentDetail/ViewAssignmnet";
import Attendance from "./myattendance/attendance/Attendance";
import EditProfile from "./Profile/EditProfile";
import UserProfile from "./Profile/UserProfile";
import MySetting from "./Profile/MySetting";
import LoginIn from "./login/LoginIn";
import LoginTwo from "./login/LoginTwo";
import Notification from "./Profile/Notification";
import AddQuestion from "./academics/Assignment/AddQuestion";
import CreateQuestion from "./academics/Assignment/CreateQuestion";
import AssignmentDetails2 from "./academics/Assignment/AssignmentDetails2";
import MyDiary from "./MyDiary/MyDiary";
import Forum from "./Forum/Forum.js";
import Wallet from "../screens/Wallet/Wallets";
import NoticeBoard from "../screens/Notice/NoticeBoard.js";
import Library from "../screens/Notice/Library.js";
import SubordinateLeave from "../screens/SubordinateLeave/SubordinateLeave.js";
import Feed from "./Feed/Feed.js";

// communications
import Sms from "./Communications/Sms.js";
import SmsDetails from "./Communications/SmsDetails.js";
import Email from "./Communications/Email/Email.js";
import PushNotification from "./Communications/PushNotification/PushNotification.js";
import Chat from "./Communications/Chat/Chat.js";

import EmailDetails from "./Communications/Email/EmailDetails.js";
import PushNotificationDetails from "./Communications/PushNotification/PushNotificationDetails.js";
import NoteView from "./MyDiary/NoteView";
import ForumView from "./Forum/ForumView.js";
import FeedView from "./Feed/FeedView.js";
import TransactionHis from "./Wallet/TransactionHis";
import LeaveReq from "./SubordinateLeave/LeaveReq";
import OfflineAssessment from "../screens/OfflineAssesment/OfflineAssesment.js";
import OfflineMarksheet from "../screens/OfflineAssesment/OfflineMarksheet.js";
import ViewOfflineAssesment from "./OfflineAssesment/ViewOfflineAssesment.js";
import CreateOfflineAssessment from "./OfflineAssesment/CreateNewOfflineAssesment.js";
import CreateExam from "../screens/OfflineAssesment/CreateExam.js";
import EditOfflineAssessment from "../screens/OfflineAssesment/EditOfflineAssesment.js";
import OfflineAssesmentHome from "../screens/OfflineAssesment/OfflineAssesmentHome.js";
import GradewiseList from "../screens/OfflineAssesment/GradewiseList.js";
import EditGradewiseList from "../screens/OfflineAssesment/EditGradewiseList.js";
import OfflineAssesmentChoose from "../screens/OfflineAssesment/OfflineAssesmentChoose.js";
import SubjectwiseList from "../screens/OfflineAssesment/SubjectwiseList.js";
import EditSubjectwiseList from "../screens/OfflineAssesment/EditSubjectwiseList.js";

function Layout() {
  return (
    //  Page Body Start
    <div className="page-body-wrapper sidebar-icon">
      <BrowserRouter>
        <Header />
        <div className="page-body" id="page_container">
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/NoticeBoard" element={<NoticeBoard />} />
            <Route path="/Library" element={<Library />} />
            <Route path="/MyAttendance" element={<MyAttendance />} />
            <Route path="/Announcement" element={<Announcement />} />
            <Route path="/Assessment" element={<Assessment />} />
            <Route path="/AssessmentDetails" element={<AssessmentDetails />} />
            <Route path="/ChannelList" element={<ChannelList />} />
            <Route path="/Channel_Video" element={<Channel_Video />} />
            <Route path="/Syllabus" element={<Syllabus />} />
            <Route path="Assignment" element={<Assignment />} />
            <Route path="/AssignmentDetails" element={<AssignmentDetails />} />
            <Route path="/No_Attends_List" element={<No_Attends_List />} />
            <Route path="/RankList" element={<RankList />} />
            <Route path="/ScoreDetails" element={<ScoreDetails />} />
            <Route path="/EmpLeaves" element={<EmpLeaves />} />
            <Route path="/todo" element={<ToDo />} />
            <Route path="/timetable" element={<TimeTable />} />
            <Route path="/ScoreDetails" element={<ScoreDetails />} />
            <Route path="/EmpLeaves" element={<EmpLeaves />} />
            <Route path="/ApproveLeaves" element={<ApproveLeaves />} />
            <Route path="/StudentAttendance" element={<StudentAttendance />} />
            <Route path="/StudentLeaves" element={<StudentLeaves />} />
            <Route path="/StudentList" element={<StudentList />} />
            <Route path="/E_Learning" element={<E_Learning />} />
            <Route path="/E_LearningDetails" element={<E_LearningDetails />} />
            <Route path="/ImgVideoGallary" element={<ImgVideoGallary />} />
            <Route path="/MyResourses" element={<MyResourses />} />
            <Route
              path="/ViewReviewedAssignment"
              element={<ViewReviewedAssignment />}
            >
              <Route
                path=":isFromReview"
                element={<ViewReviewedAssignment />}
              />
            </Route>
            <Route path="/ReviewAssignment" element={<ReviewAssignment />} />
            <Route path="/ViewAssignmnet" element={<ViewAssignmnet />} />
            <Route path="/Attendance" element={<Attendance />} />
            <Route path="/UserProfile" element={<UserProfile />} />
            <Route path="/EditProfile" element={<EditProfile />} />
            <Route path="/MySetting" element={<MySetting />} />
            <Route path="/LoginTwo" element={<LoginTwo />} />
            <Route path="/LoginIn" element={<LoginIn />} />
            <Route path="/Notification" element={<Notification />} />
            <Route path="/AddQuestion" element={<AddQuestion />} />
            <Route path="/CreateQuestion" element={<CreateQuestion />} />
            <Route
              path="/AssignmentDetails2"
              element={<AssignmentDetails2 />}
            />
            <Route path="/MyDiary" element={<MyDiary />} />
            <Route path="/Forum" element={<Forum />} />
            <Route path="/Wallet" element={<Wallet />} />
            <Route path="/TransactionHis" element={<TransactionHis />} />
            <Route path="/LeaveReq" element={<LeaveReq />} />

            <Route path="/SubordinateLeave" element={<SubordinateLeave />} />
            <Route path="/Feed" element={<Feed />} />

            {/* Communications */}

            {/* SMS */}
            <Route path="/Sms" element={<Sms />} />
            <Route path="/SmsDetails" element={<SmsDetails />} />

            {/* Email */}
            <Route path="/Email" element={<Email />} />
            <Route path="/EmailDetails" element={<EmailDetails />} />

            {/* PushNotification */}
            <Route path="/PushNotification" element={<PushNotification />} />
            <Route
              path="/PushNotificationDetails"
              element={<PushNotificationDetails />}
            />

            {/* Chat */}

            <Route path="/Chat" element={<Chat />} />
            <Route path="/NoteView" element={<NoteView />} />
            <Route path="/ForumView" element={<ForumView />} />
            <Route path="/FeedView" element={<FeedView />} />

            {/* OfflineAssessment */}
            <Route path="/OfflineAssessment" element={<OfflineAssessment />} />
            <Route path="/OfflineMarksheet" element={<OfflineMarksheet />} />
            <Route
              path="/ViewOfflineAssesment"
              element={<ViewOfflineAssesment />}
            />
            <Route
              path="/CreateOfflineAssessment"
              element={<CreateOfflineAssessment />}
            />
            <Route
              path="/EditOfflineAssessment"
              element={<EditOfflineAssessment />}
            />
            <Route path="/CreateExam" element={<CreateExam />} />
            <Route
              path="/OfflineAssesmentHome"
              element={<OfflineAssesmentHome />}
            />
            <Route path="/GradewiseList" element={<GradewiseList />} />
            <Route path="/EditGradewiseList" element={<EditGradewiseList />} />
            <Route
              path="/OfflineAssesmentChoose"
              element={<OfflineAssesmentChoose />}
            />
            <Route path="/SubjectwiseList" element={<SubjectwiseList />} />
            <Route
              path="/EditSubjectwiseList"
              element={<EditSubjectwiseList />}
            />
          </Routes>
        </div>

        <Footer />
      </BrowserRouter>
    </div>
    //Page Body End here
  );
}

export default Layout;
