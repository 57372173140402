// import React from "react";
// // import "../../../assets/css/select2.css";
// import Form from "react-bootstrap/Form";
// import chat_avatar_3 from "../../../assets/images/Chat/chat_avatar_3.svg";
// import search_icon from "../../../assets/images/Chat/search_icon.svg";

// const ChatModel = (props) => {
//   const displayStyle = {
//     display: "block",
//   };
//   return (
//     <div
//       className="modal fade show testingll"
//       style={displayStyle}
//       id="exampleModal"
//       tabindex="-1"
//       // role="dialog"
//       aria-labelledby="exampleModalLabel"
//       aria-hidden="true"
//     >
//       <div className="modal-dialog modal-md">
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title" id="exampleModalLabel">
//               Contract List
//             </h5>
//             <button
//               className="btn-close"
//               onClick={props.onClose}
//               type="button"
//               data-bs-dismiss="modal"
//               aria-label="Close"
//             >
//               {" "}
//             </button>
//           </div>
//           <div className="modal-body">
//             <div className="d-flex justify-content-between">
//               <Form.Control placeholder="Search"></Form.Control>
//             </div>
//             <h5 className="mt-2 mt-2">Contract</h5>
//             <div className="row g-0">
//                               <div className="col-md-2 col-sm-1 col-1">
//                                 <div className="chat-item-img-box">
//                                   <img src={chat_avatar_3} alt="" />
//                                   <span className="status-yellow"></span>
//                                 </div>
//                               </div>
//                              <div className="col-md-10 col-sm-11 col-11">
//                                 <div className="row g-0">
//                                   <div className="col-md-10 col-sm-10 col-10">
//                                     <div className="chat-item-text-box">
//                                       <p className="ci-main-text">
//                                         John Collins
//                                       </p>
//                                       <p className="ci-sub-text">Subject</p>
//                                     </div>
//                                   </div>
                                  
//                                 </div>
//                               </div>
//                             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default ChatModel;

import React from "react";
// import "../../../assets/css/select2.css";

const SmsModel = (props) => {
  const displayStyle = {
    display: "block",
  };
  return (
    <div
      className="modal fade modal-bookmark show testingll"
      style={displayStyle}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-md" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title " >
              Contract
            </h5>
            <button
              className="btn-close"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {" "}
            </button>
          </div>
          <div className="modal-body datetime-picker modal-scroll">
            <form
              className="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div className="form-row">
              <div className="form-group col-md-12">
                  <label for="task-title">Grade</label>
                  <select className="js-example-basic-single form-select">
                    <option value="lesson1">select All</option>
                    <option value="lesson2">6th A</option>
                    <option value="lesson2">6th B</option>

                    <option value="lesson2">6th C</option>

                  </select>
                </div>

                <div className="form-group col-md-12">
                  <label for="task-title">Student Name</label>
                  <select className="js-example-basic-single form-select">
                    <option value="lesson1">select All</option>
                    <option value="lesson2">name</option>
                  </select>
                </div>
        
              </div>
            </form>
          </div>

         
        </div>
      </div>
    </div>
  );
};
export default SmsModel;

