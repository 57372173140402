import React from 'react'
import AddEditTag from './AddEdit_Tags';
import AddEditTodo from './AddEdit_To_do'
import { CheckCircle, PlusCircle } from "react-feather";
import TodoDetails from './To_do_Details'

export class TodoMenus extends React.Component {
    //#region constructor
    constructor(props) {
        super(props);
        this.state = {
            isTodoModelShow: false,
            isTagModelShow: false,
            detailModal: {
                title: "Created by me",
                isFor: "",
            }
        };
    }
    //#endregion

    //#region Life Cycle Methodes
    componentDidMount() { }
    //#endregion

    //#region custom Methods
    setInitialState = () => {
        this.setState({
            isTodoModelShow: false,
            isTagModelShow: false           
        })
    }
    openCloseTodoModel = () => {
        // this.setInitialState();
        this.setState({ isTodoModelShow: !this.state.isTodoModelShow }, () => {
        });
    };
    openCloseTagModel = () => {
        // this.setInitialState();
        this.setState({ isTagModelShow: !this.state.isTagModelShow })
    };
    onTabClick = (event) => {
        const name = event.currentTarget.dataset.value;
        const { detailModal } = this.state;
        switch (name.toLowerCase()) {
            case "created":
                detailModal.title = "Created by me"
                break;
            case "todaytask":
                detailModal.title = "Today's Tasks"
                break;
            case "delayed":
                detailModal.title = "Delayed Tasks"
                break;
            case "upcoming":
                detailModal.title = "Upcoming Tasks"
                break;
            case "weekly":
                detailModal.title = "This Week Tasks"
                break;
            case "monthly":
                detailModal.title = "This Month Tasks"
                break;
            case "assigned":
                detailModal.title = "Assigned to me"
                break;
            case "tasks":
                detailModal.title = "My tasks"
                break;
            case "notification":
                detailModal.title = "Notification"
                break;
            case "newsletter":
                detailModal.title = "Newsletter"
                break;
        }
        this.setState({detailModal:detailModal});
    };
    //#endregion

    //#region Render
    render() {
        //#region return
        return (
            <>
                <div className="col-xl-3 box-col-4 xl-30">
                    <div className="email-sidebar">
                        <a className="btn btn-primary email-aside-toggle" href="javascript:void(0)">bookmark filter</a>
                        <div className="email-left-aside">
                            <div className="card to-do-scroll">
                                <div className="card-body">
                                    <div className="email-app-sidebar left-bookmark">
                                        <h5 className="mb-3">To-Do</h5>
                                        <ul className="nav main-menu" role="tablist">
                                            <li className="nav-item">
                                                <button className="btn btn-primary btn-block" type="button" style={{width:"100%"}} data-bs-toggle="modal" onClick={this.openCloseTodoModel} data-bs-target="#dvAddEditTodo"><CheckCircle className="me-2" /> New Task</button>
                                            </li>
                                            <li className="nav-item"><span className="main-title mt-3"> Views</span></li>
                                            <li><a id="pills-created-tab" onClick={(event) => this.onTabClick(event)} data-bs-toggle="pill" role="tab" data-value="created" aria-controls="pills-created" aria-selected="true"><span className="title"> Created by me</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-todaytask-tab" data-bs-toggle="pill" data-value="todaytask" role="tab" aria-controls="pills-todaytask" aria-selected="false"><span className="title"> Today's Tasks</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-delayed-tab" data-bs-toggle="pill" data-value="delayed" role="tab" aria-controls="pills-delayed" aria-selected="false"><span className="title"> Delayed Tasks</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-upcoming-tab" data-bs-toggle="pill" data-value="upcoming" role="tab" aria-controls="pills-upcoming" aria-selected="false"><span className="title">Upcoming Tasks</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-weekly-tab" data-bs-toggle="pill" data-value="weekly" role="tab" aria-controls="pills-weekly" aria-selected="false"><span className="title">This week tasks</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-monthly-tab" data-bs-toggle="pill" data-value="monthly" role="tab" aria-controls="pills-monthly" aria-selected="false"><span className="title">This month tasks</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-assigned-tab" data-bs-toggle="pill" data-value="assigned" role="tab" aria-controls="pills-assigned" aria-selected="false"><span className="title">Assigned to me</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-tasks-tab" data-bs-toggle="pill" data-value="tasks" role="tab" aria-controls="pills-tasks" aria-selected="false"><span className="title">My tasks</span></a></li>
                                            <li>
                                                <hr />
                                            </li>
                                            <li><span className="main-title"> Tags<span className="pull-right"><a data-bs-toggle="modal" data-bs-target="#createtag" onClick={this.openCloseTagModel}><PlusCircle /></a></span></span></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-notification-tab" data-bs-toggle="pill" data-value="notification" role="tab" aria-controls="pills-notification" aria-selected="false"><span className="title"> Notification</span></a></li>
                                            <li><a className="show" onClick={(event) => this.onTabClick(event)} id="pills-newsletter-tab" data-bs-toggle="pill" data-value="newsletter" role="tab" aria-controls="pills-newsletter" aria-selected="false"><span className="title"> Newsletter</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.isTodoModelShow ? <AddEditTodo onClose={this.openCloseTodoModel} /> : null}
                        {this.state.isTagModelShow ? <AddEditTag onClose={this.openCloseTagModel} /> : null}
                    </div>
                </div >
                <div className="col-xl-9 col-md-12 box-col-8 xl-70">
                    <TodoDetails data={this.state.detailModal} />
                </div>
            </>
        );
    }
}
export default TodoMenus;