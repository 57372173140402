import React from 'react'
import { useParams } from "react-router-dom";
import {
    Clock
} from "react-feather"
import b4 from '../../../../assets/images/user/b4.png'
import ViewAssignmnet from './ViewAssignmnet';
class ViewReviewedAssignment extends React.Component {

    //#region constructor
    constructor(props) {
        super(props);
        debugger
        this.state = {
            // isTodoModelShow: false,
            isTabShow: true,
            isFromReview: window.location.href.split('/')[window.location.href.split('/').length - 1]


            //this.props.isFromReview?this.props.isFromReview:true
        };
    }
    //#endregion

    //#region Life Cycle Methodes
    componentDidMount() {
        document.title= "Edsys | Assignmnets Review Details"
        
     }
    //#endregion




    //#region custom Methods
    setInitialState = () => {
        this.setState({
            // isTodoModelShow: false,
            isTabShow: false
        })
    }
    // openCloseTodoModel = () => {
    //     // this.setInitialState();
    //     this.setState({ isTodoModelShow: !this.state.isTodoModelShow }, () => {
    //     });


    // };
    openCloseTab = () => {
        this.setInitialState(1);
       this.setState({ isTabShow:true})
    };
    //#endregion

    //#region Render
    render() {
        return (
            <div>
                <div className="page-body" >
                    {/* <!-- Container-fluid starts--> */}
                    <div className="row view-review view-assignment">
                        <div className="col-12">
                            <div className="card br-15">
                                <div className="card-body header-card">
                                    <div className="student-header">
                                        <div className="details-banner">
                                            <h3>Assignment Title 01</h3>
                                            <h5><Clock /> 06:52 pm</h5>
                                            <div className="sub-date">28 Oct 2021, Thu</div>
                                        </div>
                                    </div>
                                    <div className="student-image">
                                        <div className="student-avatar">
                                            <img src={b4} />
                                            <div className="class">6th D</div>
                                        </div>
                                        <div className="student-name">Maria George</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <ul className="nav nav-pills" id="pills-tab" role="tablist">

                                {this.state.isFromReview ? <>
                                    <li className="nav-item"><a className="nav-link active " id="pills-home-tab" data-bs-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Teacher Review<div className="media"></div></a></li>
                                    <li className="nav-item"><a className="nav-link " onClick={this.openCloseTab} id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Check Answers</a></li>
                                </> :
    
                                   <li className="nav-item"><a className="nav-link active"  disabled="disabled" onClick={this.openCloseTab} id="pills-profile-tab" data-bs-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="true" disabled><div className="media"></div>Check Answers</a></li>
                                }

                            </ul>
                        </div>
                        <div className="col-12">
                            <div className="card br-15">
                                <div className="card-body">
                                    <div className="tab-content" id="pills-tabContent">
                                        <div className={"tab-pane fade " + (this.state.isFromReview ? "show active" : "")} id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                            <h4 className="mt-1 mb-3">Teacher Review</h4>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="card mh-145 br-15">
                                                        <div className="card-body">
                                                            <div className="t-review">
                                                                <div className="percent">58%</div>
                                                                <div className="review-title">Completeness</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="card mh-145 br-15">
                                                        <div className="card-body">
                                                            <div className="t-review">
                                                                <div className="percent">48%</div>
                                                                <div className="review-title">Creativity</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="card mh-145 br-15">
                                                        <div className="card-body">
                                                            <div className="t-review">
                                                                <div className="neatness">8<span>/10</span></div>
                                                                <div className="review-title">Neatness</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="card mh-145 br-15">
                                                        <div className="card-body">
                                                            <div className="t-review">
                                                                <ul className="student-rating-star">
                                                                    <li className="checked">
                                                                        <i className="fa fa-star"></i>
                                                                    </li>
                                                                    <li className="checked">
                                                                        <i className="fa fa-star"></i>
                                                                    </li>
                                                                    <li className="checked">
                                                                        <i className="fa fa-star"></i>
                                                                    </li>
                                                                    <li className="checked">
                                                                        <i className="fa fa-star"></i>
                                                                    </li>
                                                                    <li>
                                                                        <i className="fa fa-star"></i>
                                                                    </li>
                                                                </ul>
                                                                <div className="review-title">Overall Rating</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <h6>Comments</h6>
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse pariatur.</p>
                                            <div className="d-flex feedback-sec">
                                                <div className="fb-sec flex-grow-1">Feedback</div>
                                                <div className="fb-status">Good</div>
                                            </div>
                                        </div>
                                        <div className={"tab-pane fade " + (!this.state.isFromReview ? "show active"  : "")} id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                            <div className="file-content">
                                                {this.state.isTabShow ? <ViewAssignmnet onClose={this.openCloseTab} /> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Container-fluid Ends--> */}
                </div>
            </div>
        )
    }
}

export default ViewReviewedAssignment
