import React, { useState, useEffect } from "react";
import AssignmentCardData from "../../api/assignment_card_data.json";

import { Link } from "react-router-dom";

import { Edit3, Trash2, PlusCircle } from "react-feather";
import { Modal, Button } from "react-bootstrap";

import Academic from "../../assets/images/Academic.png";
import "../Wallet/tabstyles.css";

import "../Wallet/tabstyles.css";
const OfflineAssesmentHome = () => {
  const [isTodoModelShow, setTodoModelShow] = useState(false);
  const [isTagModelShow, setTagModelShow] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.title = "Edsysy | Assignment";
  }, []);

  const setInitialState = () => {
    setTodoModelShow(false);
    setTagModelShow(false);
  };

  const openCloseTodoModel = () => {
    setInitialState();
    setTodoModelShow(!isTodoModelShow);
  };

  const openCloseTagModel = () => {
    setInitialState();
    setTagModelShow(!isTagModelShow);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tabs = ["Pending", "Upcoming", "History"];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  return (
    <>
      <div className="library announcementnew">
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="col-md -6">Offline Assesment</h5>
                  <Link to="/CreateOfflineAssessment">
                    <a type="button" className="btn btn-edsys-primary btn1">
                      <PlusCircle className="me-2" />
                      <span style={{ marginTop: "20px" }}>
                        Create New Offline Assessment
                      </span>
                    </a>
                  </Link>
                </div>
              </div>

              <div className="row pl pr">
                <div className="col-md-12">
                  <div className="books-main-wrapper">
                    <div>
                      <div className="tabs">
                        {/* {tabs.map((tab, index) => (
                          <div
                            key={index}
                            className={`tab ${
                              tab === activeTab ? "active" : ""
                            }`}
                            onClick={() => handleClick(tab)}
                          >
                            {tab}
                          </div>
                        ))} */}
                      </div>
                      <div className="my-books">
                        <div className="row">
                          {AssignmentCardData.map((postDetail, index) => (
                            <div className="col-md-4" key={index}>
                              <Link
                                to="/OfflineAssesmentChoose"
                                style={{ color: "black" }}
                              >
                                <div className={postDetail.card_bgcolor}>
                                  <div className="card-header">
                                    <div className="d-flex">
                                      <img
                                        className="flex-grow-0"
                                        src={Academic}
                                        alt=""
                                      />
                                      <h6 className="flex-grow-1">2022-23</h6>
                                      <div className="class">1st Term</div>
                                    </div>
                                  </div>
                                  <h5 className="card-body title_text TermHeadd">
                                    Offline Assesment Title
                                  </h5>
                                  <div className="d-flex justify-content-between">
                                    <div className="card-footer">
                                      <div className="d-sm-flex">
                                        <h5>12-02-2024</h5>
                                      </div>
                                    </div>
                                    <div className="card-footer">
                                      <div className="d-sm-flex">
                                        <h5>13-02-2024</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))}

                          {/* {AssignmentCardData.map((postDetail, index) => (
                            <div className="col-md-4" key={index}>
                              <Link
                                to="/OfflineAssesmentChoose"
                                style={{ color: "black" }}
                              >
                                <div className={postDetail.card_bgcolor}>
                                  <div className="card-header">
                                    <div className="d-flex">
                                      <img
                                        className="flex-grow-0"
                                        src={subjecticon}
                                        alt=""
                                      />
                                      <div className="flex-grow-1 ">
                                        <h6 className="TermHead">
                                        
                                          Accademic Year
                                        </h6>
                                        <h6
                                     
                                        >
                                          2022-23
                                        </h6>
                                      </div>
                                      <div className="flex-grow-1 ">
                                        <h6 className="TermHead">
                                   
                                          Term
                                        </h6>
                                        <h6
                                      
                                        >
                                          First Term
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                  <h5
                                    
                                    className="card-body TermHead"
                                  >
                                    Offline Assesment Title
                                  </h5>
                                  <div className="card-footer">
                                    <div className="d-flex">
                                      <div className="flex-grow-1 ">
                                        <div
                                          lassName="d-flex"
                                          style={{
                                            flexDirection: "row",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                    
                                          <h6
                                            className="TermHead"
                                            style={{ marginLeft: "20px" }}
                                          >
                                         
                                            StartDate
                                          </h6>
                                        </div>
                                        <h6
                                          style={{ marginLeft: "20px" }}
                                       
                                        >
                                          12-02-2024
                                        </h6>
                                      </div>
                                      <div className="flex-grow-1 ">
                                        <h6 className="TermHead">
                                      
                                          EndtDate
                                        </h6>
                                        <h6
                                    
                                        >
                                          12-02-2024
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          ))} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter border-bottom">
                        Delete
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="delete-text-wrapper">
                        Are you sure you want to delete ?
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="delete-btn-wrapper border-top w-100">
                        <button onClick={handleClose} className="dlt-btn-1">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="dlt-btn-2"
                          value="delete"
                        >
                          Delete
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* <Modal
        // show={modalShow2}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
              Cancel
            </button>
            <button type="submit" className="dlt-btn-2" value="delete">
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfflineAssesmentHome;
