import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "../assets/css/bootstrap.css";

// import Dropdown from 'react-bootstrap/Dropdown';

import {
  ArrowLeft,
  Settings,
  Home,
  Airplay,
  Box,
  ArrowRight,
  AlignRight,
} from "react-feather";
import dashboardAvatar from "../assets/images/dashboard/1.png";
import dashboard_icon from "../assets/images/sidebar-menu/dashboard.svg";
import attendance_icon from "../assets/images/sidebar-menu/attendance.svg";
import announcement_icon from "../assets/images/sidebar-menu/announcement.svg";
import academic_icon from "../assets/images/sidebar-menu/academic.svg";
import e_learning_icon from "../assets/images/sidebar-menu/e-learning.svg";
import leave_icon from "../assets/images/sidebar-menu/leave.svg";
import reports_icon from "../assets/images/sidebar-menu/reports.svg";
import student_icon from "../assets/images/sidebar-menu/student.svg";
import timetable_icon from "../assets/images/sidebar-menu/timetable.svg";
import to_do_icon from "../assets/images/sidebar-menu/to-do.svg";
import Communication from "../assets/images/sidebar-menu/Communication.svg";
import { FaWallet, FaBook, FaForumbee } from "react-icons/fa";
import { MdOutlineFeed } from "react-icons/md";
import { FcLeave } from "react-icons/fc";
import { IoLibraryOutline } from "react-icons/io5";
import "./Sidebaricon.css";
function Sidebar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [open, setOpen] = useState(false);

  // const Wrapper = styled.aside`
  // background: #ccc;
  // width: 300px;
  // `;

  // const menus = [
  // {
  //   label: "Menu 1"
  // },
  // {
  //   label: "Menu 2",
  //   submenu: [
  //     {
  //       label: "Sub Menu 1"
  //     },
  //     {
  //       label: "Sub Menu 2"
  //     }
  //   ]
  // },
  // {
  //   label: "Menu 3",
  //   submenu: [
  //     {
  //       label: "Sub Menu 1",
  //       submenu: [
  //         {
  //           label: "Boom 1"
  //         },
  //         {
  //           label: "Boom 2"
  //         }
  //       ]
  //     },
  //     {
  //       label: "Sub Menu 2",
  //       submenu: [
  //         {
  //           label: "Deep 1"
  //         },
  //         {
  //           label: "Deep 2",
  //           submenu: [
  //             {
  //               label: "Lorem 1"
  //             },
  //             {
  //               label: "Lorem 2",
  //               submenu: [
  //                 {
  //                   label: "Super Deep"
  //                 }
  //               ]
  //             }
  //           ]
  //         }
  //       ]
  //     },
  //     {
  //       label: "Sub Menu 3"
  //     },
  //     {
  //       label: "Sub Menu 4",
  //       submenu: [
  //         {
  //           label: "Last 1"
  //         },
  //         {
  //           label: "Last 2"
  //         },
  //         {
  //           label: "Last 3"
  //         }
  //       ]
  //     }
  //   ]
  // },
  // {
  //   label: "RSS Menu 4"
  // }
  // ];
  const scriptAlreadyExists = () =>
    document.querySelector("script#fb-sdk") !== null;
  const appendSdkScript = () => {
    const script = document.createElement("script");
    script.id = "fb-sdk";
    script.src = "../assets/js/sidebar-menu.js";
    script.async = true;
    script.defer = true;
    script.crossOrigin = "anonymous";
    document.body.append(script);
  };
  useEffect(async () => {
    // debugger
    if (!scriptAlreadyExists()) {
      appendSdkScript();
    }
  }, []);

  const [dark, setDark] = useState(false);

  const toggleDarkMode = () => {
    setDark(!dark);
  };

  return (
    //Page Sidebar Start
    <nav>
      {/* <Wrapper>
<MultiMenus menus={menus} />
</Wrapper> */}
      <div className={`main-navbar ${click ? "close_icon" : ""}`}>
        <div className="left-arrow" id="left-arrow">
          <ArrowLeft />
        </div>
        <div id="mainnav">
          <ul className="nav-menu custom-scrollbar">
            <li className="back-btn">
              <div className="mobile-back text-end">
                <span>Back</span>
                <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
              </div>
              <div className="mobile-back text-end">
                <span>Back</span>
                <i className="fa fa-angle-right ps-2" aria-hidden="true"></i>
              </div>
            </li>
            <li className="dropdown" type="button">
              <NavLink to="/" className="nav-link menu-title link-nav">
                <img src={dashboard_icon} alt="" /> <span>Dashboard</span>
              </NavLink>
            </li>
            <li className="dropdown" type="button">
              <NavLink
                to="/MyAttendance"
                className="nav-link menu-title link-nav"
              >
                <img src={attendance_icon} alt="" /> <span>My Attendance</span>
              </NavLink>
            </li>
            <li className="dropdown" type="button">
              <NavLink to="/Wallet" className="nav-link menu-title link-nav">
                <FaWallet className="wallet-icon" />
                <span className="spanstyle"> Wallet</span>
              </NavLink>
            </li>

            {/* <li className="dropdown" type="button">
              <NavLink
                to="/SubordinateLeave"
                className="nav-link menu-title link-nav"
              >
                <FcLeave className="SubLeave" />
                <span className="spanstyle">SubordinateLeave</span>
              </NavLink>
            </li> */}
            <li className="dropdown" type="button">
              <NavLink
                to="/NoticeBoard"
                className="nav-link menu-title link-nav"
              >
                <img src={announcement_icon} alt="" />
                <span>NoticeBoard</span>
              </NavLink>
            </li>
            {/* <li className="dropdown" type="button">
              <NavLink to="/Announcement" className="nav-link menu-title">
                <img src={announcement_icon} alt="" />
                <span>Announcement</span>
              </NavLink>
            </li> */}

            <li className="dropdown" type="button">
              <a class="nav-link menu-title">
                <img src={academic_icon} />
                <span>Academics</span>
              </a>
              <ul class="nav-submenu menu-content">
                <li>
                  <Link to="/Assignment">Assignments</Link>
                </li>
                <li>
                  <Link to="/Assessment">Assessments</Link>
                </li>
                <li>
                  <Link to="/OfflineAssesmentHome">OfflineAssessment</Link>
                </li>
                <li>
                  <Link to="/E_Learning">E_Learning</Link>
                </li>
                <li>
                  <Link to="/ChannelList">Channel List</Link>
                </li>

                <li>
                  <Link to="/Syllabus">Syllabus</Link>
                </li>
                {/* <li>
                  <Link to="/">Syllabus Progress</Link>
                </li>
                <li>
                  <Link to="/">Video Conference</Link>
                </li> */}
              </ul>
            </li>

            <li className="dropdown" type="button">
              <a className="nav-link menu-title">
                <img src={leave_icon} alt="" />
                <span>Leaves</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/EmpLeaves">Apply Leaves</Link>
                </li>
                <li>
                  <Link to="/ApproveLeaves">Approve Leaves</Link>
                </li>
                <li>
                  <Link to="/StudentLeaves">Student Leaves</Link>
                </li>
                <li>
                  <Link to="/SubordinateLeave">SubordinateLeave</Link>
                </li>
              </ul>
            </li>
            {/* <li className="dropdown" type="button">
              <a className="nav-link menu-title">
                <img src={student_icon} alt="" />
                <span>Students</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/">Student List</Link>
                </li>
                <li>
                  <Link to="/">Student Attendance</Link>
                </li>
                <li>
                  <Link to="/">Student List</Link>
                </li>
                <li>
                  <Link to="/">Student Attendance</Link>
                </li>
               
              </ul>
            </li> */}
            <li className="dropdown" type="button">
              <a className="nav-link menu-title">
                <img src={Communication} alt="" />
                <span>Communications</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/Email">Email</Link>
                </li>
                <li>
                  <Link state={{ dark }} to="/Sms">
                    SMS
                  </Link>
                </li>
                <li>
                  <Link to="/PushNotification">Push Notification</Link>
                </li>
                <li>
                  <Link to="/Chat">Chat</Link>
                </li>
              </ul>
            </li>

            <li className="dropdown" type="button">
              <NavLink to="/Library" className="nav-link menu-title link-nav">
                <IoLibraryOutline className="SubLeave" />
                <span className="spanstyle">Library</span>
              </NavLink>
            </li>

            {/* <li className="dropdown" type="button">
              <Link
                to="/E_Learning"
                className="nav-link menu-title link-nav"
                href="e-learning.html"
              >
                <img src={e_learning_icon} alt="" />
                <span>E-Learning</span>
              </Link>
              <Link
                to="/E_Learning"
                className="nav-link menu-title link-nav"
                href="e-learning.html"
              >
                <img src={e_learning_icon} alt="" />
                <span>E-Learning</span>
              </Link>
            </li> */}
            <li className="dropdown" type="button">
              <Link to="/timetable" className="nav-link menu-title link-nav">
                <img src={timetable_icon} alt="" />
                <span>Timetable</span>
              </Link>
            </li>
            {/* <li className="dropdown" type="button">
              <Link to="/" className="nav-link menu-title link-nav">
                <img src={reports_icon} alt="" />
                <span>Reports</span>
              </Link>
            </li> */}
            <li className="dropdown" type="button">
              <Link to="/todo" className="nav-link menu-title link-nav">
                <img src={to_do_icon} alt="" />
                <span>To-Do</span>
              </Link>
            </li>
            <li className="dropdown" type="button">
              <Link to="/MyDiary" className="nav-link menu-title link-nav">
                <FaBook className="wallet-icon" />
                <span className="spanStyle"> My Diary</span>
              </Link>
            </li>
            <li className="dropdown" type="button">
              {/* <Link to="/" className="nav-link menu-title link-nav">
                <img src={reports_icon} alt="" />
                <span>Reports</span>
              </Link> */}
              <Link to="/Forum" className="nav-link menu-title link-nav">
                <FaForumbee className="wallet-icon" />
                <span className="spanStyle">Forum</span>
              </Link>
            </li>
            <li className="dropdown" type="button">
              <Link to="/Feed" className="nav-link menu-title link-nav">
                <MdOutlineFeed className="wallet-icon" />
                <span className="spanStyle" s>
                  Feed
                </span>
              </Link>
            </li>
            {/* <li className="dropdown" type="button">
              <Link
                to="/OfflineAssesmentHome"
                className="nav-link menu-title link-nav"
              >
                <MdOutlineFeed className="wallet-icon" />
                <span className="spanStyle" s>
                  Offline Assesment
                </span>
              </Link>
            </li> */}

            {/* <li className="dropdown" type="button">
              <a className="nav-link menu-title">
                <img src={Communication} alt="" />
                <span>Offline Assesment</span>
              </a>
              <ul className="nav-submenu menu-content">
                <li>
                  <Link to="/OfflineAssessment">Offline Assessment</Link>
                </li>
            
              </ul>
            </li> */}
          </ul>
        </div>
        <div className="right-arrow" id="right-arrow">
          {" "}
          <ArrowRight />{" "}
        </div>
      </div>
    </nav>
    //Page Sidebar Ends
  );
}

export default Sidebar;
