import React, { useState } from "react";
import "./Dropstyle.css";

function CombinedInput() {
  const [academicYear, setAcademicYear] = useState("");
  const [gradeDivision, setGradeDivision] = useState("");
  const [examTerm, setExamTerm] = useState("");
  const [examSubject, setExamSubject] = useState("");

  const [academicYearDropdownOpen, setAcademicYearDropdownOpen] =
    useState(false);
  const [gradeDivisionDropdownOpen, setGradeDivisionDropdownOpen] =
    useState(false);
  const [examTermDropdownOpen, setExamTermDropdownOpen] = useState(false);
  const [examSubjectDropdownOpen, setExamSubjectDropdownOpen] = useState(false);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  // Options for Academic Year field
  const academicYearOptions = ["2024-2025", "2023-2024", "2022-2023"];

  // Options for Grade Division field
  const gradeDivisionOptions = ["Grade 1", "Grade 2", "Grade 3"];

  // Options for Exam/Term field
  const examTermOptions = ["Midterm", "Final Exam", "Quarterly Exam"];
  const examSubjectOptions = ["Physics", "Hindi", "Mathematics"];

  const handleInputChange = (event, setter, setDropdownOpen) => {
    setter(event.target.value);
    setDropdownOpen(true); // Always show dropdown when typing
  };

  const handleSelectOption = (option, setter, setDropdownOpen) => {
    setter(option);
    setDropdownOpen(false);
  };

  const handleSubmit = () => {
    // Validate each input field
    if (!academicYear || !gradeDivision || !examTerm || !examSubject) {
      //   alert("Please fill in all fields");
      setIsFormSubmitted(true);
      return;
    }

    // All fields are filled, perform further action (e.g., submit form)
    console.log("Form submitted successfully!");
  };

  const isAcademicYearInvalid = academicYear === "" && isFormSubmitted;
  const isGradeDivisionInvalid = gradeDivision === "" && isFormSubmitted;
  const isExamTermInvalid = examTerm === "" && isFormSubmitted;
  const isExamSubjectInvalid = examSubject === "" && isFormSubmitted;

  return (
    <div className="container">
      <div className="col">
        <p className="DropTxt">Select Academic year</p>
        <input
          type="text"
          value={academicYear}
          onChange={(e) =>
            handleInputChange(e, setAcademicYear, setAcademicYearDropdownOpen)
          }
          onFocus={() => setAcademicYearDropdownOpen(true)}
          onBlur={() => setAcademicYearDropdownOpen(false)}
          placeholder="Select Academic Year"
          className={`combined-input ${isAcademicYearInvalid ? "invalid" : ""}`}
        />

        {academicYearDropdownOpen && (
          <div className="cusdropdown">
            {academicYearOptions.map((option, index) => (
              <div
                key={index}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent input from losing focus
                  handleSelectOption(
                    option,
                    setAcademicYear,
                    setAcademicYearDropdownOpen
                  );
                }}
                className="dropdown-item"
              >
                {option}
              </div>
            ))}
          </div>
        )}
        {isAcademicYearInvalid && (
          <p className="error-text">Please Fill The Field</p>
        )}
      </div>

      {/* <div className="col">
        <p className="DropTxt">Select Grade Division</p>
        <input
          type="text"
          value={gradeDivision}
          onChange={(e) =>
            handleInputChange(e, setGradeDivision, setGradeDivisionDropdownOpen)
          }
          onFocus={() => setGradeDivisionDropdownOpen(true)}
          onBlur={() => setGradeDivisionDropdownOpen(false)}
          placeholder="Select Grade Division"
          className={`combined-input ${
            isGradeDivisionInvalid ? "invalid" : ""
          }`}
        />

        {gradeDivisionDropdownOpen && (
          <div className="cusdropdown">
            {gradeDivisionOptions.map((option, index) => (
              <div
                key={index}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent input from losing focus
                  handleSelectOption(
                    option,
                    setGradeDivision,
                    setGradeDivisionDropdownOpen
                  );
                }}
                className="dropdown-item"
              >
                {option}
              </div>
            ))}
          </div>
        )}
        {isGradeDivisionInvalid && (
          <p className="error-text">Please Fill The Field</p>
        )}
      </div> */}

      <div className="col">
        <p className="DropTxt">Select Exam/Term</p>
        <input
          type="text"
          value={examTerm}
          onChange={(e) =>
            handleInputChange(e, setExamTerm, setExamTermDropdownOpen)
          }
          onFocus={() => setExamTermDropdownOpen(true)}
          onBlur={() => setExamTermDropdownOpen(false)}
          placeholder="Select Exam/Term"
          className={`combined-input ${isExamTermInvalid ? "invalid" : ""}`}
        />

        {examTermDropdownOpen && (
          <div className="cusdropdown">
            {examTermOptions.map((option, index) => (
              <div
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent input from losing focus
                  handleSelectOption(
                    option,
                    setExamTerm,
                    setExamTermDropdownOpen
                  );
                }}
                className="dropdown-item"
              >
                {option}
              </div>
            ))}
          </div>
        )}
        {isExamTermInvalid && (
          <p className="error-text">Please Fill The Field</p>
        )}
      </div>
      <div className="col">
        <p className="DropTxt">Select Subject</p>
        <input
          type="text"
          value={examSubject}
          onChange={(e) =>
            handleInputChange(e, setExamSubject, setExamSubjectDropdownOpen)
          }
          onFocus={() => setExamSubjectDropdownOpen(true)}
          onBlur={() => setExamSubjectDropdownOpen(false)}
          placeholder="Select Exam/Term"
          className={`combined-input ${isExamSubjectInvalid ? "invalid" : ""}`}
        />

        {examSubjectDropdownOpen && (
          <div className="cusdropdown">
            {examSubjectOptions.map((option, index) => (
              <div
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent input from losing focus
                  handleSelectOption(
                    option,
                    setExamSubject,
                    setExamSubjectDropdownOpen
                  );
                }}
                className="dropdown-item"
              >
                {option}
              </div>
            ))}
          </div>
        )}
        {isExamSubjectInvalid && (
          <p className="error-text">Please Fill The Field</p>
        )}
      </div>

      <button
        className="view-mark-list-button col-md-12"
        onClick={handleSubmit}
      >
        View Mark List
      </button>
    </div>
  );
}

export default CombinedInput;
