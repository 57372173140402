import React, { useEffect } from "react";
import calender_3 from "../../assets/images/svg/calendar_3.svg";
import stackedAreaData from "britecharts-react";
import StackedArea from "britecharts-react";
function EmpLeaves() {
  useEffect(() => {
    document.title = "Edsys | Apply Leaves ";
  }, []);
  const { StackedArea } = require("britecharts-react");
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row apply-leave">
        <div className="col-12">
          <div className="card br-15">
            <div className="card-body header-card">
              <div className="student-header">
                <div className="details-banner ">
                  <h3>Leave Status</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-block">
                        <div className="leave-status d-flex">
                          <div className="flex-grow-1">
                            <div className="leaves-count">15</div>
                            <div className="title">Availed Leaves</div>
                          </div>
                          <div className="ms-3">
                            <img src={calender_3} />
                          </div>
                        </div>
                        <div className="leave-status d-flex mt-2">
                          <div className="flex-grow-1">
                            <div className="leaves-count">03</div>
                            <div className="title">Leaves Balance</div>
                          </div>
                          <div className="ms-3">
                            <img src={calender_3} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="chart-area">
                        <div className="card--chart">
                          <div
                            className="js-donut-chart-container donut-chart-container"
                            style={{ height: "280px", width: "280px" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3>Apply for Leave</h3>
                  <form
                    className="form-bookmark needs-validation"
                    id="bookmark-form"
                    novalidate=""
                  >
                    <div className="form-row">
                      <div className="col-md-12 leve-form">
                        <div className="radio-group">
                          <input
                            type="radio"
                            id="option-one"
                            name="selector"
                            checked
                          />
                          <label for="option-one">Full Day</label>
                          <input type="radio" id="option-two" name="selector" />
                          <label for="option-two">First Half</label>
                          <input
                            type="radio"
                            id="option-three"
                            name="selector"
                          />
                          <label for="option-three">Second Half</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label for="task-title">From</label>
                          <input
                            className="datepicker-here form-control digits"
                            type="date"
                            data-language="en"
                            placeholder="MM-DD-YYYY"
                            data-position="top left"
                          />
                        </div>
                        <div className="form-group col-md-6">
                          <label for="task-title">To</label>
                          <input
                            className="datepicker-here form-control digits"
                            type="date"
                            data-language="en"
                            placeholder="MM-DD-YYYY"
                            data-position="top left"
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label for="task-title">Leave Category</label>
                        <select
                          className="js-example-basic-single form-select"
                          f
                        >
                          <option value="task">Planned</option>
                        </select>
                      </div>
                      <div className="form-group col-md-12">
                        <label for="task-title">Reporting Person</label>
                        <select className="js-example-basic-single form-select">
                          <option value="task">Employee</option>
                        </select>
                      </div>
                      <div className="form-group col-md-12">
                        <label for="sub-task">
                          Enter Reason for applying leave
                        </label>
                        <textarea
                          className="form-control"
                          required=""
                          autocomplete="off"
                        >
                          {" "}
                        </textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="card-footer">
              <button className="btn btn-primary" type="button">
                Apply Leave
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default EmpLeaves;
