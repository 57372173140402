import React from "react";

const AssignmentModal = (props) => {
  const displayStyle = {
    display: "block",
  };

  return (
    <div
      show
      class="modal fade show testingll"
      style={displayStyle}
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Assignment Creation
            </h5>
            <button
              class="btn-close"
              onClick={props.onClose}
              type="button"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              {" "}
            </button>
          </div>
          <div class="modal-body datetime-picker modal-scroll">
            <form
              class="form-bookmark needs-validation"
              id="bookmark-form"
              novalidate=""
            >
              <div class="form-row">
                <div class="form-group col-md-12">
                  <label for="task-title">Assignment Title</label>
                  <input
                    class="form-control"
                    id="task-title"
                    type="text"
                    required=""
                    autocomplete="off"
                  />
                </div>
                <div class="form-group col-md-12">
                  <label for="sub-task">Assignment Description</label>
                  <textarea class="form-control" required="" autocomplete="off">
                    {" "}
                  </textarea>
                </div>
                <div class="form-group col-md-12">
                  <label for="task-title">Curriculum</label>
                  <select class="js-example-basic-single form-select">
                    <option value="task">CBSE</option>
                    <option value="task">ICSE</option>
                  </select>
                </div>
                <div class="row">
                  <div class="form-group col">
                    <label for="task-title">Grade</label>
                    <select class="js-example-basic-single form-select">
                      <option value="task">10A</option>
                      <option value="task">10B</option>
                      <option value="task">10C</option>
                    </select>
                  </div>
                  <div class="form-group col">
                    <label for="task-title">Subject</label>
                    <select
                      class="js-example-disabled-results form-select"
                      id="bm-collection"
                    >
                      <option value="general">Physics</option>
                      <option value="general">Chemistry</option>
                      <option value="general">Biolody</option>
                      <option value="general">Mathematics</option>
                      <option value="general">Social Studies</option>
                    </select>
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label for="task-title">Lesson</label>
                  <select class="js-example-basic-single form-select">
                    <option value="lesson1">Lesson 1</option>
                    <option value="lesson2">Lesson 2</option>
                    <option value="lesson3">Lesson 3</option>
                    <option value="lesson4">Lesson 4</option>
                    <option value="lesson5">Lesson 5</option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label for="task-title">Topic</label>
                  <select class="js-example-basic-single form-select">
                    <option value="task">Assignment Title-1</option>
                    <option value="task">Assignment Title-2</option>
                    <option value="task">Assignment Title-3</option>
                    <option value="task">Assignment Title-4</option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label for="task-title">Assignment Type</label>
                  <select class="js-example-basic-single form-select">
                    <option value="task">Reading Assignment</option>
                  </select>
                </div>
                <div class="row">
                  <div class="form-group col-md-6">
                    <label for="task-title">Submission Mode</label>
                    <select class="js-example-basic-single form-select">
                      <option value="task">Direct</option>
                    </select>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="task-title">Submission Date</label>
                    <input
                      class="datepicker-here form-control digits"
                      type="text"
                      data-language="en"
                      placeholder="MM-DD-YYYY"
                      data-position="top left"
                    />
                  </div>
                </div>
                <div class="form-group col-md-12">
                  <label for="task-title">Reviewed By</label>
                  <select class="js-example-basic-single form-select">
                    <option value="task">Mathew James</option>
                  </select>
                </div>
                <div class="form-group col-md-12">
                  <label for="task-title">Students</label>
                  <select class="js-example-basic-single form-select">
                    <option value="task">All</option>
                  </select>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button class="btn btn-secondary" id="Bookmark" type="submit">
              Save
            </button>
            <button
              class="btn btn-primary"
              type="button"
              onClick={props.onClose}
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignmentModal;
