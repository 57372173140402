import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import Dropdown from "react-bootstrap/Dropdown";
import Modal from "react-bootstrap/Modal";

import book_1 from "../../assets/images/Feed/book_1.svg";
import book_2 from "../../assets/images/Feed/book_2.svg";
import book_3 from "../../assets/images/Feed/book_3.svg";
import book_4 from "../../assets/images/Feed/book_4.svg";
import book_5 from "../../assets/images/Feed/book_5.svg";
import book_6 from "../../assets/images/Feed/book_6.svg";
import book_7 from "../../assets/images/Feed/book_7.svg";
import book_8 from "../../assets/images/Feed/book_8.svg";
import book_9 from "../../assets/images/Feed/book_9.svg";
import search_icon from "../../assets/images/Feed/search_icon.svg";
import filter_icon from "../../assets/images/Fourms/filter_icon.svg";
import profile from "../../assets/images/Feed/part_1.svg";
import announcement_icon from "../../assets/images/Feed/announcement_icon.svg";
import dropdown_icons from "../../assets/images/Fourms/dropdown_icons.svg";
import edit_icon from "../../assets/images/Fourms/edit_icon.svg";
import delete_icon from "../../assets/images/Fourms/delete_icon.svg";
import dislike_btn from "../../assets/images/Fourms/dislike_btn.svg";
import com_icon from "../../assets/images/Fourms/com_icon.svg";
import like_btn from "../../assets/images/Fourms/like_btn.svg";
import Fedd from "./Feed.css";
import { Link } from "react-router-dom";

function Feed() {
  const [q, setQ] = useState("");
  const [searchParam] = useState(["title"]);
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const books = [
    {
      title: "Mathematics Class 9",
      img: book_1,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "All in One Mathematics",
      img: book_2,
      statusClass1: "date-gray",
      statusClass2: "date-red",
      fineClass: "red",
      fine: "Fine : Rs 100",
    },
    {
      title: "Sura's Mathematics",
      img: book_3,
      statusClass1: "date-gray",
      statusClass2: "date-red",
      fineClass: "red",
      fine: "Fine : Rs 100",
    },
    {
      title: "Smart English",
      img: book_4,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Kumarbharti Digest",
      img: book_5,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "My English Navneet",
      img: book_6,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "History of Goa",
      img: book_7,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Science and Technology",
      img: book_8,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
    {
      title: "Kumarbharti Class 9",
      img: book_9,
      statusClass1: "date-green",
      statusClass2: "date-green",
    },
  ];
  const tabs = ["All Feeds", "My Feeds"];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  function search(books) {
    return books.filter((items) => {
      return searchParam.some((newItem) => {
        return (
          items[newItem].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }
  if (error) {
    return <p>{error.message}</p>;
  } else {
    return (
      <div>
        <div class="library card p-4">
          <div className="main-section">
            <div className="row">
              <div className="col-md-12">
                <div className="books-main-wrapper">
                  <div className="col-md-12">
                    <div
                      className="announcement-top d-flex align-items-center "
                      style={{ justifyContent: "space-between" }}
                    >
                      <h4>Feeds</h4>
                      <img src={filter_icon} alt="" onClick={handleShow} />
                    </div>
                  </div>

                  <div>
                    <div className="tabs">
                      {tabs.map((tab, index) => (
                        <div
                          key={index}
                          className={`tab ${tab === activeTab ? "active" : ""}`}
                          onClick={() => handleClick(tab)}
                        >
                          {tab}
                        </div>
                      ))}
                    </div>
                    <div className="tab-content">
                      {activeTab === "All Feeds" && (
                        <div className="my-books">
                          {q === "" ? (
                            ""
                          ) : (
                            <p className="search-result">Search Results</p>
                          )}
                          <div className="row">
                            {search(books).map((item) => (
                              <>
                                <div className="col-md-6 col-lg-4 col-sm-12">
                                  <div className="cardFeed">
                                    <div class="d-flex">
                                      <img class="flex-grow-0" src={profile} />
                                      <div className="flex-grow-1">
                                        <p className="card-title">
                                          Sarah Connors
                                        </p>
                                        <p className="card-date">23/02/2023</p>
                                      </div>

                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => setModalShow1(true)}
                                          >
                                            <img src={edit_icon} alt="" />
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => setModalShow2(true)}
                                          >
                                            <img src={delete_icon} alt="" />
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <p className="Feed-title">Feeds Title</p>
                                    <p className="Feed-data">
                                      Lorem ipsum dolor sit amet, consetetur
                                      sadipscing elitr, sed diam nonumy eirmod
                                      Read more...
                                    </p>
                                    <div className="d-flex align-items-center ml-3">
                                      <div className="like-btn  align-items-center ">
                                        <img src={like_btn} alt="" />
                                        <span className="margin-left">10</span>
                                      </div>
                                      <div className="dislike-btn  align-items-center margin-left              ">
                                        <img src={dislike_btn} alt="" />
                                        <span className="margin-left">5</span>
                                      </div>
                                      <div className="comments-btn  align-items-center margin-left">
                                        <Link to="/FeedView">
                                          <img src={com_icon} alt="" />
                                        </Link>
                                        <span className="margin-left">18</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          {q === "" ? (
                            ""
                          ) : (
                            <div className="row">
                              <p className="recommended">Recommended Books</p>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_4} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_5} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_6} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {activeTab === "My Feeds" && (
                        <div className="my-books">
                          {q === "" ? (
                            ""
                          ) : (
                            <p className="search-result">Search Results</p>
                          )}
                          <div className="row">
                            {search(books).map((item) => (
                              <>
                                <div className="col-md-6 col-lg-4 col-sm-12">
                                  <div className="cardFeed">
                                    <div class="d-flex">
                                      <img class="flex-grow-0" src={profile} />
                                      <div className="flex-grow-1">
                                        <p className="card-title">
                                          Sarah Connors
                                        </p>
                                        <p className="card-date">23/02/2023</p>
                                      </div>

                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            onClick={() => setModalShow1(true)}
                                          >
                                            <img src={edit_icon} alt="" />
                                            Edit
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => setModalShow2(true)}
                                          >
                                            <img src={delete_icon} alt="" />
                                            Delete
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <p className="Feed-title">Feeds Title</p>
                                    <p className="Feed-data">
                                      Lorem ipsum dolor sit amet, consetetur
                                      sadipscing elitr, sed diam nonumy eirmod
                                      Read more...
                                    </p>
                                    <div className="d-flex align-items-center ml-3">
                                      <div className="like-btn  align-items-center ">
                                        <img src={like_btn} alt="" />
                                        <span className="margin-left">10</span>
                                      </div>
                                      <div className="dislike-btn  align-items-center margin-left              ">
                                        <img src={dislike_btn} alt="" />
                                        <span className="margin-left">5</span>
                                      </div>
                                      <div className="comments-btn  align-items-center margin-left">
                                        <Link to="/FeedView">
                                          <img src={com_icon} alt="" />
                                        </Link>
                                        <span className="margin-left">18</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                          {q === "" ? (
                            ""
                          ) : (
                            <div className="row">
                              <p className="recommended">Recommended Books</p>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_4} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_5} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="book-card">
                                  <div className="row">
                                    <div className="col-md-4">
                                      <div className="bc-img-box">
                                        <img src={book_6} alt="" />
                                      </div>
                                    </div>
                                    <div className="col-md-8">
                                      <p className="bc-title">
                                        Smart English 9th
                                      </p>
                                      <p className="bc-author">
                                        By Manjit Singh
                                      </p>
                                      <p className="date-green"></p>
                                      <p className="date-green">
                                        Available{" "}
                                        <span className="available">2/24</span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* <TabView>
                    <TabPanel header="All Feeds">
                     
                    </TabPanel>

                    <TabPanel header="My Feeds">
                   
                    </TabPanel>
                  </TabView> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="common-filter-modal"
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              <div className="title-wrapper">
                <p>Filter By</p>
                <p>Clear</p>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div class="select-wrapper">
              <ul>
                <li>
                  <input type="radio" id="f-option" name="selector" />
                  <label
                    for="f-option"
                    onClick={() => {
                      handleClose();
                      handleShow1();
                    }}
                  >
                    Most Recent
                  </label>

                  <div class="check"></div>
                </li>

                <li>
                  <input type="radio" id="s-option" name="selector" />
                  <label for="s-option">Most Liked</label>

                  <div class="check">
                    <div class="inside"></div>
                  </div>
                </li>

                <li>
                  <input type="radio" id="t-option" name="selector" />
                  <label for="t-option">Most Commented</label>

                  <div class="check">
                    <div class="inside"></div>
                  </div>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export default Feed;
