import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { PlusCircle } from "react-feather";

import assignment_card_data from "../../api/assignment_card_data.json";
import AssessmentDetails from "../academics/Assesment/AssessmentDetails";
import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";

function OfflineMarksheet() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [students, setStudents] = useState([
    { id: 1, name: "Student 1", marks: "80" },
    { id: 2, name: "Student 2", marks: "45" },
    { id: 3, name: "Student 3", marks: "90" },
    { id: 4, name: "Student 4", marks: "55" },
    { id: 5, name: "Student 5", marks: "79" },
    { id: 6, name: "Student 6", marks: "98" },
    { id: 7, name: "Student 7", marks: "86" },
    { id: 8, name: "Student 8", marks: "69" },
    { id: 9, name: "Student 9", marks: "46" },
    { id: 10, name: "Student 10", marks: "95" },
    { id: 11, name: "Student 11", marks: "65" },
    { id: 12, name: "Student 12", marks: "59" },
  ]);

  const handleMarksChange = (id, marks) => {
    // Update marks for the corresponding student
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === id ? { ...student, marks } : student
      )
    );
  };

  const [academicYear, setAcademicYear] = useState("");
  const [gradeDivision, setGradeDivision] = useState("");
  const [examTerm, setExamTerm] = useState("");
  const [subject, setSubject] = useState("");

  const [formValid, setFormValid] = useState(true);

  const handleButtonClick = () => {
    if (!academicYear || !gradeDivision || !examTerm || !subject) {
      // If any field is empty, set formValid to false
      setFormValid(false);
    } else {
      // All fields are filled, perform further action (e.g., navigate to mark list)
      console.log("Navigating to mark list...");
    }
  };
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
        {/* <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Offline Assesment</h5>
                <a
                  type="button"
                  onClick={handleButtonClick}
                  className="btn btn-edsys-primary btn1"
                >
                  <PlusCircle className="me-2" />
                  <span>Create New Offline Assesment</span>
                </a>
              </div>
            </div>
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Offline Assesment</h5>
                <Link to="/Create ">
                  <a type="button" className="btn btn-edsys-primary btn1">
                    <PlusCircle className="me-2" />
                    <span>Create New Offline Assessment</span>
                  </a>
                </Link>
              </div>
            </div> */}

        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1"> View Offline Assesment</h5>
                <Link to="/EditOfflineAssessment">
                  <a
                    type="button"
                    className="btn btn-edsys-primary "
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <PlusCircle className="me-2" />
                    <span style={{}}>Edit Offline Assessment</span>
                  </a>
                </Link>
              </div>
            </div>
            <div className="offmarkcad col-md-12 row ">
              <div className="col-md-3">
                {/* <div>
                  <div className="d-flex">
                    <h5 className="flex-grow-1 markhead">Offline Marksheet</h5>
                  </div>
                </div> */}
                <div className="col">
                  <p className="DropTxt">Select Academic year</p>
                  <select
                    className={`form-control ${
                      !academicYear && !formValid && "invalid"
                    }`}
                    value={academicYear}
                    onChange={(e) => setAcademicYear(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select Academic Year
                    </option>
                    <option value="2024-2025">2024-2025</option>
                    <option value="2023-2024">2023-2024</option>
                    <option value="2022-2023">2022-2023</option>
                    {/* Add more academic years if needed */}
                  </select>
                </div>
                <div className="col">
                  <p className="DropTxt">Select Grade Division</p>
                  <select
                    className={`form-control ${
                      !gradeDivision && !formValid && "invalid"
                    }`}
                    value={gradeDivision}
                    onChange={(e) => setGradeDivision(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select Grade Division
                    </option>
                    <option value="Grade 1">Grade 1</option>
                    <option value="Grade 2">Grade 2</option>
                    <option value="Grade 3">Grade 3</option>
                    {/* Add more grade divisions if needed */}
                  </select>
                </div>
                <div className="col">
                  <p className="DropTxt">Select Exam/Term</p>
                  <select
                    className={`form-control ${
                      !examTerm && !formValid && "invalid"
                    }`}
                    value={examTerm}
                    onChange={(e) => setExamTerm(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select Exam/Term
                    </option>
                    <option value="Midterm">Midterm</option>
                    <option value="Final Exam">Final Exam</option>
                    <option value="Quarterly Exam">Quarterly Exam</option>
                    {/* Add more exam/term options if needed */}
                  </select>
                </div>
                <div className="col">
                  <p className="DropTxt">Select Subject</p>
                  <select
                    className={`form-control ${
                      !subject && !formValid && "invalid"
                    }`}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                  >
                    <option value="" disabled>
                      Select Subject
                    </option>
                    <option value="Mathematics">Mathematics</option>
                    <option value="Science">Science</option>
                    <option value="English">English</option>
                    {/* Add more subjects if needed */}
                  </select>
                </div>
                <button
                  className="view-mark-list-button col-md-12"
                  onClick={handleButtonClick}
                >
                  View Mark List
                </button>
                <p className="p-10 "></p>
              </div>
              <div className="col-md-9">
                {/* <h5 className="flex-grow-1  markhead">Offline Marksheet</h5> */}

                <div className="table">
                  <div className="thead">
                    <div className="tr tableheadstyle">
                      <div className="thsl">Sl. No</div>
                      {/* <div className="thI">Image</div> */}
                      <div className="thsn">Student Name</div>
                      <div className="thm">Marks</div>
                      <div className="thm"> Total Marks</div>
                    </div>
                  </div>
                  <div className="tbody">
                    {students.map((student, index) => (
                      <div className="tr" key={student.id}>
                        <div className="tdsl">{index + 1}</div>
                        {/* <div className="tdI"></div> */}
                        <div className="tdsn">
                          {" "}
                          <img
                            className="rounded-circle"
                            src={dashboardAvatar}
                            alt="image"
                            style={{
                              height: "25px",
                              width: "25x",
                              marginRight: "10px",
                            }}
                          />
                          {student.name}
                        </div>
                        <div className="tdm">
                          {/* <input
                            type="number"
                            min="0"
                            max="3"
                            placeholder="Enter marks"
                            value={student.marks}
                            style={{ border: "none", width: "120px" }}
                            onChange={(e) =>
                              handleMarksChange(student.id, e.target.value)
                            }
                          /> */}
                          {student.marks}
                        </div>
                        <div className="tdm">
                          <p>100</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OfflineMarksheet;
