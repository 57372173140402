import React from 'react';
import reactDom from 'react-dom';
import { Link } from "react-router-dom";


import {
   Play, Video
} from "react-feather";
import ChannelList_data from '../../api/ChannelList_data.json'


function Channel_Video() {
    return (
        <div class="page-body">
        {/* <!-- Container-fluid starts--> */}
       
        {/* <!-- Container-fluid Ends--> */}
      </div>
    )
}

export default Channel_Video

