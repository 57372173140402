import React, { Component } from "react";
import { Link } from "react-router-dom";
import { PlusCircle } from "react-feather";
import assignment_card_data from "../../api/assignment_card_data.json";
import E_LearningModal from "./E_LearningModal";

export class E_Learning extends Component {
  //#region constructor
  constructor(props) {
    super(props);
    this.state = {
      isTodoModelShow: false,
      isTagModelShow: false,
    };
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "Edsys | E Learning";
  }
  //#endregion

  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isTodoModelShow: false,
      isTagModelShow: false,
    });
  };
  openCloseTodoModel = () => {
    // this.setInitialState();
    this.setState({ isTodoModelShow: !this.state.isTodoModelShow }, () => {});
  };
  openCloseTagModel = () => {
    // this.setInitialState();
    this.setState({ isTagModelShow: !this.state.isTagModelShow });
  };
  //#endregion

  //#region Render
  render() {
    return (
      <div>
        {/* <!-- Container-fluid starts--> */}
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="flex-grow-1">E-Learning</h5>
                  <a
                    type="button"
                    data-bs-toggle="modal"
                    onClick={this.openCloseTodoModel}
                    data-bs-target="#exampleModal"
                    className="btn btn-edsys-primary btn1"
                  >
                    <PlusCircle className="me-2" />
                    <span>Create New Resource</span>
                  </a>
                  {/* {Modal Start} */}
                  {this.state.isTodoModelShow ? (
                    <E_LearningModal onClose={this.openCloseTodoModel} />
                  ) : null}

                  {/* modal End */}
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  {assignment_card_data.map((postDetail, index) => {
                    return (
                      <div className="col-md-6">
                        <div>
                          <div className={postDetail.card_bgcolor}>
                            <div className="card-header">
                              <div className="d-flex">
                                <img
                                  className="flex-grow-0"
                                  src={postDetail.icon_set}
                                />
                                <h6 className="flex-grow-1">
                                  {postDetail.subject}
                                </h6>
                                <div className="class">
                                  {postDetail.division_grade}
                                </div>
                              </div>
                            </div>

                            <Link to="/E_LearningDetails" className="card-body">
                              <h5>{postDetail.assignment_title}</h5>
                            </Link>

                            <div className="card-footer">
                              <div className="d-sm-flex">
                                <h5>{postDetail.date}</h5>
                                {/* <!-- <div className="action-list">
                                          <ul className="list-unstyled">
                                            <li>
                                              <div data-bs-toggle="modal" data-bs-target="#exampleModal2"  className="setting-secondary">
                                                  <i data-feather="edit-3"></i>
                                              </div>

                                            </li>
                                            <li>
                                                <a data-bs-toggle="modal" data-original-title="test" data-bs-target="#deleteWarning" className="setting-secondary">
                                                    <i data-feather="trash-2"></i>
                                                </a>
                                            </li>
                                          </ul>
                                        </div> --> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
    );
  }
}

export default E_Learning;
