import React, { useEffect } from "react";
// import { Image } from 'react'
import header_icon from "../../assets/images/e-learning/header-icon.svg";
import img_1 from "../../assets/images/e-learning/img_1.jpeg";
import img_2 from "../../assets/images/e-learning/img_2.jpeg";
import img_3 from "../../assets/images/e-learning/img_3.jpeg";
import img_4 from "../../assets/images/e-learning/img_4.jpeg";
import img_5 from "../../assets/images/e-learning/img_5.jpeg";
import img_6 from "../../assets/images/e-learning/img_6.jpeg";
import img_7 from "../../assets/images/e-learning/img_7.jpeg";

function ImgVideoGallary() {
  useEffect(() => {
    document.title = "Edsys | Image Video Gallary ";
  }, []);
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div class="row e-learning-gallery">
        <div class="col-md-12">
          <div class="card br-15">
            <div class="card-body">
              <div class="d-flex">
                <div class="flex-grow-1 gallery-head">
                  <h4>American Literature</h4>
                  <h6>
                    Lesson<span>Literature</span>
                  </h6>
                  <h6>
                    Name<span>Lorem Ipsum</span>
                  </h6>
                  <div class="desc">Description</div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, nisi ut aliquip exea
                    commodo consequat.
                  </p>
                </div>
                <div class="topic-ico">
                  <img src={header_icon} />
                </div>
              </div>
              <div class="gallery-sec">
                <div class="gallery-sec">
                  <div id="gallery">
                    <img
                      alt="Preview Image 1"
                      src={img_1}
                      data-image={img_1}
                      data-description="Preview Image 1 Description"
                    />
                  </div>
                </div>
                <div class="gallery-sec">
                  <div id="gallery" style={{ display: "none" }}>
                    <img
                      alt="Preview Image 1"
                      src={img_1}
                      data-image={img_1}
                      data-description="Preview Image 1 Description"
                    />

                    <img
                      alt="Preview Image 2"
                      src={img_2}
                      data-image={img_6}
                      data-description="Preview Image 2 Description"
                    />

                    {/* <img alt="Youtube Video"
                                  data-type="youtube"
                                  data-videoid="SSqfybEq5Qc"
                                  data-description="You can include youtube videos easily!" /> */}

                    <img
                      alt="Preview Image 3"
                      src={img_3}
                      data-image={img_3}
                      data-description="Preview Image 3 Description"
                    />

                    <img
                      alt="Vimeo Video"
                      data-type="vimeo"
                      src={img_4}
                      data-image={img_4}
                      data-videoid="73234449"
                      data-description="This gallery can also play vimeo videos!"
                    />

                    {/* <img alt="Preview Image 4"
                                  src="images/e-learning/img-5.jpeg"
                                  data-image="images/e-learning/img-5.jpeg"
                                  data-description="Preview Image 4 Description" />

                                <img alt="Html5 Video"
                                  src="images/e-learning/img-2.jpeg"
                                  data-type="html5video"
                                  data-image="images/e-learning/img-2.jpeg"
                                  data-videoogv="ima"
                                  data-videowebm="images/e-learning/video/video-1.webm"
                                  data-videomp4="images/e-learning/video/video-1.mp4"
                                  data-description="This is html5 video demo played by mediaelement2 player" />

                                <img alt="Preview Image 5"
                                  src="images/e-learning/img-7.jpeg"
                                  data-image="images/e-learning/img-7.jpeg"
                                  data-description="Preview Image 5 Description" />

                                <img alt="Wistia Video"			
                                  src="images/e-learning/img-8.jpeg"
                                  data-type="wistia"
                                  data-image="images/e-learning/img-8.jpeg"
                                  data-videoid="9oedgxuciv"
                                  data-description="Hey, the gallery plays Wistia videos too!" />

                                <img alt="Preview Image 6"
                                  src="images/e-learning/img-9.jpeg"
                                  data-image="images/e-learning/img-9.jpeg"
                                  data-description="Preview Image 6 Description" />

                                <img alt="Sound Cloud Track"			
                                  src="images/e-learning/img-10.jpeg"
                                  data-type="soundcloud"
                                  data-image="images/e-learning/img-10.jpeg"
                                  data-trackid="8390970"
                                  data-description="This gallery can play a soundcloud track" />

                                <img alt="Preview Image 7"
                                  src="images/e-learning/img-11.jpeg"
                                  data-image="images/e-learning/img-11.jpeg"
                                  data-description="Preview Image 7 Description" />

                                <img alt="Preview Image 8"
                                  src="images/e-learning/img-12.jpeg"
                                  data-image="images/e-learning/img-12.jpeg"
                                  data-description="Preview Image 8 Description" />

                                <img alt="Preview Image 9"
                                  src="images/e-learning/img-13.jpeg"
                                  data-image="images/e-learning/img-13.jpeg"
                                  data-description="Preview Image 9 Description" />

                                <img alt="Preview Image 10"
                                  src="images/e-learning/img-14.jpeg"
                                  data-image="images/e-learning/img-14.jpeg"
                                  data-description="Preview Image 10 Description" />

                                <img alt="Preview Image 11"
                                  src="images/e-learning/img-15.jpeg"
                                  data-image="images/e-learning/img-15.jpeg"
                                  data-description="Preview Image 11 Description" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default ImgVideoGallary;
