import React,{useState} from 'react'
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import {
  ArrowLeft,
  Settings,
  Home,
  Airplay,
  Box,
  ArrowRight,
  Video,
  ChevronLeft,
  Mic,
  MoreVertical,
  Phone,
} from "react-feather";
import Video_User from '../assets/images/user/video-user.png'
import Video_Friend from '../assets/images/user/video-friend.jpg'
import lessonslider_data from "../api/lesson_slider.json";
const VideoModal = props => {
  return(
  // <!-- Video Conference Modal -->
  <div class="modal fade bd-example-modal-lg show" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg video-conference">
      <div class="modal-content">
        <div class="video-container">
          <img class="user-video-bg" src={Video_User} />
          <div class="top-left">
            <a data-bs-dismiss="modal" aria-label="Close"><ChevronLeft /></a>
            <span>6th A - Physics</span>
            <div class="duration">00:31</div>
          </div>
          <div class="top-right">
            <img src={Video_Friend}/>
          </div>
          <div class="centered">
            <div class="call-btn" data-bs-dismiss="modal" aria-label="Close"><Phone /></div>
            <div class="d-flex control-bar">
              <div class="message"><i data-feather="message-square"></i></div>
              <div class="mic"><Mic /></div>
              <div class="call-area"></div>
              <div class="video-btn"><Video /></div>
              <div class="video-option"><MoreVertical /></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

function LessonsSlider() {
  const [state, setState] = useState(1);
  return (
    <div className="row">
      <div className="col-md-12 subject-sec">
        <main className="main">
          <div className="container">
            <div className="scroll">
              <Swiper
                spaceBetween={0}
                slidesPerView={3}

                onSlideChange={() => console.log("slide change")}
                onSwiper={swiper => console.log(swiper)}
              >



                {lessonslider_data.map((postDetail, index) => {
                  return (
                    <SwiperSlide>
                      <div className={postDetail.scroll_container1}>
                        <Link to="/Attendance">
                          <div onclick="location.href='attendance.html';" className="head-block">

                            <div className="class">{postDetail.class_division}<span>{postDetail.class_suffix}</span></div>
                            <div className="period">Period <span>{postDetail.period_number}</span></div>
                          </div>
                        </Link>
                        <div className="scroll-container__text">
                          <div className="d-flex">
                            <h5 onclick="location.href='attendance.html';" className="flex-grow-1">{postDetail.subject_name}</h5>
                            <div data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg" className="video-conf">
                              {state == 1 ?
                                <VideoModal /> :

                                null
                              }
                              <Video onClick={() => { setState(state + 1) }} />
                            </div>
                          </div>
                          <p>{postDetail.class_timings}</p>
                          <div className="row">
                            <div className="col-8">
                              <h6>Lesson 01</h6>
                            </div>
                            <div className="col-4">
                              <div onclick="openRightMenu1()" className="detail">
                                <i className="icofont icofont-book-alt"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}

              </Swiper>
            </div>
          </div>

        </main>
      </div>
    </div>
  )
}

export default LessonsSlider
