import React, { Component } from "react";
// import { Modal } from 'react-bootstrap'

import LeavesModal from "./LeavesModal";
import user from "../../assets/images/gif/user.gif";

export class ApproveLeaves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTodoModelShow: false,
      isTagModelShow: false,
    };
  }
  //#endregion

  //#region Life Cycle Methodes
  componentDidMount() {
    document.title = "Edsys | Approve Leaves";
  }
  //#endregion

  //#region custom Methods
  setInitialState = () => {
    this.setState({
      isTodoModelShow: false,
      isTagModelShow: false,
    });
  };
  openCloseTodoModel = () => {
    // this.setInitialState();
    this.setState({ isTodoModelShow: !this.state.isTodoModelShow }, () => {});
  };
  openCloseTagModel = () => {
    // this.setInitialState();
    this.setState({ isTagModelShow: !this.state.isTagModelShow });
  };
  //#endregion

  //#region Render

  render() {
    const myData = [
      {
        id: 1,
        rank: 1,
        name: "Lucy Tom",
        icon_set: "../assets/images/user/b1.png",
        leaves: 4,
      },
      {
        id: 2,
        rank: 2,
        name: "Lucy Tom",
        icon_set: "../assets/images/user/b2.png",
        leaves: 4,
      },
      {
        id: 3,
        rank: 3,
        name: "Thomas Abraham",
        icon_set: "../assets/images/user/b3.png",
        leaves: 4,
      },
      {
        id: 4,
        rank: 4,
        name: "Janet James",
        icon_set: "../assets/images/user/b4.png",
        leaves: 4,
      },

      {
        id: 5,
        rank: 5,
        name: "Ganesh",
        icon_set: "../assets/images/user/b5.png",
        leaves: 4,
      },

      {
        id: 6,
        rank: 6,
        name: "Linga Lopez",
        icon_set: "../assets/images/user/b6.png",
        leaves: 4,
      },

      {
        id: 7,
        rank: 7,
        name: "Sam Joseph",
        icon_set: "../assets/images/user/b7.png",
        leaves: 4,
      },
    ];
    return (
      <div className=" approve-leave">
        {/* <!-- Container-fluid starts--> */}
        <div className="row">
          <div className="col-12">
            <div className="card br-15">
              <div className="card-body">
                <div className="n-attend-total d-flex">
                  <div className="flex-grow-1 n-attend-title">
                    <h3>Approve Leaves</h3>
                  </div>
                  <div className="h-i-m-g">
                    <img src={user} />
                  </div>
                </div>
                {myData.map((myData) => (
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    onClick={this.openCloseTodoModel}
                    className="student-rank-list"
                    key={myData}
                  >
                    <div className="d-flex align-items-center">
                      <div className="ranks">{myData.rank}</div>
                      <div className="p-image">
                        <img src={myData.icon_set} alt="..." />
                      </div>
                      <div className="student-name">{myData.name}</div>
                      <div className="no-leave ms-auto">
                        {myData.leaves} Days
                      </div>
                    </div>
                  </div>
                ))}

                {/* Modal start */}
                {this.state.isTodoModelShow ? (
                  <LeavesModal onClose={this.openCloseTodoModel} />
                ) : null}
                {this.state.isTagModelShow ? (
                  <LeavesModal onClose={this.openCloseTagModel} />
                ) : null}
                {/* modal End */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Container-fluid Ends--> */}
      </div>
    );
  }
}

export default ApproveLeaves;
