import React, { useEffect } from "react";
import b1 from "../../../assets/images/user/b1.png";
import b2 from "../../../assets/images/user/b2.png";
import b3 from "../../../assets/images/user/b3.png";
import b4 from "../../../assets/images/user/b4.png";
import b5 from "../../../assets/images/user/b5.png";
import b6 from "../../../assets/images/user/b6.png";
import b7 from "../../../assets/images/user/b7.png";
import b8 from "../../../assets/images/user/b8.png";
import layers from "../../../assets/images/assessments/layers.gif";
import { Link } from "react-router-dom";

function RankList() {
  useEffect(() => {
    document.title = "Edsys | RankList";
  }, []);
  const rankData = [
    {
      id: 4,
      rank: 4,
      name: "Janet James",
      icon_set: "../assets/images/user/b4.png",
      point: 40,
      pointOf: 50,
    },

    {
      id: 5,
      rank: 5,
      name: "Ganesh",
      icon_set: "../assets/images/user/b5.png",
      point: 38,
      pointOf: 50,
    },

    {
      id: 6,
      rank: 6,
      name: "Linga Lopez",
      icon_set: "../assets/images/user/b6.png",
      point: 36,
      pointOf: 50,
    },

    {
      id: 7,
      rank: 7,
      name: "Sam Joseph",
      icon_set: "../assets/images/user/b7.png",
      point: 34,
      pointOf: 50,
    },
    {
      id: 8,
      rank: 8,
      name: "Lucy Tom",
      icon_set: "../assets/images/user/b1.png",
      point: 49,
      pointOf: 50,
    },
    {
      id: 9,
      rank: 9,
      name: "Lucy Tom",
      icon_set: "../assets/images/user/b2.png",
      point: 46,
      pointOf: 50,
    },
    {
      id: 10,
      rank: 10,
      name: "Thomas Abraham",
      icon_set: "../assets/images/user/b3.png",
      point: 42,
      pointOf: 50,
    },
  ];
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row rank-list">
        <div className="col-12">
          <div className="rank-list-banner banner-img">
            <div className="profile">
              <div className="person second">
                <Link to="/ScoreDetails">
                  <div className="num">2</div>
                  <i className="icofont icofont-rounded-double-right"></i>
                  <img
                    onclick="location.href='score-details.html';"
                    src={b1}
                    alt=""
                    className="photo"
                  />
                  <p className="link">Lucky Tom</p>
                  <p className="points">
                    46<span>/50</span>
                  </p>
                </Link>
              </div>
              <div className="person first">
                <Link to="/ScoreDetails">
                  <div className="num">1</div>
                  <i className="icofont icofont-queen-crown"></i>
                  <img
                    onclick="location.href='score-details.html';"
                    src={b2}
                    alt=""
                    className="photo main"
                  />
                  <p className="link">Martin</p>
                  <p className="points">
                    49<span>/50</span>
                  </p>
                </Link>
              </div>
              <div className="person third">
                <Link to="/ScoreDetails">
                  <div className="num">3</div>
                  <i className="icofont icofont-rounded-double-right"></i>
                  <img
                    onclick="location.href='score-details.html';"
                    src={b3}
                    alt=""
                    className="photo"
                  />
                  <p className="link">Thomas Abra</p>
                  <p className="points">
                    42<span>/50</span>
                  </p>
                </Link>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              {rankData.map((rankData) => (
                <div className="student-rank-list" key={rankData}>
                  <Link to="/ScoreDetails">
                    <div className="d-flex align-items-center">
                      <div className="ranks">{rankData.rank}</div>
                      <div className="p-image">
                        <img src={rankData.icon_set} alt="..." />
                      </div>
                      <div className="student-name">{rankData.name}</div>
                      <div className="ms-auto scores">
                        {rankData.point}
                        <span>/{rankData.pointOf}</span>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}
export default RankList;
