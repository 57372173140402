import React from 'react'

function StudentList() {
    return (
        <div>
            <h1>Not Availabale</h1>
           
        </div>
    )
}

export default StudentList
