import React, { useEffect } from "react";
import No_Attends_List from "module";
import RankList from "./RankList";

import { Clock } from "react-feather";
import { Link } from "react-router-dom";

function AssessmentDetails() {
  useEffect(() => {
    document.title = "Edsys | AssessmentDetails";
  }, []);
  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div class="row assessment-details">
        <div class="col-12">
          <div class="card br-15">
            <div class="card-body">
              <div class="knowledgebase-bg b-center bg-size banner-img"></div>
              <div class="knowledgebase-search">
                <div class="details-banner">
                  <h3>Assessment Title 01</h3>
                  <h5>Physics</h5>
                  <h6>
                    Assigned by<span>Ronald James</span>
                  </h6>
                  <div class="d-flex">
                    <div class="sub-date">28 Oct 2021, Thu</div>
                    <div class="sub-time">
                      <i class="icofont icofont-clock-time"></i>30:00 Mins
                    </div>
                  </div>
                </div>
              </div>
              <div class="desc">
                <h5>Description</h5>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam, quis nostrud exercitation.Ut enim ad
                  minim veniam, quis nostrud exercitation. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit, sed do eiusmod tempor
                  incididunt ut labore et dolore magna aliqua. Ut enim ad minim
                  veniam, quis nostrud exercitation.Ut enim ad minim veniam,
                  quis nostrud exercitation. Lorem ipsum dolor sit amet,
                  consectetur adipiscing elit, sed do eiusmod tempor incididunt
                  ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                  quis nostrud exercitation.Ut enim ad minim veniam, quis
                  nostrud exercitation.
                </p>
              </div>
            </div>
            <div class="modal-footer">
              <Link
                to="/No_Attends_List"
                class="btn btn-secondary"
                onclick="location.href='not-attended-list.html';"
              >
                Not Attended List
              </Link>
              <Link
                to="/RankList"
                class="btn btn-primary"
                onclick="location.href='rank-list.html';"
              >
                Rank List
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Container-fluid Ends--> */}
    </div>
  );
}

export default AssessmentDetails;
