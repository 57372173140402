import React from 'react'

import b1 from '../../../assets/images/user/b1.png'
import b2 from '../..//../assets/images/user/b2.png'
import b3 from '../..//../assets/images/user/b3.png'
import b4 from '../..//../assets/images/user/b4.png'
import b5 from '../..//../assets/images/user/b5.png'
import b6 from '../..//../assets/images/user/b6.png'
import b7 from '../..//../assets/images/user/b7.png'
import b8 from '../..//../assets/images/user/b8.png'
import { ChevronDown, ChevronLeft, ChevronRight, CheckSquare, XSquare } from 'react-feather'
const AbsentTab = props => {
  const studData = [
    {
      "id": 1,
      "name": "Ganesh",
      "icon_set": "../assets/images/user/b8.png",


    },
    {
      "id": 2,
      "name": "onkar",
      "icon_set": "../assets/images/user/b7.png",

    },
    {
      "id": 3,
      "name": "Rushikesh",
      "icon_set": "../assets/images/user/b6.png",

    },
    {
      "id": 4,
      "name": " Pankaj ",
      "icon_set": "../assets/images/user/b5.png",

    },

    {
      "id": 5,
      "name": "Ajinkya",
      "icon_set": "../assets/images/user/b4.png",

    },

    {
      "id": 6,
      "name": "Linga ",
      "icon_set": "../assets/images/user/b3.png",

    }
    ,
    {
      "id": 7,
      "name": "Janet James",
      "icon_set": "../assets/images/user/b2.png",

    },

    {
      "id": 8,
      "name": "Ganesh",
      "icon_set": "../assets/images/user/b1.png",

    },

    {
      "id": 9,
      "name": "Linga Lopez",
      "icon_set": "../assets/images/user/b6.png",

    }
  ]
  return (

    
      <ul class="responsive-table">
      {studData.map(studData=>
        <li class="table-row" key={studData}>
         
          <div class="col col-1 avatar" data-label="Student Image" >
            <img src={studData.icon_set} />
          </div>
          <div class="col col-8 std-name" data-label="Student Name">{studData.name}</div>
          <div class="col col-3" data-label="Action">
            <div class="switch-field">
              <input type="radio" id="radio-one" name="switch-one" value="absent" />
              <label for="radio-one">Absent</label>
              <input type="radio" id="radio-two" name="switch-one" value="present" checked />
              <label for="radio-two">Present</label>
            </div>
          </div>
             
        </li>
         )
        }
      </ul>
    


  )
}
export default AbsentTab
