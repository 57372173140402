import React from "react";

import b1 from "../../assets/images/user/b1.png";
import b2 from "../../assets/images/user/b2.png";
import b3 from "../../assets/images/user/b3.png";
import b4 from "../../assets/images/user/b4.png";
import b5 from "../../assets/images/user/b5.png";
import b6 from "../../assets/images/user/b6.png";
import b7 from "../../assets/images/user/b7.png";
import b8 from "../../assets/images/user/b8.png";
const LeavesModal = (props) => {
  const displayStyle = {
    display: "block",
    overflow: "auto",
  };

  {
    return (
      <div
        className="modal fade modal-bookmark show testingll"
        style={displayStyle}
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <div className="d-flex align-items-center">
                <div className="modal-header">
                  <img src={b3} />
                </div>
                <div className="student-name flex-grow-1">Lucy Tom</div>
                <div className="no-leave">4 Days</div>
              </div>
            </div>
            <div className="modal-body modal-scroll">
              <div className="row">
                <div className="col-6">
                  <div className="fixed-form">
                    <div className="al-label">From</div>
                    <div className="al-input">01-12-2021</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fixed-form">
                    <div className="al-label">To</div>
                    <div className="al-input">05-12-2021</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="fixed-form">
                    <div className="al-label">Applied Date</div>
                    <div className="al-input">28-11-2021</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fixed-form">
                    <div className="al-label">No. of Days</div>
                    <div className="al-input">5 Days</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <div className="fixed-form">
                    <div className="al-label">Leave Type</div>
                    <div className="al-input">Full Day</div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="fixed-form">
                    <div className="al-label">Leave Category</div>
                    <div className="al-input">Planned</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="fixed-form">
                    <div className="al-label">Leave Reason</div>
                    <div className="al-text-area">Going to native place</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-secondary"
                onClick={props.onClose}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button className="btn btn-primary" type="submit">
                Approve
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
export default LeavesModal;





// import React, { useState } from "react";
// import "../../assets/css/bootstrap.css";
// import "../../assets/css/CreateExam.css"; // Import your CSS file for additional styling

// function CreateExam() {
//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState("");

//   //   const handleGradeClick = (grade) => {
//   //     setGrades(prevGrades => ({
//   //       ...prevGrades,
//   //       [grade]: !prevGrades[grade]
//   //     }));
//   //     setSelectedGrade(grade);
//   //     setSelectedSubject('');
//   //   };

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade((prev) => (prev === grade ? "" : grade));
//     setSelectedSubject("");
//   };
//   const handleSubjectChange = (e) => {
//     setSelectedSubject(e.target.value);
//   };

//   const [selectedExam, setSelectedExam] = useState("");
//   const [newExamName, setNewExamName] = useState("");
//   const [exams, setExams] = useState(["Exam 1", "Exam 2", "Exam 3"]);

//   const handleExamChange = (event) => {
//     setSelectedExam(event.target.value);
//   };

//   // Function to handle changes in the input field
//   const handleInputChange = (event) => {
//     setNewExamName(event.target.value);
//   };

//   // Function to add a new exam to the dropdown
//   const addNewExam = () => {
//     if (newExamName.trim() !== "") {
//       setExams([...exams, newExamName]);
//       setNewExamName("");
//     }
//   };

//   const [startTime, setStartTime] = useState("");
//   const [endTime, setEndTime] = useState("");

//   const handleStartTimeChange = (event) => {
//     setStartTime(event.target.value);
//   };

//   // Function to handle changes in the end time input field
//   const handleEndTimeChange = (event) => {
//     setEndTime(event.target.value);
//   };


//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">
//           <div className="card">
//             <div className="mt-2">
//               STEP 1
//               <div className="card-body header-card">
//                 <form
//                   className="form-bookmark needs-validation"
//                   id="bookmark-form"
//                   novalidate=""
//                 >
//                   {/* <div className="form-row">

//                      <div className="form-group col-md-12">
//                       <label htmlFor="task-title">Name Exam</label>
//                       <select
//                         className="js-example-basic-single form-select"
//                         value={selectedExam}
//                         onChange={handleExamChange}
//                       >
//                         <option value="">Select Exam Name</option>
//                         {exams.map((exam, index) => (
//                           <option key={index} value={exam}>
//                             {exam}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>
//                   <div className="form-row">
//                     <div className="form-group col-md-12">
//                       <label htmlFor="new-exam">Add New Exam</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="new-exam"
//                         value={newExamName}
//                         onChange={handleInputChange}
//                       />
//                     </div>
//                   </div>
//                   <div className="form-group col-md-12">
//                       <button
//                         type="button"
//                         className="btn btn-primary"
//                         onClick={addNewExam}
//                       >
//                         Add Exam
//                       </button>
//                     </div> */}

//                   <div className="form-row">
//                     <div className="form-group col-md-12">
//                       <label for="exampleDataList">Name Exam</label>
//                       <input
//                         class="form-control"
//                         list="exam"
//                         id="exampleDataList"
//                         placeholder="Type the exam name"
//                       />

//                       <datalist id="exam">
//                         <option value="Exam1">Exam1</option>
//                         <option value="Exam2">Exam2</option>
//                         <option value="Exam3">Exam3</option>
//                       </datalist>
//                     </div>
//                   </div>

//                   <div className="form-row">
//                     <div className="form-group col-md-12">
//                       <label for="exampleDataList">Academic Year</label>
//                       <input
//                         class="form-control"
//                         list="datalistOptions"
//                         id="exampleDataList"
//                         placeholder="Type the year"
//                       />

//                       <datalist id="datalistOptions">
//                         <option value="2022">2022</option>
//                         <option value="2023">2023</option>
//                         <option value="2024">2024</option>
//                       </datalist>
//                     </div>

//                     {/* <div className="form-group col-md-12">
//                       <label for="task-title">Academic Year</label>
//                       <select className="js-example-basic-single form-select">
//                         <option value="">Select Academic Year</option>
//                         <option value="2022">2022</option>
//                         <option value="2023">2023</option>
//                         <option value="2024">2024</option>
//                       </select>
//                     </div> */}
//                     <div className="form-group col-md-12">
//                       <label for="sub-task">Terms</label>
//                       <input
//                         className="form-control"
//                         type="text"
//                         name="terms"
//                       />
//                     </div>

//                     <div className="row">
//                       <div className="form-group col-md-6">
//                         <label for="task-title">Starting Date</label>
//                         <input
//                           className="datepicker-here form-control digits"
//                           type="date"
//                           data-language="en"
//                           placeholder="MM-DD-YYYY"
//                           data-position="top left"
//                         />
//                       </div>
//                       <div className="form-group col-md-6">
//                         <label for="task-title">Ending Date</label>
//                         <input
//                           className="datepicker-here form-control digits"
//                           type="date"
//                           data-language="en"
//                           placeholder="MM-DD-YYYY"
//                           data-position="top left"
//                         />
//                       </div>
//                     </div>
//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div>
//               STEP 2
//               <div>
//                 <h6 className="grade">select Grade</h6>
//                 <div className="grade-checkboxes">
//                   {Object.keys(grades).map((grade) => (
//                     <div key={grade} className="grade-checkbox">
//                       <label>
//                         <input
//                           type="checkbox"
//                           checked={grades[grade]}
//                           onChange={() => handleGradeClick(grade)}
//                         />
//                         {grade}
//                       </label>
//                       {selectedGrade === grade && (
//                         <div className="selected-subject">
//                           <h6>Selected Subject for {grade}</h6>
//                           <select
//                             value={selectedSubject}
//                             onChange={handleSubjectChange}
//                           >
//                             <option value="">Select Subject</option>
//                             <option value="Maths">Maths</option>
//                             <option value="Physics">Physics</option>
//                           </select>
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//             <div>
//               STEP 3
//               <div className="card-body header-card">
//                 <div className="form-group col-md-12s">
//                   <label htmlFor="start-time">Start Time</label>
//                   <input
//                     type="time"
//                     className="form-control"
//                     id="start-time"
//                     value={startTime}
//                     onChange={handleStartTimeChange}
//                   />
//                 </div>

//                 <div className="form-group col-md-12">
//                   <label for="sub-task">Duration</label>
//                   <input className="form-control" type="text" name="terms" />
//                 </div>
//                 <div className="form-group col-md-12">
//                   <label for="sub-task">Max Mark</label>
//                   <input className="form-control" type="text" name="terms" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreateExam;


//   return (
//     <div className="container-fluid">
//       <div className="row offlineassesment">
//         <div className="col-sm-12">

//             <div className="card">
//             <div className="mt-2">
//          STEP 1
//               <div className="card-body header-card">
//                 <form
//                   className="form-bookmark needs-validation"
//                   id="bookmark-form"
//                   novalidate=""
//                 >
//                   <div className="form-row">

//                      <div className="form-group col-md-12">
//                       <label htmlFor="task-title">Name Exam</label>
//                       <select
//                         className="js-example-basic-single form-select"
//                         value={selectedExam}
//                         onChange={handleExamChange}
//                       >
//                         <option value="">Select Exam Name</option>
//                         {exams.map((exam, index) => (
//                           <option key={index} value={exam}>
//                             {exam}
//                           </option>
//                         ))}
//                       </select>
//                     </div>
//                   </div>
//                   <div className="form-row">
//                     <div className="form-group col-md-12">
//                       <label htmlFor="new-exam">Add New Exam</label>
//                       <input
//                         type="text"
//                         className="form-control"
//                         id="new-exam"
//                         value={newExamName}
//                         onChange={handleInputChange}
//                       />
//                     </div>
//                   </div>
//                   <div className="form-group col-md-12">
//                       <button
//                         type="button"
//                         className="btn btn-primary"
//                         onClick={addNewExam}
//                       >
//                         Add Exam
//                       </button>
//                     </div>

//                   <div className="form-row">

//                     <div className="form-group col-md-12">
//                       <label for="task-title">Academic Year</label>
//                       <select className="js-example-basic-single form-select">
//                         <option value="">Select Academic Year</option>
//                         <option value="2022">2022</option>
//                         <option value="2023">2023</option>
//                         <option value="2024">2024</option>
//                       </select>
//                     </div>
//                     <div className="form-group col-md-12">
//                       <label for="sub-task">Terms</label>
//                       <input
//                         className="form-control"
//                         type="text"
//                         name="terms"
//                       />
//                     </div>

//                     <div className="row">
//                       <div className="form-group col-md-6">
//                         <label for="task-title">Starting Date</label>
//                         <input
//                           className="datepicker-here form-control digits"
//                           type="date"
//                           data-language="en"
//                           placeholder="MM-DD-YYYY"
//                           data-position="top left"
//                         />
//                       </div>
//                       <div className="form-group col-md-6">
//                         <label for="task-title">Ending Date</label>
//                         <input
//                           className="datepicker-here form-control digits"
//                           type="date"
//                           data-language="en"
//                           placeholder="MM-DD-YYYY"
//                           data-position="top left"
//                         />
//                       </div>
//                     </div>

//                   </div>
//                 </form>
//               </div>
//             </div>
//             <div>
//               STEP 2
//               <div>
//                 <h6 className="grade">select Grade</h6>
//                 <div className="grade-checkboxes">
//                   {Object.keys(grades).map((grade) => (
//                     <div key={grade} className="grade-checkbox">
//                       <label>
//                         <input
//                           type="checkbox"
//                           checked={grades[grade]}
//                           onChange={() => handleGradeClick(grade)}
//                         />
//                         {grade}
//                       </label>
//                       {selectedGrade === grade && (
//                         <div className="selected-subject">
//                           <h6>Selected Subject for {grade}</h6>
//                           <select
//                             value={selectedSubject}
//                             onChange={handleSubjectChange}
//                           >
//                             <option value="">Select Subject</option>
//                             <option value="Maths">Maths</option>
//                             <option value="Physics">Physics</option>
//                           </select>
//                         </div>
//                       )}
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//             <div>
//               STEP 3
//               <div className="card-body header-card">

//                     <div className="form-group col-md-12s">
//                       <label htmlFor="start-time">Start Time</label>
//                       <input
//                         type="time"
//                         className="form-control"
//                         id="start-time"
//                         value={startTime}
//                         onChange={handleStartTimeChange}
//                       />
//                     </div>

//               <div className="form-group col-md-12">
//                       <label for="sub-task">Duration</label>
//                       <input
//                         className="form-control"
//                         type="text"
//                         name="terms"
//                       />
//                     </div>
//                     <div className="form-group col-md-12">
//                       <label for="sub-task">Max Mark</label>
//                       <input
//                         className="form-control"
//                         type="text"
//                         name="terms"
//                       />
//                     </div>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default CreateExam;


// import React from "react";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import "../../assets/css/CreateExam.css";

// const step1Content = <h1>Hello</h1>;
// const step2Content = <h1>Hai</h1>;
// const step3Content = <h1>Hello World</h1>;
// const step4Content = <h1>Welcome</h1>;

// function step2Validator() {
//   return true; // Returning true means the validation passed
// }

// function step3Validator() {
//   return true; // Returning true means the validation passed
// }

// function onFormSubmit() {
//   // Handle submit logic here
// }

// const App = () => (
//   <div className="container-fluid">
//     <div className="card">
//       <StepProgressBar
//         startingStep={0}
//         onSubmit={onFormSubmit}
//         steps={[
//           {
//             label: "Step 1",
//             // subtitle: '25%',
//             name: "hello",
//             content: step1Content,
//           },
//           {
//             label: "Step 2",
//             // subtitle: '50%',
//             name: "hai",
//             content: step2Content,
//             validator: step2Validator,
//           },
//           {
//             label: "Step 3",
//             // subtitle: '75%',
//             name: "helloworld",
//             content: step3Content,
//             validator: step3Validator,
//           },
//           {
//             label: "Step 4",
//             // subtitle: '100%',
//             name: "welcome",
//             content: step4Content,
//           },
//         ]}
//       />
//     </div>
//   </div>
// );

// export default App;

// import React, { useState } from "react";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import "../../assets/css/CreateExam.css";

// const App = () => {
//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState("");

//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade(grade);
//     setSelectedSubject(""); // Reset selected subject when a grade is clicked
//   };

//   const handleSubjectChange = (e) => {
//     setSelectedSubject(e.target.value);
//   };

//   const step1Content = <h1>h</h1>;

//   const step2Content = (
//     <div>
//       <h6 className="grade">Select Grade</h6>
//       <div className="grade-checkboxes">
//         {Object.keys(grades).map((grade) => (
//           <div key={grade} className="grade-checkbox">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={grades[grade]}
//                 onChange={() => handleGradeClick(grade)}
//               />
//               {grade}
//             </label>
//             {selectedGrade === grade && (
//               <div className="selected-subject">
//                 <h6>Selected Subject for {grade}</h6>
//                 <select value={selectedSubject} onChange={handleSubjectChange}>
//                   <option value="">Select Subject</option>
//                   <option value="Maths">Maths</option>
//                   <option value="Physics">Physics</option>
//                 </select>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
//   const step3Content = <h1>hlo</h1>;
//   // const step4Content = <h1>Welcome</h1>;

//   function step2Validator() {
//     return true; // Returning true means the validation passed
//   }

//   // function step3Validator() {
//   //   return true; // Returning true means the validation passed
//   // }

//   function onFormSubmit() {
//     // Handle submit logic here
//   }

//   return (
//     <div className="container-fluid">
//       <div className="card">
//         <StepProgressBar
//           startingStep={0}
//           onSubmit={onFormSubmit}
//           steps={[
//             {
//               label: "Step 1",
//               name: "hello",
//               content: step1Content,
//             },
//             {
//               label: "Step 2",
//               name: "hai",
//               content: step2Content,
//               validator: step2Validator,
//             },
//             {
//               label: "Step 3",
//               name: "helloworld",
//               content: step3Content,
//               // validator: step3Validator,
//             },
//             // {
//             //   label: "Step 4",
//             //   name: "welcome",
//             //   content: step4Content,
//             // },
//           ]}
//         />
//       </div>
//     </div>
//   );
// };

// export default App;


// import React, { useState } from "react";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import "../../assets/css/CreateExam.css";


// const CreateExam = () => {


//   const [grades, setGrades] = useState({
//     "10A": false,
//     "10B": false,
//     "10C": false,
//     "10D": false,
//   });
//   const [selectedGrade, setSelectedGrade] = useState("");
//   const [selectedSubject, setSelectedSubject] = useState("");
//   const [startTime, setStartTime] = useState("");


//   const handleGradeClick = (grade) => {
//     setGrades((prevGrades) => {
//       const updatedGrades = {};
//       for (const key in prevGrades) {
//         updatedGrades[key] = key === grade ? !prevGrades[key] : false;
//       }
//       return updatedGrades;
//     });
//     setSelectedGrade((prev) => (prev === grade ? "" : grade));
//     setSelectedSubject("");
//   };

//   const handleSubjectChange = (e) => {
//     setSelectedSubject(e.target.value);
//   };

//   const handleStartTimeChange = (event) => {
//     setStartTime(event.target.value);
//   };


  
//   const step1Content = (
//     <div className="mt-2">
//       <div className="card-body header-card">
//         <form
//           className="form-bookmark needs-validation"
//           id="bookmark-form"
//           novalidate=""
//         >
//           <div className="form-row">
//             <div className="form-group col-md-12">
//               <label for="exampleDataList">Name Exam</label>
//               <input
//                 class="form-control"
//                 list="exam"
//                 id="exampleDataList"
//                 placeholder="Type the exam name"
//               />
  
//               <datalist id="exam">
//                 <option value="Exam1">Exam1</option>
//                 <option value="Exam2">Exam2</option>
//                 <option value="Exam3">Exam3</option>
//               </datalist>
//             </div>
//           </div>
  
//           <div className="form-row">
//             <div className="form-group col-md-12">
//               <label for="exampleDataList">Academic Year</label>
//               <input
//                 class="form-control"
//                 list="datalistOptions"
//                 id="exampleDataList"
//                 placeholder="Type the year"
//               />
  
//               <datalist id="datalistOptions">
//                 <option value="2022">2022</option>
//                 <option value="2023">2023</option>
//                 <option value="2024">2024</option>
//               </datalist>
//             </div>
  
//             <div className="form-group col-md-12">
//               <label for="sub-task">Terms</label>
//               <input className="form-control" type="text" name="terms" />
//             </div>
  
//             <div className="row">
//               <div className="form-group col-md-6">
//                 <label for="task-title">Starting Date</label>
//                 <input
//                   className="datepicker-here form-control digits"
//                   type="date"
//                   data-language="en"
//                   placeholder="MM-DD-YYYY"
//                   data-position="top left"
//                 />
//               </div>
//               <div className="form-group col-md-6">
//                 <label for="task-title">Ending Date</label>
//                 <input
//                   className="datepicker-here form-control digits"
//                   type="date"
//                   data-language="en"
//                   placeholder="MM-DD-YYYY"
//                   data-position="top left"
//                 />
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
//   const step2Content = (
//     <div className="mt-2">
//       <h6 className="grade">select Grade</h6>
//       <div className="grade-checkboxes">
//         {Object.keys(grades).map((grade) => (
//           <div key={grade} className="grade-checkbox">
//             <label>
//               <input
//                 type="checkbox"
//                 checked={grades[grade]}
//                 onChange={() => handleGradeClick(grade)}
//               />
//               {grade}
//             </label>
//             {selectedGrade === grade && (
//               <div className="selected-subject">
//                 <h6>Selected Subject for {grade}</h6>
//                 <select value={selectedSubject} onChange={handleSubjectChange}>
//                   <option value="">Select Subject</option>
//                   <option value="Maths">Maths</option>
//                   <option value="Physics">Physics</option>
//                 </select>
//               </div>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
//   const step3Content = (
//     <div className="card-body header-card">
//     <div className="form-group col-md-12s">
//       <label htmlFor="start-time">Start Time</label>
//       <input
//         type="time"
//         className="form-control"
//         id="start-time"
//         value={startTime}
//         onChange={handleStartTimeChange}
//       />
//     </div>

//     <div className="form-group col-md-12">
//       <label htmlFor="duration">Duration</label>
//       <input
//         className="form-control"
//         type="text"
//         id="duration"
   
//       />
//     </div>
//     <div className="form-group col-md-12">
//       <label htmlFor="max-mark">Max Mark</label>
//       <input
//         className="form-control"
//         type="text"
//         id="max-mark"
      
//       />
//     </div>
//   </div>
//   );
//   const step4Content = <h1>Welcome</h1>;
  
//   function step2Validator() {
//     return true; // Returning true means the validation passed
//   }
  
//   function step3Validator() {
//     return true; // Returning true means the validation passed
//   }
  
//   function onFormSubmit() {
//     // Handle submit logic here
//   }
  




//   return (
//     <div className="container-fluid">
//       <div className="card">
//         <StepProgressBar
//           startingStep={0}
//           onSubmit={onFormSubmit}
//           steps={[
//             {
//               label: "Step 1",
//               // name: "hello",
//               content: step1Content,
//             },
//             {
//               label: "Step 2",
//               // name: "hai",
//               content: step2Content,
//               validator: step2Validator,
//             },
//             {
//               label: "Step 3",
//               // name: "helloworld",
//               content: step3Content,
//               validator: step3Validator,
//             },
//             {
//               label: "Step 4",
//               // name: "welcome",
//               content: step4Content,
//             },
//           ]}
//         />
//       </div>
//     </div>
//   );
// };

// export default CreateExam;


// import React, { useState } from "react";
// import StepProgressBar from "react-step-progress";
// import "react-step-progress/dist/index.css";
// import "../../assets/css/CreateExam.css";

// const CreateExam = () => {
//   const [selectedExam, setSelectedExam] = useState("");
//   const [newExamName, setNewExamName] = useState("");
//   const [exams, setExams] = useState(["Exam 1", "Exam 2", "Exam 3"]);

//   const handleExamChange = (event) => {
//     setSelectedExam(event.target.value);
//   };

//   // Function to handle changes in the input field
//   const handleInputChange = (event) => {
//     setNewExamName(event.target.value);
//   };

//   // Function to add a new exam to the dropdown
//   const addNewExam = () => {
//     if (newExamName.trim() !== "") {
//       setExams([...exams, newExamName]);
//       setNewExamName("");
//     }
//   };

//   const step1Content = (
//     <div className="mt-2">
//       STEP 1
//       <div className="card-body header-card">
//         <form
//           className="form-bookmark needs-validation"
//           id="bookmark-form"
//           novalidate=""
//         >
//           <div className="form-row">
//             <div className="form-group col-md-12">
//               <label htmlFor="task-title">Name Exam</label>
//               <select
//                 className="js-example-basic-single form-select"
//                 value={selectedExam}
//                 onChange={handleExamChange}
//               >
//                 <option value="">Select Exam Name</option>
//                 {exams.map((exam, index) => (
//                   <option key={index} value={exam}>
//                     {exam}
//                   </option>
//                 ))}
//               </select>
//             </div>
//           </div>
//           <div className="form-row">
//             <div className="form-group col-md-12">
//               <label htmlFor="new-exam">Add New Exam</label>
//               <input
//                 type="text"
//                 className="form-control"
//                 id="new-exam"
//                 value={newExamName}
//                 onChange={handleInputChange}
//               />
//             </div>
//           </div>
//           <div className="form-group col-md-12">
//             <button
//               type="button"
//               className="btn btn-primary"
//               onClick={addNewExam}
//             >
//               Add Exam
//             </button>
//           </div>
//           <div className="form-row">
//             <div className="form-group col-md-12">
//               <label for="task-title">Academic Year</label>
//               <select className="js-example-basic-single form-select">
//                 <option value="">Select Academic Year</option>
//                 <option value="2022">2022</option>
//                 <option value="2023">2023</option>
//                 <option value="2024">2024</option>
//               </select>
//             </div>
//             <div className="form-group col-md-12">
//               <label for="sub-task">Terms</label>
//               <input className="form-control" type="text" name="terms" />
//             </div>

//             <div className="row">
//               <div className="form-group col-md-6">
//                 <label for="task-title">Starting Date</label>
//                 <input
//                   className="datepicker-here form-control digits"
//                   type="date"
//                   data-language="en"
//                   placeholder="MM-DD-YYYY"
//                   data-position="top left"
//                 />
//               </div>
//               <div className="form-group col-md-6">
//                 <label for="task-title">Ending Date</label>
//                 <input
//                   className="datepicker-here form-control digits"
//                   type="date"
//                   data-language="en"
//                   placeholder="MM-DD-YYYY"
//                   data-position="top left"
//                 />
//               </div>
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );

//   const step2Content = <h1>Hai</h1>;
//   const step3Content = <h1>Hello World</h1>;
//   const step4Content = <h1>Welcome</h1>;

//   function step2Validator() {
//     return true; // Returning true means the validation passed
//   }

//   function step3Validator() {
//     return true; // Returning true means the validation passed
//   }

//   function onFormSubmit() {
//     // Handle submit logic here
//   }

//   return (
//     <div className="container-fluid">
//       <div className="card">
//         <StepProgressBar
//           startingStep={0}
//           onSubmit={onFormSubmit}
//           steps={[
//             {
//               label: "Step 1",
//               name: "hello",
//               content: step1Content,
//             },
//             {
//               label: "Step 2",
//               name: "hai",
//               content: step2Content,
//               validator: step2Validator,
//             },
//             {
//               label: "Step 3",
//               name: "helloworld",
//               content: step3Content,
//               validator: step3Validator,
//             },
//             {
//               label: "Step 4",
//               name: "welcome",
//               content: step4Content,
//             },
//           ]}
//         />
//       </div>
//     </div>
//   );
// };

// export default CreateExam;

