// import React from "react";
// import "./GradewiseList.css";
// import { PlusCircle } from "react-feather";
// import { Link } from "react-router-dom";

// const data = [
//   {
//     id: 1,
//     name: "John Doe",
//     physics: 85,
//     chemistry: 90,
//     math: 75,
//     history: 95,
//     biology: 80,
//     geography: 85,
//     english: 90,
//     art: 85,
//     music: 88,
//   },
//   {
//     id: 2,
//     name: "Jane Smith",
//     physics: 90,
//     chemistry: 85,
//     math: 80,
//     history: 92,
//     biology: 85,
//     geography: 90,
//     english: 88,
//     art: 90,
//     music: 82,
//   },
//   {
//     id: 3,
//     name: "Bob Johnson",
//     physics: 75,
//     chemistry: 80,
//     math: 85,
//     history: 90,
//     biology: 78,
//     geography: 82,
//     english: 85,
//     art: 80,
//     music: 85,
//   },
//   {
//     id: 4,
//     name: "Alice Brown",
//     physics: 95,
//     chemistry: 92,
//     math: 88,
//     history: 89,
//     biology: 90,
//     geography: 95,
//     english: 92,
//     art: 88,
//     music: 90,
//   },
//   {
//     id: 5,
//     name: "Michael Johnson",
//     physics: 80,
//     chemistry: 85,
//     math: 90,
//     history: 85,
//     biology: 92,
//     geography: 78,
//     english: 85,
//     art: 82,
//     music: 88,
//   },
//   {
//     id: 6,
//     name: "Emily Davis",
//     physics: 88,
//     chemistry: 90,
//     math: 85,
//     history: 92,
//     biology: 85,
//     geography: 90,
//     english: 88,
//     art: 90,
//     music: 82,
//   },
//   {
//     id: 7,
//     name: "David Wilson",
//     physics: 92,
//     chemistry: 80,
//     math: 75,
//     history: 90,
//     biology: 78,
//     geography: 82,
//     english: 85,
//     art: 80,
//     music: 85,
//   },
// ];

// const ResponsiveTable = ({ data }) => {
//   const subjects = [
//     "Physics",
//     "Chemistry",
//     "Math",
//     "History",
//     "Biology",
//     "Geography",
//     "English",
//     "Art",
//     "Music",
//   ];

//   return (
//     <div className="table-responsive">
//       <table className="custom-table">
//         <thead className="thead">
//           <tr>
//             <th>Sl. No</th>
//             <th>Student Name</th>
//             {subjects.map((subject) => (
//               <th key={subject}>{subject} (100)</th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((row, index) => (
//             <tr key={index}>
//               <td>{row.id}</td>
//               <td>{row.name}</td>
//               {subjects.map((subject) => (
//                 <td key={subject}>{row[subject.toLowerCase()]}</td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// const GradewiseList = () => {
//   return (
//     <div className="App">
//       <div className="d-flex mt-4 mb-2">
//         <h5 className="col-md -6">Grade Wise List</h5>
//         <Link to="/EditGradewiseList">
//           <a type="button" className="btn btn-edsys-primary btn1">
//             <div className="d-flex">
//             <PlusCircle className="me-2" />
//             <h6 style={{ marginTop: "2px" }}>
//             Edit Grade Wise List
//             </h6>
//             </div>
//           </a>
//         </Link>
//       </div>
//       <ResponsiveTable data={data} />
//     </div>
//   );
// };

// export default GradewiseList;

import React, { useEffect, useState } from "react";
import "./GradewiseList.css";
import { PlusCircle } from "react-feather";
import { Link } from "react-router-dom";
import CusGrade from "./CusGrade";
const StaicData = [
  {
    id: 1,
    name: "John Doe",
    physics: 85,
    chemistry: 90,
    math: 75,
    history: 95,
    biology: 80,
    geography: 85,
    english: 90,
    art: 85,
    music: 88,
    grade: "10A",
  },
  {
    id: 2,
    name: "Jane Smith",
    physics: 90,
    chemistry: 85,
    math: 80,
    history: 92,
    biology: 85,
    geography: 90,
    english: 88,
    art: 90,
    music: 82,
    grade: "10A",
  },
  {
    id: 3,
    name: "Bob Johnson",
    physics: 75,
    chemistry: 80,
    math: 85,
    history: 90,
    biology: 78,
    geography: 82,
    english: 85,
    art: 80,
    music: 85,
    grade: "10A",
  },
  {
    id: 4,
    name: "Alice Brown",
    physics: 95,
    chemistry: 92,
    math: 88,
    history: 89,
    biology: 90,
    geography: 95,
    english: 92,
    art: 88,
    music: 90,
    grade: "10A",
  },
  {
    id: 5,
    name: "Michael Johnson",
    physics: 80,
    chemistry: 85,
    math: 90,
    history: 85,
    biology: 92,
    geography: 78,
    english: 85,
    art: 82,
    music: 88,
    grade: "10A",
  },
  {
    id: 6,
    name: "Emily Davis",
    physics: 88,
    chemistry: 90,
    math: 85,
    history: 92,
    biology: 85,
    geography: 90,
    english: 88,
    art: 90,
    music: 82,
    grade: "10A",
  },
  {
    id: 7,
    name: "David Wilson",
    physics: 92,
    chemistry: 80,
    math: 75,
    history: 90,
    biology: 78,
    geography: 82,
    english: 85,
    art: 80,
    music: 85,
    grade: "10A",
  },
  {
    id: 1,
    name: "David Wilson",
    physics: 92,
    chemistry: 80,
    math: 75,
    history: 90,
    biology: 78,
    geography: 82,
    english: 85,
    art: 80,
    music: 85,
    grade: "10B",
  },
  {
    id: 2,
    name: "Michael Johnson",
    physics: 80,
    chemistry: 85,
    math: 90,
    history: 85,
    biology: 92,
    geography: 78,
    english: 85,
    art: 82,
    music: 88,
    grade: "10B",
  },
  {
    id: 1,
    name: "Bob Johnson",
    physics: 75,
    chemistry: 80,
    math: 85,
    history: 90,
    biology: 78,
    geography: 82,
    english: 85,
    art: 80,
    music: 85,
    grade: "10C",
  },
  {
    id: 2,
    name: "Jane Smith",
    physics: 90,
    chemistry: 85,
    math: 80,
    history: 92,
    biology: 85,
    geography: 90,
    english: 88,
    art: 90,
    music: 82,
    grade: "10C",
  },
  {
    id: 1,
    name: "Michael Johnson",
    physics: 80,
    chemistry: 85,
    math: 90,
    history: 85,
    biology: 92,
    geography: 78,
    english: 85,
    art: 82,
    music: 88,
    grade: "10D",
  },
  {
    id: 2,
    name: "David Wilson",
    physics: 92,
    chemistry: 80,
    math: 75,
    history: 90,
    biology: 78,
    geography: 82,
    english: 85,
    art: 80,
    music: 85,
    grade: "10D",
  },
];

const ResponsiveTable = ({ data, subjects }) => (
  <div className="table-responsive">
    <table className="custom-table">
      <thead className="thead">
        <tr>
          <th>Sl. No</th>
          <th>Student Name</th>
          {subjects.map((subject) => (
            <th key={subject}>{subject} (100)</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td>{row.id}</td>
            <td>{row.name}</td>
            {subjects.map((subject) => (
              <td key={subject}>{row[subject.toLowerCase()]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

const GradewiseList = () => {
  const [selectedGrade, setSelectedGrade] = useState(null);

  // Function to filter data based on selected grade
  const filterDataByGrade = () => {
    if (selectedGrade === null) {
      return []; // If no grade is selected, return an empty array
    } else {
      return StaicData.filter((item) => item.grade === selectedGrade);
    }
  };

  // Handler to select grade
  const selectGrade = (grade) => {
    setSelectedGrade(grade);
  };

  const subjects = [
    "Physics",
    "Chemistry",
    "Math",
    "History",
    "Biology",
    "Geography",
    "English",
    "Art",
    "Music",
  ];

  // Automatically select grade "10A" when component mounts
  useEffect(() => {
    selectGrade("10A");
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  return (
    <div className="card pl pr p-4">
      <div className="d-flex grade_div justify-content-between">
        <h4 className="col-md-6">Grade Wise List</h4>
        <Link to="/EditGradewiseList" className="link">
          <a type="button" className="btn btn-edsys-primary btn1">
            <div className="d-flex">
              <PlusCircle className="me-2" />
              <h6 style={{ marginTop: "2px" }}>Edit Grade Wise List</h6>
            </div>
          </a>
        </Link>
      </div>
      {/* <div className="">
        <label className="mt-2 ml-2">
          <input
            type="radio"
            value="10A"
            checked={selectedGrade === "10A"}
            onChange={() => selectGrade("10A")}
          />
          10A
        </label>
        <label className="mt-2 ml-2">
          <input
            type="radio"
            value="10B"
            checked={selectedGrade === "10B"}
            onChange={() => selectGrade("10B")}
          />
          10B
        </label>
        <label className="mt-2 ml-2">
          <input
            type="radio"
            value="10C"
            checked={selectedGrade === "10C"}
            onChange={() => selectGrade("10C")}
          />
          10C
        </label>
        <label className="mt-2 ml-2">
          <input
            type="radio"
            value="10D"
            checked={selectedGrade === "10D"}
            onChange={() => selectGrade("10D")}
          />
          10D
        </label>
      </div> */}
      <CusGrade />
      {selectedGrade && (
        <ResponsiveTable data={filterDataByGrade()} subjects={subjects} />
      )}
    </div>
  );
};

export default GradewiseList;
