import React, { useEffect, useState, ChangeEvent } from "react";

import add_btn from "../../../assets/images/Chat/add_btn.svg";
import audio_icon from "../../../assets/images/Chat/audio_icon.svg";
import chat_avatar_1 from "../../../assets/images/Chat/chat_avatar_1.svg";
import chat_avatar_2 from "../../../assets/images/Chat/chat_avatar_2.svg";
import chat_avatar_3 from "../../../assets/images/Chat/chat_avatar_3.svg";
import chat_avatar_4 from "../../../assets/images/Chat/chat_avatar_4.svg";
import chat_avatar_5 from "../../../assets/images/Chat/chat_avatar_5.svg";
import dropdown_icons from "../../../assets/images/Chat/dropdown_icons.svg";
import img_icon from "../../../assets/images/Chat/img_icon.svg";
import pdf_icon from "../../../assets/images/Chat/pdf_icon.svg";
import search_icon from "../../../assets/images/Chat/search_icon.svg";
import send_btn_icon from "../../../assets/images/Chat/send_btn_icon.svg";
import video_icon from "../../../assets/images/Chat/video_icon.svg";
import { FaSearch } from "react-icons/fa";

import send from "../../../assets/images/Chat/send.svg";
import { PlusCircle } from "react-feather";

import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import Nav from "react-bootstrap/Nav";

import Tab from "react-bootstrap/Tab";
import Dropdown from "react-bootstrap/Dropdown";

import ChatModel from "./ChatModel";

// import "../../../assets/css/SmsDetails"

const chatData = [
  {
    id: 0,
    avatar: chat_avatar_1,
    name: "Jasmin Doe",
    status: "status-green",
    subject: "Subject",
    count: "11/1",
  },
  {
    id: 1,
    avatar: chat_avatar_2,
    name: "Julia Damn",
    status: "status-red",
    subject: "Subject",
    count: "11/1",
  },
  {
    id: 2,
    avatar: chat_avatar_3,
    name: "John Collins",
    status: "status-yellow",
    subject: "Subject",
    count: "11/1",
  },
  {
    id: 3,
    avatar: chat_avatar_4,
    name: "Siera Dash",
    status: "status-yellow",
    subject: "Subject",
    count: "11/1",
  },
  // {
  //   id: 4,
  //   avatar: chat_avatar_5,
  //   name: "Sammer Doe",
  //   status: "status-green",
  //   subject: "Subject",
  //   count: "11/1",
  // },
];

function Chat() {
  const [isTodoModelShow, setIsTodoModelShow] = useState(false);

  const openCloseTodoModel = () => {
    // setInitialState();
    setIsTodoModelShow(!isTodoModelShow);
  };

  return (
    <div className="chat announcementnew assignment-sec">
      <div className="main-section">
        <div className="row">
          <div className="col-md-12">
            <div className=" ">
              <div className="d-flex">
                <h3 className="flex-grow-1 text-dark">Chat</h3>
                <a
                  type="button"
                  data-bs-toggle="modal"
                  onClick={openCloseTodoModel}
                  data-bs-target="#exampleModal"
                  className="btn btn-edsys-primary btn1"
                >
                  <PlusCircle className="me-2" />
                  <span>ContractList</span>
                </a>
                {/* Modal start */}
                {isTodoModelShow ? (
                  <ChatModel onClose={openCloseTodoModel} />
                ) : null}
                {/* modal End */}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="chat-wrapper">
              <Tab.Container id="left-tabs-example" defaultActiveKey="0">
                <div className="row">
                  <div className="col-md-4">
                    <div className="chat-list-section">
                      <div className="input-container">
                        <input
                          className="form-control"
                          type="search"
                          placeholder="Search"
                          aria-label="Search"
                          style={{ width: "100%" }}
                        />
                        <FaSearch
                          className="search-icon"
                          style={{ marginLeft: 3, color: "#949FA6" }}
                        />
                      </div>
                      <p className="chat-heading mt-2">Messages</p>
                      <Nav variant="pills" className="flex-column">
                        {chatData.map((chatItem) => (
                          <Nav.Item key={chatItem.id}>
                            <Nav.Link eventKey={chatItem.id.toString()}>
                              <div className="row g-0">
                                <div className="col-md-2 col-sm-1 col-1">
                                  <div className="chat-item-img-box">
                                    <img src={chatItem.avatar} alt="" />
                                    <span className={chatItem.status}></span>
                                  </div>
                                </div>
                                <div className="col-md-10 col-sm-11 col-11">
                                  <div className="row g-0">
                                    <div className="col-md-10 col-sm-10 col-10">
                                      <div className="chat-item-text-box">
                                        <p className="ci-main-text">
                                          {chatItem.name}
                                        </p>
                                        <p className="ci-sub-text">
                                          {chatItem.subject}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-2 col-sm-2 col-2">
                                      <div className="chat-item-count-box">
                                        {chatItem.count}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="chat-detail-section">
                      <Tab.Content>
                        <Tab.Pane eventKey="0">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_1} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">Jasmine Doe</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_1} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between teacherchat">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="1">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_2} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">Julia Damn</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_2} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_2} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_2} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between teacherchat">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="2">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_3} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">John Collins</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_3} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_3} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_3} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between teacherchat">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send} alt="" />
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="3">
                          <div className="chat-header">
                            <div className="row g-0">
                              <div className="col-md-1 col-sm-1 col-1">
                                <div className="chat-img-box">
                                  <img src={chat_avatar_4} alt="" />
                                  <span className="status-green"></span>
                                </div>
                              </div>
                              <div className="col-md-11 col-sm-11 col-11">
                                <div className="row">
                                  <div className="col-md-11 col-sm-11 col-10">
                                    <div className="chat-user-section">
                                      <p className="user-name">Siera Dash</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-2">
                                    <div className="chat-dropdown-wrap">
                                      <Dropdown className="Common_dropDown">
                                        <Dropdown.Toggle
                                          className="dropdown-btn"
                                          id="dropdown-basic"
                                        >
                                          <img src={dropdown_icons} alt="" />
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item>
                                            <img src={img_icon} alt="" />
                                            Image
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={video_icon} alt="" />
                                            Video
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={pdf_icon} alt="" />
                                            Attachment
                                          </Dropdown.Item>
                                          <Dropdown.Item>
                                            <img src={audio_icon} alt="" />
                                            Audio
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="chat-main-content">
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_4} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="sender">
                              <div className="row">
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="sender-img-box">
                                    <img src={chat_avatar_5} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                                <div className="col-md-11 col-sm-11 col-11">
                                  <div className="sender-chat">
                                    <p>
                                      Hello! Finally found the time to write to
                                      you. I need your help in creating
                                      interactive animations for my mobile
                                      application.
                                    </p>
                                  </div>
                                  <div className="sender-chat">
                                    <p>Can I send you files?</p>
                                  </div>
                                  <div className="sc-time-stamp">2:27 Pm</div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_4} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="reciever">
                              <div className="row g-0">
                                <div className="col-md-11 col-sm-11 col-11 d-flex justify-content-end">
                                  <div className="reciever-chat">
                                    <p>Hey! Okay, send out.</p>
                                  </div>
                                  <div className="rc-time-stamp">2:27 Pm</div>
                                </div>
                                <div className="col-md-1 col-sm-1 col-1">
                                  <div className="reciever-img-box">
                                    <img src={chat_avatar_4} alt="" />
                                    <span className="status-green"></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between teacherchat">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send} alt="" />
                            </div>
                          </div>
                          {/* <div className="chat-input-wrapper">
                            <Form.Control
                              type="text"
                              placeholder="Type a message here..."
                            />
                            <div className="send-btn">
                              <img src={send_btn_icon} alt="" />
                            </div>
                          </div> */}
                        </Tab.Pane>
                       
                      </Tab.Content>
                    </div>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chat;
