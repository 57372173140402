import React, { useState, useEffect } from "react";
import AssignmentCardData from "../../api/assignment_card_data.json";
import subjecticon from "../../assets/images/assignment/subtopicicon.svg";
import { Link } from "react-router-dom";
import { TabPanel, TabView } from "primereact/tabview";
import AssignmentModal from "../academics/Assignment/AssignmentModal";
import { Edit3, Trash2, PlusCircle, BookOpen } from "react-feather";
import { Modal, Button } from "react-bootstrap";
import { RiDeleteBin6Fill } from "react-icons/ri";
import "../Wallet/tabstyles.css";
const OfflineAssesment = () => {
  const [isTodoModelShow, setTodoModelShow] = useState(false);
  const [isTagModelShow, setTagModelShow] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    document.title = "Edsysy | Assignment";
  }, []);

  const setInitialState = () => {
    setTodoModelShow(false);
    setTagModelShow(false);
  };

  const openCloseTodoModel = () => {
    setInitialState();
    setTodoModelShow(!isTodoModelShow);
  };

  const openCloseTagModel = () => {
    setInitialState();
    setTagModelShow(!isTagModelShow);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const tabs = ["Pending", "Upcoming", "History"];

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleClick = (tab) => {
    setActiveTab(tab);
  };
  const gradeOptions = ["6A", "6B", "6C", "6D", "6F"];

  // Create state to hold the selected grade
  const [selectedGrade, setSelectedGrade] = useState("");

  // Handle change in selected grade
  const handleGradeChange = (event) => {
    setSelectedGrade(event.target.value);
  };

  return (
    <>
      <div className="library announcementnew">
        <div className="row assignment-sec">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header pb-0">
                <div className="d-flex">
                  <h5 className="col-md -6">Offline Assesment</h5>
                  <Link to="/GradewiseList">
                    <a type="button" className="btn btn-edsys-primary btn1">
                      <BookOpen className="me-2" />
                      <span style={{ marginTop: "20px" }}>
                        Grade Wise MarkList
                      </span>
                    </a>
                  </Link>
                  <Link to="/SubjectwiseList">
                    <a
                      type="button"
                      className="btn btn-edsys-primary btn1"
                      style={{ marginLeft: "10px" }}
                    >
                      <BookOpen className="me-2" />
                      <span style={{ marginTop: "20px" }}>
                        Subject Wise MarkList
                      </span>
                    </a>
                  </Link>
                </div>
              </div>

              <div className="row" style={{}}>
                <div className="col-md-12">
                  <div className="books-main-wrapper p-4">
                    <div className="offline_border"></div>
                    <div>
                      <div className="assessment_topdiv">
                        <div className="col-md-12 row  headoffline">
                          <div className="col-md-3 inputchoose">
                            <div className="Term">Accademic Year</div>
                            <div className="TermHead"> 2022-23</div>
                          </div>
                          <div className="col-md-2 inputchoose">
                            <div className="Term"> Term Selected</div>
                            <div className="TermHead">Term</div>
                          </div>

                       
                          <div className="col-md-3 inputchoose">
                            <div className="Term">Select Grade</div>
                            <input
                              type="text"
                              list="grades"
                              value={selectedGrade}
                              onChange={handleGradeChange}
                              className="TermHead col-md-12 placeholder-red"
                              id="exampleDataList"
                              placeholder="6A"
                              style={{ color: "#772569" }}
                            />
                            <datalist id="grades">
                              {gradeOptions.map((grade, index) => (
                                <option key={index} value={grade}>
                                  {grade}
                                </option>
                              ))}
                            </datalist>
                          </div>
                        </div>
                      </div>
                      <div className="offline_border"></div>
                      <div className="my-books">
                        <div className="row">
                          {AssignmentCardData.map((postDetail, index) => (
                            <div className="col-md-4" key={index}>
                              <div className={postDetail.card_bgcolor}>
                                <div className="card-header">
                                  <div className="d-flex">
                                    <img
                                      className="flex-grow-0"
                                      src={subjecticon}
                                      alt=""
                                    />
                                    <h6 className="flex-grow-1">
                                      {postDetail.subject}
                                    </h6>
                                    <div className="class">
                                      {postDetail.division_grade}
                                    </div>
                                  </div>
                                </div>
                                <div
                                  // onClick={() =>
                                  //   (window.location.href =
                                  //     "assignment-detail.html")
                                  // }
                                  className="card-body"
                                >
                                  <Link to="/ViewOfflineAssesment">
                                    Offline Assesment Title
                                  </Link>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="card-footer">
                                    <div className="d-sm-flex">
                                      <h5>12-02-2024</h5>
                                    </div>
                                  </div>
                                  <div className="card-footer">
                                    <div className="d-sm-flex">
                                      <div onClick={handleShow} className="Dur">
                                        1 hr
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="card-footer">
                                  <div className="d-sm-flex">
                                    <h5>{postDetail.date}</h5>

                                    <div className="action-list">
                                      <ul className="list-unstyled">
                                        <li>
                                          <div
                                            style={{
                                              background: "white",
                                              padding: "3px",
                                              borderRadius: "5px",
                                            }}
                                            onClick={openCloseTodoModel}
                                            className="setting-secondary"
                                          >
                                            <p
                                              style={{
                                                color: "#78266a",
                                                paddingLeft: "5px",
                                                paddingRight: "5px",
                                              }}
                                            >
                                              Duration : 1hr
                                            </p>
                                            <Edit3 />
                                          </div>
                                        </li>
                                        <li>
                                          <div
                                            onClick={handleShow}
                                            className="setting-secondary"
                                          >
                                            <Trash2 />
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div> */}
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  <Modal show={show} onHide={handleClose} centered>
                    <Modal.Header closeButton>
                      <Modal.Title id="contained-modal-title-vcenter border-bottom">
                        Delete
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="delete-text-wrapper">
                        Are you sure you want to delete ?
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="delete-btn-wrapper border-top w-100">
                        <button onClick={handleClose} className="dlt-btn-1">
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="dlt-btn-2"
                          value="delete"
                        >
                          Delete
                        </button>
                      </div>
                    </Modal.Footer>
                  </Modal>
                  {/* <Modal
        // show={modalShow2}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter border-bottom">
            Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="delete-text-wrapper">
            Are you sure you want to delete ?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="delete-btn-wrapper border-top w-100">
            <button onClick={() => setModalShow2(false)} className="dlt-btn-1">
              Cancel
            </button>
            <button type="submit" className="dlt-btn-2" value="delete">
              Delete
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfflineAssesment;
