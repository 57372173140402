import React, { useState } from "react";


import "../../../src/assets/css/OfflineAssesment.css";
import dashboardAvatar from "../../assets/images/dashboard/1.png";
import StudentCusDroprow from "./StudentCusDroprow";


function EditSubjectwiseList() {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [students, setStudents] = useState([
    { id: 1, name: "Student 1", marks: "80" },
    { id: 2, name: "Student 2", marks: "45" },
    { id: 3, name: "Student 3", marks: "90" },
    { id: 4, name: "Student 4", marks: "55" },
    { id: 5, name: "Student 5", marks: "79" },
    { id: 6, name: "Student 6", marks: "98" },
    { id: 7, name: "Student 7", marks: "86" },
    { id: 8, name: "Student 8", marks: "69" },
    { id: 9, name: "Student 9", marks: "46" },
    { id: 10, name: "Student 10", marks: "95" },
    { id: 11, name: "Student 11", marks: "65" },
    { id: 12, name: "Student 12", marks: "59" },
  ]);
  const handleMarksChange = (id, marks) => {
    // Update marks for the corresponding student
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === id ? { ...student, marks } : student
      )
    );
  };

  return (
    <div>
      {/* <!-- Container-fluid starts--> */}
      <div className="row offlineassesment">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header pb-0">
              <div className="d-flex">
                <h5 className="flex-grow-1">Edit Subject wise List</h5>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <StudentCusDroprow />
             
              </div>
            </div>
            <div className="d-flex">
              <h5 className="flex-grow-1 ml-30">Mark List</h5>
            </div>
            <div className="table">
              <div className="thead">
                <div className="tr tableheadstyle">
                  <div className="thsl">Sl. No</div>
                
                  <div className="thsn">Student Name</div>
                  <div className="thm">Marks</div>
                  <div className="thm"> Total Marks</div>
                </div>
              </div>
              <div className="tbody">
                {students.map((student, index) => (
                  <div className="tr" key={student.id}>
                    <div className="tdsl">{index + 1}</div>
                    {/* <div className="tdI"></div> */}
                    <div className="tdsn">
                      {" "}
                      <img
                        className="rounded-circle"
                        src={dashboardAvatar}
                        alt="image"
                        style={{
                          height: "25px",
                          width: "25x",
                          marginRight: "10px",
                        }}
                      />
                      {student.name}
                    </div>
                    <div className="edittdm">
                      <input
                        type="number"
                        min="0"
                        max="3"
                        placeholder="Enter marks"
                        // value={student.marks}
                        style={{ border: "none", width: "120px" }}
                        onChange={(e) =>
                          handleMarksChange(student.id, e.target.value)
                        }
                      />
                      {/* {student.marks} */}
                    </div>
                    <div className="tdm">100</div>
                  </div>
                ))}
              </div>
         
            </div>
            <button
        className="view-mark-list-button1 "
     
      >
        Submit
      </button>
          </div>
        </div>
        
      </div>
      {/* <!-- Container-fluid Ends--> */}
      
    </div>
  );
}

export default EditSubjectwiseList;
