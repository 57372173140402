import React,{useEffect} from 'react'
import { Camera } from 'react-feather'
import { Link } from 'react-router-dom'
import UserPic from '../../assets/images/dashboard/1.png'

 function EditProfile() {
  useEffect(() => {
    document.title = "Edsys | Edit Profile "
  }, [])
    return (
        <div class="page-body">
        {/* <!-- Container-fluid starts--> */}
        <div class="row user-profile">
            <div class="col-sm-12">
                <div class="edit-pro my-profile-header">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <div class="profile-pic-wrapper">
                                <div class="pic-holder">
                                {/* <!-- uploaded pic shown here --> */}
                                <img id="profilePic" class="pic" src={UserPic   } />
                            
                                <label for="newProfilePhoto" class="upload-file-block">
                                <div class="text-center">
                                    <div class="mb-2">
                                    <Camera class="fa fa-camera fa-2x" />
                                    </div>
                                    <div class="text-uppercase">
                                    Update <br /> Profile Photo
                                    </div>
                                </div>
                                </label>
                                <input class="uploadProfileInput" type="file" name="profile_pic" id="newProfilePhoto" accept="image/*" style={{display: "none" }}/>
                            </div>
                          </div>
                        </div>
                        <div class="col ml-md-n2 profile-user-info">
                            <h4 class="first-name mb-0">Mary</h4>
                            <h4 class="sur-name mb-0">Alexander</h4>
                            <div class="user-Location"><i class="fas fa-map-marker-alt"></i> Cochin, Kerala</div>
                            <div class="about-text">Lorem ipsum dolor sit amet.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12">
                <form class="card mt-3">
                    <div class="card-header pb-0">
                      <h4 class="card-title mb-0">Edit Profile</h4>
                      <div class="card-options"><a class="card-options-collapse" href="#" data-bs-toggle="card-collapse"><i class="fe fe-chevron-up"></i></a><a class="card-options-remove" href="#" data-bs-toggle="card-remove"><i class="fe fe-x"></i></a></div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <div class="mb-3">
                            <label class="form-label">First Name</label>
                            <input class="form-control" type="text" placeholder="First Name"/>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <div class="mb-3">
                            <label class="form-label">Last Name</label>
                            <input class="form-control" type="text" placeholder="Last Name"/>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="task-title">Date of Birth</label>  
                            <input class="datepicker-here form-control digits" type="date" data-language="en" placeholder="MM-DD-YYYY" data-position="top left"/>
                          </div>
                        <div class="form-group col-md-6">
                            <label for="task-title">Gender</label>  
                            <select class="js-example-basic-single form-select">
                              <option value="task">Male</option>
                              <option value="task">Female</option>
                            </select>
                        </div>
                        <div class="form-group col-md-6">
                            <div class="mb-3">
                              <label class="form-label">Email</label>
                              <input class="form-control" type="email" placeholder="Email"/>
                            </div>
                          </div>
                          <div class="form-group col-md-6">
                            <div class="mb-3">
                              <label class="form-label">Contact Number</label>
                              <input class="form-control" type="text" placeholder=""/>
                            </div>
                          </div>  
                        <div class="form-group col-md-12">
                          <div class="mb-3">
                            <label class="form-label">Address</label>
                            <input class="form-control" type="text" placeholder="Home Address"/>
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                          <div class="mb-3">
                            <label class="form-label">City</label>
                            <input class="form-control" type="text" placeholder="City"/>
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                          <div class="mb-3">
                            <label class="form-label">Postal Code</label>
                            <input class="form-control" type="number" placeholder="ZIP Code"/>
                          </div>
                        </div>
                        <div class="form-group col-md-4">
                            <label for="task-title">Nationality</label>  
                            <select class="js-example-basic-single form-select">
                                <option value="0">--Select--</option>
                                <option value="1">Germany</option>
                                <option value="2">Canada</option>
                                <option value="3">Usa</option>
                                <option value="4">Aus</option>
                            </select>
                        </div>
                        <div class="form-group col-md-12">
                          <div>
                            <label class="form-label">Short Bio</label>
                            <textarea class="form-control" rows="3" placeholder="Enter About your description"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer text-end">
                        <Link to="/UserProfile">
                      <a class="btn btn-primary" >Update Profile</a>
                      </Link>
                    </div>
                  </form>
            </div>
        </div>
          {/* <!-- Container-fluid Ends--> */}
      </div>
    )
}
export default EditProfile  
